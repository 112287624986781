import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { defined } from 'lib/helpers';
import { Page } from 'components/page';
import NavbarContainer from 'containers/navbar';
import V2PageContent from 'components/v2/page-content';
import SidenavContainer from 'component-lib/components/sidenav-container';
import Loading from 'components/loading';
// TODO: Remove commented-out Guide & Tour after the entire infrastructure of Tour Guide is removed
// import Guide from 'components/guide';
// import Tour from 'components/tour';
import Notifications from 'components/notifications/notifications';
import { ModalConductor } from 'components/v2/modal-conductor';
import SnackBar from 'components/v2/snack-bar';

class AppContainer extends React.Component {
  constructor(props) {
    const { appv2 } = props;

    super();

    this._snackBarHidingTimeouts = [];

    this.state = {
      currentModalComponent: null,
      currentModalProps: null,
      currentSnackBarStyle: null,
      currentSnackBarText: null,
    };

    appv2.ui.setModalHandlers({
      open: ({ modalComponent, modalProps }) => {
        this.setState({
          currentModalComponent: defined(modalComponent),
          currentModalProps: defined(modalProps),
        });
      },
      close: () => {
        this.setState({
          currentModalComponent: null,
          currentModalProps: null,
        });
      },
    });

    const SNACKBAR_ACTIVE_CLASS = 'v2-snack-bar--is-active';

    appv2.ui.setSnackBarHandlers({
      show: ({ style, text }) => {
        // remove all the previous hiding timeouts
        this._snackBarHidingTimeouts.map((tout) => {
          clearTimeout(tout);
        });

        this._snackBarHidingTimeouts = [];

        this.setState({
          currentSnackBarStyle: defined(style),
          currentSnackBarText: defined(text),
        });

        // adding the class in a separate loop cycle, to let the component be rerendered before that
        setTimeout(() => {
          this._snackBarDiv && this._snackBarDiv.classList.add(SNACKBAR_ACTIVE_CLASS);
        }, 10);
      },
      hide: () => {
        if (this._snackBarDiv) {
          this._snackBarDiv.classList.remove(SNACKBAR_ACTIVE_CLASS);
        }

        this._snackBarHidingTimeouts.push(setTimeout(() => {
          this.setState({
            currentSnackBarStyle: null,
            currentSnackBarText: null,
          });
        }, 5000)); // actual number doesn't matter, it's called just to clean up the data
      },
    });
  }

  render() {
    const {
      appv2,
      isSidenavOpen,
      pageId,
      children,
      routes,
      params,
      isFetching,
      location: { query },
    } = this.props;

    const {
      currentModalComponent,
      currentModalProps,
      currentSnackBarStyle,
      currentSnackBarText,
    } = this.state;

    if (isFetching) return <Loading fullscreen />;

    const className = classNames(
      `page--${pageId}`,
      { 'has--overlay': isSidenavOpen },
      { [`is--${query.state}`]: query.state },
    );

    return (
      <Page className={className}>
        <SidenavContainer
          appv2={appv2}
        />
        <NavbarContainer
          appv2={appv2}
          routes={routes}
          params={params}
        />
        <V2PageContent isFull>
          { React.cloneElement(children, { appv2 }) }
        </V2PageContent>
        {/* <Tour /> */}
        <Notifications />
        {/* <Guide /> */}

        <ModalConductor
          appv2={appv2}
          currentModalComponent={currentModalComponent}
          currentModalProps={currentModalProps}
        />

        <SnackBar
          style={currentSnackBarStyle}
          text={currentSnackBarText}
          storeDivRef={(elem) => { this._snackBarDiv = elem; }}
        />
      </Page>
    );
  }
}

export default connect((state) => ({
  pageId: state.ui.pageId,
  isSidenavOpen: state.ui.isSidenavOpen,
  isFetching: state.api.fetching.indexOf('app') > -1,
}))(AppContainer);
