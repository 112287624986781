import './text.scss';

import React from 'react';
import classnames from 'classnames';

const Text = ({ children, isSmall }) => (
  <p className = { classnames('text', {
    'is--small': isSmall
  })} >
    { children }
  </p>
);

export default Text;
