import React from 'react';
import CardExtension from 'components/card-extension';

import './card.scss';

export default ({
  children,
  title,
  description,
  subtitle,
  className,
  isLoading,
  isCentered,
  extensionComponent,
  collapseLabelText,
  expandLabelText,
  hasOneColumn,
  hasActions,
}) => (
  <div
    className={
      'card' +
      `${className ? ' ' + className : ''}` +
      `${isCentered ? ' is--centered' : ''}` +
      `${isLoading ? ' is--loading' : ''}` +
      `${extensionComponent ? ' has--extension' : ''}` +
      `${hasActions ? ' has--actions' : ''}` +
      `${hasOneColumn ? ' has--one-column' : ''}`
    }
  >
    { title &&
      <h3 className="card__title">
        { title }
        { subtitle && <small>{ subtitle }</small>}
      </h3>
    }
    { description &&
      <h3 className="card__description">
        { description }
        { subtitle && <small>{ subtitle }</small>}
      </h3>
    }
    { children }
    { extensionComponent &&
      <CardExtension
        collapseLabelText={collapseLabelText}
        expandLabelText={expandLabelText}
      >
        { extensionComponent }
      </CardExtension>
    }
  </div>
);
