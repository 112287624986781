import React               from 'react';
import { connect }         from 'react-redux';
import { PAGE_PANELS }     from 'config/panels';
import Panel               from 'components/panel';
import { profileSelector } from 'selectors/index';

import {
  TransitionMotion,
  spring
} from 'react-motion';

import {
  updateSettings,
  startTour,
  votePanel
} from 'modules/ui';

const getStyles = (isOpen) => () => (
  isOpen
    ? [{ key: 'panel__transition', style: { opacity: spring(1), maxHeight: 1000 } }]
    : []
);

class PagePanels extends React.Component {
  UNSAFE_componentWillUpdate(nextProps) {
    const {
      dismissed_panels, pageId
    } = this.props;

    const panelProps = PAGE_PANELS[pageId];
    const hasChanged = !_.isEqual(nextProps.dismissed_panels, dismissed_panels);

    const isDismissed = panelProps && nextProps.dismissed_panels.indexOf(panelProps.id) > -1;
    this.shouldLeave = isDismissed && hasChanged;
  }

  render() {
    const {
      profile, dismissed_panels,
      voted, pageId,
      updateSettings, voteClick, startTour,
      isLoading
    } = this.props;

    const panelProps = PAGE_PANELS[pageId];

    if (!panelProps) {
      return null;
    }

    const isDismissed = dismissed_panels.indexOf(panelProps.id) > -1;

    if (isLoading || (isDismissed && !this.shouldLeave)) {
      return null;
    }

    const panelItemProps = {
      icon:                pageId,
      vote:                voted[panelProps.id],
      onClose:             updateSettings({ dismissed_panels: _.union(dismissed_panels, [panelProps.id])}),
      onVoteClick:         voteClick(panelProps.id),
      onActionButtonClick: panelProps.tour_id && startTour(panelProps.tour_id),
      profile,
      ...panelProps
    };

    return (
      <TransitionMotion
        willEnter = { () => ({ opacity: 0,         maxHeight: 0 }) }
        willLeave = { () => ({ opacity: spring(0), maxHeight: spring(0) }) }
        styles    = { getStyles(!this.shouldLeave) }
      >
        { styles => <div> { styles.map(item =>
          <div
            key       = "panel__transition"
            className = "panel__transition"
            style     = { {
              opacity:   item.style.opacity,
              maxHeight: item.style.maxHeight
            } }
          >
            <Panel { ...panelItemProps } />
          </div>
          )}
        </div> }
      </TransitionMotion>
    );
  }
}

export default connect(
  state => ({
    dismissed_panels: state.ui.dismissed_panels,
    hidden_panels:    state.ui.hidden_panels,
    voted:            state.ui.voted,
    pageId:           state.ui.pageId,
    profile:          profileSelector(state)
  }),
  dispatch => ({
    updateSettings: (json) => ()    => dispatch(updateSettings({ json })),
    startTour:  (id) => ()          => dispatch(startTour({ id })),
    voteClick:  (id) => value => () => dispatch(votePanel({ id, value })),
  })
)(PagePanels);
