import './share.scss';

import React   from 'react';
import config  from 'config';
import SvgIcon from 'components/svg-icon';
import Text    from 'components/input-text';

const {
  PADDL_URL,
  FACEBOOK_APP_ID
} = config;

const ENDPOINTS = {
  FACEBOOK: 'https://www.facebook.com/dialog/feed',
  LINKEDIN: 'https://www.linkedin.com/shareArticle',
  TWITTER:  'https://twitter.com/intent/tweet',
  EMAIL:    'mailto:'
};

const ITEMS_MAP = {
  facebook: {
    name: 'Facebook',
    getUrl: (url) => `${ENDPOINTS.FACEBOOK}?app_id=${FACEBOOK_APP_ID}&display=page&link=${url}&redirect_uri=${url}`,
    target: '_blank'
  },
  linkedin: {
    name: 'LinkedIn',
    getUrl: (url, title) => `${ENDPOINTS.LINKEDIN}?mini=true&url=${url}&title=${title}&summary=Check%20the%20following%20URL%20${url}&source=Paddljobs`,
    target: '_blank'
  },
  twitter: {
    name: 'Twitter',
    getUrl: (url, title) => `${ENDPOINTS.TWITTER}?text=${title}%20${url}&via=PaddlJobs`,
    target: '_blank'
  },
  email: {
    name: 'Mail',
    getUrl: (url, title) => `${ENDPOINTS.EMAIL}?body=${url}&subject=${title}`,
    target: '_blank'
  }
};

const Share = ({
  title,
  subtitle,
  url,
  job,
  itemTitle,
  handleCopy,
  isCopied,
  items,
  setRef,
  onShare
}) => {
  if (!items) return null;

  return (
    <div className = "share">
      { title &&
        <h3 className = "share__title">
          { title }
        </h3>
      }
      { subtitle &&
        <p className = "share__subtitle">
          { subtitle }
        </p>
      }
      <div className = "share__wrapper">
        { items.map(share_type =>
          <div className = "share__button" key = { share_type }>
            <a
              href    = { ITEMS_MAP[share_type].getUrl(`${PADDL_URL}/${url}`, itemTitle) }
              target  = { ITEMS_MAP[share_type].target }
              rel     = "noopener noreferrer"
              onClick = { () => onShare(job, ITEMS_MAP[share_type].name) }
            >
              <SvgIcon id = { `${share_type}-circle` } />
            </a>
          </div>
        )}
      </div>
      { handleCopy && [
        <Text
          type         = "text"
          name         = "share"
          style        = { { width: '100%', textAlign: 'center' } }
          defaultValue = { `${PADDL_URL}/${url}` }
          setRef       = { setRef }
          onFocus      = { e => e.target.select() }
          readOnly
        />,
        <a
          className = "share__link"
          onClick   = { () => { handleCopy(); onShare(job, 'Link') } }
        >
          { isCopied ? 'Copied!' : 'Click to copy' }
        </a>
      ]}
    </div>
  );
};

export default Share;
