import './form-panel.scss';

import React     from 'react';
import FormCard  from 'components/form-card';

export default ({
  icon,
  title,
  description,
  ...rest
}) => (
  <div className = "form__panel">
    <div className = "form__panel__content">
      <div className = "form__panel__icon">
        { icon && <span className = { `icon icon-${icon}` } /> }
      </div>
      <h3 className = "form__panel__title">
        { title }
      </h3>
      <div className = "form__panel__description">
        { description }
      </div>
    </div>
    <FormCard { ...rest } />
  </div>
);
