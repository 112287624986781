import './index.scss';
import React from 'react';

import BasecampProgressBarTexts from 'components/v2/basecamp/basecamp-progress-bar-texts';
import BasecampProgressBar      from 'components/v2/basecamp/basecamp-progress-bar';

const BasecampCommittedPerWeek = ({ committedHoursPerWeek }) => {
  const committedHoursThresholds = [0, 20, 40, 60, 80];

  const getCommittedPerWeekThresholdDetails = () => {
    if (committedHoursPerWeek > committedHoursThresholds[4]) {
      return {
        'color': 'blue',
        'title': 'Extreme',
        'width': '100%',
      };
    }

    if (committedHoursPerWeek > committedHoursThresholds[3]) {
      return {
        'color': 'blue',
        'title': 'High',
        'width': '80%',
      };
    }

    if (committedHoursPerWeek > committedHoursThresholds[2]) {
      return {
        'color': 'blue',
        'title': 'Solid',
        'width': '60%',
      };
    }

    if (committedHoursPerWeek > committedHoursThresholds[1]) {
      return {
        'color': 'blue',
        'title': 'Average',
        'width': '40%',
      };
    }

    if (committedHoursPerWeek > committedHoursThresholds[0]) {
      return {
        'color': 'blue',
        'title': 'Low',
        'width': '20%',
      };
    }

    if (committedHoursPerWeek === committedHoursThresholds[0]) {
      return {
        'color': 'bluegrey',
        'title': 'No Active Cards',
        'width': '0%',
      };
    }

    if (committedHoursPerWeek === null) {
      return {
        'color': 'bluegrey',
        'title': 'No Active Cards',
        'width': '0%',
      };
    }

    throw Error('Unexpected committedHoursPerWeek');
  };

  const details = getCommittedPerWeekThresholdDetails();

  return (
    <div className = "basecamp-committed-per-week">
      <BasecampProgressBarTexts
        statsValue = { committedHoursPerWeek }
        unit = 'hrs'
        subtitle = 'Committed p/week'
      />
      <BasecampProgressBar
        color = { details.color }
        width = { details.width }
        title = { details.title }
      />
    </div>
  );
};

export default BasecampCommittedPerWeek;
