import './input-checkbox.scss';

import React from 'react';

export default ({
  addon,
  options,
  attrs,
  className,
  children,
  ...props
}) => (
  <div
    className = { `input__checkbox ${ props.value ? 'is--checked' : ''}`}
  >
    <input
      checked = { props.value ? ' checked' : ''}
      id = { props.name }
      { ...props }  />
    <label htmlFor = { props.name } >
      { children }
    </label>
  </div>
)
