import React from 'react';

export const SvgIconComment = () => (
  <svg
    width = { 48 }
    height = { 48 }
    viewBox = "0 0 48 48"
    fill = "currentColor"
  >
    <path d="M41.0905 3.78955H17.1675C15.3819 3.78955 13.9212 5.25052 13.9212 7.03587L13.9028 17.6843H7.20854C5.32316 17.6843 3.78943 19.218 3.78943 21.1034V36.0738C3.78943 37.9592 5.69351 39.158 7.5789 39.158V44.2106L17.6842 39.158H30.3137C32.1991 39.158 34.1032 38.3559 34.1032 36.6317V27.1885L40.3579 30.3159V26.271V25.0106C42.1435 25.0106 44.3368 24.1436 44.3368 22.358V7.03587C44.3368 5.25052 42.8758 3.78955 41.0905 3.78955ZM32.8411 21.1817C32.8405 21.2785 32.84 21.3757 32.84 21.4738V36.6317C32.84 37.552 31.2904 37.8948 30.3137 37.8948H17.6842H17.3861L17.1193 38.0282L8.84206 42.1668V39.158V37.8948H7.5789C6.0184 37.8948 5.05259 36.9495 5.05259 36.0738V21.1034C5.05259 19.9145 6.01966 18.9474 7.20854 18.9474H30.2506C31.85 18.9474 32.3901 19.2504 32.5697 19.4307C32.8489 19.7112 32.8453 20.3928 32.8411 21.1817ZM43.0736 22.358C43.0736 23.1768 41.6425 23.7474 40.3579 23.7474H39.0947V25.0106V26.271V28.2721L34.1032 25.7763V21.4738C34.1032 19.4843 34.3179 17.6843 30.2506 17.6843H15.1659L15.1844 7.03587C15.1844 5.94222 16.0741 5.05271 17.1675 5.05271H41.0905C42.1841 5.05271 43.0736 5.94248 43.0736 7.03587V22.358Z" />
    <path d="M27.7895 24.6316H8.84216V25.8947H27.7895V24.6316Z" />
    <path d="M18.9474 28.4211H8.84216V29.6843H18.9474V28.4211Z" />
    <path d="M39.1578 9.47363H18.9473V10.7368H39.1578V9.47363Z" />
    <path d="M33.4587 13.2632H18.9473V14.5263H33.4587V13.2632Z" />
  </svg>
);
