import requireAuth from './require-auth';

export default store => ({
  indexRoute: {
    title: 'Applications',
    isIndex: true,
    getComponent: (location, cb) => {
      const { role } = store.getState().auth;

      if (role) {
        cb(null, require(`pages/applications/${role}/index`).default);
      }
    }
  },
  path: 'applications',
  childRoutes: [
    {
      path: 'jobs/:id',
      link: '/applications',
      title: ({ id }) => {
        const state = store.getState();

        return state.jobs.entities[id].title;
      },
      subTitle: 'Back To Applications',
      formId: 'APPLICATIONS-LIST',
      onEnter: requireAuth(store, 'employer'),
      getComponent: (location, cb) => {
        const { role } = store.getState().auth;

        if (role) {
          cb(null, require('pages/applications/employer/job-view').default);
        }
      }
    },
    {
      path: ':id(/:status)',
      title: ({ id }) => {
        const state = store.getState();

        if (state.auth.role === 'student') {
          return state.jobs.entities[state.applications.entities[id].campaign].title;
        }

        if (state.auth.role === 'employer') {
          return 'Applicant';
        }
      },
      subTitle: 'Back To Applications',
      link: ({ id }) => {
        const state = store.getState();

        if (state.auth.role === 'student') {
          return '/applications';
        }

        if (state.auth.role === 'employer') {
          return `/applications/jobs/${state.applications.entities[id].campaign}`;
        }
      },
      getComponent: (location, cb) => {
        const { role } = store.getState().auth;

        if (role) {
          cb(null, require(`pages/applications/${role}/item-view`).default);
        }
      }
    }
  ]
});
