import React              from 'react';
import PropTypes          from 'prop-types';
import { connect }        from 'react-redux';
import { Link }           from 'react-router';
import SvgIcon            from 'components/svg-icon';
import MessageThread      from 'components/message-thread';
import QuestionThread     from 'components/question-thread';
import ProfilePhoto       from 'components/profile-photo';
import PageActions        from 'components/page-actions';
import PageContent        from 'components/page-content';
import {
  fetchConversation,
  answerQuestion
} from 'modules/conversations';

// Poll for a new update every 5 seconds
const _updatePollingInterval = 5000;

const viewStates = {
  indeterminate: 'indeterminate',
  question: 'question',
  message: 'message',
  closed: 'closed',
  archived: 'archived',
};

// Auto-scroll frame to bottom if we're less than this far from the (new) bottom.
const autoScrollToBottomThreshold = 100;

class ConversationsView extends React.Component {

  constructor(props, context) {
    super(props, context);

    // initial state
    this.state = {
      messageErrors: [],
      overrideState: null,
      profilePath:   null,
    };
  }

  // Register polling timers
  componentDidMount() {
    let { dispatch, params } = this.props;
    dispatch(fetchConversation(params.id));
  }

  componentDidUpdate(prevProps, prevState) {
    window.scrollTo(0, 99999);
  }

  componentWillUnmount() {
    clearInterval(this._updatePollingTimer);
  }

  // returns the "state" of our conversation view:
  // @returns "question" When in Question state
  // @returns "message" When in Messaging state
  // @returns null when in indeterminate or loading state
  getConversationState() {
    let { overrideState } = this.state,
      { conversation = {}, conversationIsFetching, job, auth } = this.props,
      {
        conversation_messages: messages,
        campaign_question_answers: questions
      } = conversation;

    // If the component has overridden the state (in order to force transition
    // to message state), return it first
    if (overrideState) {
      return overrideState;
    }

    // If we have no question, no message, and we're loading, we're in undefined state
    if (!messages && !questions && conversationIsFetching) {
      return viewStates.indeterminate;
    }

    if (job && job.status == viewStates.archived) {
      return viewStates.archived;
    }

    if (questions && questions.length > 0) {
      for (let question of questions) {
        // if we have any questions that are unanswered, we're in question state
        if (!question.response) {
          return viewStates.question;
        }
      }

      // if we have questions and they're all answered, we're in question state
      if (auth.role === 'student' && (!messages || messages.length === 0)) {
        return viewStates.question;
      }
    }

    // if we have no questions and we're not loading, we're in message state
    return viewStates.message;
  }

  handleQuestionAnswer(question, answer) {
    let { conversation, dispatch, auth } = this.props;

    dispatch(answerQuestion(conversation.id, question.id, answer, auth));
  }

  handleConfirmation(success) {
    let { history } = this.props;

    if (success) {
      this.setState({
        overrideState: viewStates.message
      });
    } else {
      history.pushState(null, '/jobs');
    }
  }

  renderQuestionThread() {
    let { conversation = {}, profiles, auth } = this.props,
      conversationState = this.getConversationState(),
      { campaign_question_answers: questions } = conversation;

    if (questions) {
      return <QuestionThread
                             className = "content-container"
                             auth = {auth}
                             questions = { questions }
                             onQuestionAnswer = { this.handleQuestionAnswer.bind(this) }
                             onConfirmation = { this.handleConfirmation.bind(this) }
                             showConfirmation = { conversationState === viewStates.question }
                             employer = { this.props.employer } />;
    }

    return null;

  }

  renderMessageThread() {
    let { conversation = {}, profiles } = this.props,
      { conversation_messages: messages } = conversation;

    return <MessageThread className = "content-container" messages = { messages } profiles = { profiles } />;
  }

  render() {
    const {
      conversation,
      job,
      profiles,
      auth: { role }
    } = this.props;

    const employerName = job && profiles[job.employer] && profiles[job.employer].business_name;

    const profilePath = this.state.profilePath ||
      (
        window._appv2.slugs.generateProfilePath({
          id:       conversation.applicant,
          callback: ({ path }) => { this.setState({ profilePath: path }); },
        })
      );

    return (
      <PageContent>
        { role === 'employer' && conversation &&
          <PageActions>
            <Link
              className = "button is--rounded is--secondary"
              to        = { profilePath + '/experience' } >
              <div className = "button__icon"><SvgIcon id = "user" /></div>
              <div className = "button__title">Student Profile</div>
            </Link>
          </PageActions>
        }
        { conversation &&
          <div>
            { this.renderQuestionThread() }
            { this.renderMessageThread() }
          </div>
        }
        { job && job.status === viewStates.archived &&
          <ul className = "messageThread">
            <li className = "message is--tuki">
              <div className = "messageThread--photo">
                <ProfilePhoto photo = { require('images/tukiAvatar.svg') } />
              </div>
              <div className = "content pls">
                <h3 className = "author">
                  Tuki
                </h3>
                Oh no! It looks like this Job has Closed. Unfortunately that means you can no longer chat
                with { employerName } about this role. Let's look for another opportunity...
              </div>
            </li>
          </ul>
        }
      </PageContent>
    );
  }
}

function select(state, props) {
  let { params } = props,
    slice = {
      auth: state.auth,
      profiles: state.profiles,
      conversation: null,
      conversationIsFetching: null,
      job: null,
      employer: null
    }; // default

  if (params && params.id) {
    const conversation = state.conversations[params.id];
    const hasContent   = conversation && (conversation.conversation_messages || conversation.campaign_question_answers);

    slice.conversation = hasContent && state.conversations[params.id];
    slice.job          = hasContent && state.jobs.entities[slice.conversation.job];

    slice.conversationIsFetching = state.api.fetching.indexOf('conversations') > -1;
  }
  if (slice.job) {
    slice.employer = state.profiles[slice.job.employer];
  }

  return slice;
}

ConversationsView.propTypes = {
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  conversation: PropTypes.object,
  profiles: PropTypes.object
};

ConversationsView.defaultProps = {};

export default connect(select)(ConversationsView);
