import React from 'react';

const SvgIconSettings = props => (
  <svg
    width = { 32 }
    height = { 32 }
    viewBox = "0 0 32 32"
    fill = "currentColor"
    { ...props }
  >
    <path
      d = "M12.3 0h7.4v2.6c1 .3 2 .7 3 1.2l2.2-2.1L30.1 7l-2 2.1c.5.8 1 1.7 1.3 2.9H32v7.5h-2.6c-.1.8-.3 1.3-.6 1.8-.2.4-.4.7-.6 1l1.9 1.9-5.4 5.3-2-2c-.9.5-2 .9-3 1.2v3h-7.4v-3.1c-1.1-.4-1.7-.6-2.7-1.2l-2.1 2.1-5.3-5.3 2.1-2.1c-.5-.9-.9-1.8-1.2-2.8H0V12h3c.3-1.2.7-2.1 1.2-2.9L2.2 7l5.3-5.3 2 2.1c.9-.5 1.8-.9 2.8-1.2V0zm1.3 1.3v2.3l-.5.1c-1.2.3-2.3.8-3.4 1.4l-.4.2-1.8-1.8L4 7l1.9 1.9-.3.4c-.6 1-1.1 1.9-1.4 3.4l-.1.5H1.3v4.9H4l.1.5c.3 1.2.8 2.3 1.4 3.4l.3.4-1.8 2 3.5 3.5 1.9-1.8.4.2c1.3.8 1.9 1 3.4 1.5l.4.1v2.8h4.9V28l.5-.1c1.2-.3 2.5-.8 3.6-1.4l.4-.3 1.8 1.8 3.6-3.5-1.7-1.7.3-.4c.1-.2.3-.4.4-.6.2-.3.3-.5.5-.8.2-.4.4-1 .5-2l.1-.6h2.4v-5h-2.4l-.1-.5c-.3-1.5-.8-2.4-1.5-3.4l-.3-.4L28.3 7l-3.5-3.5-2 1.9-.4-.2c-1-.6-2.3-1.1-3.5-1.4l-.5-.1V1.3h-4.8zm2.6 6.5c-4.2 0-7.6 3.5-7.6 7.7 0 4.3 3.4 7.7 7.6 7.7s7.6-3.5 7.6-7.7c0-4.3-3.4-7.7-7.6-7.7zm-8.9 7.7c0-5 4-9 8.9-9s8.9 4 8.9 9-4 9-8.9 9-8.9-4-8.9-9z"
      fillRule = "evenodd"
      clipRule = "evenodd"
    />
  </svg>
);

export default SvgIconSettings;
