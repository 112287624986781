export default {
  path: '/resetpassword',
  indexRoute: {
    getComponent: (location, cb) => {
      cb(null, require('pages/auth/reset-password').default);
    },
  },
  childRoutes: [{
    path: 'success',
    getComponent: (location, cb) => {
      cb(null, require('pages/auth/reset-password-success').default);
    },
  }],
};
