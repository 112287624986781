import './page-title.scss';

import React  from 'react';

const PageTitle = ({
  children
}) => (
  <h2 className = "page__title">
    { children }
  </h2>
);

export default PageTitle;
