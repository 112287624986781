
import React from 'react';

// WEBPACKU TODO:
// the package react-stripe-checkout uses the old stripe API and doesn't appear to work with React 16
// it does seem that it was ever set up to work (the button onclick is 'undefined')
// when the time comes to make this actually take money, we'll need to use the new Stripe packages;
// https://stripe.com/docs/stripe-js/react
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements, CardElement } from '@stripe/react-stripe-js';


export default (props) => {
  const {
    amount, profile, roles,
    token, isFetching
  } = props;

  const isFree = amount === 0;

  return (
    <button
      type="button"
      disabled={isFetching}
      onClick={isFree ? (e) => { e.stopPropagation(); token({ id: '' }) } : undefined}
      className="button is--primary is--fixed ui__payment-submit"
    >
      <div className="button__label">
        {isFree
          ? 'Post for Free'
          : 'Pay with Credit Card'
        }
      </div>
    </button>
  );
};

// this is the previous version of this file

// import React          from 'react';
// import config         from 'config';
// import StripeCheckout from 'react-stripe-checkout';

// export default (props) => {
//   const {
//     amount, profile, roles,
//     token, isFetching
//   } = props;

//   const stripeProps = {
//     name: `Hello, ${profile.full_name}`,
//     description: `You're paying for 1 jobs (${roles} roles).`,
//     currency: 'AUD',
//     stripeKey: config.STRIPE_KEY,
//     locale: 'auto',
//     email: profile.email,
//     allowRememberMe: true,
//     amount,
//     token
//   };

//   const isFree = amount === 0;

//   return (
//     <StripeCheckout { ...stripeProps }>
//       <button
//         disabled  = { isFetching }
//         onClick   = { isFree ? (e) => { e.stopPropagation(); token({ id: '' }) }  : undefined }
//         className = "button is--primary is--fixed ui__payment-submit">
//         <div className="button__label">
//           { isFree
//             ? 'Post for Free'
//             : 'Pay with Credit Card'
//           }
//         </div>
//       </button>
//     </StripeCheckout>
//   );
// };
