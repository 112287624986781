import Switch from 'components/input-switch';
import Location from 'components/input-location';
import Text from 'components/input-text';
import Radio from 'components/input-radio';
import Checkbox from 'components/input-checkbox';
import Textarea from 'components/input-textarea';
import Select from 'components/input-select';
import Autocomplete from 'components/input-autocomplete';
import InputValue from 'components/input-value';
import InputValueSelect from 'components/input-value/input-value-select';
import Markdown from 'components/markdown';

export const TYPES = Object.freeze({
  TEXT: Symbol.for('text'),
  PASSWORD: Symbol.for('password'),
  NUMBER: Symbol.for('number'),
  EMAIL: Symbol.for('email'),
  CHECKBOX: Symbol.for('checkbox'),
  SELECT: Symbol.for('select'),
  SWITCH: Symbol.for('switch'),
  TEXTAREA: Symbol.for('textarea'),
  RADIO: Symbol.for('radio'),
  LOCATION: Symbol.for('location'),
  AUTOCOMPLETE: Symbol.for('autocomplete'),
  DEFAULT: Symbol.for('default'),
});

export const COMPONENTS = {
  edit: {
    [TYPES.TEXT]: Text,
    [TYPES.PASSWORD]: Text,
    [TYPES.NUMBER]: Text,
    [TYPES.EMAIL]: Text,
    [TYPES.CHECKBOX]: Checkbox,
    [TYPES.SELECT]: Select,
    [TYPES.SWITCH]: Switch,
    [TYPES.TEXTAREA]: Textarea,
    [TYPES.RADIO]: Radio,
    [TYPES.LOCATION]: Location,
    [TYPES.AUTOCOMPLETE]: Autocomplete,
    [TYPES.DEFAULT]: Text,
  },
  static: {
    [TYPES.TEXTAREA]: Markdown,
    [TYPES.SELECT]: InputValueSelect,
    [TYPES.AUTOCOMPLETE]: InputValueSelect,
    [TYPES.DEFAULT]: InputValue,
  },
};
