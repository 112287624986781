import { date  } from 'utils/normalize';
import { TYPES } from 'modules/forms/constants';
import {
  createValidations, required, minLength, maxLength
} from 'utils/validations';

const FORM_VALIDATIONS = {
  // date_of_birth: required
};

export const validate    = createValidations(FORM_VALIDATIONS);
export const FORM_PANELS = [{
  id: 'details',
  icon: 'user',
  title: 'Personal Details',
  description: 'Providing this information helps us personalise your Paddl experience. Only your name will be displayed on your profile, visible to employers when you apply for Jobs. We keep the rest secret.',
  fields: [
    [{
      name: 'full_name',
      type: TYPES.TEXT,
      label: 'Full Name',
      placeholder: '-'
    }],
    [{
      name: 'date_of_birth',
      type: TYPES.TEXT,
      label: 'Date of Birth',
      placeholders: {
        input: 'dd/mm/yyyy',
        default: '—-/—-/—-'
      },
      normalize: date
    }, {
      name: 'gender',
      type: TYPES.SELECT,
      label: 'Gender',
      placeholders: {
        input: 'Select your gender',
        default: 'Unselected'
      },
      options: [
        { value: 'male',   title: 'Male' },
        { value: 'female', title: 'Female' },
        { value: 'other', title: 'Other' },
      ]
    }]
  ]
}, {
  id: 'contact',
  icon: 'envelope',
  title: 'Contact',
  description: 'Your contact details help employers get in touch during and after a Job Application. They also help Paddl keep you up to date with notifications and opportunities.',
  fields: [
    [{
      name: 'email',
      type: TYPES.TEXT,
      label: 'Email',
      placeholder: 'E.g. your@email.com'
    }],
    [{
      name: 'secondary_email',
      type: TYPES.TEXT,
      label: 'Secondary Email',
      placeholders: {
        input: 'E.g. your@email.com',
        default: '-'
      }
    }],
    [{
      name: 'mobile_number',
      type: TYPES.TEXT,
      label: 'Mobile Number',
      placeholder: '+00'
    }, {
      name: 'skype_handle',
      type: TYPES.TEXT,
      label: 'Skype Handle',
      placeholders: {
        input: 'Your Skype account',
        default: '-'
      }
    }]
  ]
},
// TODO: implement once we have api endpoint for it
// {
//   id: 'security',
//   icon: 'security',
//   title: 'Security',
//   description: 'Control your password and account access. Make sure you choose a strong password with a mix of numbers and letters that’s hard to guess.',
//   fields: [
//     [{
//       name: 'password',
//       type: TYPES.PASSWORD,
//       label: 'Password',
//       placeholder: '•••••••'
//     }],
//     [{
//       name: 'new_password',
//       type: TYPES.PASSWORD,
//       label: 'New Password',
//       editOnly: true
//     }, {
//       name: 'confirm_password',
//       type: TYPES.PASSWORD,
//       label: 'Confirm Password',
//       editOnly: true
//     }]
//   ]
// }
]
