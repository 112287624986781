import { CALL_API }           from 'middleware/call-api';
import createReducer          from 'utils/create-reducer';
import { conversationSchema } from 'schemas';

const CREATE_REQUEST          = 'conversations/CREATE_REQUEST';
const CREATE_SUCCESS          = 'conversations/CREATE_SUCCESS';
const CREATE_FAILURE          = 'conversations/CREATE_FAILURE';
const FETCH_REQUEST           = 'conversations/FETCH_REQUEST';
const FETCH_SUCCESS           = 'conversations/FETCH_SUCCESS';
const FETCH_FAILURE           = 'conversations/FETCH_FAILURE';
const RESET_REQUEST           = 'conversations/RESET_REQUEST';
const RESET_SUCCESS           = 'conversations/RESET_SUCCESS';
const RESET_FAILURE           = 'conversations/RESET_FAILURE';
const SEND_MESSAGE_REQUEST    = 'conversations/SEND_MESSAGE_REQUEST';
const SEND_MESSAGE_SUCCESS    = 'conversations/SEND_MESSAGE_SUCCESS';
const SEND_MESSAGE_FAILURE    = 'conversations/SEND_MESSAGE_FAILURE';
const QUESTION_ANSWER_REQUEST = 'conversations/QUESTION_ANSWER_REQUEST';
const QUESTION_ANSWER_SUCCESS = 'conversations/QUESTION_ANSWER_SUCCESS';
const QUESTION_ANSWER_FAILURE = 'conversations/QUESTION_ANSWER_FAILURE';

const initialState = {};

const conversationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_REQUEST:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          campaign_question_answers: [],
          conversation_messages: []
        }
      }
      break;
    case QUESTION_ANSWER_SUCCESS:
      return {
        ...state,
        [action.meta.id]: {
          ...state[action.meta.id],
          campaign_question_answers: action.payload
        }
      }
      break;
    default: return state;
  }
}

export function fetchConversation(id) {
  return {
    [CALL_API]: {
      types: [
        FETCH_REQUEST,
        FETCH_SUCCESS,
        FETCH_FAILURE
      ],
      endpoint: !id ? '/conversations' : `/conversations/${id}`,
      schema: conversationSchema
    }
  }
}

export function createConversation(job_id) {
  return {
    [CALL_API]: {
      types: [
        CREATE_REQUEST,
        CREATE_SUCCESS,
        CREATE_FAILURE
      ],
      method: 'POST',
      data: { job_id },
      endpoint: '/conversations',
      schema: conversationSchema,
      meta: {
        [CREATE_SUCCESS]: {
          redirect: ({ payload: { result }}) => `/conversations/${result[0]}`
        }
      }
    }
  }
}

export function reset(id) {
  return {
    [CALL_API]: {
      types: [
        RESET_REQUEST,
        RESET_SUCCESS,
        RESET_FAILURE
      ],
      method: 'DELETE',
      data: { id },
      endpoint:`/conversations/${id}/reset`,
      schema: conversationSchema
    }
  }
}

export function sendMessage(id, message) {
  return {
    [CALL_API]: {
      types: [
        SEND_MESSAGE_REQUEST,
        SEND_MESSAGE_SUCCESS,
        SEND_MESSAGE_FAILURE
      ],
      method: 'POST',
      data: { message },
      endpoint:`/conversations/${id}/messages`,
      schema: conversationSchema
    }
  }
}

export function answerQuestion(id, question_id, answer) {
  return {
    [CALL_API]: {
      types: [
        QUESTION_ANSWER_REQUEST,
        QUESTION_ANSWER_SUCCESS,
        QUESTION_ANSWER_FAILURE
      ],
      method: 'POST',
      data: { answer },
      endpoint:`/conversations/${id}/questions/${question_id}`,
      meta: {
        [QUESTION_ANSWER_SUCCESS]: { id }
      }
    }
  }
}

export default createReducer('conversations', conversationsReducer);
