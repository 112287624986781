import React                   from 'react';
import { connect }             from 'react-redux';
import { applicationSelector } from 'selectors';
import { browserHistory }      from 'react-router';
import PageSection             from 'components/page-section';
import ChallengesList          from 'components/challenges-list';

class ApplicationChallengesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleChallengeStart = this.handleChallengeStart.bind(this);
  }
  handleChallengeStart() {
    browserHistory.push(`/conversations/${this.props.application.conversation.id}`);
  }
  render() {
    const {
      application: {
        scores: {
          max_score,
          score
        },
        campaign: { challenges },
        conversation: {
          campaign_question_answers = [],
          question_count = []
        }
      },
      isSubmitted
    } = this.props;

    if (!challenges || (challenges && challenges.length === 0)) return null;

    const isCompleted = question_count === (campaign_question_answers && campaign_question_answers.filter(v => v.response !== null ).length);

    const challengesWithActions = challenges.map(challenge => ({
      ...challenge,
      isCompleted,
      data            : campaign_question_answers,
      score           : isSubmitted && `${score}/${max_score}`,
      onChallengeStart: this.handleChallengeStart
    }));

    return (
        <ChallengesList challenges = { challengesWithActions } />
    );
  }
}

export default connect((state, { id }) => {
  const application = applicationSelector(state, id);

  return {
    application,
    isSubmitted: ['sent', 'shortlisted', 'unsuccessful'].indexOf(application.status.status) > -1
  };
})(ApplicationChallengesContainer);
