import './index.scss';
import React       from 'react';
import SvgIconStar from 'components/v2/svg-icons/svg-icon-star';

const ShortlistApplicationButton = ({
  onClick
}) => (
  <button
    type      =  "button"
    className =  "button--application-shortlist"
    onClick   = { onClick } >
    <SvgIconStar />
  </button>
);

export default ShortlistApplicationButton;
