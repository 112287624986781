import './index.scss';
import React        from 'react';
import SvgIconShare from 'components/v2/svg-icons/svg-icon-share';

const ShareJobButton = ({
  onClick
}) => {
  return(
    <button
      type      = "button"
      className =  "button--share-job"
      onClick   = { onClick } >
      <SvgIconShare />
    </button>
  )};

  export default ShareJobButton;
