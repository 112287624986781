import React            from 'react';
import { connect }      from 'react-redux';
import PageTitle        from 'components/page-title';
import FormCard         from 'components/form-card';
import JobChallengesAdd from 'components/job-create/challenges/job__challenges__add';
import {
  SortableContainer,
  SortableElement
} from 'react-sortable-hoc';
import {
  FORM_ID,
  CARD_VIEWS_BY_ID
} from 'modules/jobs/create/constants';

const SortableItem = SortableElement(props  => <FormCard { ...props } key = { props.id } />);
const SortableList = SortableContainer(({
  errors,
  canEdit,
  challenges,
  editingCards,
  editCard,
  saveCard,
  handleRemoveChallenge
}) => (
  <div className = "challenges">
    { challenges && challenges.map(({ id, title }, index) => (
      <SortableItem
        id           = { `challenges[${index}]` }
        type         = { id }
        key          = { `challenges[${index}]` }
        index        = { index }
        name         = { `challenges[${index}].data` }
        title        = { title }
        isEditing    = { editingCards.indexOf(`challenges[${index}]`) > -1 }
        views        = { CARD_VIEWS_BY_ID[id] }
        draggable    = { canEdit }
        onEdit       = { canEdit && editCard }
        onSave       = { canEdit && saveCard }
        onDelete     = { canEdit && handleRemoveChallenge }
        values       = { challenges[index] && challenges[index].data } />
    )) }
  </div>
));

const JobChallenges = props => {
  const {
    handleAddChallenge,
    handleSwapChallenge,
    canEdit,
  } = props;

  return (
    <div>
      <PageTitle>
        Challenges
      </PageTitle>
      <h3 className = "page__subtitle">
        Now test your applicants to get the best result
      </h3>
      <SortableList
        onSortEnd     = { handleSwapChallenge }
        useDragHandle = { true }
        helperClass   = "is--dragging"
        axis          = "y"
        lockAxis      = "y"
        canEdit       = { canEdit }
        { ...props } />
      { canEdit && <JobChallengesAdd { ...{ handleAddChallenge } } /> }
    </div>
  );
};

export default connect(({ form }) => ({
  challenges: form[FORM_ID] && form[FORM_ID].values && form[FORM_ID].values.challenges,
}))(JobChallenges)
