import React     from 'react';
import PropTypes from 'prop-types';
import Button    from 'components/button';

const ChallengeStatusButton = ({
  isCompleted,
  score,
  onClick
}) => {
  const hasIcon = isCompleted && !score;
  const label = score
    ? `SCORE: ${score}`
    : isCompleted
      ? 'Completed'
      : 'Start Challenge';

  return (
    <Button
      className   = { `${score ? 'has--score' : ''} ui__challenge-status-button` }
      onClick     = { onClick }
      icon        = { hasIcon && 'confirm' }
      isCompleted = { isCompleted }
      isAction
      isWide
      hasShadow >
      { label }
    </Button>
  );
};

export default ChallengeStatusButton;

ChallengeStatusButton.propTypes = {
  isComplete: PropTypes.bool,
  beforeCompletedLabel: PropTypes.string,
  afterCompletedLabel: PropTypes.string,
  icon: PropTypes.string
};
