import './list-filter-item.scss';

import React      from 'react';
import classnames from 'classnames';
import { Link }   from 'react-router';
import PropTypes  from 'prop-types';
import xor        from 'lodash/xor';

const ListFilterItem = ({
  id,
  size,
  title,
  isSuccess,
  isWarning,
  isDanger,
  isPrimary,
  isInactive,
  isActive
}) => {

  // This was re-written as part of the webpack upgrade, 
  // as 'context' is no longer available as a second prop
  const { pathname, search } = window.location;
  const urlParams = new URLSearchParams(search);
  const filtersArray = urlParams.getAll('filter');

  return (
    <Link
      key       = { id }
      to        = { { pathname, query: { filter: xor(filtersArray, [id]) } } }
      className = { classnames('list-filter__item', {
        'is--active':   isActive || filtersArray.indexOf(id) > -1,
        'is--primary' : isPrimary,
        'is--danger'  : isDanger,
        'is--warning' : isWarning,
        'is--inactive': isInactive,
        'is--success' : isSuccess,
      }) } >
      <span className = "list-filter__size">{size > 0 ? size : 0}</span>
      { title }
    </Link>
  );
};

ListFilterItem.contextTypes = {
  location: PropTypes.object
};

export default ListFilterItem;
