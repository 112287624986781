import './input-switch.scss';
import React from 'react';

export default ({ addon, options, attrs, ...props }) => (
  <div className = "input__switch">
    <label>
      <input { ...props } checked = { !!props.value } type = "checkbox" />
      <span />
    </label>
  </div>
);
