import './index.scss';
import React from 'react';

import Text                        from 'component-lib/type/text';
import { style16pxMediumBluegrey } from 'component-lib/type/text/styles';
import { style16pxLightBluegrey }  from 'component-lib/type/text/styles';
import { SubmitButton }            from 'components/v2/buttons/square-buttons/submit-button';

const BasecampFooter = ({ lastCollectionDateObject, isXpReadyForCollection, onCollectClick }) => {
  const { day, month, year } = lastCollectionDateObject;
  const lastCollectionText = day && month && year ? 'Last Collection:' : null;
  const lastCollectionDate = day && month && year ? `${day}/${month}/${year}` : null;
  const buttonCopy = isXpReadyForCollection ? 'Collect Available XP' : 'No XP Available';

  return (
    <div className = "v2-basecamp-footer">
      <div className = "v2-basecamp-footer__last-collected-section">
        <Text { ...style16pxMediumBluegrey } text = { lastCollectionText } />
        <Text { ...style16pxLightBluegrey } text = { lastCollectionDate } />
      </div>
      <div className="v2-basecamp-footer__collect-xp">
        <SubmitButton customCopy = { buttonCopy } onClick = { () => { onCollectClick(); } } isDisabled = { !isXpReadyForCollection } />
      </div>
    </div>
  );
};

export default BasecampFooter;
