import './form-multipage.scss';

import React       from 'react';
import { connect } from 'react-redux';
import { setPage } from 'modules/forms';
import {
  TransitionMotion,
  spring
} from 'react-motion';

class FormMultiPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleGoBack   = this.handleGoBack.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.handleGoTo     = this.handleGoTo.bind(this);
  }
  handleGoTo({ index }) {
    const {
      setPage,
      pathname
    } = this.props;

    return () => { setPage({ index, pathname }) }
  }
  handleComplete() {
    const {
      currentPage,
      setPage,
      children,
      pathname
    } = this.props;

    if (currentPage < children.length) {
      setPage({ index: currentPage + 1, pathname });
    }
  }
  handleGoBack() {
    const {
      currentPage,
      setPage,
      pathname
    } = this.props;

    setPage({ index: currentPage - 1, pathname });
  }
  render() {
    const {
      children,
      currentPage,
      setPage
    } = this.props;

    return (
      <TransitionMotion
        willLeave     = { () => ({ opacity: spring(0), x: spring(-200) }) }
        willEnter     = { () => ({ opacity: 0,         x: 30 }) }
        styles        = { [{ key: currentPage, style: { opacity: spring(1), x: spring(0) } }] }>
        { styles =>
          <div className = "form__multipage">
            { styles.map(({ key, style }) =>
              <div
                style     = {{
                  opacity: style.opacity,
                  transform: `translate3d(${style.x}px, 0, 0)`,
                }}
                key       = { key }
                className = "form__multipage__page">
                 { React.cloneElement(
                    children[currentPage],
                    {
                      onComplete: this.handleComplete,
                      goBack:     this.handleGoBack
                    }
                  )
                }
              </div>
            )}
            <div className = "form__multipage__nav">
              { children.map((v, index) =>
                <div
                  key       = { index }
                  className = { `form__multipage__nav__item ${index === currentPage ? 'is--active' : ''}`} />
              )}
            </div>
          </div>
        }
      </TransitionMotion>
    );
  }
}

export default connect(({ forms }, { currentPage }) => ({
  currentPage: parseInt(currentPage || forms.currentPage, 10)
}), { setPage })(FormMultiPage);
