import React from 'react';

export const SvgIconExpand = props => (
  <svg
    width = { 54.9 }
    height = { 24 }
    viewBox = "0 0 54.9 24"
    fill = "currentColor"
    { ...props }
  >
    <path d = "M26.2 0h2.5v24h-2.5z" />
    <path className = "arrow-r" d = "M43.5.7l-1.8 1.8 8.3 8.3H33v2.5h17l-8.3 8.3 1.8 1.8L54.9 12" />
    <path className = "arrow-l" d = "M0 12l11.4 11.4 1.8-1.8-8.3-8.3h17v-2.5h-17l8.3-8.3L11.4.7" />
  </svg>
);
