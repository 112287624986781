import FeedbackPage from 'pages/v2/register/student/feedback-page';

export default ({ appv2 }) => {
  return {
    path:         'register/feedback',
    title:        'Register (Feedback)',
    getComponent: ({ params }, cb) => {
      cb(null, FeedbackPage);
    },
  };
};
