import React       from 'react';
import { connect } from 'react-redux';
import Payment     from 'components/payment';

import {
  applyCoupon,
  createPayment
} from 'modules/payments';

class PaymentRoute extends React.Component {
  constructor() {
    super();

    this.handleCouponApply    = this.handleCouponApply.bind(this);
    this.handlePaymentSuccess = this.handlePaymentSuccess.bind(this);
  }

  handleCouponApply({ couponCode }) {
    const {
      job,
      applyCoupon
    } = this.props;

    return applyCoupon({
      job,
      coupon: couponCode.toUpperCase()
    });
  }

  handlePaymentSuccess({ id }) {
    const {
      job = '',
      createPayment
    } = this.props;

    return createPayment({
      card_token: id,
      job
    });
  }

  render() {
    const {
      job, profile, ui, isFetching
    } = this.props;

    const stripeOrder = job && job.stripe_order;
    const orderItems  = job && stripeOrder.stripe_order_items.slice(0);
    const orderProps  = {
      items:        job && orderItems.splice(0, 1),
      addons:       job && orderItems.filter(v => v.type === 'sku'),
      summaryItems: job && orderItems.filter(v => ['tax', 'discount'].indexOf(v.type) > -1),
      total:                job && stripeOrder.amount,
      handleCouponApply:    this.handleCouponApply,
      handlePaymentSuccess: this.handlePaymentSuccess,
      handleEditClick:      this.handleEditClick,
      job,
      profile,
      ui,
      isFetching
    };

    return <Payment { ...orderProps } />;
  }
}

export default connect((state, ownProps) => ({
  job:        state.jobs.entities[ownProps.params.id],
  profile:    state.profiles[state.auth.profile_id],
  ui:         state.ui,
  isFetching: state.api.fetching.indexOf('payments') > -1
}), { applyCoupon, createPayment })(PaymentRoute);
