import React          from 'react';
import { connect }    from 'react-redux';
import List           from 'components/list';
import JobListPanel   from 'containers/job-list-panel';
import PageContent    from 'components/page-content';
import PagePanels     from 'components/panels';
import PageFilterTabs from 'components/filter-tabs';
import Panel          from 'components/panel';
import HelloUsername  from 'components/hellousername';
import EmptyView      from 'components/empty-view';

import {
  sortedJobsSelector,
  profileSelector
} from 'selectors/index';

const TABS = {
  selector: 'location',
  theme: 'buttons',
  mode: 'tabs',
  items: [
    { id: 'basecamp', title: 'Near Basecamp' },
    { id: 'outpost',  title: 'Near Outpost' },
    { id: 'search',   title: 'Search' }
  ]
};

const StudentIndex = (props) => {
  const {
    items,
    pageId,
    activeLocation
  } = props;

  const hasList = activeLocation === 'basecamp';

  return (
    <PageContent>
      <HelloUsername />
      <PageFilterTabs { ...TABS } />
      <PagePanels />
      { hasList &&
        <List
          items     = { items }
          Component = { JobListPanel }
        />
      }
      { hasList && items.length === 0 &&
        <EmptyView pageId = { pageId } />
      }
      <Panel
        icon   = "jobs-student"
        text   = "We're still gathering new jobs nearby...<br /> Help us by voting for employers you'd love to work with!"
        action = "Vote Now"
        linkTo = "https://paddl.typeform.com/to/yhwQlp"
        type   = "cta"
      />
    </PageContent>
  );
};

export default connect((state, { location: { query } }) => ({
  profile: profileSelector(state),
  items: sortedJobsSelector(state),
  pageId: state.ui.pageId,
  activeLocation: (query && query.location) || 'basecamp'
}))(StudentIndex);
