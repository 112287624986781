import CampaignsPage from 'pages/v2/profile/campaigns-page';

export default ({ appv2 }) => {
  return {
    path:         'profile/:id/campaigns',
    title:        'Profile Campaigns',
    getComponent: (nextState, cb) => {
      const { location } = nextState;
      const isPublicPreviewPage = (location.search.indexOf('public=1') > -1);

      if (isPublicPreviewPage) {
        return appv2.ui.redirect({ url: `/not-found` });
      }

      return cb(null, CampaignsPage);
    },
  };
};
