import './flexbox.scss';
import React from 'react';

export default ({
  children
}) => (
  <div className = "flexbox">
    { children }
  </div>
)
