import './index.scss';

import React       from 'react';
import { defined } from 'lib/helpers';

import PanelFormHeader       from 'components/v2/panel/form-header';
import StudentBiographyInput from 'components/v2/profile/fields/student-biography/input';
import { FormFooter }        from 'components/v2/form-footer';

class StudentAboutPanelForm extends React.Component {
  constructor(props) {
    const { data } = props;

    super(props);

    this._handleClick = (event) => {
      if (
        data.getIsDataChanged() &&
        this._form &&
        !this._form.contains(event.target)
      ) {
        if (confirm("You have unsaved information, are you sure you want to leave this page?") === true) {
          data.resetToPersistedState();
          data.setIsDataChanged(false);
          data.closeSummaryForm();
        }
      }
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClick, false);
  }

  render() {
    const { data } = this.props;

    const onCancel = () => {
      data.resetToPersistedState();
      data.setIsDataChanged(false);
      data.closeSummaryForm();
    };

    const onSubmit = () => {
      data
        .updateSummaryViaApi()
        .then(() => {
          data.setAsPersistedState();
          data.setIsDataChanged(false);
          data.refreshCurrentProfileLegacy();
          data.closeSummaryForm();
        });
    };

    return (
      <div
        className = "v2-student-about-panel-form"
        ref = { (elem) => { this._form = elem; } }
      >
        <div className = "v2-student-about-panel-form__header">
          <PanelFormHeader
            iconSrc     = { require('images/v2/icon-circle-paper.svg') }
            heading     = 'About Me'
            description = 'Share some more about yourself...'
          />
        </div>

        <div className = "v2-student-about-panel-form__body">
          <StudentBiographyInput
            value    = { data.getSummary() || '' }
            onChange = { ({ name }) => { data.setSummary(defined(name)); } }
          />
        </div>

        <FormFooter
          customSubmitCopy = 'Save'
          onCancel         = { onCancel }
          onSubmit         = { onSubmit }
          isSubmitDisabled = { !data.getIsDataChanged() }
        />
      </div>
    );
  }
};

export default StudentAboutPanelForm;
