import React       from 'react';
import { connect } from 'react-redux';
import { TYPES }   from 'modules/forms/constants';
import { FORM_ID } from 'modules/onboarding/constants';
import Card        from 'components/card';
import Button      from 'components/button';
import getForm     from 'components/form';
import FormRow     from 'components/form-row';
import FormField   from 'components/form-field';
import { date  }   from 'utils/normalize';
import {
  createValidations,
  required,
  dob
} from 'utils/validations';

const Form = getForm({
  id: FORM_ID,
  destroyOnUnmount: false,
  validate: createValidations({
    full_name: required,
    date_of_birth: dob
  })
});

const OnboardingDetailsStudent = ({ isCompleted, onComplete }) => (
  <Form>
    <Card title     = "Your Details">
      <div className = "card__content">
        <FormRow>
          <FormField
            label       = "Name"
            placeholder = "E.g. Pat Williams"
            type        = { TYPES.TEXT }
            name        = "full_name" />
        </FormRow>
        <FormRow>
          <FormField
            label       = "Date of Birth"
            placeholder = "DD/MM/YYYY"
            type        = { TYPES.TEXT }
            name        = "date_of_birth"
            normalize   = { date } />
        </FormRow>
        <FormRow className = "is--centered is--spacer">
          <Button
            className = "is--action is--fixed"
            disabled  = { !isCompleted }
            onClick   = { onComplete }>
            That's me!
          </Button>
        </FormRow>
      </div>
    </Card>
  </Form>
);

export default connect(({ form }) => ({
  isCompleted: form[FORM_ID] && !form[FORM_ID].syncErrors,
}))(OnboardingDetailsStudent);
