const fp = require('lodash/fp').convert({ cap: false });

import map             from 'lodash/map';
import pickBy          from 'lodash/pickBy';
import isEqual         from 'lodash/isEqual';
import includes        from 'lodash/includes';
import mapValues       from 'lodash/mapValues';
import orderBy         from 'lodash/orderBy';
import filter          from 'lodash/filter';
import countBy         from 'lodash/countBy';
import { denormalize } from 'denormalizr';
import { arrayOf }     from 'normalizr';
import {
  createSelectorCreator,
  createSelector,
  defaultMemoize
} from 'reselect';

import {
  applicationSchema,
  profileSchema,
  jobSchema
} from 'schemas';

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual
);

const application      = (state, id) => state.applications.entities[id];
const profile          = (state, id) => state.profiles[id  || state.auth.profile_id];
const educator         = (state, id) => state.educators[id] || {};
const educatorCampuses = (state, id) => filter(state.campuses, { educator: id }) || [];
const educatorCourses  = (state, id) => filter(state.courses,  { educator: id }) || [];
const job              = (state, id) => pickBy(state.jobs.entities[id], v => v !== null);
const conversations     = state => state.conversations  || {};
const profiles          = state => state.profiles       || {};
const campuses          = state => state.campuses       || {};
const courses           = state => state.courses        || {};
const educators         = state => state.educators      || {};
const villages          = state => state.villages       || {};
const jobs              = state => state.jobs.entities  || {};
const applications      = state => state.applications.entities  || {};
const currentProfileId  = state => state.auth.profile_id;

const jobsArray         = state => Object.keys(state.jobs.entities).map(k => state.jobs.entities[k]);
const applicationsArray = state => Object.keys(state.applications.entities).map(k => state.applications.entities[k]);
const statusFilter      = (state, { location: { query: { filter } }}) => ['archived'].concat(filter);
const applicationsFilter = (state, id, filter) => [].concat(filter);

export const profileSelector = createDeepEqualSelector(
  [profile, campuses, courses, educators, villages],
  (profile, campuses, courses, educators, villages) =>
    denormalize(
      profile,
      { campuses, courses, educators, villages },
      profileSchema
    )
);

export const jobSelector = createDeepEqualSelector(
  [job, conversations, applications, jobs, profiles, villages, campuses, courses, educators],
  (job, conversations, applications, jobs, profiles, villages, campuses, courses, educators) =>
    denormalize(
      job,
      { profiles, conversations, applications, jobs, villages, campuses, courses, educators },
      jobSchema
    )
);

export const applicationSelector = createDeepEqualSelector(
  [application, conversations, applications, jobs, profiles, villages, campuses, courses, educators],
  (application, conversations, applications, jobs, profiles, villages, campuses, courses, educators) =>
    denormalize(
      application,
      { conversations, applications, jobs, profiles, villages, campuses, courses, educators },
      applicationSchema
    )
);

export const applicationsSelector = createDeepEqualSelector(
  [applicationsArray, conversations, applications, jobs, profiles, villages, campuses, courses, educators],
  (applicationsArray, conversations, applications, jobs, profiles, villages, campuses, courses, educators) =>
    denormalize(
      applicationsArray,
      { conversations, applications, jobs, profiles, villages, campuses, courses, educators },
      arrayOf(applicationSchema)
    )
);

export const denormalizedJobsSelector = createDeepEqualSelector(
  [jobsArray, conversations, applications, jobs, profiles, villages, campuses, courses, educators],
  (jobsArray, conversations, applications, jobs, profiles, villages, campuses, courses, educators) =>
    denormalize(
      jobsArray,
      { conversations, applications, jobs, profiles, villages, campuses, courses, educators },
      arrayOf(jobSchema)
    )
);

export const sortedJobsSelector = createSelector(
  [denormalizedJobsSelector],
  fp.flow(
    fp.orderBy(['distance', 'updated_at'], ['asc', 'desc']),
    fp.filter(({ status }) => status === 'open')
  )
);

export const archivedJobsSelector = createSelector(
  [denormalizedJobsSelector],
  (jobs) => filter(jobs, ({ status }) => status === 'archived')
);

export const groupsSizeJobsSelector = createSelector(
  [denormalizedJobsSelector],
  fp.flow(
    fp.groupBy('status'),
    fp.mapValues('length')
  )
);

export const groupsSizeApplicationsSelector = createSelector(
  [jobSelector],
  job => fp.flow(
    fp.groupBy('status.status'),
    fp.mapValues('length')
  )(job.application_ids)
);

export const filteredGroupedJobsSelector = createSelector(
  [denormalizedJobsSelector, statusFilter, currentProfileId],
  (jobs, statusFilter, currentProfileId) =>
  fp.flow(
    fp.orderBy(['updated_at'], ['desc']),
    fp.filter(v => v.employer.id === currentProfileId),
    fp.filter(v => !includes(statusFilter, v.status)),
    fp.groupBy('status')
  )(jobs)
);

export const filteredGroupedApplicationsSelector = createSelector(
  [jobSelector, applicationsFilter],
  (job, filter) => fp.flow(
    fp.orderBy(['updated_at'], ['desc']),
    fp.filter(v => !includes(filter, v.status.status)),
    fp.groupBy('status.status')
  )(job.application_ids)
);

export const employerJobsSelector = ({ id }) => createSelector(
  [denormalizedJobsSelector],
  (jobs) => filter(jobs, v => v.employer.id === parseInt(id, 10))
);

export const jobsWithApplicationsSelector = createSelector(
  [denormalizedJobsSelector],
  (jobs) => filter(jobs, ({ application_ids }) => application_ids && application_ids.length > 0)
);

export const educatorSelector = createSelector(
  [educator, educatorCampuses, educatorCourses],
  (educator, campuses, courses) => ({
    ...educator,
    campuses,
    courses
  })
);
