import React               from 'react';
import { connect }         from 'react-redux';
import { submit }          from 'redux-form';
import { profileSelector } from 'selectors/index';
import { updateProfile }   from 'modules/profiles';
import PageContent         from 'components/page-content';
import withEditConfirm     from 'containers/withEditConfirm';
import {
  saveCard,
  editCard
} from 'modules/accounts';
import { FORM_ID } from 'modules/accounts/constants';

const IndexView = RoleView => class View extends React.Component {
  constructor(props) {
    super(props);

    this.handleSave   = this.handleSave.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(profile) {
    this.props.updateProfile({  ...profile });
  }
  handleSave({ id }) {
    this.props.saveCard({ id });
    this.props.submit(FORM_ID);
  }
  render() {
    return (
      <PageContent isWide>
        { RoleView &&
          <RoleView { ...{
            ...this.props,
            saveCard:     this.handleSave,
            handleSubmit: this.handleSubmit,
          } } />
        }
      </PageContent>
    );
  }
};

export default RoleView => connect(
  (state, { params: { id } }) => ({
    profile:      profileSelector(state, id),
    editingCards: state.accounts.editingCards,
    isEditing:    state.accounts.editingCards && state.accounts.editingCards.length > 0
  }),
  { updateProfile, saveCard, editCard, submit }
)(withEditConfirm(IndexView(RoleView)));
