import React       from 'react';
import { connect } from 'react-redux';
import { TYPES }   from 'modules/forms/constants';
import { FORM_ID } from 'modules/jobs/create/constants';
import FormRow     from 'components/form-row';
import FormSubrow  from 'components/form-subrow';
import FormField   from 'components/form-field';

const JobDetailsForm = ({
  is_paid,
  is_hours_unknown,
  is_pay_unknown,
  isEditingLive
}) => (
  <div className = "card__content">
    <FormRow>
      <FormField
        type      = { TYPES.TEXT }
        attrs     = {{ autoFocus: true }}
        name      = "details.headline"
        label     = "Headline"
        className = "is--large" />
    </FormRow>
    <FormRow>
      <FormField
        type  = { TYPES.SELECT }
        name  = "details.category"
        placeholder = "Select campaign category"
        label = "Category">
        <option value = "Experience">Experience</option>
        <option value = "Project">Project</option>
        <option value = "Internship">Internship</option>
        <option value = "Casual Work">Casual Work</option>
        <option value = "Graduate Role">Graduate Role</option>
      </FormField>
      <FormField
        disabled  = { isEditingLive }
        type      = { TYPES.NUMBER }
        name      = "details.num_of_positions"
        label     = "# of Positions"
        className = "is--short is--primary" />
    </FormRow>
    <FormRow>
      <FormSubrow title = "Paid?">
        <FormField
          type         = { TYPES.SWITCH }
          defaultValue = { true }
          disabled     = { !!is_pay_unknown }
          name         = "details.is_paid" />
        { is_paid
          ? <FormField
              type = { TYPES.NUMBER }
              name  = "details.pay_rate"
              disabled = { !!is_pay_unknown }
              addon = {{
                prefix:  <span>$</span>,
                postfix: <span>Per Hour</span>
              }} />
          : <div className="form___placeholder">Volunteering</div>
        }
        <FormField
          type = { TYPES.CHECKBOX }
          name = "details.is_pay_unknown" >
          Undisclosed
        </FormField>
      </FormSubrow>
      <FormSubrow title = "Hours to Student">
        <FormField
          className = "is--short"
          type     = { TYPES.NUMBER }
          disabled = { !!is_hours_unknown }
          name     = "details.hours_amount"
          addon    = {{
            postfix: <span><strong>Hours</strong> (XP)</span>
          }} />
        <FormField
          value    = "Per Week"
          type     = { TYPES.SELECT }
          disabled = { !!is_hours_unknown }
          name     = "details.hours_unit">
          <option value = "Total">Total</option>
          <option value = "Per Week">Per Week</option>
          <option value = "Per Fortnight">Per Fortnight</option>
        </FormField>
        <FormField
          type = { TYPES.CHECKBOX }
          name = "details.is_hours_unknown" >
          Unknown
        </FormField>
      </FormSubrow>
    </FormRow>
    <FormRow>
      <FormField
        type  = { TYPES.LOCATION }
        name  = "details.location"
        label = "Location" />
    </FormRow>
    <FormRow>
      <FormField
        type        = { TYPES.TEXTAREA }
        name        = "details.description"
        description = "Remember to write a good description that will attract all students."
        attrs       = {{ rows: 5 }}
        label       = "Description" />
    </FormRow>
  </div>
);

export default connect(({ form }) => {
  const details = form[FORM_ID] && form[FORM_ID].values && form[FORM_ID].values.details;

  return {
    is_paid:       details && details.is_paid,
    is_hours_unknown: details && details.is_hours_unknown,
    is_pay_unknown: details && details.is_pay_unknown,
  };
})(JobDetailsForm);
