import './index.scss';
import React from 'react';

const StatusPanelButton = ({ copy, onClick }) => {
  return (
    <a className = "status-panel-button" id = "button-stats" onClick = { onClick } >
      { copy }
    </a>
  );
};

export default StatusPanelButton;
