import ProfilePage from 'pages/v2/get-started/student/profile-page';

export default ({ appv2 }) => {
  return {
    path:         'onboarding',
    title:        'Onboarding (Profile)',
    getComponent: ({ params }, cb) => {
      cb(null, ProfilePage);
    },
  };
};
