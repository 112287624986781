import './index.scss';
import React from 'react';
import classNames from 'classnames';

export const AvatarSpinner = ({ animated, backgroundImage }) => {
  const className = classNames(
    'avatar-spinner',
    { 'avatar-spinner--animated': animated }
  );

  return (
    <div className = { className }>
      <div className = "avatar-spinner__background">
        <svg className = "avatar-spinner__background__svg">
          <circle cx = "50%" cy = "50%" r = "100" />
        </svg>
      </div>
      <div
        className = "avatar-spinner__image"
        style = { { backgroundImage } }
      />
    </div>
  );
};
