import './page-actions.scss';

import React  from 'react';
import map    from 'lodash/map';
import Button from 'components/button';

export default ({ children, items, disabled, type = 'top' }) => (
  <div>
    <div className = { `page__actions is--${type} ${disabled ? 'is--disabled' : ''}` }>
      <div className = "page__actions__container">
        { children || map(items, Button) }
      </div>
    </div>
  </div>
);
