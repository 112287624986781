import './index.scss';
import React       from 'react';
import { defined } from 'lib/helpers';
import Timeline    from 'components/v2/activities/generics/timeline';
import Moment      from 'lib/helpers/moment';

import {
  createGroupsByMonth,
  createGroupsByYear,
} from './calculator';

const renderListOfActivitiesForMonth = (appv2, canEdit, activities, month, canOpenModal) => {
  // Months are zero indexed, so January is month 0
  const monthInShortTerm = Moment.monthsShort()[month - 1];

  return (
    <div key = { month } className = "v2-generics-timeline v2-generics-timeline__group-month">
      <div className = "v2-generics-timeline__calendar-vertical">
        <div className = "v2-generics-timeline__month">
          { monthInShortTerm }
        </div>
      </div>

      {
        activities.map((activity) => {
          return (
            <div key = { activity.get('id') } className = "v2-generics-timeline__timeline-card">
              <Timeline
                appv2        = { appv2 }
                activity     = { activity }
                canEdit      = { canEdit }
                canOpenModal = { canOpenModal }
              />
            </div>
          );
        })
      }
    </div>
  );
};

const renderListOfActivitiesForYear = (appv2, canEdit, activities, viewedAt, year, canOpenModal) => {
  const groups = createGroupsByMonth(activities, viewedAt);
  const sortedMonths = Object.keys(groups).sort((a, b) => (b - a));

  return (
    <div key = { year } className = "v2-generics-timeline v2-generics-timeline__group-year">
      <div className = "v2-generics-timeline__calendar-vertical v2-generics-timeline--calendar-vertical-line">
        <div className = "v2-generics-timeline__year">
          <div> { year }</div>
        </div>
      </div>

      {
        sortedMonths.map((month) => {
          const activities = groups[month];
          return renderListOfActivitiesForMonth(appv2, canEdit, activities, month, canOpenModal);
        })
      }
    </div>
  );
};

const TimelineOfActivities = ({ appv2, canEdit, activities, viewedAt, canOpenModal }) => {
  const groups = createGroupsByYear(activities, viewedAt);
  const sortedYears = Object.keys(groups).sort((a, b) => (b - a));

  return (
    <div>
      {
        sortedYears.map((year) => {
          const activities = groups[year];
          return renderListOfActivitiesForYear(appv2, canEdit, activities, viewedAt, year, canOpenModal);
        })
      }
    </div>
  );
};

export default TimelineOfActivities;
