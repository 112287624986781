const SET_STEP = 'forms/SET_STEP';
const SET_PAGE = 'forms/SET_PAGE';

const initialState = {
  currentStep: 0,
  currentPage: 0,
};

export default (state = initialState, action) => {
  switch(action.type) {
    case SET_STEP: {
      const { index } = action.payload;

      return {
        ...state,
        currentStep: index
      }
      break;
    }
    case SET_PAGE: {
      const { index } = action.payload;

      return {
        ...state,
        currentPage: index
      }
      break;
    }
    default: return state;
  }
}

export function setStep({ index }) {
  return {
    type: SET_STEP,
    payload: { index }
  }
}

export function setPage({ index, pathname }) {
  return {
    type: SET_PAGE,
    payload: { index },
    meta: {
      redirect: `${pathname}/step/${index}`
    }
  }
}
