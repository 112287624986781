import './list-groups.scss';

import React     from 'react';
import map       from 'lodash/map';
import ListGroup from 'components/list-group';

const ListGroups = ({
  children,
  groups,
  groupedItems
}) => (
  <div className = "list-groups">
    { map(groups, group => {
      const items = groupedItems[group.id];

      return (
        <ListGroup
          key      = { group.id }
          hasTitle = { items && items.length > 0 }
          { ...group } >
            { children(items) }
        </ListGroup>
      );
    })}
  </div>
);

export default ListGroups;

