import './page-content.scss';

import React      from 'react';
import classNames from 'classnames';

const PageContent = ({
  children,
  isFull,
  isWide,
}) => (
  <div
    className = { classNames('page__content', {
      'is--wide': isWide,
      'is--full': isFull,
    }) }
  >
    { children }
  </div>
);

export default PageContent;
