import React           from 'react';
import { connect }     from 'react-redux';
import { jobSelector } from 'selectors';
import Status          from 'components/status';
import Avatar          from 'components/avatar';
import SvgIcon         from 'components/svg-icon';
import getJobStatus    from 'utils/get-job-status';

const STATE_MAP = {
  student: {
    draft               : 'primary',
    open                : 'success',
    'open-to-closed'    : 'warning',
    closed              : 'inactive',
    'closed-to-archived': 'inactive',
    archived            : 'inactive'
  },
  employer: {
    draft               : 'primary',
    open                : 'success',
    'open-to-closed'    : 'warning',
    closed              : 'danger',
    'closed-to-archived': 'danger',
    archived            : 'inactive'
  }
};

const ICONS_BY_STATUS = {
  student: {
    open                 : ({ url }) => <Avatar url = { url } role = "employer" />,
    'open-to-closed'     : ({ url }) => <Avatar url = { url } role = "employer" />,
    closed               : ({ url }) => <Avatar url = { url } role = "employer" />,
    'closed-to-archived' : ({ url }) => <Avatar url = { url } role = "employer" />,
    draft                : ({ url }) => <Avatar url = { url } role = "employer" />,
    archived             : ({ url }) => <Avatar url = { url } role = "employer" />
  },
  employer: {
    open                 : () => null,
    'open-to-closed'     : () => <SvgIcon id = "circle" />,
    closed               : () => <SvgIcon id = "three-dots" />,
    'closed-to-archived' : () => <SvgIcon id = "three-dots" />,
    draft                : () => <SvgIcon id = "three-lines" />,
    archived             : () => <SvgIcon id = "two-and-half-lines" />
  }
};

const JobStatusContainer = (props) => {
  const {
    status_percentage_complete,
    status_days_till_transition,
    status,
    state,
    title,
    role,
    icon,
    employer: { photo_url },
    isLarge,
    isHorizontal,
    hasDuration,
    isCompact
  } = props;

  return (
    <Status
      title        = { title }
      state        = { state || STATE_MAP[role][status] }
      icon         = { !isCompact && (icon || ICONS_BY_STATUS[role][status]({ url: photo_url })) }
      complete     = { status_percentage_complete }
      daysLeft     = { status_days_till_transition }
      isHorizontal = { isHorizontal }
      isLarge      = { isLarge }
      hasDuration  = { hasDuration }
      isCompact    = { isCompact } />
  );
};

export default connect((
  state, { id }
) => {
  const job = jobSelector(state, id);
  const {
    status_percentage_complete,
    status_days_till_transition,
    employer = {}
  } = job;

  return {
    role: state.auth.role,
    status_percentage_complete,
    status_days_till_transition,
    status: getJobStatus(job),
    employer,
  };
})(JobStatusContainer);
