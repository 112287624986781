import './index.scss';
import React from 'react';
import Text from 'component-lib/type/text';

export const UseProfileIdAsSlugSection = ({ isDisabled, onClick, profileId }) => {
  return (
    <div
      className = { `use-profile-id-as-slug-section use-profile-id-as-slug-section--${isDisabled ? 'disabled' : 'enabled'}` }
      onClick   = { isDisabled ? null : () => { onClick(profileId); } }
    >
      <Text
        fontSize = "16px"
        fontWeight = "regular"
        color = { isDisabled ? 'grey-xl' : 'blue' }
        text = "Use Profile ID:"
      />
      <div className = "use-profile-id-as-slug-section__profile-id">
        <Text
          fontSize = "16px"
          fontWeight = "light"
          color = { isDisabled ? 'grey-xl' : 'blue' }
          text = { profileId }
        />
      </div>
    </div>
  );
};
