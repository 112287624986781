import React from 'react';
import './index.scss';

export default () => {
  return (
    <div className = "v2-campaigns-lite">
      <div className = "v2-campaigns-lite__img-wrapper">
        <img src = { require('images/v2/icon-target.svg') } alt = "" />
      </div>
      <div className = "v2-campaigns-lite__text">
        <img src = { require('./icon-campaigns-lite.svg') } alt = "" />
        <p className = "v2-campaigns-lite__description">Paddl is working hard on a bold new update to Campaigns, in the meantime we hope you enjoy FREE use of Campaigns Lite to attract talent your Business.</p>
        <a className = "v2-campaigns-lite__link" href = "https://blog.paddljobs.com/blog/paddl-releases-temporary-campaigns-lite-ahead-of-major-app-updates" target="_blank">Find out more</a>
      </div>
    </div>
  );
};
