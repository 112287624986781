import './navbar.scss';

import React from 'react';
import classNames from 'classnames';
import { PaddlLogo } from 'component-lib/components/paddl-logo';
import DocumentMeta from 'components/document-meta';
import SvgIcon from 'components/svg-icon';

export const Navbar = ({
  children,
  onClick,
  onSidenavToggle,
  title,
  subTitle,
  isExpanded,
  hasBack,
  hasLogo,
  isActive,
}) => (
  <div
    className={classNames(
      'navbar',
      { 'is--expanded': isExpanded },
      { 'has--back': hasBack },
      { 'has--logo': hasLogo },
      { 'is--active': isActive },
      { 'has--subtitle': subTitle },
    )}
  >
    { title && <DocumentMeta title={title} /> }
    { hasLogo && <PaddlLogo hasLink hasName /> }
    <a
      className="navbar__toggle"
      onClick={onSidenavToggle}
    />
    {
      title &&
      <div
        onClick={onClick}
        className="navbar__wrapper"
      >
        <SvgIcon
          id="arrow-left"
          className="navbar__back"
        />
        <div className="navbar__content">
          <div className="navbar__title" >{ isActive ? 'Back' : title }</div>
          <div className="navbar__subtitle">{ subTitle }</div>
        </div>
      </div>
    }

    { children }
  </div>
);
