import './onboarding-photo.scss';

import React                    from 'react';
import { connect }              from 'react-redux';
import { profileSelector }      from 'selectors/index';
import { toggleAvatarUploader } from 'modules/ui';
import Card                     from 'components/card';
import Button                   from 'components/button';
import ProfileInfoAvatar        from 'components/profile-info-avatar';

const OnboardingPhoto = ({ profile = {}, toggleAvatarUploader }) => {
  const isEditing = !profile.photo_url;

  return (
    <Card title = { `Upload ${profile.role === 'student' ? 'a Profile Picture' : 'your company logo'}` }>
      <div className = "onboarding-photo__avatar">
        <ProfileInfoAvatar { ...{ isEditing, toggleAvatarUploader, ...profile } } />
      </div>
      <ul className = "card__links">
        <li>
          <Button
            link = "/profile"
            isAction = { !isEditing }
            isFixed  = { !isEditing }
            isLink   = { isEditing }>
            { isEditing ? 'Skip for now' : 'Continue' }
          </Button>
        </li>
      </ul>
    </Card>
  );
};

export default connect(state => ({
  profile: profileSelector(state),
}), { toggleAvatarUploader })(OnboardingPhoto);
