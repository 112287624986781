import React       from 'react';
import formatDate  from './formatDate';
import formatPrice from './formatPrice';

const formatter = (type, value) => {
  switch (type) {
    case 'currency': return formatPrice(value)
    case 'date': return formatDate(value)
  }
}

const Formatted = ({
  type,
  value,
  ...props
}) => (
  <span>{ formatter(type, value) }</span>
);

export default Formatted;
