import './index.scss';
import React from 'react';

import StatusPanelButton          from 'components/v2/buttons/square-buttons/status-panel-button';
import BasecampTotalActivities    from 'components/v2/basecamp/basecamp-status-total-activities';
import BasecampTotalSkills        from 'components/v2/basecamp/basecamp-status-total-skills';
import BasecampCollectedXpSection from 'components/v2/basecamp/basecamp-status-collected-xp-section';
import BasecampDailyAverageXp     from 'components/v2/basecamp/basecamp-daily-average-xp';
import BasecampCommittedPerWeek   from 'components/v2/basecamp/basecamp-committed-per-week';

const BasecampStatusPanel = ({
  totalCollectedXp,
  totalActivities,
  totalSkills,
  dailyAverageXp,
  committedHoursPerWeek,
  viewStatsOnClick,
  hideStatsOnClick,
  isStatusPanelExpanded,
}) => {
  const renderBasecampStatusPanelExpanded = () => {
    return (
      <div className = "basecamp-status-panel basecamp-status-panel--expanded">
        <div className = "basecamp-status-panel__stats">
          <BasecampCollectedXpSection totalCollectedXp = { totalCollectedXp } />
          <BasecampTotalActivities totalActivities = { totalActivities } />
          <BasecampTotalSkills totalSkills = { totalSkills } />
        </div>
        <div className = "basecamp-status-panel__divider">
        </div>
        <div className = "basecamp-status-panel__progress-container">
          <BasecampDailyAverageXp dailyAverageXp = { dailyAverageXp } />
          <BasecampCommittedPerWeek committedHoursPerWeek = { committedHoursPerWeek } />
        </div>
        <div className = "basecamp-status-panel__hide-stats-btn">
          <StatusPanelButton copy = 'Hide Stats' onClick = { hideStatsOnClick } />
        </div>
      </div>
    );
  };

  const renderBasecampStatusPanelMobileView = () => {
    return (
      <div className = "basecamp-status-panel basecamp-status-panel--mobile-view">
        <div className = "basecamp-status-panel__stats">
          <BasecampCollectedXpSection totalCollectedXp = { totalCollectedXp } />
          <StatusPanelButton copy = 'View Stats' onClick = { viewStatsOnClick } />
        </div>
      </div>
    );
  };

  const renderBasecampStatusPanelCompactDesktopView = () => {
    return (
      <div className = "basecamp-status-panel basecamp-status-panel--compact-desktop-view">
        <div className = "basecamp-status-panel__stats">
          <BasecampCollectedXpSection totalCollectedXp = { totalCollectedXp } />
          <BasecampTotalActivities totalActivities = { totalActivities } />
          <BasecampTotalSkills totalSkills = { totalSkills } />
          <StatusPanelButton copy = 'View Stats' onClick = { viewStatsOnClick } />
        </div>
      </div>
    );
  };

  const renderBasecampStatusPanelLooseDesktopView = () => {
    return (
      <div className = "basecamp-status-panel basecamp-status-panel--loose-desktop-view">
        <div className = "basecamp-status-panel__stats">
          <BasecampCollectedXpSection totalCollectedXp = { totalCollectedXp } />
          <BasecampTotalActivities totalActivities = { totalActivities } />
          <BasecampTotalSkills totalSkills = { totalSkills } />
        </div>
        <div className = "basecamp-status-panel__divider">
        </div>
        <div className = "basecamp-status-panel__progress-container">
          <BasecampDailyAverageXp dailyAverageXp = { dailyAverageXp } />
          <BasecampCommittedPerWeek committedHoursPerWeek = { committedHoursPerWeek } />
        </div>
      </div>
    );
  };

  if (isStatusPanelExpanded) {
    return (
      <div>
        { renderBasecampStatusPanelExpanded() }
      </div>
    );
  }

  return (
    <div>
      { renderBasecampStatusPanelMobileView() }
      { renderBasecampStatusPanelCompactDesktopView() }
      { renderBasecampStatusPanelLooseDesktopView() }
    </div>
  );
};

export default BasecampStatusPanel;
