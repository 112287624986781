import { getToken } from 'modules/auth';

export default ({ dispatch }) => ({
  path: '/welcome(/step/:stepId)',
  getComponent: (props, cb) => {
    const { location } = props;

    if (location.query.email && location.query.token) {
      dispatch(getToken({ ...location.query }))
        .then(({ payload: { entities, result }}) => {
          return {
            role: entities.profiles[result[0]].role
          }
        })
        .then(({ role }) => {
          cb(null, require(`pages/onboarding/${role}/index`).default)
        })
        .catch(console.log)
    }
  }
});
