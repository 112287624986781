import { fetchApplications } from 'modules/applications';
import { fetchConversation } from 'modules/conversations';
import { fetchJob }          from 'modules/jobs';
import { fetchProfile }      from 'modules/profiles';
import { fetchSettings }     from 'modules/ui';
import { fetchVillages }     from 'modules/villages';
import realV1deps            from 'lib/real-v1deps';
import Appv2                 from 'lib/appv2';
import { STUDENT }           from 'lib/auth';

export const INIT_APP_REQUEST = 'app/INIT_APP_REQUEST';
export const INIT_APP_ERROR   = 'app/INIT_APP_ERROR';
export const INIT_APP_SUCCESS = 'app/INIT_APP_SUCCESS';

export function initApp() {
  return (store) => {
    const { dispatch, getState } = store;
    const state = getState();

    dispatch({
      type: INIT_APP_REQUEST,
      meta: { isFetching: true }
    });

    const appv2 = new Appv2({ store, v1deps: realV1deps });

    return appv2.featureFlags.refreshAll().then(() => {
      let loadDataIntoStore;

      if (appv2.featureFlags.isEnabled({ featureName: 'prepopulateLegacyStore' })) {
        loadDataIntoStore = appv2.prepopulateLegacyStore();
      } else {
        loadDataIntoStore = Promise.all([
          dispatch(fetchApplications()),
          dispatch(fetchConversation()),
          dispatch(fetchJob()),
          dispatch(fetchVillages()),
        ]);
      }

      return Promise.all([
        loadDataIntoStore,
        dispatch(fetchProfile(state.auth.profile_id)),
        dispatch(fetchSettings()),
      ]).then(() => {
        return dispatch({
          type: INIT_APP_SUCCESS,
          meta: { isFetching: false },
        });
      }).then(() => {
        if (appv2.auth.currentProfileRole() !== STUDENT) {
          return null;
        }

        appv2.api.profiles
          .fetchIsOnboarded({ id: appv2.auth.currentProfileId() })
          .then((isOnboarded) => {
            if (window.location.pathname.indexOf('/onboarding') === -1) {
              if (!isOnboarded) {
                return appv2.ui.redirect({ url: '/onboarding' });
              }
            } else {
              if (isOnboarded) {
                return appv2.ui.redirect({ url: '/jobs' });
              }
            }
          });
      });
    });
  };
}
