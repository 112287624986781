import requireAuth  from './require-auth';
import { fetchJob } from 'modules/jobs';
import { NotFound } from 'components/not-found';

export default store => ({
  path: 'jobs',
  indexRoute: {
    title: () => store.getState().auth.role === 'employer' ? 'Campaigns' : 'Jobs',
    isIndex: true,
    getComponent: (location, cb) => {
      const { role } = store.getState().auth;

      if (role) {
        cb(null, require(`pages/jobs/${role}/index`).default);
      }
    }
  },
  childRoutes: [{
    path: 'archive',
    title: 'Archive',
    subTitle: 'Back To Jobs',
    link: '/jobs',
    onEnter: requireAuth(store, 'employer'),
    getComponent: (location, cb) => cb(null, require('pages/jobs/employer/archive').default)
  }, {
    path: 'new',
    title: 'New Job',
    subTitle: 'Back To Jobs',
    link: '/jobs',
    onEnter: requireAuth(store, 'employer'),
    getComponent: (location, cb) => cb(null, require('pages/jobs/create/index').default)
  }, {
    path: ':id',
    title: ({ id }) => store.getState().jobs.entities[id] && store.getState().jobs.entities[id].title,
    subTitle: 'Back To Jobs',
    link: '/jobs',
    isPublic: true,
    onEnter: (nextState, replace, cb) => {
      const id = nextState.params.id;
      store.dispatch(fetchJob(id))
      .then((data) => {
        if (data === undefined) {
          cb(null, replace({ pathname: '/not-found', state: { nextPathname: nextState.location.pathname } }));
        } else {
          cb(null);
        }
      });
    },
    getComponent: ({ params: { id } }, cb) => {
      const { role } = store.getState().auth;

      if (role) {
        cb(null, require(`pages/jobs/${role}/item-view`).default);
      } else {
        store.dispatch(fetchJob(id)).then(() => {
          cb(null, require('pages/jobs/anonymous/item-view').default);
        });
      }
    }
  }, {
    path: ':id/edit',
    title: 'Edit Job',
    subTitle: 'Back To Jobs',
    link: '/jobs',
    onEnter: requireAuth(store, 'employer'),
    getComponent: (location, cb) => cb(null, require('pages/jobs/create/index').default)
  }, {
    path: ':id/confirm',
    title: 'Payment',
    isIndex: true,
    onEnter: requireAuth(store, 'employer'),
    getComponent: (location, cb) => cb(null, require('pages/jobs/payment/index').default)
  },
  {
    path: 'not-found',
    component: NotFound
  }]
});
