import './application-challenges.scss';

import PropTypes         from 'prop-types';
import React             from 'react';
import JobChallengesList from 'components/job-challenges-list';

const ApplicationChallenges = ({ challenges }) => {
  if (!challenges || challenges.length === 0) return null;

  return (
    <div className = "application-challenges">
      <div className = "application-challenges__header">
        <div className = "application-challenges__title">
          Application Challenges
        </div>
        <div className = "application-challenges__tagline">
          Complete these challenges during your application
        </div>
      </div>
      <JobChallengesList challenges = { challenges } />
    </div>
  );
};
ApplicationChallenges.propTypes = {
  challenges: PropTypes.array
};
export default ApplicationChallenges;
