import React     from 'react';
import Formatted from 'components/formatted';

const PayRate = (props) => {
  const {
    pay_rate,
    isDefaultTheme
  } = props;


  const pay_rate_value = (input) => {
    switch(input) {
      case undefined:
      case null:      return 'Private';
      case 0:         return 'Volunteer'
      default:        return `$ ${input}`
    }
  }

  const pay_rate_text = (input) => {
    switch(input) {
      case undefined:
      case null:      return 'Paid Role'
      case 0:         return 'Unpaid Position'
      default:        return 'Per Hour'
    }
  }

  return(
    <div className = "job-details__info__item">
      <span
        className = {
          `job-details__info__indicator
           ${isDefaultTheme ? 'is--default' : 'is--highlighted-primary'}` }>
        { pay_rate_value(pay_rate) }
      </span>
      <span className = "job-details__info__label">
        { pay_rate_text(pay_rate) }
      </span>
    </div>
  )
}

export default PayRate;
