import './index.scss';
import React from 'react';

import Text                          from 'component-lib/type/text';
import { style26pxRegularBlueL }     from 'component-lib/type/text/styles';
import { style14pxRegularBluegreyD } from 'component-lib/type/text/styles';

const BasecampTotalActivities = ({ totalActivities }) => {
  return (
    <div className = "basecamp-total-activities">
      <Text { ...style26pxRegularBlueL } text = { totalActivities } />
      <Text { ...style14pxRegularBluegreyD } text = 'Activities' />
    </div>
  );
};

export default BasecampTotalActivities;
