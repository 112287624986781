import './index.scss';
import React from 'react';

import State       from 'components/v2/profile/company-summary-content/state';
import Data        from 'components/v2/profile/company-summary-content/data';
import UI          from 'components/v2/profile/company-summary-content/ui';
import UIBehaviour from 'components/v2/profile/company-summary-content/ui-behaviour';

import CompanyStraplinePanelForm from 'components/v2/profile/company-strapline-panel/form';
import CompanyStraplinePanelView from 'components/v2/profile/company-strapline-panel/view';
import CompanyStraplinePanelAdd  from 'components/v2/profile/company-strapline-panel/add';

import CompanyBiographyPanelForm from 'components/v2/profile/company-biography-panel/form';
import CompanyBiographyPanelView from 'components/v2/profile/company-biography-panel/view';
import CompanyBiographyPanelAdd  from 'components/v2/profile/company-biography-panel/add';

class CompanySummaryContent extends React.Component {
  constructor(props) {
    const { appv2 } = props;

    super(props);

    const state = new State({ component: this });
    this._data = new Data({ state, appv2 });

    const ui = new UI({ state, appv2 });
    this._uiBehaviour = new UIBehaviour({ ui, canEdit: props.canEdit });
  }

  UNSAFE_componentWillMount() {
    this._data.initializeState({ profile: this.props.profile });
    this._uiBehaviour.reset();
  }

  render() {
    const data = this._data;
    const uiBehaviour = this._uiBehaviour;

    const renderCompanyStraplinePanel = ({ data, uiBehaviour }) => {
      if (uiBehaviour.isStraplineFormOpen()) {
        return <CompanyStraplinePanelForm data = { data } uiBehaviour = { uiBehaviour } />;
      }

      if (data.isStraplineDataPresent()) {
        return <CompanyStraplinePanelView data = { data } uiBehaviour = { uiBehaviour } />;
      }

      if (uiBehaviour.canEdit) {
        return <CompanyStraplinePanelAdd uiBehaviour = { uiBehaviour } />;
      }

      return null;
    };

    const renderCompanyBiographyPanel = ({ data, uiBehaviour }) => {
      if (uiBehaviour.isSummaryFormOpen()) {
        return <CompanyBiographyPanelForm data = { data } uiBehaviour = { uiBehaviour } />;
      }

      if (data.isSummaryDataPresent()) {
        return <CompanyBiographyPanelView data = { data } uiBehaviour = { uiBehaviour } />;
      }

      if (uiBehaviour.canEdit) {
        return <CompanyBiographyPanelAdd uiBehaviour = { uiBehaviour } />;
      }

      return null;
    };

    return (
      <div className = "v2-company-summary-content">
        <div className = "v2-company-summary-content__panel">
          { renderCompanyStraplinePanel({ data, uiBehaviour }) }
        </div>
        <div className = "v2-company-summary-content__panel">
          { renderCompanyBiographyPanel({ data, uiBehaviour }) }
        </div>
      </div>
    );
  }
};

export default CompanySummaryContent;
