import React               from 'react';
import { connect }         from 'react-redux';
import { setPassword }     from 'modules/auth';
import { updateProfile }   from 'modules/profiles';
import { TYPES }           from 'modules/forms/constants';
import { FORM_ID }         from 'modules/onboarding/constants';
import { profileSelector } from 'selectors/index';
import Card                from 'components/card';
import Button              from 'components/button';
import getForm             from 'components/form';
import FormRow             from 'components/form-row';
import FormField           from 'components/form-field';

const Form = getForm({
  id: FORM_ID,
  destroyOnUnmount: false
});

class OnboardingPassword extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit({ password, full_name, date_of_birth }) {
    const {
      setPassword,
      updateProfile,
      onComplete,
      isSubmitting,
      profile: { id, email },
      auth:    { onboarding_token: token, role }
    } = this.props;

    return setPassword({ token, email, password })
            .then(() => role === 'student' && updateProfile({ id, full_name, date_of_birth }))
            .then(onComplete);
  }
  render() {
    const {
      isSubmitting,
      isAgreed
    } = this.props;

    return (
      <Form onSubmit  = { this.handleSubmit } >
        <Card title     = "Create a Password">
          <div className = "card__content">
            <FormRow>
              <FormField
                label = "Enter your password"
                type  = { TYPES.PASSWORD }
                name  = "password" />
            </FormRow>
            <FormRow>
              <FormField
                label = "Confirm your password"
                type  = { TYPES.PASSWORD }
                name  = "password_confirm" />
            </FormRow>
            <FormRow className = "is--centered">
              <FormField
                type        = { TYPES.CHECKBOX }
                name        = "agreedterms" >
                <span>I agree to the&nbsp;</span>
                <a
                  className = "Link"
                  href      = "/terms"
                >
                  Terms & Conditions
                </a>
              </FormField>
            </FormRow>
            <FormRow className = "is--centered is--spacer">
              <Button
                className = "is--action is--fixed"
                disabled  = { isSubmitting || !isAgreed }>
                Continue
              </Button>
            </FormRow>
          </div>
        </Card>
      </Form>
    );
  }
}

export default connect(state => ({
  isSubmitting: state.form[FORM_ID] && state.form[FORM_ID].submitting,
  isAgreed:     state.form[FORM_ID] && state.form[FORM_ID].values && state.form[FORM_ID].values.agreedterms,
  profile:      profileSelector(state),
  auth:         state.auth
}), { setPassword, updateProfile })(OnboardingPassword);
