import React from 'react';
import classNames from 'classnames';

import AccessTitles from 'components/v2/register/AccessTitles/AccessTitles';
import { style16pxLightBluegrey } from 'component-lib/type/text/styles';
import Text from 'component-lib/type/text';
import ActivityCardSuggestionList from 'component-lib/components/activity-card-suggestion-list';

import './index.scss';

const VARIANT_IDS = [7, 5, 1];

class ActivityCardPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activities: [],
    };

    this._isAnythingCreated = () => {
      return this.state.activities.some((a) => (VARIANT_IDS.indexOf(a.get('variantId')) > -1));
    };
  }

  UNSAFE_componentWillMount() {
    const { appv2 } = this.props;
    const currentProfileId = appv2.auth.currentProfileId();

    appv2.api.activities
      .fetchTimeline({ profileId: currentProfileId, isXpFeatureEnabled: false, isLoggedIn: true })
      .then((activities) => {
        this.setState({
          activities,
        });

        if (this._isAnythingCreated() && window.location.pathname.indexOf('add/another') === -1) {
          return appv2.ui.redirect({ url: '/onboarding/add/another' });
        }
      });
  }

  render() {
    const { appv2 } = this.props;

    const onFinishClick = () => {
      appv2.api.profiles.finishOnboarding().then(() => {
        return appv2.ui.redirect({ url: '/jobs' });
      });
    };

    return (
      <div className="v2-get-started-student-activity-card">
        <AccessTitles
          heading={
            this._isAnythingCreated()
              ? 'Add another?'
              : 'Add an Activity Card'
          }
          subheading={
            this._isAnythingCreated()
              ? 'Would you like to add one more from below?'
              : 'Choose an Activity below to add it to your Profile...'
          }
        />

        <ActivityCardSuggestionList
          appv2={appv2}
          variantIds={VARIANT_IDS}
          activities={this.state.activities}
          containerClassName="v2-get-started-student-activity-card"
          itemShouldBeDisabledIfInList
          listShouldBeFilteredAndShuffled={false}
          isHidingEnabled={false}
        />

        <div className="v2-get-started-student-activity-card__btn-wrapper">
          <button
            className={classNames('v2-get-started-student-activity-card__btn', {
              'v2-get-started-student-activity-card__btn--done': this._isAnythingCreated(),
              'v2-get-started-student-activity-card__btn--skip': !this._isAnythingCreated(),
            })}
            type="button"
            onClick={onFinishClick}
          >
            { this._isAnythingCreated() ? 'All Done!' : 'Skip for now' }
          </button>
        </div>

        {
          !this._isAnythingCreated() &&
          <Text {...style16pxLightBluegrey} text="You can add Activity cards to your profile at any time." />
        }
      </div>
    );
  }
}

ActivityCardPage.navBarStep = 2;

export default ActivityCardPage;
