import './avatar-uploader.scss';

import React                    from 'react';
import AvatarEditor             from 'react-avatar-editor';
import Button                   from 'components/button';
import Modal                    from 'components/modal';
import SvgIcon                  from 'components/svg-icon';
import { connect }              from 'react-redux';
import { uploadImageToS3 }      from 'modules/profiles';
import { toggleAvatarUploader } from 'modules/ui';
import dataURLtoBlob            from 'utils/dataurl-to-blob';

const CANVAS_SIZE = 138;

class AvatarUploader extends React.Component {
  constructor(props) {
    super();
    this.state = {
      scale: 1,
      image: props.image,
      file: null,
    };
    this.handleScale      = this.handleScale.bind(this);
    this.handleSubmit     = this.handleSubmit.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleDelete     = this.handleDelete.bind(this);
  }
  UNSAFE_componentWillUpdate({ isOpen, image }) {
    if (!isOpen && this.props.isOpen) {
      this.setState({ scale: 1, image });
    }
  }
  handleScale({ target }) {
    this.setState({ scale: parseFloat(target.value) });
  }
  handleSubmit() {
    const {
      uploadImageToS3,
      id,
      isUploading,
      onClose,
      onSubmit,
    } = this.props;

    if (isUploading)      return;
    if (!this.state.file) return onClose();

    const image = {
      original: dataURLtoBlob(this.state.file),
      resized:  dataURLtoBlob(this.editor.getImageScaledToCanvas().toDataURL())
    };

    uploadImageToS3({ image, id, withoutNotifications: true })
      .then(() => {
        onClose();
        onSubmit && onSubmit();
      });
  }
  handleFileChange({ target }) {
    const reader  = new FileReader();
    const file    = target.files[0];
    reader.onload = ({ target: { result } }) => this.setState({ file: result });
    reader.readAsDataURL(file);
  }
  handleDelete() {
    this.setState({ image: null, file: null })
  }

  setEditorRef = (editor) => this.editor = editor

  render() {
    const {
      isOpen, isUploading,
      onClose
    } = this.props;

    const {
      image, file
    } = this.state;

    const avatar = file || image;

    return (
      <Modal { ...{ onClose, isOpen } } isSmall>
        <div className = {
              'avatar-uploader' +
              `${isUploading ? ' is--uploading' : ''}` +
              `${!avatar      ? ' is--empty'     : ''}` } >
          { avatar &&
            <a
              onClick = { this.handleDelete }
              className = "avatar-uploader__delete" >
              <SvgIcon id = "circle-close" />
            </a>
          }
          <div className = "avatar-uploader__canvas">
            { avatar
              ? <AvatarEditor
                  ref        = {this.setEditorRef}
                  image      = { avatar }
                  width      = { CANVAS_SIZE }
                  height     = { CANVAS_SIZE }
                  border     = { 0 }
                  scale      = { this.state.scale } />
              : <input
                  type      = "file"
                  accept    = "image/*"
                  onChange  = { this.handleFileChange }
                  className = "avatar-uploader__file" />
              }
          </div>
          <div className = "avatar-uploader__range">
            <input
              className = "avatar-uploader__range__input"
              type      = "range"
              ref       = "scale"
              onChange  = { this.handleScale }
              min       = "0.25"
              max       = "2"
              step      = "0.01"
              disabled  = { !avatar || isUploading }
              value     = { this.state.scale } />
          </div>
          <Button
            type      = "submit"
            disabled  = { !avatar || isUploading }
            onClick   = { this.handleSubmit }
            isAction
            isBlock >
            Done
          </Button>
        </div>
      </Modal>
    )
  }
}

export default connect(
  ({ ui, profiles, auth, api }) => ({
    isOpen:      ui.isAvatarUploaderOpen,
    image:       profiles[auth.profile_id] && profiles[auth.profile_id].photo_url,
    id:          auth.profile_id,
    isUploading: api.fetching.indexOf('avatar') > -1
  }),
  { onClose: toggleAvatarUploader, uploadImageToS3 }
)(AvatarUploader)
