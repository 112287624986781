import './filter-tabs.scss';

import React     from 'react';
import PropTypes from 'prop-types';
import isArray   from 'lodash/isArray';
import without   from 'lodash/without';
import union     from 'lodash/union';
import map       from 'lodash/map';
import SvgIcon   from 'components/svg-icon';
import { Link }  from 'react-router';

const MODES_MAP = {
  tabs: ({ query, selector }) => ({
    isActive: ({ index, id }) => index > 0 ? query === id : !query,
    getQuery: ({ index, id})  => index > 0 && { [selector]: id }
  }),
  buttons: ({ query, selector }) => {
    const values = query
      ? isArray(query) ? query : [query]
      : '';

    return {
      isActive: ({ id }) => values.indexOf(id) > -1,
      getQuery: ({ id }) => ({
        ...query,
        ...{
          [selector]: values.indexOf(id) > -1
            ? without(values, id)
            : union(values, [id])
          }
      })
    };
  }
};

const FilterTabs = (props, context) => {
  const {
    items,
    selector = 'tab',
    theme    = 'buttons',
    mode     = 'buttons',
    hasSize  = false
  } = props;
  
  // This was re-written as part of the webpack upgrade, 
  // as 'context' is no longer available as a second prop
  const { pathname, search } = window.location;
  const urlParams = new URLSearchParams(search);
  const query = urlParams.get('location');

  const {
    getQuery, isActive
  } = MODES_MAP[mode]({ query, selector });
  const ids         = map(items, v => v.id);
  const activeIndex = ids.indexOf(query) > 0 ? ids.indexOf(query) : 0;

  return (
    <div className = { `filter-tabs--${theme}` }>
      { items.map((tab, index) =>
        <Link
          key       = { tab.id }
          to        = { { pathname, query: getQuery({ index, id: tab.id }) } }
          className = {
            'filter-tabs__item' +
            `${tab.disabled                    ?' is--disabled' : ''}` +
            `${isActive({ index, id: tab.id }) ?' is--active':''}`     +
            `${hasSize                         ?` is--${tab.id}`:''}`
          } >
          { theme === 'buttons' &&
            <SvgIcon
              id        = { tab.id }
              className = "filter-tabs__icon" />
          }
          { hasSize &&
            <span className="filter-tabs__item__size">{tab.size > 0 ? tab.size : 0}</span>
          }
          { tab.title }
        </Link>
      )}
      { theme === 'tabs' &&
        <span
          className = "filter-tabs__underline"
          style     = {{
            width: '25%',
            transform: `translate3d(${activeIndex * 100 + (400 - items.length * 100) / 2 }%, 0px, 0px)`
          }} />
      }
    </div>
  );
};

export default FilterTabs;
