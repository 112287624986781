import './index.scss';
import React from 'react';
import SvgIconCircleAdd from 'components/v2/svg-icons/svg-icon-circle-add';

const CompanyStraplinePanelAdd = ({ uiBehaviour }) => {
  return (
    <div className = "v2-company-strapline-panel-add">
      <div
        className = "v2-company-strapline-panel-add__button"
        onClick   = { () => { uiBehaviour.clickToEditStrapline(); } }
      >
        <div className = "v2-company-strapline-panel-add__label">
          <SvgIconCircleAdd />
          <span className = "v2-company-strapline-panel-add__label-copy">
            Add a Company Strapline
          </span>
        </div>
      </div>
    </div>
  );
};

export default CompanyStraplinePanelAdd;
