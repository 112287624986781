// Sentry error reporting
// https://github.com/getsentry/sentry-javascript
import * as Sentry from '@sentry/browser';
import config from 'config';

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: config.SENTRY_DSN,
  });
}
