import React                   from 'react';
import { connect }             from 'react-redux';
import withEditConfirm         from 'containers/withEditConfirm';
import { applicationSelector } from 'selectors';
import {
  shortlist,
  reject
} from 'modules/applications';
import {
  toggleModal
} from 'modules/ui';

const ApplicationView = RoleView => (props) => <RoleView { ...props } />;

export default RoleView => connect((state, { params: { id }}) => ({
  application: applicationSelector(state, id),
  openedModals: state.ui.openedModals,
  isEditing: state.applications.application.editingCards
          && state.applications.application.editingCards.length > 0
}), { shortlist, reject, toggleModal })(withEditConfirm(ApplicationView(RoleView)));
