import './page-panel-header.scss';

import React from 'react';

const PagePanelHeader = ({
  title,
  subtitle,
  thumb
}) => {
  return (
    <div className = "page-panel__header">
      { thumb &&
        <div className = "page-panel__thumb">
          { thumb }
        </div>
      }
      <div className = "page-panel__wrapper">
        <div className = "page-panel__title">
          { title }
        </div>
        { subtitle &&
          <div className = "page-panel__subtitle">
            { subtitle }
          </div>
        }
      </div>
    </div>
  );
};
export default PagePanelHeader;
