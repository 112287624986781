import React from 'react';
import { connect } from 'react-redux';

import { TYPES } from 'modules/forms/constants';
import Card from 'components/card';
import Button from 'components/button';
import getForm from 'components/form';
import FormRow from 'components/form-row';
import FormField from 'components/form-field';
import { RESET_PASSWORD_FORM } from 'modules/auth/constants';
import { resetPassword } from 'modules/auth';

const Form = getForm({ id: RESET_PASSWORD_FORM });

const ResetPasswordRoute = ({ resetPassword, isSubmitting, location: { query } }) => (
  <Card
    title="Reset Password"
    subtitle="Confirm your email"
    isCentered
  >
    <Form
      initialValues={query}
      onSubmit={resetPassword}
    >
      <div className="card__content">
        <FormRow>
          <FormField
            disabled
            type={TYPES.TEXT}
            name="email"
          />
        </FormRow>
        <FormRow>
          <FormField
            placeholder="Enter new password"
            type={TYPES.PASSWORD}
            name="password"
          />
        </FormRow>
        <FormRow>
          <FormField
            placeholder="Confirm new password"
            type={TYPES.PASSWORD}
            name="password_again"
          />
        </FormRow>
        <FormRow className="is--centered is--spacer">
          <Button
            className="is--action is--fixed"
            disabled={isSubmitting}
          >
            Reset Password
          </Button>
        </FormRow>
      </div>
    </Form>
  </Card>
);

export default connect(({ form }) => ({
  isSubmitting: form && form[RESET_PASSWORD_FORM] && form[RESET_PASSWORD_FORM].submitting,
}), { resetPassword })(ResetPasswordRoute);
