import React           from 'react';
import { connect }     from 'react-redux';
import { submit }      from 'redux-form';
import getForm         from 'components/form';
import FormCard        from 'components/form-card';
import Modal           from 'components/modal';
import Button          from 'components/button';
import PageActions     from 'components/page-actions';
import { TYPES }       from 'modules/forms/constants';

import { applicationSelector } from 'selectors';
import {
  editCard,
  saveCard,
  updateApplication,
  toggleModalConfirm,
  submitApplication,
  cancelApplication
} from 'modules/applications';

import {
  createValidations,
  required,
  maxLength
} from 'utils/validations';

const FORM_ID = 'application-form';

const Form = getForm({
  id: FORM_ID,
  enableReinitialize: false,
  // destroyOnUnmount: false,
  warn: createValidations({
    cover_note:  [required, maxLength(1000)],
    applicant_mobile: [required, maxLength(15)],
    applicant_skype: maxLength(100),
  })
});

const CARDS = [{
  id: 'cover-note',
  title: 'Cover Note',
  fields: [
    {
      name: 'cover_note',
      type: TYPES.TEXTAREA,
      description: 'Share why you\'re a great choice for this opportunity...',
      attrs: { rows: 3 }
    }
  ]
}, {
  id: 'contact-details',
  title: 'Your Contact Details',
  description: 'The employer will use these to contact you during your application.',
  modifiers: { hasOneColumn: true },
  fields: [
    {
      name: 'applicant_email',
      type: TYPES.TEXT,
      icon: 'envelope',
      placeholder: '-',
      disabled: true
    },
    {
      name: 'applicant_mobile',
      type: TYPES.TEXT,
      icon: 'phone',
      placeholder: '-',
    },
    {
      name: 'applicant_skype',
      type: TYPES.TEXT,
      icon: 'skype',
      placeholder: '-',
    }
  ]
}];

class ApplicationFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit        = this.handleFormSubmit.bind(this);
    this.handleSaveCard          = this.handleSaveCard.bind(this);
    this.handleApplicationSubmit = this.handleApplicationSubmit.bind(this);
    this.handleApplicationCancel = this.handleApplicationCancel.bind(this);
  }
  UNSAFE_componentWillMount() {
    const {
      isInProgress,
      editCard,
      cover_note
    } = this.props;

    if (isInProgress && !cover_note) {
      editCard({ id: 'cover-note' });
    }
  }
  handleSaveCard(id) {
    this.props.submit(FORM_ID);
    this.props.saveCard(id);
  }
  handleFormSubmit(values) {
    this.props.updateApplication({
      id: this.props.id,
      values
    });
  }
  handleApplicationSubmit() {
    const {
      submitApplication,
      id
    } = this.props;

    submitApplication(id);
  }

  handleApplicationCancel() {
    const {
      cancelApplication,
      id
    } = this.props;

    cancelApplication(id);
  }
  render() {
    const {
      editCard,
      editingCards,
      cover_note,
      applicant_email,
      applicant_mobile,
      applicant_skype,
      toggleModalConfirm,
      isConfirmVisible,
      isInProgress,
      isCompleted
    } = this.props;

    const ModalAction = (
      <Button
        type = "button"
        onClick = { this.handleApplicationSubmit }
        isAction
        isBlock
        hasShadow>
        Accept & Submit
      </Button>
    );

    return (
      <Form
        initialValues  = { { cover_note, applicant_email, applicant_mobile, applicant_skype } }
        onSubmit       = { this.handleFormSubmit } >
        { CARDS.map(({ id, title, fields, modifiers, children, description }) => (
          <FormCard
            id          = { id }
            key         = { id }
            title       = { title }
            description = { description }
            fields      = { fields }
            isEditing   = { isInProgress && editingCards.indexOf(id) > -1 }
            onEdit      = { isInProgress && editCard }
            onSave      = { isInProgress && this.handleSaveCard }
            modifiers   = { modifiers }
            children    = { children } />
        )) }
        { isInProgress &&
          <Modal
            icon = "icon-gdpr"
            onClose = { toggleModalConfirm }
            action  = { ModalAction }
            isOpen  = { isConfirmVisible }
            isLarge
            hasCancel >
            <div className = "modal__title">Confirm Submit</div>
            <p>Some international providers are not covered by GDPR. Submitting your information may mean you are not protected by the same privacy regulations.</p>
          </Modal>
        }
        { isInProgress &&
          <PageActions type = "bottom">
            <Button
              onClick = { this.handleApplicationCancel }
              isLink >
              Cancel
            </Button>
            <Button
              disabled = { !isCompleted }
              onClick  = { toggleModalConfirm }
              isPrimary>
              Submit Application
            </Button>
          </PageActions>
        }
      </Form>
    );
  }
}

export default connect((state, { id }) => {
  const application = applicationSelector(state, id);

  const {
    conversation: {
      campaign_question_answers = [],
      question_count = []
    },
    cover_note,
    applicant_email,
    applicant_mobile,
    applicant_skype
  } = application;

  const isChallengeCompleted = question_count === (campaign_question_answers && campaign_question_answers.length);
  const editingCards = state.applications.application.editingCards;
  const isCompleted = isChallengeCompleted
    && (cover_note && applicant_email && applicant_mobile)
    && editingCards.length === 0;

  return {
    id,
    cover_note,
    applicant_email,
    applicant_mobile,
    applicant_skype,
    editingCards,
    isConfirmVisible: state.applications.application.isConfirmVisible,
    isInProgress: application.status.status === 'in_progress',
    isCompleted
  };
}, {
  editCard,
  saveCard,
  updateApplication,
  submit,
  toggleModalConfirm,
  submitApplication,
  cancelApplication
})(ApplicationFormContainer);
