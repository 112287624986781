import './page-list-nav.scss';

import React       from 'react';
import PropTypes   from 'prop-types';
import SvgIcon     from 'components/svg-icon';
import PageSection from 'components/page-section';


const PageListNav = ({
  onBackClick,
  onForwardClick,
  title,
  currentItem,
  itemsLength
}) => {
  if (!title) return null;

  return (
    <PageSection isCta>
      <div className = "page-list-nav">
        <div className = "page-list-nav__arrow" onClick = { onBackClick }>
          { onBackClick && <SvgIcon id = "arrow-left" /> }
        </div>
        <div className = "page-list-nav__content">
          <div className = "page-list-nav__title">{ title } </div>
          { itemsLength &&
            <div className = "page-list-nav__counter">
              Application <b>{currentItem}</b> of {itemsLength}
            </div>
          }
        </div>
        <div className = "page-list-nav__arrow" onClick = { onForwardClick }>
          { onForwardClick && <SvgIcon id = "arrow-left" className = "is--right" /> }
        </div>
      </div>
    </PageSection>
  );
};

PageListNav.propTypes = {
  onBackClick: PropTypes.func,
  onForwardClick: PropTypes.func,
  title: PropTypes.string,
  currentItem: PropTypes.number,
  itemsLength: PropTypes.number
};

export default PageListNav;
