import React from 'react';
import { reduxForm } from 'redux-form';
import PageActions from 'components/page-actions';
import FormField from 'components/form-field';
import FormRow from 'components/form-row';

import './form.scss';

const Form = (props) => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    fields,
    getActions,
    children,
    className,
    initialValues = {},
    isEditing,
    actions,
    canEdit,
    error,
  } = props;

  const pageActions = actions || getActions && { items: (getActions && getActions({ reset, pristine, submitting })[isEditing ? 'edit' : 'index']) };

  return (
    <form
      className={`form ${className ? className : ''}${submitting ? ' is--submitting' : ''}`}
      onSubmit={handleSubmit}
    >
      { !pristine && error && <div className="form__error">{ error }</div> }
      { fields && fields.map((props, i) => (
        <FormRow key={i}>
          <FormField {...{ isEditing, canEdit, value: initialValues[props.name], ...props }} />
        </FormRow>
      )) }
      { children }
      { pageActions && <PageActions {...pageActions} /> }
    </form>
  );
};

export default ({
  id,
  ...props
}) => reduxForm({
  form: id,
  ...props,
})(Form);
