import './form-section.scss';

import React from 'react';

export default ({ icon, children, className = '' }) => (
  <div className = { `form__section ${className}` }>
    { icon && <span className = { `form__section__icon icon-${icon}` } /> }
    { children }
  </div>
)
