import React               from 'react';
import { connect }         from 'react-redux';
import MainView            from '../item-view';
import PageActions         from 'components/page-actions';
import EditJobButton       from 'components/v2/buttons/circle-buttons/edit-job-button';
import ShareJobButton      from 'components/v2/buttons/circle-buttons/share-job-button';
import { toggleModal }     from 'modules/ui';
import { SHARE_MODAL_ID }  from 'modules/ui/constants';

const JobViewIndexEmployer = ({
  userCanEditJob,
  job,
  toggleModal
}) => {
  const jobIsOpen = (job.status === 'open');
  return (
    <div>
      <PageActions>
        { userCanEditJob &&
          <EditJobButton link = { `/jobs/${job.id}/edit` }/>
        }
        { jobIsOpen &&
          <ShareJobButton onClick = { toggleModal }/>
        }
      </PageActions>
    </div>
  );
};

const authedProfileOwnsJob = (job, auth)       => (job.employer.id === auth.profile_id)
const mapStateToProps      = (state, ownProps) => ({ userCanEditJob: authedProfileOwnsJob(ownProps.job, state.auth) })
const dispatchToProps      = (dispatch)        => ({ toggleModal: () => dispatch(toggleModal(SHARE_MODAL_ID)) })

export default MainView(connect(mapStateToProps, dispatchToProps)(JobViewIndexEmployer));
