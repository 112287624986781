import React                            from 'react';
import { connect }                      from 'react-redux';
import List                             from 'components/list';
import PageTitle                        from 'components/page-title';
import PageContent                      from 'components/page-content';
import PagePanels                       from 'components/panels';
import EmptyView                        from 'components/empty-view';
import JobApplicationsListPanel         from 'containers/job-applications-list-panel';
import { jobsWithApplicationsSelector } from 'selectors';

const ApplicationsEmployerIndex = ({
  items
}) => (
  <PageContent>
    <PageTitle>Applications</PageTitle>
    <PagePanels />
    <List
      key       = "list"
      items     = { items }
      Component = { JobApplicationsListPanel }
    />
    { items.length === 0 && <EmptyView /> }
  </PageContent>
);

export default connect(state => ({
  items: jobsWithApplicationsSelector(state)
}))(ApplicationsEmployerIndex);
