import * as selectors                from 'selectors';
import * as modulesApp               from 'modules/app';
import * as modulesProfiles          from 'modules/profiles';
import * as modulesAuth              from 'modules/auth';
import * as modulesUi                from 'modules/ui';
import * as componentsAvatarUploader from 'components/avatar-uploader';

export default {
  'selectors':                  selectors,
  'modules/app':                modulesApp,
  'modules/profiles':           modulesProfiles,
  'modules/auth':               modulesAuth,
  'modules/ui':                 modulesUi,
  'components/avatar-uploader': componentsAvatarUploader,
};
