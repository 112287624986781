import React         from 'react';
import getForm       from 'components/form';
import FormCard      from 'components/form-card';
import PageSection   from 'components/page-section';
import JobChallenges from 'components/job-create/challenges/job__challenges';
import validate      from 'modules/jobs/create/validate';

import {
  CARDS,
  FORM_ID,
  CARD_VIEWS_BY_ID
} from 'modules/jobs/create/constants';

const Form = getForm({ id: FORM_ID, validate });

export default ({
  shouldValidate,
  handleSubmit,
  handlePost,
  handleAddChallenge,
  handleRemoveChallenge,
  handleSwapChallenge,
  handleReset,
  editingCards,
  editCard,
  saveCard,
  initialValues,
  canEditChallenges,
  canPay,
  isChallengesHidden,
  isEditingLive
}) => (
  <PageSection>
    <Form
      initialValues  = { initialValues }
      onSubmit       = { handleSubmit }
      shouldValidate = { shouldValidate }
      className      = "form__cards"
      actions        = { {
        disabled: !canPay,
        type: 'bottom',
        items: [
          { className: 'is--secondary', label: 'Cancel',     onClick: handleReset },
          { className: 'is--primary',   label: 'Post & Pay', onClick: handlePost, disabled: editingCards.length > 0 }
        ]
      } }
    >
      { CARDS.map(({ id, title }) => (
        <FormCard
          id            = { id }
          key           = { id }
          title         = { title }
          views         = { CARD_VIEWS_BY_ID[id] }
          onEdit        = { editCard }
          onSave        = { saveCard }
          isEditing     = { editingCards.indexOf(id) > -1 }
          isEditingLive = { isEditingLive }
        />
      )) }
      { !isChallengesHidden &&
        <JobChallenges
          { ...{
            canEdit: canEditChallenges,
            handleAddChallenge,
            handleRemoveChallenge,
            handleSwapChallenge,
            editingCards,
            editCard,
            saveCard
          } }
        />
      }
    </Form>
  </PageSection>
);
