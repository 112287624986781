import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import config from 'config';
import { login } from 'modules/auth';
import { LOGIN_FORM } from 'modules/auth/constants';
import { TYPES } from 'modules/forms/constants';
import Card from 'components/card';
import Button from 'components/button';
import getForm from 'components/form';
import FormRow from 'components/form-row';
import FormField from 'components/form-field';

const Form = getForm({ id: LOGIN_FORM });

const LoginRoute = ({ login, isSubmitting }) => (
  <Card title="Login to Paddl" isCentered>
    <Form onSubmit={login} >
      <div className="card__content">
        <FormRow>
          <FormField
            placeholder="Enter your email"
            type={TYPES.TEXT}
            name="email"
          />
        </FormRow>
        <FormRow>
          <FormField
            placeholder="Enter your password"
            type={TYPES.PASSWORD}
            name="password"
          />
        </FormRow>
        <FormRow className="is--centered is--spacer">
          <Button
            className="is--action is--fixed"
            disabled={isSubmitting}
          >
            Login
          </Button>
          <p>
            <Link className="card__link" to="/forgot-password">Forgot Password?</Link>
          </p>
        </FormRow>
      </div>
      <ul className="card__links is--vertical">
        <li className="card__links__msg">
          Don't have an account? <a className="card__link is--highlighted-link" href={config.SIGN_UP_PAGE_URL}>Sign up</a>
        </li>
      </ul>
    </Form>
  </Card>
);

export default connect(({ form }) => ({
  isSubmitting: form && form[LOGIN_FORM] && form[LOGIN_FORM].submitting,
}), { login })(LoginRoute);
