import './index.scss';
import React from 'react';

export const SaveChangesButton = ({ customCopy, onClick, isDisabled }) => {
  return (
    <button
      className = { `save-changes-button save-changes-button--${isDisabled ? 'disabled' : 'enabled'}` }
      onClick   = { () => { !isDisabled && onClick(); } }
    >
      <img src = { require('images/v2/icon-tick-large.svg') } />
      <span>{ customCopy || 'Save Changes' }</span>
    </button>
  );
};
