import { ExperiencePage }       from 'pages/v2/profile/experience-page';
import { PublicExperiencePage } from 'pages/v2/public/profile/experience-page';

export default ({ appv2 }) => {
  return {
    path:         'profile/:id/experience',
    title:        'Profile Experience',
    getComponent: (nextState, cb) => {
      const { location } = nextState;
      const isPublicPreviewPage = (location.search.indexOf('public=1') > -1);

      return cb(null, isPublicPreviewPage ? PublicExperiencePage : ExperiencePage);
    },
  };
};
