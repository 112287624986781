import './index.scss';
import React from "react";
import Slick from "react-slick";

import "scss/plugins/slick/slick.scss";

class MobileSwiper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      windowInnerWidth: 0,
      containerStyles: null,
    };

    this._updateDimensions = () => {
      const container = document.getElementsByClassName(`${this.props.containerClassName}`)[0];

      this.setState({
        windowInnerWidth: window.innerWidth,
        containerStyles: container && window.getComputedStyle(container),
      });
    };
  }

  componentDidMount() {
    this._updateDimensions();
    window.addEventListener("resize", this._updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._updateDimensions);
  }

  render() {
    const { wrapperClassName } = this.props;
    const { windowInnerWidth, containerStyles } = this.state;

    const propValueInPx = (propValue) => Number(propValue.replace(/(\d+)px/, '\$1'));

    const getWrapperWidth = () => {
      if (containerStyles) {
        const width       = containerStyles.getPropertyValue('width');
        const marginRight = containerStyles.getPropertyValue('margin-right');
        const marginLeft  = containerStyles.getPropertyValue('margin-left');

        return propValueInPx(width) + propValueInPx(marginRight) + propValueInPx(marginLeft);
      }
    };

    const getStyle = () => {
      if (containerStyles) {
        const width        = containerStyles.getPropertyValue('width');
        const marginRight  = containerStyles.getPropertyValue('margin-right');
        const marginLeft   = containerStyles.getPropertyValue('margin-left');
        const paddingRight = containerStyles.getPropertyValue('padding-right');
        const paddingLeft  = containerStyles.getPropertyValue('padding-left');

        return {
          width: `${propValueInPx(width) + propValueInPx(marginRight) + propValueInPx(marginLeft)}px`,
          marginRight: `-${propValueInPx(marginRight) + propValueInPx(paddingRight)}px`,
          marginLeft: `-${propValueInPx(marginLeft) + propValueInPx(paddingLeft)}px`,
        };
      }

      return { width: '100%' };
    };

    const settings = {
      variableWidth: true,
      dots:          true,
      arrows:        false,
      className:     "center",
      centerMode:    true,
      infinite:      false,
      centerPadding: "60px",
      speed:         500,
      slidesToShow:  1,
    };

    if (getWrapperWidth() > 768) {
      return (
        <div className = { `${wrapperClassName} mobile-swiper__children-wrapper` }>
          { this.props.children }
        </div>
      );
    }

    return (
      <div className = { wrapperClassName } style = { getStyle() } >
        <Slick { ...settings }>
          { this.props.children }
        </Slick>
      </div>
    );
  }
}

export default MobileSwiper;
