import './index.scss';
import React from 'react';
import classNames from 'classnames';

export const SidenavTooltip = ({ visible, position, text }) => {
  const className = classNames(
    'sidenav-tooltip',
    { '--visible': visible }
  );

  return (
    <div
      className = { className }
      style = { {
        left: position[0],
        top: position[1],
      } }
    >
      { text }
    </div>
  );
};
