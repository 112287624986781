import React from 'react';
import { connect } from 'react-redux';

import { TYPES } from 'modules/forms/constants';
import Card from 'components/card';
import Button from 'components/button';
import getForm from 'components/form';
import FormRow from 'components/form-row';
import FormField from 'components/form-field';
import { FORGOTTEN_PASSWORD_FORM } from 'modules/auth/constants';
import { resetPasswordRequest } from 'modules/auth';

const Form = getForm({ id: FORGOTTEN_PASSWORD_FORM });

const ForgottenPasswordRoute = ({ resetPasswordRequest, isSubmitting }) => (
  <Card
    title="Forgotten Password"
    subtitle="Confirm your email"
    isCentered
  >
    <Form onSubmit={resetPasswordRequest}>
      <div className="card__content">
        <FormRow>
          <FormField
            placeholder="Enter your email"
            type={TYPES.TEXT}
            name="email"
          />
        </FormRow>
        <FormRow className="is--centered is--spacer">
          <Button
            className="is--action is--fixed"
            disabled={isSubmitting}
          >
            Send reset link
          </Button>
        </FormRow>
      </div>
    </Form>
  </Card>
);

export default connect(({ form }) => ({
  isSubmitting: form && form[FORGOTTEN_PASSWORD_FORM] && form[FORGOTTEN_PASSWORD_FORM].submitting,
}), { resetPasswordRequest })(ForgottenPasswordRoute);
