import './challenge-questions-result.scss';

import React     from 'react';
import PropTypes from 'prop-types';

const ChallengeQuestionsResult = ({
  data,
  revealResult
}) => {
  if (!data) return null;

  return (
    <div className = "challenge-questions-result">
      { data.map(({ question, response, insight_correct }, i) => (
        <div
          className = {
            'challenge-questions-result__item' +
            (revealResult ? ` is--${insight_correct ? 'correct' : 'incorrect' }` : '')
          }
          key = { i } >
          <div className = "challenge-questions-result__question">
            { question }
          </div>
          <div className = "challenge-questions-result__answer">
            { response }
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChallengeQuestionsResult;

ChallengeQuestionsResult.propTypes = {
  data: PropTypes.array
};
