import xor from 'lodash/xor';

import { CALL_API }  from 'middleware/call-api';
import { GOTO_STEP } from 'modules/guide';
import {
  COUPON_FAILURE,
  COUPON_REQUEST
} from 'modules/payments';

export const TOGGLE_SIDENAV         = 'ui/TOGGLE_SIDENAV';
export const TOGGLE_AVATAR_UPLOADER = 'ui/TOGGLE_AVATAR_UPLOADER';
export const TOGGLE_BULK            = 'ui/TOGGLE_BULK';

export const CLOSE_SIDENAV    = 'ui/CLOSE_SIDENAV';
export const START_TOUR       = 'ui/START_TOUR';
export const SET_PAGE         = 'ui/SET_PAGE';

export const FETCH_REQUEST    = 'ui/FETCH_REQUEST';
export const FETCH_SUCCESS    = 'ui/FETCH_SUCCESS';
export const FETCH_FAILURE    = 'ui/FETCH_FAILURE';

const TOGGLE_MODAL            = 'ui/TOGGLE_MODAL';

const UPDATE_SETTINGS_REQUEST = 'settings/UPDATE_SETTINGS_REQUEST';
const UPDATE_SETTINGS_SUCCESS = 'settings/UPDATE_SETTINGS_SUCCESS';
const UPDATE_SETTINGS_FAILURE = 'settings/UPDATE_SETTINGS_FAILURE';

const VOTE_PANEL_REQUEST      = 'panels/VOTE_PANEL_REQUEST';
const VOTE_PANEL_SUCCESS      = 'panels/VOTE_PANEL_SUCCESS';
const VOTE_PANEL_FAILURE      = 'panels/VOTE_PANEL_FAILURE';

const initialState = {
  isSidenavOpen:           false,
  headerState:             '',
  error:                   '',
  voted:                   {},
  dismissed_panels:        [],
  hidden_panels:           [],
  dismissed_page_tours:    [],
  opened_page_tours:       [],
  tour_guide_visible:      false,
  isRedirectedFromGuide:   false,
  isAvatarUploaderOpen:    false,
  open_tour_guide_on_load: false,
  pageId:                  '',
  isBulkActive:            false,
  openedModals:            []
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDENAV:
      return {
        ...state,
        isSidenavOpen: !state.isSidenavOpen
      };
    case TOGGLE_AVATAR_UPLOADER:
      return {
        ...state,
        isAvatarUploaderOpen: !state.isAvatarUploaderOpen
      };
    case TOGGLE_BULK:
      return {
        ...state,
        isBulkActive: !state.isBulkActive
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GOTO_STEP:
      return {
        ...state,
        isRedirectedFromGuide: true
      };
    case CLOSE_SIDENAV:
      return {
        ...state,
        isSidenavOpen: false
      };
    case UPDATE_SETTINGS_REQUEST: {
      const {
        json
      } = action.payload;

      return {
        ...state,
        ...json
      };
      break;
    }
    case COUPON_REQUEST:
      return {
        ...state,
        error: ''
      };
    case COUPON_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case START_TOUR: {
      const {
        id
      } = action.payload;

      if (state.opened_page_tours.indexOf(id) > -1) {
        break;
      }

      return {
        ...state,
        opened_page_tours: [...state.opened_page_tours, ...[id]]
      };
    }
    case VOTE_PANEL_REQUEST: {
      const {
        json: { voted }
      } = action.payload;

      return {
        ...state,
        voted
      };
    }
    case SET_PAGE: {
      const {
        id
      } = action.payload;

      return {
        ...state,
        pageId: id
      };
    }
    case TOGGLE_MODAL: {
      const {
        id
      } = action.payload;

      return {
        ...state,
        openedModals: xor(state.openedModals, [id])
      };
    }
    default: { return state; }
  }
}

export function toggleModal(id) {
  return {
    type: TOGGLE_MODAL,
    payload: { id }
  };
}

export function toggleSidenav() {
  return {
    type: TOGGLE_SIDENAV
  };
}

export function toggleAvatarUploader() {
  return {
    type: TOGGLE_AVATAR_UPLOADER
  };
}

export function toggleBulk() {
  return {
    type: TOGGLE_BULK
  };
}

export function closeSidenav() {
  return {
    type: CLOSE_SIDENAV
  };
}

export function updateSettings({ json }) {
  return {
    [CALL_API]: {
      types: [
        UPDATE_SETTINGS_REQUEST,
        UPDATE_SETTINGS_SUCCESS,
        UPDATE_SETTINGS_FAILURE
      ],
      method: 'PATCH',
      endpoint: '/settings',
      data: { json },
    }
  };
}

export function startTour({ id }) {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0);
  }
  return {
    type: START_TOUR,
    payload: { id }
  };
}

export function setPage({ id }) {
  return {
    type: SET_PAGE,
    payload: { id }
  };
}

export function fetchSettings() {
  return {
    [CALL_API]: {
      types: [
        FETCH_REQUEST,
        FETCH_SUCCESS,
        FETCH_FAILURE
      ],
      endpoint: '/settings',
    }
  };
}

function votePanelRequest({ id, value, json }) {
  return {
    [CALL_API]: {
      types: [
        VOTE_PANEL_REQUEST,
        VOTE_PANEL_SUCCESS,
        VOTE_PANEL_FAILURE
      ],
      method: 'PATCH',
      endpoint: '/settings',
      data: { json },
    }
  };
}

export function votePanel({ id, value }) {
  return ({ getState, dispatch }) => {
    const json = {
      voted: { ...getState().ui.voted, [id]: value }
    };
    dispatch(votePanelRequest({ id, value, json }));
  };
}
