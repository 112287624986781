import './index.scss';
import React from 'react';

export const ProgressBarReadonly = ({ fillColor, fillWidth, progressBarHeight }) => {
  return (
    <div className = "progress-bar-readonly">
      <div
        className = "progress-bar-readonly__track"
        style = { {
          height: progressBarHeight,
          borderRadius: progressBarHeight,
        } }
      >
        <div
          className = { `progress-bar-readonly__fill progress-bar-readonly__fill--${fillColor}` }
          style = { {
            width: fillWidth,
            height: progressBarHeight,
            borderRadius: progressBarHeight,
          } }
        />
      </div>
    </div>
  );
};
