import './input-textarea.scss';

import React from 'react';

const resize = target => {
  if (!target) return;

  target.style.height = 'auto';
  target.style.height = `${target.scrollHeight}px`;
};

export default class InputTextarea extends React.Component {
  componentDidMount() {
    resize(this.input);
  }
  componentDidUpdate() {
    resize(this.input);
  }
  render() {
    const {
      attrs,
      addon,
      options,
      className,
      hasLines,
      ...props
    } = this.props;

    return (
      <div className = { `input__textarea ${className}${hasLines ? ' has--lines' : ''}` } >
        <textarea { ...{...props, ...attrs } } ref = { r => this.input = r } />
      </div>
    );
  }
}
