import Cookies from 'cookies-js';

const CONFIG = {
  tokenKey: 'authToken',
  referrerKey: 'referrerCode',
  expires: 365 * 24 * 3600,
  path: '/',
};

// Gets a token object from cookies
// Returns raw user token object like:
// {token: "a9RNv1r07q5nAM4xo2/qdJnSsirR4y6QNcK/rDgqINE=", user_id: 1, profile_id: 1}
export const getToken = () => {
  const cookieToken = Cookies.get(CONFIG.tokenKey);

  if (!cookieToken) {
    return null; // avoid try-catch overhead if no cookie at all
  }

  try {
    return JSON.parse(cookieToken);
  } catch (e) {
    return null; // yeah, nah.
  }
};

export const setToken = (tokenObj) => {
  Cookies.set(CONFIG.tokenKey, JSON.stringify(tokenObj), CONFIG);

  // eslint-disable-next-line no-unused-expressions
  window.Sentry && window.Sentry.setUserContext({
    id: tokenObj.user_id,
    profile_id: tokenObj.profile_id,
    role: tokenObj.role,
  });
};

export const clearToken = () => {
  Cookies.expire(CONFIG.tokenKey, CONFIG);
};

// handleAuthyResponse should be used in a .then() handler, when the response
// is likely to look like
// {token: "a9RNv1r07q5nAM4xo2/qdJnSsirR4y6QNcK/rDgqINE=", user_id: 1, profile_id: 1}
export const requireAuthyResponse = (data) => {
  // eslint-disable-next-line no-use-before-define
  if (!isAuthyResponse(data)) {
    throw new Error('Authentication response mismatch');
  }

  setToken(data);

  return data;
};

export const isAuthyResponse = (data) => (data.token && data.profile_id && data.role);
