import React                    from 'react';
import { connect }              from 'react-redux';
import List                     from 'components/list';
import JobListPanel             from 'containers/job-list-panel';
import PageContent              from 'components/page-content';
import PagePanels               from 'components/panels';
import PageTitle                from 'components/page-title';
import { archivedJobsSelector } from 'selectors/index';

const JobsEmployerArchive = (props) => {
  const {
    items
  } = props;

  return (
    <PageContent>
      <PageTitle>Archive</PageTitle>
      <PagePanels />
      <List
        items     = { items }
        Component = { JobListPanel }
      />
    </PageContent>
  );
};

export default connect(state => ({
  items: archivedJobsSelector(state),
}))(JobsEmployerArchive);
