export const FORM_ID = 'job-create';

export const CARDS = [
  {
    id: 'details',
    title: 'Job Details'
  }
];

export const CHALLENGES = [
  // {
  //   id: 'questions-knockout',
  //   title: 'Questions - Knockout',
  //   description: 'Only Corresponding answers will make the applicant progress.',
  //   type: 'challenge'
  // },
  {
    id: 'questions-insight',
    title: 'Questions - Insight',
    description: 'Use these to learn more about the applicant and their skills',
    type: 'challenge'
  }
];

export const CARD_VIEWS_BY_ID = {
  details: {
    edit:    require('components/job-create/details/form').default,
    default: require('components/job-create/details/index').default
  },
  'questions-knockout': {
    edit:    require('components/job-create/questions/form').default,
    default: require('components/job-create/questions/index').default
  },
  'questions-insight': {
    edit:    require('components/job-create/questions/form').default,
    default: require('components/job-create/questions/index').default
  }
};

export const MAP_JOB_TO_CAMPAIGN = job => {
  return {
    id:      job.id,
    status:  job.status || 'draft',
    details: {
      headline:         job.title,
      category:         job.category,
      description:      job.description,
      pay_rate:         job.pay_rate,
      hours_amount:     job.hours_amount,
      is_paid:          job.pay_rate || true,
      hours_unit:       job.hours_unit || 'Per Week',
      num_of_positions: job.num_of_positions,
      location: {
        address: job.address,
        lat:     job.lat,
        lng:     job.lng
      }
    },
    challenges: job.challenges
  };
};
