import React                 from 'react';
import { connect }           from 'react-redux';
import MainView              from '../item-view';
import PageActions           from 'components/page-actions';
import Button                from 'components/button';
import ApplyJobButton        from 'components/v2/buttons/circle-buttons/apply-job-button';
import ShareJobButton        from 'components/v2/buttons/circle-buttons/share-job-button'
import SeeApplicationButton  from 'components/v2/buttons/circle-buttons/see-application-button'
import PageSection           from 'components/page-section';
import JobStatusSummary      from 'components/job-status-summary';
import JobEmployerDetails    from 'components/job-employer-details';
import { createApplication } from 'modules/applications';
import { toggleModal }       from 'modules/ui';
import Modal                 from 'components/modal';
import { SHARE_MODAL_ID }    from 'modules/ui/constants';

const JobViewIndexStudent = ({
  createApplication,
  job,
  toggleModal
}) => {
  const hasApplied = job
    && job.application_ids
    && job.application_ids[0]
    && (job.application_ids[0].status.status === 'sent');

  return (
    <div>
      <PageActions>
        { (hasApplied) &&
          <SeeApplicationButton link = { `/applications/${job.application_ids[0].id}` } />
        }
        { (!hasApplied && job.status == 'open') &&
          [ <ApplyJobButton onClick = { createApplication }/>,
            <ShareJobButton onClick = { toggleModal }/>
          ]
        }
        { (!hasApplied && job.status !== 'open') &&
          <ShareJobButton onClick = { toggleModal }/>
        }
      </PageActions>
      { (!hasApplied) &&
        <PageSection isCta isNarrow>
          <JobStatusSummary id = { job.id } status = { job.status } createApplication = { createApplication } />
        </PageSection>
      }
      <PageSection isDark>
        <JobEmployerDetails { ...job } />
      </PageSection>
    </div>
  );
};

export default MainView(
  connect(null, (dispatch, ownProps) => ({
    createApplication: () => dispatch(createApplication(ownProps.job.id)),
    toggleModal      : () => dispatch(toggleModal(SHARE_MODAL_ID))
  }))(JobViewIndexStudent));
