export default (store) => ({
  path: 'conversations',
  childRoutes: [{
    path: ':id',
    title: ({ id }) => {
      const {
        jobs: { entities },
        conversations
      } = store.getState();

      return entities[conversations[id].job].title;
    },
    subTitle: 'Back To Applications',
    getComponent: (location, cb) => {
      cb(null, require('pages/conversations/view').default);
    }
  }]
});
