import './status.scss';

import React          from 'react';
import SvgIcon        from 'components/svg-icon';
import ProgressCircle from 'components/progress-circle';

const getCompleteValue = (state, complete) => {
  const PERCENTAGE_COMPLETED = 100;

  if (state === 'inactive') {
    return PERCENTAGE_COMPLETED;
  }
  return complete;
}

const Status = ({
  state,
  icon,
  title,
  complete,
  daysLeft,
  isHorizontal,
  isLarge,
  isCompact,
  hasDuration,
  hasProgress = true
}) => {
  return (
    <div
      className = {
        'status ' +
        `is--${state}` +
        `${isCompact    ? ' is--compact' : ''} ` +
        `${isHorizontal ? ' is--horizontal' : ''} ` +
        `${isLarge      ? ' is--large' : ''}`
      } >
      <div className = "status__inner">
        <div className = "status__inner__icon">
          { typeof icon === 'string' ?  <SvgIcon id = { icon } /> : icon  }
        </div>
        { hasProgress &&
          <ProgressCircle
            state    = { state }
            complete = { getCompleteValue(state, complete) }
            isThin   = { isLarge } />
        }
      </div>
      { state != 'inactive' && hasDuration && daysLeft &&
        <div className = "status__duration">
          { title &&
            <div className = "status__duration__title">
              { title }
            </div>
          }
          <div className = "status__duration__label">
            <span>{ daysLeft }</span> day
            { daysLeft > 1 && 's' }
            { daysLeft < 7 && '!' }
          </div>
        </div>
      }
      { state == 'inactive' && hasDuration &&
        <div className = "status__duration">
          <div className = "status__duration__label">
            <span>{ 'Applications Closed' }</span>
          </div>
        </div>
      }
    </div>
  )
};

export default Status;
