import './progress-circle.scss';

import React from 'react';
const COLORS = {
  primary: {
    secondary: '#B1DBF5',
    primary: '#37B1FF'
  },
  success: {
    secondary: '#D0F9F1',
    primary: '#14E3BB'
  },
  warning: {
    secondary: '#FEF5DB',
    primary: '#FEBF0E'
  },
  danger: {
    secondary: '#FFE2CE',
    primary: '#FF720C'
  },
  inactive: {
    secondary: '#EAF1F4',
    primary: '#D2D8DA'
  }
};

const polarToCartesian = (svg_size, radius, angle) => {
  const angleInRadians = (angle - 90) * Math.PI / 180.0;

  return {
    x: svg_size / 2 + (radius * Math.cos(angleInRadians)),
    y: svg_size / 2 + (radius * Math.sin(angleInRadians))
  };
};

const getPath = ({ svg_size, radius, complete }) => {
  const left      = 1 - (complete < 100 && complete > 0 ? complete / 100 : 0.00001);
  const startAgngle = 0;
  const endAngle  = 360 * left;

  const start = polarToCartesian(svg_size, radius, endAngle);
  const end   = polarToCartesian(svg_size, radius, startAgngle);

  const d = [
    'M', start.x, start.y,
    'A', radius, radius, 0, endAngle <= 180 ? '0' : '1', 0, end.x, end.y
  ].join(' ');

  return d;
};

export default ({
  complete,
  state  = 'primary',
  size   = 20,
  isFat,
  isThin
}) => {
  const strokeModifier = isFat ? 5 : isThin ? 30 : 15;

  const stroke   = size / strokeModifier;
  const svg_size = size + stroke * 3;
  const radius   = size / 2;

  return (
    <svg
      viewBox   = { `0 0 ${svg_size} ${svg_size}` }
      className = "progress-circle" >
      <circle
        cx          = { svg_size / 2 }
        cy          = { svg_size / 2 }
        r           = { radius }
        fill        = "none"
        stroke      = "#fff"
        strokeWidth = { stroke * 3 } />
      <circle
        className   = "progress-circle__circle"
        cx          = { svg_size / 2 }
        cy          = { svg_size / 2 }
        r           = { radius }
        fill        = "none"
        stroke      = { COLORS[state].secondary }
        strokeWidth = { stroke } />
      <path
        className   = "progress-circle__path"
        d           = { getPath({ svg_size, complete, radius }) }
        fill        = "none"
        stroke      = { COLORS[state].primary }
        strokeWidth = { stroke } />
    </svg>
  );
};
