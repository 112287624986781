import React        from 'react';
import PropTypes    from 'prop-types';
import _            from 'lodash';
import cx           from 'classnames';
import Button       from 'components/button';
import Markdown     from 'components/markdown';
import ProfilePhoto from 'components/profile-photo';

const Question = ({
  question,
  onQuestionAnswer,
  answers
}) => {
  const classNames = cx(
    'message is--question',
    { 'messageThread--faded': question.response }
  );

  return (
    <li
      key       = { question.id }
      className = { classNames } >
      <div className = "messageThread--photo">
        <ProfilePhoto text = { question.order + 1 } />
      </div>
      <div className = "content pls">
        <p>
          { question.question }
        </p>
        <ul className = "answers">{
          _.map(answers, answer => (
            <li>
              <Button
                onClick   = { onQuestionAnswer.bind(this, question, answer) }
                className = { cx(`is--${answer.toLowerCase()}`, {
                  'is--primary': question.response  === answer && question.correct,
                  'is--danger': question.response   === answer && !question.correct,
                  '': question.response !== answer }) }
                hasShadow >
                { answer }
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

Question.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.array.isRequired,
  onQuestionAnswer: PropTypes.func.isRequired
};

Question.defaultProps = {
  answers: [],
  onQuestionAnswer: () => {}
};

export default Question;
