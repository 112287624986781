import React               from 'react';
import { connect }         from 'react-redux';
import { updateProfile }   from 'modules/profiles';
import { TYPES }           from 'modules/forms/constants';
import Card                from 'components/card';
import Button              from 'components/button';
import getForm             from 'components/form';
import FormRow             from 'components/form-row';
import FormField           from 'components/form-field';
import { profileSelector } from 'selectors/index';
import {
  createValidations,
  required,
} from 'utils/validations';

const FORM_ID = 'onboarding-details';
const Form    = getForm({
  id: FORM_ID,
  destroyOnUnmount: false,
  validate: createValidations({
    business_name:  required,
    business_phone: required
  })
});

class OnboardingDetailsEmployer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit({ business_name, business_phone }) {
    const {
      auth,
      updateProfile,
      onComplete
    } = this.props;

    return updateProfile({ id: auth.profile_id, business_name, business_phone })
                  .then(onComplete);
  }
  render() {
    const {
      isSubmitting,
      profile: {
        business_name,
        business_phone
      }
    } = this.props;

    return (
      <Form
        initialValues = {{ business_name, business_phone }}
        onSubmit      = { this.handleSubmit } >
        <Card title     = "Business Details">
          <div className = "card__content">
            <FormRow>
              <FormField
                label = "Business Name"
                type  = { TYPES.TEXT }
                name  = "business_name" />
            </FormRow>
            <FormRow>
              <FormField
                label = "Contact Number"
                type  = { TYPES.TEXT }
                name  = "business_phone" />
            </FormRow>
            <FormRow className = "is--centered is--spacer">
              <Button
                className = "is--action is--fixed"
                disabled  = { isSubmitting }>
                Continue
              </Button>
            </FormRow>
          </div>
        </Card>
      </Form>
    );
  }
}

export default connect(state => ({
  isSubmitting: state.form[FORM_ID] && state.form[FORM_ID].submitting,
  auth:         state.auth,
  profile:      profileSelector(state)
}), { updateProfile })(OnboardingDetailsEmployer);
