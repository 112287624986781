import React                    from 'react';
import { connect }              from 'react-redux';
import List                     from 'components/list';
import PageTitle                from 'components/page-title';
import PageContent              from 'components/page-content';
import PagePanels               from 'components/panels';
import EmptyView                from 'components/empty-view';
import ApplicationListPanel     from 'containers/application-list-panel';
import getForm                  from 'components/form';
import { applicationsSelector } from 'selectors';

const Form = getForm({ id: 'selectable-list' });

const ApplicationsStudentIndex = ({
  items
}) => (
  <PageContent>
    <PageTitle>Applications</PageTitle>
    <PagePanels />
    <Form>
      <List
        key       = "list"
        items     = { items }
        Component = { ApplicationListPanel }
      />
    </Form>
    { items.length === 0 && <EmptyView /> }
  </PageContent>
);

export default connect(state => ({
  items: applicationsSelector(state)
}))(ApplicationsStudentIndex);
