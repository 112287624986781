import SvgIconBasecamp  from 'component-lib/svg-components/sidenav/svg-icon-basecamp';
import SvgIconFeather   from 'component-lib/svg-components/sidenav/svg-icon-feather';
import SvgIconParchment from 'component-lib/svg-components/sidenav/svg-icon-parchment';
import SvgIconSettings  from 'component-lib/svg-components/sidenav/svg-icon-settings';
import SvgIconHelp      from 'component-lib/svg-components/sidenav/svg-icon-help';

export const menuItems = [
  {
    visibleToRoles: ['student'],
    xpFeatureEnabledRequired: true,
    title: 'Basecamp',
    icon: SvgIconBasecamp,
    url: '/basecamp',
    active: false,
  },
  {
    visibleToRoles: ['student'],
    title: 'Jobs',
    icon: SvgIconFeather,
    url: '/jobs',
    notification: {
      type: 'passive',
      count: 0,
    },
    active: true,
  },
  {
    visibleToRoles: ['employer'],
    title: 'Campaigns',
    icon: SvgIconFeather,
    url: '/jobs',
    notification: {
      type: 'passive',
      count: 0,
    },
    active: true,
  },
  {
    visibleToRoles: ['student', 'employer'],
    title: 'Applications',
    icon: SvgIconParchment,
    url: '/applications',
    notification: {
      type: 'alert',
      count: 0,
    },
    active: false,
  }
];

export const footerMenuItems = [
  {
    visibleToRoles: ['student', 'employer'],
    title: 'Settings',
    icon: SvgIconSettings,
    url: '/settings',
    active: false,
  },
  {
    visibleToRoles: ['student', 'employer'],
    title: 'Support',
    icon: SvgIconHelp,
    active: false,
  }
];
