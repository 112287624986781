import { defined } from 'lib/helpers';

const SUMMARY   = 'summary';
const STRAPLINE = 'strapline';

class UIBehaviour {
  constructor({ ui, canEdit }) {
    this._ui = defined(ui);
    this.canEdit = defined(canEdit);

    this.reset = () => {
      this._ui.initializeState();
      this._ui.closeForm();
    };

    this.clickToEditSummary = () => {
      this._ui.openForm({ id: SUMMARY });
    };

    this.cancelSummaryForm = () => {
      this._ui.initializeState();
      this._ui.closeForm();
    };

    this.saveSummaryForm = () => {
      this._ui.closeForm();
      this._ui.displayProfileSaved();
    };

    this.isSummaryFormOpen = () => {
      return this._ui.getCurrentlyOpenForm() === SUMMARY;
    };

    this.clickToEditStrapline = () => {
      this._ui.openForm({ id: STRAPLINE });
    };

    this.cancelStraplineForm = () => {
      this._ui.initializeState();
      this._ui.closeForm();
    };

    this.saveStraplineForm = () => {
      this._ui.closeForm();
      this._ui.displayProfileSaved();
    };

    this.isStraplineFormOpen = () => {
      return this._ui.getCurrentlyOpenForm() === STRAPLINE;
    };
  }
}

export default UIBehaviour;
