import React from 'react';

import { getActivityCardContentProps } from 'component-lib/components/activity-card-content/get-props';
import { IssuerData }                  from 'lib/api/var-edu/verified/issuer-data';

import {
  ActivityCardContent,
  COOLDOWN,
  READY,
  COLLECTED,
} from 'component-lib/components/activity-card-content';

export class ActivityCardContentInBasecamp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldShowAsCollected: false,
      issuerProfile: null,
    };

    this._showAsCollected = () => {
      this.setState({ shouldShowAsCollected: true });

      setTimeout(() => {
        this.setState({ shouldShowAsCollected: false });
      }, 5000);
    };
  }

  componentDidMount() {
    const { activity, appv2 } = this.props;

    const issuerCode = activity.get('details').get('issuerCode');

    if (!issuerCode) { return null; }

    const issuerData = IssuerData.getFor(issuerCode.value);
    const issuerProfileId = issuerData.profileId;

    return appv2.api.profiles
      .fetchWithExtraByIdOrSlug({ idOrSlug: issuerProfileId, isLoggedIn: true })
      .then(({ profile }) => {
        this.setState({ issuerProfile: profile });
      });
  }

  componentDidUpdate(prevProps) {
    const { activity } = this.props;

    const cooldownLeft = activity.get('cooldownLeft');
    const cooldownMax = activity.get('cooldownMax');
    const prevCooldownLeft = prevProps.activity.get('cooldownLeft');
    const prevCooldownMax = prevProps.activity.get('cooldownMax');

    /**
     * This `if` statement below is a guard to prevent infinite recursion.
     * `componentDidUpdate` method is being called on every `setState`.
    **/

    if (cooldownLeft === prevCooldownLeft && cooldownMax === prevCooldownMax) {
      return;
    }

    /**
     * We cannot use the following more logical implementation in this case,
     * because updates may happen at any time, multiple times in a row.
     * Basically, we cannot rely on values in `prevProps`.
     *
     * ```
     * if (prevCooldownLeft === 0 && cooldownLeft === cooldownMax) {
     *   ...
     * }
     * ```
    **/

    if (cooldownLeft === 0 || cooldownLeft !== cooldownMax) {
      return;
    }

    this._showAsCollected();
  }

  render() {
    const { activity } = this.props;
    const { issuerProfile } = this.state;

    const getState = () => {
      if (activity.get('cooldownLeft') === 0) return READY;
      if (this.state.shouldShowAsCollected) return COLLECTED;
      return COOLDOWN;
    };

    return (
      <div className = "activity-card-content-in-basecamp">
        <ActivityCardContent
          { ...getActivityCardContentProps({ activity, issuerProfile }) }
          state = { getState() }
        />
      </div>
    );
  }
}
