import './list-panel-thumb.scss';

import React             from 'react';
import classNames        from 'classnames';
import ListPanelCheckbox from 'components/list-panel-checkbox';

const ListPanelThumb = (props) => {
  const {
    id,
    thumb,
    sticker,
    className,
    isSelectable
  } = props;

  if (!thumb) return null;

  return (
    <div
      className = { classNames('list-panel__thumb', className, {
        'is--sticker': !!sticker
      }) }>
      { isSelectable && <ListPanelCheckbox id = { id } /> }
      { thumb }
      { sticker &&
        <div className = "list-panel__thumb__addons">
          { sticker }
        </div>
      }
    </div>
  );
};
export default ListPanelThumb;
