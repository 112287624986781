import './challenge-questions-header.scss';
import React                 from 'react';
import PropTypes             from 'prop-types';
import ChallengeStatusButton from 'components/challenge-status-button';

const ChallengeQuestionsHeader = ({
  id,
  title,
  subTitle,
  isCompleted = false,
  score,
  onChallengeStart
}) => {
  if (!title) return null;

  return (
    <div className = "challenge-questions-header">
      <div className = "challenge-questions-header__wrapper">
        <img
          className = "challenge-questions-header__icon"
          src = { require(`images/icon--${id}.svg`) } />
        <div className = "challenge-questions-header__title">
          { title }
          <span className = "challenge-questions-header__subtitle">
            { subTitle }
          </span>
        </div>
      </div>
      <ChallengeStatusButton
        onClick             = { onChallengeStart }
        isCompleted         = { isCompleted }
        score               = { score } />
    </div>
  );
};

export default ChallengeQuestionsHeader;

ChallengeQuestionsHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string
};
