import './list-filter.scss';

import React          from 'react';
import classnames     from 'classnames';
import ListFilterItem from 'components/list-filter-item';

const ListFilter = ({ items, isDisabled }) => (
  <div
    className = { classnames('list-filter', {
      'is--disabled' : isDisabled,
    }) } >
    { items.map(props => <ListFilterItem key = { props.id } { ...props } />) }
  </div>
);

export default ListFilter;
