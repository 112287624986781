import React from 'react';

export default () => {
  return (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className="svg-icon">
      <circle strokeWidth="2" cx="15" cy="15" r="14"/>
      <path d="M6 5l20 20" strokeWidth="2"/>
    </svg>
  );
};
