import React from 'react';

export const SvgIconHelp = () => (
  <svg
    width = { 48 }
    height = { 48 }
    viewBox = "0 0 48 48"
    fill = "currentColor"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M24 42.99C34.4879 42.99 42.99 34.4879 42.99 24C42.99 13.5121 34.4879 5.01 24 5.01C13.5121 5.01 5.01 13.5121 5.01 24C5.01 34.4879 13.5121 42.99 24 42.99ZM24 44.25C35.1838 44.25 44.25 35.1838 44.25 24C44.25 12.8162 35.1838 3.75 24 3.75C12.8162 3.75 3.75 12.8162 3.75 24C3.75 35.1838 12.8162 44.25 24 44.25Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M24.1694 13.8252C16.8769 13.8252 8.97686 16.0804 5.95219 17.2054L5.51294 16.0244C8.61774 14.8696 16.6737 12.5652 24.1694 12.5652C31.6666 12.5652 39.4986 14.8703 42.4944 16.0272L42.0405 17.2026C39.1327 16.0797 31.4603 13.8252 24.1694 13.8252Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M23.8306 34.1751C31.1231 34.1751 39.0231 31.9199 42.0478 30.7949L42.4871 31.9758C39.3823 33.1306 31.3263 35.4351 23.8306 35.4351C16.3334 35.4351 8.50143 33.13 5.50559 31.9731L5.9595 30.7977C8.86731 31.9206 16.5397 34.1751 23.8306 34.1751Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M32.0486 24.1778C32.0486 16.5205 29.6753 8.2269 28.4913 5.051L29.6719 4.61084C30.8859 7.86697 33.3086 16.3169 33.3086 24.1778C33.3086 32.0402 30.8852 40.255 29.6691 43.3966L28.4941 42.9418C29.676 39.8883 32.0486 31.8335 32.0486 24.1778Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.9514 24.1778C15.9514 16.5205 18.3247 8.2269 19.5087 5.051L18.3281 4.61084C17.1141 7.86697 14.6914 16.3169 14.6914 24.1778C14.6914 32.0402 17.1148 40.255 18.3309 43.3966L19.5059 42.9418C18.324 39.8883 15.9514 31.8335 15.9514 24.1778Z" />
    <path d="M19.75 20.9197C19.75 20.9887 19.806 21.0447 19.875 21.0447C19.883 21.0447 19.8908 21.044 19.8983 21.0425H21.3392C21.4082 21.0425 21.4642 20.9866 21.4642 20.9175C21.4642 19.4052 22.7018 18.4407 24.2042 18.4407C25.7191 18.4407 27.1227 19.4137 27.1227 20.9175C27.1227 22.1349 26.1583 23.0204 25.0583 23.3385C24.2082 23.5766 23.4522 23.8962 22.9084 24.4114C22.3592 24.9318 22.0368 25.6418 22.0368 26.6352V27.4352C22.0368 27.5043 22.0928 27.5602 22.1618 27.5602H23.6283C23.6974 27.5602 23.7533 27.5043 23.7533 27.4352V26.6352C23.7533 26.0425 23.941 25.6863 24.2467 25.4371C24.5628 25.1794 25.0157 25.026 25.5722 24.8715C27.3436 24.3902 28.8393 22.9568 28.8393 20.9197C28.8393 18.4116 26.6069 16.8398 24.2065 16.8398C21.8 16.8398 19.7512 18.4165 19.75 20.9175C19.75 20.9183 19.75 20.919 19.75 20.9197ZM24.2422 30.3645C24.2422 29.6638 23.6097 29.1749 22.8951 29.1749C22.1805 29.1749 21.5479 29.6638 21.5479 30.3645C21.5479 31.0651 22.1779 31.5541 22.8951 31.5541C23.612 31.5541 24.2422 31.0674 24.2422 30.3645Z" stroke="black" strokeWidth="0.25" strokeMiterlimit="10" strokeLinejoin="round"/>
  </svg>
);
