import './text-collapsible.scss';

import React     from 'react';
import PropTypes from 'prop-types';
import Markdown  from 'components/markdown';

const CHARACTER_LIMIT = 400;

export default ({ children }) => {
  if (!children || typeof children !== 'string') return null;

  if (children.length < CHARACTER_LIMIT) {
    return <Markdown>{ children }</Markdown>;
  }

  return <TextCollapsible>{ children }</TextCollapsible>;
};

class TextCollapsible extends React.Component {
  constructor() {
    super();
    this.state = {
      isExpanded: false
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }
  render() {
    const {
      isExpanded
    } = this.state;

    return (
      <div className = { `text-collapsible ${isExpanded ? 'is--expanded' : ''}` }>
        <div className = "text-collapsible__wrapper">
          <Markdown>
            { this.props.children }
          </Markdown>
        </div>
        <a
          onClick = { this.handleToggle }
          className = "text-collapsible__show-link">
          + Read More
        </a>
      </div>
    );
  }
}

TextCollapsible.propTypes = {
  children: PropTypes.string
};
