import './index.scss';
import React from 'react';

import { VERIFIED_VARIANTS } from 'lib/api/categories';
import { ActivityCardWrapper } from 'component-lib/components/activity-card-wrapper';
import { ActivityCardContentInBasecamp } from 'components/v2/basecamp/activity-card-content-in-basecamp';

class BasecampCardList extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    ['in-progress-icon', 'completed-icon'].forEach((className) => {
      const elements = Array.from(document.getElementsByClassName(className));

      elements.forEach((elem) => {
        elem.classList.remove(className);
      });

      setTimeout(() => {
        elements.forEach((elem) => {
          elem.classList.add(className);
        });
      }, 1);
    });
  }

  render() {
    const { appv2, basecampCards, basecampRefreshFunction } = this.props;

    const onClick = (basecampCard) => {
      const variantId = basecampCard.get('variantId');
      const activityId = basecampCard.get('id');

      if (VERIFIED_VARIANTS.indexOf(variantId) !== -1) {
        return appv2.ui.redirect({ url: `/verified-activities/${activityId}` });
      }

      return appv2.ui.openProgressModal({ activityId, basecampRefreshFunction });
    };

    return (
      <div className = "basecamp-card-list">
        {
          basecampCards.map((basecampCard) => (
            <div key = { basecampCard.get('id') }>
              <ActivityCardWrapper onClick = { () => onClick(basecampCard) }>
                <ActivityCardContentInBasecamp
                  appv2 = { appv2 }
                  activity = { basecampCard }
                />
              </ActivityCardWrapper>
            </div>
          ))
        }
      </div>
    );
  }
}

export default BasecampCardList;
