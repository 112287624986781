import './application-tabs.scss';

import React   from 'react';
import Score   from 'components/score';
import SvgIcon from 'components/svg-icon';

export default ({
  scores: {
    score,
    max_score
  },
  course,
  educator
}) => (
  <div className = "application-tabs">
    <div className = "application-tabs__score">
      <Score score = { `${score}` } maxScore = { `${max_score}` } isSuccess />
      <div className = "application-tabs__score__title">
        Application Score
      </div>
    </div>
    <div className = "application-tabs__educator">
      <div className = "application-tabs__educator__icon">
        <SvgIcon id = "basecamp" />
      </div>
      <div className = "application-tabs__educator__title">
        <div>{ course.name }</div>
        { educator.name }
      </div>

    </div>
  </div>
);
