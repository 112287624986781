import './index.scss';
import React from 'react';
import classNames from 'classnames';

const PUBLIC = 'Public';
const PRIVATE = 'Private';

export const ProfileSlugSectionSavedView = ({ urlDomain, profileSlug, visibility }) => {
  const visibilityClassName = classNames(
    'v2-profile-slug-section-saved-view__visibility',
    { 'v2-profile-slug-section-saved-view__visibility--public': visibility === PUBLIC },
    { 'v2-profile-slug-section-saved-view__visibility--private': visibility === PRIVATE },
  );

  return (
    <div className = "v2-profile-slug-section-saved-view">
      <div className = "v2-profile-slug-section-saved-view__divider" />

      <div className = "v2-profile-slug-section-saved-view__url-and-visibility">
        <div className = "v2-profile-slug-section-saved-view__url">
          <div className = "v2-profile-slug-section-saved-view__url-domain-and-path">
            { urlDomain }
          </div>
          <span className = "v2-profile-slug-section-saved-view__url-profile-slug">
            { profileSlug }
          </span>
        </div>
        <div className = { visibilityClassName }>
          { visibility }
        </div>
      </div>
    </div>
  );
};
