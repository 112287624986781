import React from 'react';

import InfoEnvelope from 'components/v2/register/InfoEnvelope/InfoEnvelope';

import './index.scss';

const ThanksPage = () => (
  <div className="v2-register-thanks">
    <InfoEnvelope />

    <div className="v2-register-thanks__thumbs-up-block">
      <div className="v2-register-thanks__thumbs-up-title">
        Thanks!
      </div>
      <img
        src={require('./thumbs-up.svg')}
        alt=""
      />
      <div className="v2-register-thanks__thumbs-up-crew">
        &#10084;️ Paddl Crew
      </div>
    </div>
  </div>
);

export default ThanksPage;
