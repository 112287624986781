import React                  from 'react';
import { connect }            from 'react-redux';
import { reset }              from 'redux-form';
import map                    from 'lodash/map';
import getForm                from 'components/form';
import Button                 from 'components/button';
import TransitionList         from 'components/list/transition-list';
import ListGroups             from 'components/list-groups';
import PageContent            from 'components/page-content';
import PagePanels             from 'components/panels';
import PageSection            from 'components/page-section';
import PageActions            from 'components/page-actions';
import ListFilter             from 'components/list-filter';
import Modal                  from 'components/modal';
import ApplicationListPanel   from 'containers/application-list-panel';
import JobApplicationsDetails from 'containers/job-applications-details';
import BulkUnsuccessfulApplicationButton from 'components/v2/buttons/circle-buttons/bulk-shortlist-application-button';
import BulkShortlistApplicationButton    from 'components/v2/buttons/circle-buttons/bulk-unsuccessful-application-button';

import {
  toggleModal,
  toggleBulk
} from 'modules/ui';
import {
  shortlist,
  reject
} from 'modules/applications';
import {
  filteredGroupedApplicationsSelector,
  groupsSizeApplicationsSelector,
  jobSelector
} from 'selectors';

const FORM_ID          = 'APPLICATIONS-LIST';
const CONFIRM_MODAL_ID = 'CONFIRM';
const REJECT_MODAL_ID  = 'REJECT';

const Form = getForm({
  id: FORM_ID,
  initialValues: {
    list: []
  }
});

const GROUPS = [{
  id: 'sent',
  title: 'Received',
  isPrimary: true
}, {
  id: 'shortlisted',
  title: 'Shortlisted',
  isSuccess: true
}, {
  id: 'unsuccessful',
  title: 'Unsuccessful',
  isInactive: true
}];

class JobApplicationEmployerIndex extends React.Component {
  constructor(props) {
    super(props);

    this.handleShortlist          = this.handleShortlist.bind(this);
    this.handleReject             = this.handleReject.bind(this);
    this.handleConfirmModalToggle = this.handleConfirmModalToggle.bind(this);
    this.handleRejectModalToggle  = this.handleRejectModalToggle.bind(this);
  }
  UNSAFE_componentWillUpdate(nextProps) {
    const {
      isActive,
      toggleBulk
    } = this.props;

    if (isActive && nextProps.isActive && nextProps.selectedItems.length === 0) {
      toggleBulk();
    }
    if (!isActive && !nextProps.isActive && nextProps.selectedItems.length > 0) {
      toggleBulk();
    }
  }
  handleConfirmModalToggle() {
    this.props.toggleModal(CONFIRM_MODAL_ID);
  }
  handleRejectModalToggle() {
    this.props.toggleModal(REJECT_MODAL_ID);
  }
  handleShortlist() {
    const {
      shortlist,
      selectedItems,
      reset
    } = this.props;

    shortlist(selectedItems);
    this.handleConfirmModalToggle();
    reset(FORM_ID);
  }
  handleReject() {
    const {
      reject,
      selectedItems,
      reset
    } = this.props;

    reject(selectedItems);
    this.handleRejectModalToggle();
    reset(FORM_ID);
  }
  render() {
    const {
      id,
      applications,
      groupsSize,
      openedModals,
      selectedItems,
      status,
      isActive
    } = this.props;

    return (
      <PageContent isFull>
        <JobApplicationsDetails id = { id } />
        { isActive &&
          <PageActions>
            <BulkShortlistApplicationButton onClick = { this.handleRejectModalToggle } />
            <BulkUnsuccessfulApplicationButton onClick = { this.handleConfirmModalToggle } />
          </PageActions>
        }
        <PageSection>
          <ListFilter
            items      = { map(GROUPS, v => ({ ...v, size: groupsSize[v.id] })) }
            isDisabled = { status === 'archived' }
          />
          <PagePanels />
          <Form>
            <ListGroups
              groups       = { GROUPS }
              groupedItems = { applications }
            >
              { items => (
                <TransitionList
                  items     = { items }
                  Component = { ApplicationListPanel }
                />
              )}
            </ListGroups>
          </Form>
        </PageSection>
        <Modal
          icon = "shortlist"
          onClose = { this.handleConfirmModalToggle }
          action = {
            <Button
              type = "button"
              onClick = { this.handleShortlist }
              isSuccess
              isBlock
              hasShadow
            >
              Confirm Shortlist
            </Button>
          }
          isOpen = { openedModals.indexOf(CONFIRM_MODAL_ID) > -1 }
          isLarge
          hasCancel
        >
          <div className = "modal__title">Shortlisting</div>
          <p><strong>{ selectedItems.length }</strong> Application{selectedItems.length > 1 ? 's' : ''} will be moved to Shortlisted</p>
          <p>This will reveal Applicant's contact details and they will be notified that they have been Shortlisted.</p>
        </Modal>
        <Modal
          icon = "unsuccessful"
          onClose = { this.handleRejectModalToggle }
          action = {
            <Button
              type = "button"
              onClick = { this.handleReject }
              isPrimary
              isBlock
              hasShadow
            >
              Confirm Unsuccessful
            </Button>
          }
          isOpen = { openedModals.indexOf(REJECT_MODAL_ID) > -1 }
          isLarge
          hasCancel
        >
          <div className = "modal__title">Unsuccessful</div>
          <p><strong>{ selectedItems.length }</strong> Application{selectedItems.length > 1 ? 's' : ''} will be moved to Unsuccessful</p>
          <p>Applicants will be notified they have been unsuccessful and presented alternative jobs.</p>
        </Modal>
      </PageContent>
    );
  }
}

export default connect((state, { location: { query: { filter } }, params: { id }}) => {
  const selectedItems = state.form[FORM_ID]
    ? state.form[FORM_ID].values.list.map((v, i) => (v ? i : false)).filter(v => v)
    : [];

  return {
    id,
    applications: filteredGroupedApplicationsSelector(state, id, filter),
    groupsSize: groupsSizeApplicationsSelector(state, id),
    openedModals: state.ui.openedModals,
    isActive: state.ui.isBulkActive,
    status: jobSelector(state, id).status,
    selectedItems
  };
}, {
  toggleModal,
  toggleBulk,
  shortlist,
  reject,
  reset
})(JobApplicationEmployerIndex);
