import './index.scss';
import React from 'react';

const V2Switch = ({ isChecked, onChange }) => {
  return (
    <div className = "v2-switch">
      <input
        className = "v2-switch__input"
        type      = "checkbox"
        checked   = { isChecked ? ' checked' : '' }
        onChange  = { (event) => onChange(event.target.checked) }
      />
      <span className = "v2-switch__circle"></span>
      <label className = "v2-switch__label">
        <span className = "v2-switch__label-unchecked">No</span>
        <span className = "v2-switch__label-checked">Yes</span>
      </label>
      <div className = "v2-switch__bg"></div>
    </div>
  );
};

export default V2Switch;
