import './job-applications-details-header.scss';

import React    from 'react';
import { Link } from 'react-router';

const JobApplicationsDetailsHeader = ({
  title,
  thumb,
  link,
  statusLabel
}) => {
  if (!title) return null;

  return (
    <div className = "job-applications-details__header">
      { thumb &&
        <div className = "job-applications-details__thumb">
          { thumb }
        </div>
      }
      <div className = "job-applications-details__wrapper">
        { title &&
          <Link to = { link } className = "job-applications-details__title">
            { title }
          </Link>
        }
        { statusLabel &&
          <div className = "job-applications-details__status">
            { statusLabel }
          </div>
        }
      </div>
    </div>
  );
};

export default JobApplicationsDetailsHeader;
