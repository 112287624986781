import React from 'react';

import { defined } from 'lib/helpers';
import { V2NavbarLoginGetStarted } from 'components/v2/navbar-login-get-started';
import { Support } from 'components/support';
import { PageFooter } from 'components/v2/page-footer';
import { ModalConductor } from 'components/v2/modal-conductor';
import SnackBar from 'components/v2/snack-bar';

import './index.scss';

export class V2LandingGetStarted extends React.Component {
  constructor(props) {
    const { appv2 } = props;

    super(props);

    this._snackBarHidingTimeouts = [];

    this.state = {
      currentModalComponent: null,
      currentModalProps: null,
      currentSnackBarStyle: null,
      currentSnackBarText: null,
    };

    appv2.ui.setModalHandlers({
      open: ({ modalComponent, modalProps }) => {
        this.setState({
          currentModalComponent: defined(modalComponent),
          currentModalProps: defined(modalProps),
        });
      },
      close: () => {
        this.setState({
          currentModalComponent: null,
          currentModalProps: null,
        });
      },
    });

    const SNACKBAR_ACTIVE_CLASS = 'v2-snack-bar--is-active';

    appv2.ui.setSnackBarHandlers({
      show: ({ style, text }) => {
        // remove all the previous hiding timeouts
        this._snackBarHidingTimeouts.map((tout) => {
          clearTimeout(tout);
        });

        this._snackBarHidingTimeouts = [];

        this.setState({
          currentSnackBarStyle: defined(style),
          currentSnackBarText: defined(text),
        });

        // adding the class in a separate loop cycle, to let the component be rerendered before that
        setTimeout(() => {
          this._snackBarDiv && this._snackBarDiv.classList.add(SNACKBAR_ACTIVE_CLASS);
        }, 10);
      },
      hide: () => {
        if (this._snackBarDiv) {
          this._snackBarDiv.classList.remove(SNACKBAR_ACTIVE_CLASS);
        }

        this._snackBarHidingTimeouts.push(setTimeout(() => {
          this.setState({
            currentSnackBarStyle: null,
            currentSnackBarText: null,
          });
        }, 5000)); // actual number doesn't matter, it's called just to clean up the data
      },
    });
  }

  render() {
    const { appv2, children } = this.props;

    const {
      currentModalComponent,
      currentModalProps,
      currentSnackBarStyle,
      currentSnackBarText,
    } = this.state;

    const navBarStep = children.type.navBarStep || 1;

    return (
      <div className="v2-landing-get-started">
        <div className="v2-landing-get-started__page-header">
          <V2NavbarLoginGetStarted step={navBarStep} />
        </div>

        <div className="v2-landing-get-started__page-body">
          { React.cloneElement(children, this.props) }
        </div>

        <div className="v2-landing-get-started__page-footer">
          <Support />
          <PageFooter />
        </div>

        <ModalConductor
          appv2={appv2}
          currentModalComponent={currentModalComponent}
          currentModalProps={currentModalProps}
        />

        <SnackBar
          withoutSidebarOffset
          style={currentSnackBarStyle}
          text={currentSnackBarText}
          storeDivRef={(elem) => { this._snackBarDiv = elem; }}
        />
      </div>
    );
  }
}
