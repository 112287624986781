import React from 'react';

import AccessTitles from 'components/v2/register/AccessTitles/AccessTitles';
import InputTextSingleLine from 'components/v2/input-fields/input-text-single-line';
import Select from 'components/v2/input-fields/select';

import './index.scss';

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      identityValue: '',
      fullNameValue: '',
      dobValue: '',
      errors: {},
    };
  }

  render() {
    const { identityValue, fullNameValue, dobValue, errors } = this.state;

    const setFullNameValue = (value) => {
      const transformedValue = value
        .replace(/\d/g, '');

      this.setState({
        fullNameValue: transformedValue,
      });
    };

    const identityOptions = [
      { value: 'male', title: 'Male' },
      { value: 'female', title: 'Female' },
      { value: 'other', title: 'Other' },
    ];

    const setIdentityValue = (value) => {
      this.setState({
        identityValue: value,
      });
    };

    const setDobValue = (value) => {
      let transformedValue = value
        .replace(/\D/g, '')
        .replace(/^(.{2})(.)/, '$1/$2')
        .replace(/^(.{5})(.)/, '$1/$2');

      if (value.slice(-1) == '/') {
        transformedValue += '/';
      }

      transformedValue = transformedValue.slice(0, 10);

      this.setState({
        dobValue: transformedValue,
      });
    };

    const getDataErrors = () => {
      const dataErrors = {};

      if (fullNameValue.length === 0) {
        dataErrors.fullName = 'Required';
      }

      if (dobValue.match(/^\d{2}\/\d{2}\/\d{4}$/) === null) {
        dataErrors.dob = 'Required, enter as day/month/year';
      }

      if (identityValue.length === 0) {
        dataErrors.identity = 'Required';
      }

      return dataErrors;
    };

    const onSubmit = (e) => {
      const { appv2 } = this.props;
      const dataErrors = getDataErrors();

      e.preventDefault();

      if (Object.keys(dataErrors).length > 0) {
        this.setState({ errors: dataErrors });
        return;
      }

      appv2.api.profiles.initWithBasics({
        id: appv2.auth.currentProfileId(),
        fullName: fullNameValue,
        dob: dobValue.split('/').reverse().join('-'),
        identity: identityValue,
      })
        .then((data) => {
          return appv2.ui.redirect({ url: '/onboarding/add' });
        });
    };

    return (
      <div className="v2-get-started-student-profile">
        <AccessTitles
          heading="Create a Profile"
          subheading="Let’s start with a few basics for your Paddl Profile..."
        />

        <form className="v2-get-started-student-profile__form" onSubmit={onSubmit}>
          <div className="row">
            <div className="col">
              <InputTextSingleLine
                name="fullName"
                label="Full Name"
                placeholder="e.g. John Appleseed"
                value={fullNameValue}
                onChange={(value) => { setFullNameValue(value); }}
                isAutoFocused
                message={errors.fullName}
                isError={!!errors.fullName}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <InputTextSingleLine
                name="dob"
                label="Date of Birth"
                placeholder="DD/MM/YYYY"
                value={dobValue}
                onChange={(value) => { setDobValue(value); }}
                message={errors.dob}
                isError={!!errors.dob}
              />
            </div>

            <div className="col-sm-6">
              <Select
                name="identity"
                label="Identity"
                placeholder="Select Gender"
                value={identityValue}
                options={identityOptions}
                onChange={(value) => { setIdentityValue(value); }}
                message={errors.identity}
                isError={!!errors.identity}
              />
            </div>
          </div>
          <button
            className="v2-get-started-student-profile__form-btn"
            type="submit"
            disabled={Object.keys(getDataErrors()).length > 0}
          >
            Create Profile
          </button>
        </form>

        <div className="v2-get-started-student-profile__terms">
          Your DOB and gender will not be shared in Paddl
        </div>
      </div>
    );
  }
}

ProfilePage.navBarStep = 1;

export default ProfilePage;
