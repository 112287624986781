import _ from 'lodash';
import {
  TTL
} from './constants';

const SEND    = 'notifications/SEND';
const DISMISS = 'notifications/DISMISS';

const initialState = [];

export default (state = initialState, action) => {
  switch(action.type) {
  case SEND: {
    return [...state, action.payload]
    break;
  }
  case DISMISS: {
    const { id } = action.payload;

    return state.filter(v => v.id !== id)
    break;
  }
  default: return state;
  }
}

export function dismissNotification({ id }) {
  return {
    type: DISMISS,
    payload: { id }
  };
}

function showNotification({ id, msg, type, icon }) {
  return {
    type: SEND,
    payload: { id, msg, type, icon }
  };
}

export function sendNotification({
  id = new Date().getTime(),
  msg,
  type,
  icon,
  ttl = TTL
}) {
  return ({ dispatch }) => {
    setTimeout(() => dispatch(dismissNotification({ id })), ttl);

    dispatch(showNotification({ id, msg, type, icon }));
  }
}


