import React              from 'react';
import { connect }        from 'react-redux';
import Moment             from 'lib/helpers/moment';
import Avatar             from 'components/avatar';
import Score              from 'components/score';
import Sticker            from 'components/sticker';
import ListPanel          from 'components/list-panel';
import ApplicationStatusContainer from 'containers/application-status';
import {
  applicationSelector
} from 'selectors';

const STICKER_BY_STATUS = {
  shortlisted:  <Sticker icon = "sticker-shortlist" />,
  unsuccessful: <Sticker icon = "sticker-unsuccess" />,
};

const DATA_BY_ROLE = {
  student: ({
    id,
    campaign: { title, employer: { business_name, photo_url } },
    status: { status }
  }) => ({
    id,
    title,
    data: business_name,
    thumb: <ApplicationStatusContainer id = { id } icon = { <Avatar url = { photo_url } /> } />,
    link: status === 'in_progress' ? `/applications/${id}/draft` : `/applications/${id}`
  }),
  employer: ({
    id,
    received_at,
    applicant_id: { full_name, photo_url, id: applicantId },
    status: { status },
    scores: { score, max_score }
  }) => ({
    id,
    title: full_name,
    data: `Received: ${ Moment.format_L(new Date(received_at)) }`,
    thumb: <Avatar url = { photo_url } id = { applicantId } role = "student" />,
    sticker: STICKER_BY_STATUS[status],
    addons: <Score score = { score } maxScore = { max_score } isSuccess />,
    link: `/applications/${id}`,
    isSelectable: status === 'sent'
  })
};

const ApplicationListPanel = props => {
  const {
    id,
    role
  } = props;

  if (!id) return null;

  const data = DATA_BY_ROLE[role](props);

  return (
    <ListPanel
      className = { `ui__id-${id}` }
      { ...data } />
  );
};

export default connect((state, { id }) => ({
  ...applicationSelector(state, id),
  role: state.auth.role
}))(ApplicationListPanel);
