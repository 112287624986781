import './job-details.scss';

import React           from 'react';
import { Link }        from 'react-router';
import PropTypes       from 'prop-types';
import Button          from 'components/button';
import Avatar          from 'components/avatar';
import TextCollapsible from 'components/text-collapsible';
import PayRate         from 'components/pay-rate';

class JobDetails extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      employerProfilePath: null,
    };
  }

  render() {
    const {
      address,
      id,
      title,
      description,
      employer,
      category,
      hours_amount,
      hours_unit,
      pay_rate,
      villages,
      isHorizontal,
      hasAvatar,
      hasAddress,
      hasDescription,
      isDefaultTheme,
      isMuted
    } = this.props;

    if (!title)  return null;
    if (isMuted) return <div className = "job-details is--muted" />;

    const jobLink = `/jobs/${id}`;

    const employerProfilePath = this.state.employerProfilePath ||
      (
        employer &&
        window._appv2.slugs.generateProfilePath({
          id:       employer.id,
          callback: ({ path }) => { this.setState({ employerProfilePath: path }); },
        })
      );

    return (
      <div className = { `job-details ${isHorizontal ? 'is--horizontal' : ''}` }>
        <div className = "job-details__wrapper">
          { hasAvatar &&
            <div className = "job-details__avatar">
              <Avatar
                id   = { employer.id }
                role = { employer.role }
                url  = { employer.photo_url }
                hasBorder
                isLinked />
            </div>
          }
          <div className = "job-details__header">
            <Link to = { jobLink } className = "job-details__title">
              { title }
            </Link>
            <div className = "job-details__company-name">
              { employer &&
                <Link to = { employerProfilePath + '/summary' }>
                  { employer.business_name }
                </Link>
              }
            </div>
            {!isHorizontal &&
              <div className = "job-details__category">
                { category }
              </div>
            }
            { hasAddress &&
              <div className = "job-details__address">
                { address }
              </div>
            }
          </div>
        </div>
        <div className = "job-details__info">
          <div className = "job-details__info__item">
            <span
              className = {
                `job-details__info__indicator
                ${isDefaultTheme ? 'is--default' : 'is--highlighted-secondary'}` }>
              { hours_amount || '--' }
            </span>
            <span className = "job-details__info__label">
              { hours_amount && hours_unit ? `Hours ${hours_unit}` : 'N/A' }
            </span>
          </div>
          <PayRate pay_rate = { pay_rate } isDefaultTheme = { isDefaultTheme } />
        </div>
        { hasDescription &&
          <div className = "job-details__description">
            <div className = "job-details__row">
              <TextCollapsible>
                { description }
              </TextCollapsible>
            </div>
          </div>
        }
        { isHorizontal &&
          <Button
            link = { jobLink }
            icon = "arrow-right"
            isMobileLink />
        }
      </div>
    );
  }
}

JobDetails.propTypes = {
  title: PropTypes.string,
  employer: PropTypes.object,
  category: PropTypes.string,
  hours_amount: PropTypes.number,
  hours_unit: PropTypes.string,
  description: PropTypes.string,
  pay_rate: PropTypes.number,
  villages: PropTypes.array,
  isHorizontal: PropTypes.bool,
  hasAvatar: PropTypes.bool,
  hasAddress: PropTypes.bool
};

export default JobDetails;
