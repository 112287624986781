import React        from 'react';
import PropTypes    from 'prop-types';
import ProfilePhoto from 'components/profile-photo';
import Markdown     from 'components/markdown';
import Formatted    from 'components/formatted';

const Message = ({ message }) => (
  <li
    key       = { message.id }
    className = "message is--tuki" >
      <div className = "messageThread--photo">
        <ProfilePhoto photo = { require('images/tukiAvatar.svg') } />
      </div>
    <div className = "content pls">
      <h3 className = "author">
        <span className = "text-muted">
          Sent <Formatted type="date" value={ message.created_at } />
        </span>
      </h3>
      <Markdown>
        { message.message }
      </Markdown>
    </div>
  </li>
);

Message.propTypes = {
  message: PropTypes.object.isRequired,
  author: PropTypes.object,
};

export default Message;
