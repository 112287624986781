import { CALL_API } from 'middleware/call-api';
import {
  educatorSchema,
  campusSchema,
  courseSchema
} from 'schemas';

const REGISTER_EMPLOYER_REQUEST = 'register/EMPLOYER_REQUEST';
const REGISTER_EMPLOYER_SUCCESS = 'register/EMPLOYER_SUCCESS';
const REGISTER_EMPLOYER_FAILURE = 'register/EMPLOYER_FAILURE';

const REGISTER_STUDENT_REQUEST  = 'register/STUDENT_REQUEST';
const REGISTER_STUDENT_SUCCESS  = 'register/STUDENT_SUCCESS';
const REGISTER_STUDENT_FAILURE  = 'register/STUDENT_FAILURE';

const REGISTER_WAITLIST_REQUEST = 'register/WAITLIST_REQUEST';
const REGISTER_WAITLIST_SUCCESS = 'register/WAITLIST_SUCCESS';
const REGISTER_WAITLIST_FAILURE = 'register/WAITLIST_FAILURE';

const GET_EDUCATORS_REQUEST     = 'register/GET_EDUCATORS_REQUEST';
const GET_EDUCATORS_SUCCESS     = 'register/GET_EDUCATORS_SUCCESS';
const GET_EDUCATORS_FAILURE     = 'register/GET_EDUCATORS_FAILURE';

const GET_CAMPUSES_REQUEST      = 'register/GET_CAMPUSES_REQUEST';
const GET_CAMPUSES_SUCCESS      = 'register/GET_CAMPUSES_SUCCESS';
const GET_CAMPUSES_FAILURE      = 'register/GET_CAMPUSES_FAILURE';

const GET_COURSES_REQUEST       = 'register/GET_COURSES_REQUEST';
const GET_COURSES_SUCCESS       = 'register/GET_COURSES_SUCCESS';
const GET_COURSES_FAILURE       = 'register/GET_COURSES_FAILURE';

const initialState = {};

export default (state = initialState, action) => {
  switch(action.type) {
    default: return state;
  }
}

export function getEducators() {
  return {
    [CALL_API]: {
      types: [
        GET_EDUCATORS_REQUEST,
        GET_EDUCATORS_SUCCESS,
        GET_EDUCATORS_FAILURE
      ],
      endpoint: '/educators',
      schema: educatorSchema
    }
  }
}

export function getCampuses({ educator }) {
  return {
    [CALL_API]: {
      types: [
        GET_CAMPUSES_REQUEST,
        GET_CAMPUSES_SUCCESS,
        GET_CAMPUSES_FAILURE
      ],
      endpoint: `/educators/${educator}/campuses`,
      schema: campusSchema
    }
  }
}

export function getCourses({ educator, campus }) {
  return {
    [CALL_API]: {
      types: [
        GET_COURSES_REQUEST,
        GET_COURSES_SUCCESS,
        GET_COURSES_FAILURE
      ],
      endpoint: `/educators/${educator}/campuses/${campus}/courses`,
      schema: courseSchema
    }
  }
}

export function registerEmployer({ agreedterms, ...values}) {
  return {
    [CALL_API]: {
      types: [
        REGISTER_EMPLOYER_REQUEST,
        REGISTER_EMPLOYER_SUCCESS,
        REGISTER_EMPLOYER_FAILURE
      ],
      method: 'POST',
      data: values,
      endpoint: '/register/employer',
      meta: {
        [REGISTER_EMPLOYER_SUCCESS]: {
          redirect: `/register/employer/success/${ encodeURIComponent(values.email) }`,
          segment: { data : values }
        }
      }
    }
  }
}

export function registerStudent(values) {
  return {
    [CALL_API]: {
      types: [
        REGISTER_STUDENT_REQUEST,
        REGISTER_STUDENT_SUCCESS,
        REGISTER_STUDENT_FAILURE
      ],
      method: 'POST',
      data: values,
      endpoint: '/register/student',
      meta: {
        [REGISTER_STUDENT_SUCCESS]: {
          redirect: `/register/student/success/${ encodeURIComponent(values.email) }`,
        }
      }
    }
  }
}

export function registerWaitlist(values) {
  return {
    [CALL_API]: {
      types: [
        REGISTER_WAITLIST_REQUEST,
        REGISTER_WAITLIST_SUCCESS,
        REGISTER_WAITLIST_FAILURE
      ],
      method: 'POST',
      data: values,
      endpoint: '/register/waitlist',
      meta: {
        [REGISTER_WAITLIST_SUCCESS]: {
          redirect: '/register/student/waitlist'
        }
      }
    }
  }
}
