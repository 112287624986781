import { destroyAuth } from 'modules/auth';

export default (store, role) => (nextState, replace) => {
  if (!store) return;
  if (nextState.routes[nextState.routes.length - 1].isPublic) return;

  const { auth } = store.getState();

  const isAuthorized = role ? auth.role === role : true;
  const hasToken     = auth.token;
  const {
    location: {
      pathname,
      search = ''
    }
  } = nextState;

  if (!hasToken || !isAuthorized) {
    replace('/login');
    store.dispatch(destroyAuth(pathname + search));
  }
};
