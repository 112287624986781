import React from 'react';

export default () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18 0C8.05886 0 0 8.05886 0 18C0 27.9411 8.05886 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05886 27.9411 0 18 0ZM17.9999 1.28571C27.2162 1.28571 34.7142 8.78374 34.7142 18C34.7142 27.2163 27.2162 34.7143 17.9999 34.7143C8.78367 34.7143 1.28564 27.2163 1.28564 18C1.28564 8.78374 8.78367 1.28571 17.9999 1.28571Z" fill="#B1E5FC"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0.0599143L15.4273 0L15.4299 0.658286L0.00257143 0.7182L0 0.0599143V0.0599143Z" transform="translate(10.5415 17.6892)" stroke="#69D2F7"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0.00257143L0.665229 0L0.725657 15.2681L0.0604286 15.2707L0 0.00257143V0.00257143Z" transform="translate(17.8936 10.4129)" stroke="#69D2F7"/>
    </svg>
  );
};
