import './index.scss';
import React              from 'react';
import { browserHistory } from 'react-router';
import SvgIconEdit        from 'components/v2/svg-icons/svg-icon-edit';

const EditJobButton = ({
  link
}) => {
  return(
    <button
      type      = "button"
      className =  "button--edit-job"
      onClick   = { link && (() => browserHistory.push(link)) }>
      <SvgIconEdit/>
    </button>
  )};

  export default EditJobButton;
