import './profile-info.scss';

import React             from 'react';
import PropTypes         from 'prop-types';
import PageSection       from 'components/page-section';
import ProfileInfoAvatar from 'components/profile-info-avatar';

const ProfileInfo = (props) => {
  const {
    role,
    title,
    subTitle,
    content,
    onClick
  } = props;

  const onClickDefined = (onClick) => { return typeof onClick !== 'undefined' ? 'is--link': '' }

  if (!title) return null;

  return (
    <PageSection backgroundUrl = { role && require(`images/bg-opaque--profile-${role}.svg`) }>
      <div className = { `profile-info ${content ? ' has--content' : ''}` }>
        <div className = "profile-info__avatar" onClick={ onClick }>
          <ProfileInfoAvatar { ...props } />
        </div>
        <div className = "profile-info__wrapper">
          <h1 className={`profile-info__title ${onClickDefined(onClick)}`} onClick={ onClick }>
            { title }
          </h1>

          { subTitle &&
            <h4 className = "profile-info__subtitle">
              { subTitle }
            </h4>
          }

          { content &&
            <div className = "profile-info__content">
              { content }
            </div>
          }
        </div>
      </div>
    </PageSection>
  );
};

ProfileInfo.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string
};

export default ProfileInfo;
