import './index.scss';
import React                      from 'react';
import SvgIconEmptyState          from 'components/v2/svg-icons/svg-icon-empty-state';
import CreateCampaignSquareButton from 'components/v2/buttons/square-buttons/create-campaign-button';

const CampaignsTabEmptyState = () => {
  return (
    <div className = "v2-campaigns-tab-empty-state">
      <div className = "v2-campaigns-tab-empty-state__container">
        <div className = "v2-campaigns-tab-empty-state__content">
          <SvgIconEmptyState />
          <p className = "v2-campaigns-tab-empty-state__text">
            Create Campaigns to attract talented Paddlrs to your Company.
          </p>
          <div className = "v2-campaigns-tab-empty-state__button">
            <CreateCampaignSquareButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignsTabEmptyState;
