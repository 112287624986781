import BasecampPage          from 'pages/v2/basecamp-page';
import { STUDENT, EMPLOYER } from 'lib/api/profiles/profile';

export default ({ appv2, store }) => {
  return {
    path:         'basecamp',
    title:        'Basecamp',
    getComponent: ({ params }, cb) => {
      const { role } = store.getState().auth;

      switch (role) {
        case STUDENT:  return cb(null, BasecampPage);
        case EMPLOYER: return appv2.ui.redirect({ url: `/jobs` });
        default:       return appv2.ui.redirect({ url: `/login` });
      }
    },
  };
};
