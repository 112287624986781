import { defined } from 'lib/helpers';
import { isEndDateInThePast } from 'lib/api/activities/generics/duration-common';

export const calcYear = (isEndDateInThePast, endYear, viewedAt) => {
  return isEndDateInThePast ? endYear : viewedAt.getFullYear();
};

export const calcMonth = (isEndDateInThePast, endMonth, viewedAt) => {
  return isEndDateInThePast ? endMonth : viewedAt.getMonth() + 1;
};

export const createGroupsByMonth = (activities, viewedAt) => {
  const reduceFunction = (groups, activity) => {
    const { endYear, endMonth, endDay } = activity.get('duration');

    const month = calcMonth(
      isEndDateInThePast({
        endYear:  endYear  && endYear.value,
        endMonth: endMonth && endMonth.value,
        endDay:   endDay   && endDay.value,
      }),
      endMonth.value,
      viewedAt
    );

    if (!groups.hasOwnProperty(month)) {
      groups[month] = [];
    }

    groups[month].push(activity);
    return groups;
  };

  return activities.reduce(reduceFunction, {});
};

export const createGroupsByYear = (activities, viewedAt) => {
  const reduceFunction = (groups, activity) => {
    const { endYear, endMonth, endDay } = activity.get('duration');

    const year = calcYear(
      isEndDateInThePast({
        endYear:  endYear  && endYear.value,
        endMonth: endMonth && endMonth.value,
        endDay:   endDay   && endDay.value,
      }),
      endYear.value,
      viewedAt
    );

    if (!groups.hasOwnProperty(year)) {
      groups[year] = [];
    }

    groups[year].push(activity);
    return groups;
  };

  return activities.reduce(reduceFunction, {});
};
