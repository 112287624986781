import './index.scss';
import React from 'react';

export class CtaButton extends React.Component {
  render() {
    return (
      <button className = "cta-button" { ...this.props }>
        { this.props.children }
      </button>
    );
  }
}
