import './index.scss';
import React from 'react';

import { isProfilePage } from 'lib/helpers';
import { STUDENT } from 'lib/api/profiles/profile';
import Common from 'pages/v2/profile/common';
import ShareProfileButton from 'components/v2/buttons/circle-buttons/share-profile-button';
import { NavbarLogInButton } from 'components/v2/navbar-button/log-in-button';
import { NavbarSignUpButton } from 'components/v2/navbar-button/sign-up-button';

export class NavbarPageActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: Common.createEmptyProfile(),
    };
  }

  componentDidMount() {
    if (isProfilePage() === false) return;

    const { appv2, params } = this.props;

    const isLoggedIn = appv2.auth.isLoggedIn();

    appv2.api.profiles
      .fetchWithExtraByIdOrSlug({ idOrSlug: params.id, isLoggedIn })
      .then(({ profile }) => {
        this.setState({ profile });
      });
  }

  render() {
    const { appv2 } = this.props;
    const { profile } = this.state;

    const renderShareProfileButton = () => {
      if (isProfilePage() === false) return null;
      if (profile.get('role') !== STUDENT) return null;

      const isProfileOwner = appv2.auth.isCurrentProfile({ profileId: profile.get('id') });

      const openShareProfileModal = () => {
        appv2.ui.openShareProfileModal({
          withLeftOffset: true,
          isProfileOwner,
          profile,
        });
      };

      return <ShareProfileButton onClick={openShareProfileModal} />;
    };

    const { currentProfileId } = this.props;

    return (
      <div className="navbar-page-actions">
        {
          !currentProfileId &&
          <div className="navbar-page-actions__actions">
            <NavbarLogInButton />
            <NavbarSignUpButton />
          </div>
        }
        { renderShareProfileButton() }
      </div>
    );
  }
}
