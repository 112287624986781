import { browserHistory } from 'react-router';

export default () => (next) => (action) => {
  if (!action.meta || !action.meta.redirect) {
    return next(action);
  }

  const result = next(action);

  const url = typeof action.meta.redirect === 'function'
    ? action.meta.redirect(action)
    : action.meta.redirect

  browserHistory.push(url);

  return result;
};
