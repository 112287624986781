import React       from 'react';
import { connect } from 'react-redux';
import PropTypes   from 'prop-types';
import PageTitle   from 'components/page-title';
import {
  profileSelector
} from 'selectors';

const HelloUsername = ({ profile: { full_name } }) => {
  const firstName = full_name && full_name.split(' ')[0];

  return (
    <PageTitle>
      { firstName ? `Hello, ${firstName}!` : 'Hello!' }
    </PageTitle>
  );
};

HelloUsername.propTypes = {
  profile: PropTypes.object
};

export default connect(state => ({
  profile: profileSelector(state)
}))(HelloUsername);
