import React              from 'react';
import PropTypes          from 'prop-types';
import ChallengesListItem from 'components/challenges-list-item';

const ChallengesList = ({ challenges }) => {
  if (challenges === undefined || challenges.length === 0) return null;

  return (
    <div className = "challenges-list">
      { challenges.map(ChallengesListItem) }
    </div>
  );
};

export default ChallengesList;

ChallengesList.propTypes = {
  challenges: PropTypes.array
};
