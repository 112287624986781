import './notifications.scss';

import React                   from 'react';
import { connect }             from 'react-redux';
import { dismissNotification } from 'modules/notifications';
import SvgIcon                 from 'components/svg-icon';
import {
  TransitionMotion,
  spring
} from 'react-motion';


const Notifications = ({ items, dismissNotification }) => (
  <TransitionMotion
    willLeave     = { () => ({ opacity: spring(0) }) }
    willEnter     = { () => ({ opacity: 0 }) }
    styles        = { items ? items.map((data, index) => ({ key: data.id, style: { opacity: spring(1) }, data })) : [] }>
    { styles =>
      <div className = "notifications">
        { styles.map(({ key, data: { id, msg, type, icon }, style }) =>
          <div
            style     = { { opacity: style.opacity } }
            onClick   = { () => dismissNotification({ id }) }
            key       = { key }
            className = { `notification is--${type}` } >
            { msg }
            { icon && <span className = "notification__icon"><SvgIcon id = { icon } /></span> }
          </div>
        )}
      </div>
    }
  </TransitionMotion>
);

export default connect(state => ({
  items: state.notifications
}), { dismissNotification })(Notifications);
