import './index.scss';
import React from 'react';

import { PaddlLogo } from 'component-lib/components/paddl-logo';

export const V2NavbarLoginGetStarted = ({ step }) => {
  const numberOfSteps = 2;

  return (
    <nav className="v2-navbar-login-get-started">
      <div className="v2-navbar-login-get-started__content-item">
        <div className="v2-navbar-login-get-started__brand--desktop">
          <PaddlLogo hasLink hasName />
        </div>
        <div className="v2-navbar-login-get-started__brand--mobile">
          <PaddlLogo hasLink />
        </div>
      </div>

      <div className="v2-navbar-login-get-started__content-item">
        <div className="v2-navbar-login-get-started__nav-steps">
          {
            [...Array(numberOfSteps)]
            .map((_, idx) => {
              const num = idx + 1;

              const namesOfClasses = 'v2-navbar-login-get-started__nav-steps-item-circle' + (
                (step == num)
                ? ' v2-navbar-login-get-started__nav-steps-item-circle--active'
                : ''
              );

              return (<div key={num} className={namesOfClasses}>{ num }</div>);
            })
          }
        </div>
      </div>
    </nav>
  );
};
