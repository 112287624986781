import './list.scss';

import React       from 'react';
import map         from 'lodash/map';
import withWrapper from './transition-wrapper';

const List = ({
  items,
  itemStyles,
  module,
  Component
}) => {
  const Item = withWrapper({ itemStyles, module })(Component);

  return (
    <div className = "list" >
      { map(items, Item) }
    </div>
  );
};

export default List;
