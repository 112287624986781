import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import api                 from 'modules/api';
import prepopulate         from 'modules/prepopulate';
import applications        from 'modules/applications';
import conversations       from 'modules/conversations';
import jobs                from 'modules/jobs';
import ui                  from 'modules/ui';
import accounts            from 'modules/accounts';
import notifications       from 'modules/notifications';
import forms               from 'modules/forms';
import guide               from 'modules/guide';
import profiles            from 'modules/profiles';
import villages            from 'modules/villages';
import createReducer       from 'utils/create-reducer';
import auth, { DESTROY }   from 'modules/auth';
import v2                  from 'modules/v2';

import {
  prepopulateApplications,
  prepopulateConversations,
  prepopulateJobs,
  prepopulateProfiles,
  prepopulateVillages,
  prepopulateEducators,
  prepopulateCampuses,
  prepopulateCourses,
} from 'modules/prepopulate';

const chainReducers = (reducer1, reducer2) => {
  return (state, action) => {
    const state1 = reducer1(state, action);
    const state2 = reducer2(state1, action);
    return state2;
  };
};

const appReducer = combineReducers({
  api,
  accounts,
  auth,
  applications:  chainReducers(prepopulateApplications, applications),
  conversations: chainReducers(prepopulateConversations, conversations),
  notifications,
  form,
  forms,
  guide,
  jobs:          chainReducers(prepopulateJobs, jobs),
  profiles:      chainReducers(prepopulateProfiles, profiles),
  villages:      chainReducers(prepopulateVillages, villages),
  ui,
  educators:     chainReducers(prepopulateEducators, createReducer('educators')),
  campuses:      chainReducers(prepopulateCampuses, createReducer('campuses')),
  courses:       chainReducers(prepopulateCourses, createReducer('courses')),
  v2
});

export default (state, action) => (
  appReducer(action.type === DESTROY ? undefined : state, action)
);
