import './index.scss';
import React from 'react';

import { ProfileSlugSuggestionListItem } from 'components/v2/profile-slug-suggestion-list-item';

const NUMBER_OF_SHOWN_SUGGESTIONS = 3;

export class ProfileSlugSuggestions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shownSuggestions: [],
      hiddenSuggestions: [],
    };

    this._setSuggestions = () => {
      const { suggestions } = this.props;

      this.setState({
        shownSuggestions: suggestions.slice(0, NUMBER_OF_SHOWN_SUGGESTIONS),
        hiddenSuggestions: suggestions.slice(NUMBER_OF_SHOWN_SUGGESTIONS),
      });
    };

    this._hideSuggestion = (target) => {
      const { shownSuggestions, hiddenSuggestions } = this.state;

      this.setState({
        shownSuggestions: shownSuggestions.map(e => { return (e === target) ? hiddenSuggestions[0] : e; }),
        hiddenSuggestions: hiddenSuggestions.slice(1).concat([target]),
      });
    };
  }

  componentDidMount() {
    this._setSuggestions();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.suggestions) === JSON.stringify(this.props.suggestions)) {
      return;
    }

    this._setSuggestions();
  }

  render() {
    const { shownSuggestions } = this.state;
    const { onClick } = this.props;

    return (
      <div className = "v2-profile-slug-suggestions">
        {
          shownSuggestions.map(suggestion => {
            return (
              <ProfileSlugSuggestionListItem
                key = { suggestion }
                suggestion = { suggestion }
                onClick = { () => { onClick(suggestion); } }
                onHideClick = { () => { this._hideSuggestion(suggestion); } }
                shouldFadeIn
              />
            );
          })
        }
      </div>
    );
  }
}
