import './messagethread.scss';

import React     from 'react';
import PropTypes from 'prop-types';
import Message   from 'components/message';

const MessageThread = ({
  messages,
  profiles,
  className
}) => (
  <ul className = { `messageThread ${className}` }>
    { messages && messages.map(message =>
      <Message
        key     = { message.id }
        message = { message }
        author  = { profiles[message.sender_id] } />
    )}
  </ul>
);

MessageThread.propTypes = {
  className: PropTypes.string,
  messages: PropTypes.array.isRequired,
  profiles: PropTypes.object.isRequired
};

export default MessageThread;
