import './index.scss';

import { defined } from 'lib/helpers';
import React       from 'react';

import StateWithSavePoints    from 'lib/helpers/state-with-save-points';
import PanelFormHeader        from 'components/v2/panel/form-header';
import { FormFooter }         from 'components/v2/form-footer';
import InputTextDropdown      from 'components/v2/input-fields/input-text-dropdown';
import Select                 from 'components/v2/input-fields/select';
import Checkbox               from 'components/v2/input-fields/checkbox';
import { VISA_STATUSES }      from 'components/v2/profile/student-location-panel/visa-statuses';

import ProfileExtra from 'lib/api/profiles/profile-extra';
import Location     from 'lib/api/location';
import VisaStatus   from 'lib/api/profiles/profile-extra/visa-status';

class StudentLocationPanelForm extends React.Component {
  constructor(props) {
    super(props);
    this._state = new StateWithSavePoints({ component: this });

    this._fetchLocationSuggestions = ({ substring }) => {
      const updateSuggestions = (predictions, status) => {
        const hashes = predictions
          ? predictions.filter((obj) => obj.hasOwnProperty('place_id')).map((obj) => {
            return {
              id:   defined(obj.place_id),
              name: defined(obj.description),
            };
          })
          : [];

        this._state.uiSet({
          locationSuggestions: hashes,
        });
      };

      if (typeof google === 'undefined') {
        console.log('Google has not been loaded yet');
        return null;
      }

      if (typeof this._googleService == 'undefined') {
        this._googleService = new google.maps.places.AutocompleteService();
      }

      if (defined(substring).trim().length > 0) {
        this._googleService.getQueryPredictions({ input: substring }, updateSuggestions);
      } else {
        this._state.uiSet({
          locationSuggestions: [],
        });
      }
    };
  }

  UNSAFE_componentWillMount() {
    const { profileExtra } = this.props;

    this._state.uiSet({
      isLocationAccepted:  (profileExtra.get('location').placeId !== null),
      locationSuggestions: [],
    });

    this._state.reset({
      location:                profileExtra.get('location'),
      visaStatus:              profileExtra.get('visaStatus').value,
      visaConditionEmployment: profileExtra.get('visaConditionEmployment'),
      visaConditionStudy:      profileExtra.get('visaConditionStudy'),
      opportunitiesRelocation: profileExtra.get('opportunitiesRelocation'),
      opportunitiesRemote:     profileExtra.get('opportunitiesRemote'),
    });
  }

  componentDidMount () {
    const script = document.createElement("script");

    if (typeof google === 'undefined') {
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDMJNmxwNJspSrQvgejfgB4KxqK8Wsxh-Q&libraries=places";
      script.async = true;
      document.body.appendChild(script);
    }
  }

  render() {
    const visaStatusOptions = Object.keys(VISA_STATUSES).map((k) => (
      {
        value: k,
        title: VISA_STATUSES[k],
      }
    ));

    const isDataValid = (
      this._state.uiGet({ id: 'isLocationAccepted' })
      && this._state.get({ id: 'visaStatus' }) !== null
    );

    const setStateOnVisaStatusChange = (value) => {
      this._state.change({ visaStatus: value });

      switch (value) {
        case VisaStatus.CITIZEN:
        case VisaStatus.RESIDENT:
          this._state.change({ visaConditionEmployment: true });
          this._state.change({ visaConditionStudy:      true });
          break;
        case VisaStatus.WORKER:
          this._state.change({ visaConditionEmployment: true  });
          this._state.change({ visaConditionStudy:      false });
          break;
        case VisaStatus.STUDENT:
          this._state.change({ visaConditionEmployment: false });
          this._state.change({ visaConditionStudy:      true  });
          break;
      }
    };

    return (
      <div className = "v2-student-location-panel-form">
        <div className = "v2-student-location-panel-form__header">
          <PanelFormHeader
            iconSrc     = { require('images/v2/icon-location.svg') }
            heading     = 'Current Location'
            description = 'Enter your current location and local visa conditions...'
          />
        </div>

        <div className = "v2-student-location-panel-form__body">
          <div className = "v2-student-location-panel-form__input-row">
            <InputTextDropdown
              name = "current-location"
              label = "Current Location"
              value = { this._state.get({ id: 'location' }).originalText || '' }
              options = { this._state.uiGet({ id: 'isLocationAccepted' }) ? null : this._state.uiGet({ id: 'locationSuggestions' }) }
              placeholder = "e.g. Melbourne Australia"
              isFreeTextRejected = { true }
              onBlur = { () => {
                if (this._state.get({ id: 'location' }).placeId) return;

                const newLocation = new Location({
                  placeId:      null,
                  originalText: '',
                  shortText:    '',
                  lat:          null,
                  lng:          null,
                });

                this._state.change({ location: newLocation });
                this._fetchLocationSuggestions({ substring: '' });
              } }

              onInputChange = { (value) => {
                const newLocation = new Location({
                  placeId:      null,
                  originalText: value,
                  shortText:    value,
                  lat:          null,
                  lng:          null,
                });

                this._state.change({ location: newLocation });
                this._fetchLocationSuggestions({ substring: value });
                this._state.uiSet({ isLocationAccepted: false });
              } }

              onOptionClick = { ({ id, name }) => {
                const newLocation = new Location({
                  placeId:      id,
                  originalText: name,
                  shortText:    name,
                  lat:          null,
                  lng:          null,
                });

                this._state.change({ location: newLocation });
                this._state.uiSet({ isLocationAccepted: true });
              } }
            />
          </div>

          <div className = "row">
            <div className = "col-sm-6">
              <div className = "v2-student-location-panel-form__input-row">
                <Select
                  name = "visa-status"
                  value = { this._state.get({ id: 'visaStatus' }) || '' }
                  label = "Local Visa status"
                  options = { visaStatusOptions }
                  placeholder = "Select Type"
                  onChange = { (value) => { setStateOnVisaStatusChange(value) } }
                />
              </div>
            </div>
          </div>

          <div className = "v2-student-location-panel-form__input-row">
            <label className = "v2-student-location-panel-form__label">Visa Conditions</label>

            <div className = "row">
              <div className = "col-sm-6">
                <Checkbox
                  value = '1'
                  isChecked = { this._state.get({ id: 'visaConditionEmployment' }) }
                  name = "employment"
                  label = "Employment"
                  onChange = { (value) => { this._state.change({ visaConditionEmployment: value }) } }
                />
              </div>

              <div className = "col-sm-6">
                <Checkbox
                  value = '1'
                  isChecked = { this._state.get({ id: 'visaConditionStudy' }) }
                  name = "study"
                  label = "Study"
                  onChange = { (value) => { this._state.change({ visaConditionStudy: value }) } }
                />
              </div>
            </div>

            <div className = "row">
              <div className = "col">
                <div className = "v2-student-location-panel-form__subtitle">
                  Check the opportunities you are eligible for in this location.
                </div>
              </div>
            </div>
          </div>

          <div className = "v2-student-location-panel-form__input-row">
            <label className = "v2-student-location-panel-form__label">Opportunities</label>

            <div className = "row">
              <div className = "col-sm-6">
                <Checkbox
                  value = '1'
                  isChecked = { this._state.get({ id: 'opportunitiesRelocation' }) }
                  name = "relocation"
                  label = "Relocation"
                  onChange = { (value) => { this._state.change({ opportunitiesRelocation: value }) } }
                />
              </div>

              <div className = "col-sm-6">
                <Checkbox
                  value = '1'
                  isChecked = { this._state.get({ id: 'opportunitiesRemote' }) }
                  name = "remote"
                  label = "Remote"
                  onChange = { (value) => { this._state.change({ opportunitiesRemote: value }) } }
                />
              </div>
            </div>

            <div className = "row">
              <div className = "col">
                <div className = "v2-student-location-panel-form__subtitle">
                  Check opportunities you are open to outside of this location.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className = "v2-student-location-panel-form__footer">
          <FormFooter
            customSubmitCopy = 'Save'
            onCancel = { () => { this.props.onCancel() } }
            onSubmit = { () => {
              const extra = new ProfileExtra({
                location:                this._state.get({ id: 'location' }),
                visaStatus:              new VisaStatus(this._state.get({ id: 'visaStatus' })),
                visaConditionEmployment: this._state.get({ id: 'visaConditionEmployment' }),
                visaConditionStudy:      this._state.get({ id: 'visaConditionStudy' }),
                opportunitiesRelocation: this._state.get({ id: 'opportunitiesRelocation' }),
                opportunitiesRemote:     this._state.get({ id: 'opportunitiesRemote' }),
              });

              this.props.updateViaApi({ extra });
            } }
            isSubmitDisabled = { !this._state.isChanged() || !isDataValid }
          />
        </div>
      </div>
    );
  }
};

export default StudentLocationPanelForm;
