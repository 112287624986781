import './index.scss';
import React from 'react';
import SvgIconCircleAdd from 'components/v2/svg-icons/svg-icon-circle-add';

const AddActivityPanel = ({ title, onAddActivity }) => {
  return (
    <div className = "v2-add-activity-panel">
      <div
        className = "v2-add-activity-panel__button"
        onClick   = { () => { onAddActivity(); } }
      >
        <div className = "v2-add-activity-panel__label">
          <SvgIconCircleAdd />
          <span className = "v2-add-activity-panel__label-copy">
            { title }
          </span>
        </div>
      </div>
    </div>
  );
};

export default AddActivityPanel;
