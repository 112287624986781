import './page-section-info-items.scss';

import React from 'react';
import SvgIcon from 'components/svg-icon';

export default ({
  items
}) => (
  <div className = "page-section__info-items">
    { items.map(({ title, icon, value }) => (
      <div className = "page-section__info-item">
        <div className = "page-section__info-item__icon">
          <SvgIcon id = { icon } />
        </div>
        <div className = "page-section__info-item__content">
          <div className = "page-section__info-item__title">
            { title }
          </div>
          <div className = "page-section__info-item__value">
            { value }
          </div>
        </div>
      </div>
    ))}
  </div>
);
