import './index.scss';
import React from 'react';
import { browserHistory } from 'react-router';

const CreateCampaignSquareButton = () => {
  return (
    <button
      type      = "button"
      className = "create-campaign-button"
      onClick   = { () => browserHistory.push('/jobs/new') }
    >
      Create a Campaign
    </button>
  );
};

export default CreateCampaignSquareButton;
