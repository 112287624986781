import React                 from 'react';
import { connect }           from 'react-redux';
import { jobSelector }       from 'selectors';
import PageContent           from 'components/page-content';
import PageHeader            from 'components/page-header';
import PageSection           from 'components/page-section';
import ApplicationChallenges from 'components/application-challenges';
import JobDetails            from 'components/job-details';
import JobMap                from 'components/job-map';
import Avatar                from 'components/avatar';
import JobStatus             from 'containers/job-status';
import ShareModalContainer   from 'containers/share-modal-container';
import InelibleToApplyModal  from 'components/ineligible-to-apply-modal';
import { toggleModal }       from 'modules/ui';
import {
  SHARE_MODAL_ID,
  CAMPAIGN_INELIBABLE_MODAL_ID
} from 'modules/ui/constants';

const JobViewIndex = RoleView => ({
  job,
  openedModals,
  toggleShareModal,
  toggleCampaignIneligibleModal
}) => {
  const {
    id,
    challenges,
    employer: { photo_url }
  } = job;

  return (
    <PageContent isFull>
      <PageHeader>
        <div className = "page__header__badge">
          <div className = "page__header__badge__container">
            <JobStatus
              id = { id }
              icon = { <Avatar url = { photo_url } /> }
              isLarge
              hasDuration />
          </div>
        </div>
      </PageHeader>
      <PageSection >
        <JobDetails { ...job } hasDescription />
      </PageSection>
      <PageSection isFull>
        <JobMap { ...job } />
      </PageSection>
      <PageSection>
        <ApplicationChallenges challenges = { challenges } />
      </PageSection>
      <RoleView job = { job } />
      <ShareModalContainer
        job     = { job }
        isOpen  = { openedModals.indexOf(SHARE_MODAL_ID) > -1 }
        onClose = { toggleShareModal } />
      {
        openedModals.indexOf(CAMPAIGN_INELIBABLE_MODAL_ID) > -1 &&
        <InelibleToApplyModal
          isOpen
          onClose = { toggleCampaignIneligibleModal }
          ctaLink = '/jobs'
        />
      }

    </PageContent>
  );
};

export default RoleView => connect(
  (state,  { params: { id } }) => ({  job: jobSelector(state, id), openedModals: state.ui.openedModals}),
  (dispatch) => ({
    toggleShareModal:              () => dispatch(toggleModal(SHARE_MODAL_ID)),
    toggleCampaignIneligibleModal: () => dispatch(toggleModal(CAMPAIGN_INELIBABLE_MODAL_ID)),
  })
)(JobViewIndex(RoleView));
