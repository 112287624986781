import './application-profile.scss';

import React     from 'react';
import PropTypes from 'prop-types';
import Button    from 'components/button';
import Avatar    from 'components/avatar';
import Moment    from 'lib/helpers/moment';

const ApplicationProfile = ({
  id,
  role,
  full_name,
  updated_at,
  photo_url
}) => {
  if (!updated_at) return null;

  return (
    <div className = "application-profile">
      <div className = "application-profile__wrapper">
        <div className = "application-profile__avatar">
          <Avatar
            id   = { id }
            role = { role }
            url  = { photo_url }
            hasBorder />
        </div>
        <div className = "application-profile__info">
          <span className = "application-profile__name">{ full_name }</span>
          <span className = "application-profile__last-update">Last Updated on { Moment.format_L(new Date(updated_at)) }</span>
        </div>
      </div>
      <Button
        className = "is--secondary"
        link      = "/profile?edit"
        hasShadow
        isWide
        hasCaret >
        <span className = "application-profile__label">Edit Profile</span>
      </Button>
    </div>
  );
};
export default ApplicationProfile;

ApplicationProfile.propTypes = {
  id: PropTypes.number,
  role: PropTypes.string,
  full_name: PropTypes.string,
  updated_at: PropTypes.string
};
