import { combineReducers }   from 'redux';
import without               from 'lodash/without';
import omit                  from 'lodash/omit';
import { CALL_API }          from 'middleware/call-api';
import createReducer         from 'utils/create-reducer';
import { applicationSchema } from 'schemas';

const CREATE_REQUEST       = 'applications/CREATE_REQUEST';
const CREATE_SUCCESS       = 'applications/CREATE_SUCCESS';
const CREATE_FAILURE       = 'applications/CREATE_FAILURE';

const REJECT_REQUEST       = 'applications/REJECT_REQUEST';
const REJECT_SUCCESS       = 'applications/REJECT_SUCCESS';
const REJECT_FAILURE       = 'applications/REJECT_FAILURE';

const SHORTLIST_REQUEST    = 'applications/SHORTLIST_REQUEST';
const SHORTLIST_SUCCESS    = 'applications/SHORTLIST_SUCCESS';
const SHORTLIST_FAILURE    = 'applications/SHORTLIST_FAILURE';

const SUBMIT_REQUEST       = 'applications/SUBMIT_REQUEST';
const SUBMIT_SUCCESS       = 'applications/SUBMIT_SUCCESS';
const SUBMIT_FAILURE       = 'applications/SUBMIT_FAILURE';

const CANCEL_REQUEST       = 'applications/CANCEL_REQUEST';
const CANCEL_SUCCESS       = 'applications/CANCEL_SUCCESS';
const CANCEL_FAILURE       = 'applications/CANCEL_FAILURE';

const FETCH_REQUEST        = 'applications/FETCH_REQUEST';
const FETCH_SUCCESS        = 'applications/FETCH_SUCCESS';
const FETCH_FAILURE        = 'applications/FETCH_FAILURE';

const UPDATE_REQUEST       = 'applications/UPDATE_REQUEST';
const UPDATE_SUCCESS       = 'applications/UPDATE_SUCCESS';
const UPDATE_FAILURE       = 'applications/UPDATE_FAILURE';

const EDIT_CARD            = 'applications/EDIT_CARD';
const SAVE_CARD            = 'applications/SAVE_CARD';

const TOGGLE_MODAL_CONFIRM = 'applications/TOGGLE_MODAL_CONFIRM';

export function toggleModalConfirm() {
  return {
    type: TOGGLE_MODAL_CONFIRM
  };
}

export function editCard({ id }) {
  return {
    type: EDIT_CARD,
    payload: { id }
  };
}

export function saveCard({ id }) {
  return {
    type: SAVE_CARD,
    payload: { id }
  };
}

export function shortlist(ids) {
  return {
    [CALL_API]: {
      types: [
        SHORTLIST_REQUEST,
        SHORTLIST_SUCCESS,
        SHORTLIST_FAILURE
      ],
      method: 'POST',
      data: { ids },
      endpoint: '/applications/shortlist',
      schema: applicationSchema,
      meta: {
        [UPDATE_SUCCESS]: {
          notification: { msg: 'Shortlisted' }
        }
      }
    }
  };
}


export function reject(ids) {
  return {
    [CALL_API]: {
      types: [
        REJECT_REQUEST,
        REJECT_SUCCESS,
        REJECT_FAILURE
      ],
      method: 'POST',
      data: { ids },
      endpoint: '/applications/unsuccessful',
      schema: applicationSchema,
      // meta: {
      //   [UPDATE_SUCCESS]: {
      //     notification: { msg: 'Not ' }
      //   }
      // }
    }
  };
}

export function createApplication(job_id) {
  return {
    [CALL_API]: {
      types: [
        CREATE_REQUEST,
        CREATE_SUCCESS,
        CREATE_FAILURE
      ],
      method: 'POST',
      data: { job_id },
      endpoint: '/applications',
      schema: applicationSchema,
      meta: {
        [CREATE_SUCCESS]: {
          // response[0] is an ID of created entity due to incomplete
          // normalizr integration
          redirect: ({ payload: { result }}) => `/applications/${result[0]}/draft`
        }
      }
    }
  };
}

export function cancelApplication(id) {
  return {
    [CALL_API]: {
      types: [
        CANCEL_REQUEST,
        CANCEL_SUCCESS,
        CANCEL_FAILURE
      ],
      method: 'DELETE',
      endpoint: `/applications/${id}`,
      data: { id },
      schema: applicationSchema,
      meta: {
        [CANCEL_REQUEST]: {
          redirect: '/applications'
        }
      }
    }
  };
}

export function updateApplication({ id, values }) {
  return {
    [CALL_API]: {
      types: [
        UPDATE_REQUEST,
        UPDATE_SUCCESS,
        UPDATE_FAILURE
      ],
      method: 'PUT',
      data: values,
      endpoint: `/applications/${id}`,
      schema: applicationSchema,
      meta: {
        [UPDATE_SUCCESS]: {
          notification: { msg: 'Application Updated' }
        }
      }
    }
  };
}

export function fetchApplications() {
  return {
    [CALL_API]: {
      types: [
        FETCH_REQUEST,
        FETCH_SUCCESS,
        FETCH_FAILURE
      ],
      endpoint: '/applications',
      schema: applicationSchema
    }
  };
}

export function postApplication(id) {
  return {
    [CALL_API]: {
      types: [
        SUBMIT_REQUEST,
        SUBMIT_SUCCESS,
        SUBMIT_FAILURE
      ],
      method: 'POST',
      data: { id },
      endpoint: `/applications/${id}/submit`,
      schema: applicationSchema,
      meta: {
        [SUBMIT_FAILURE]: {
          notification: {
            msg: 'Oops',
            type: 'failure'
          }
        },
        [SUBMIT_SUCCESS]: {
          redirect: ({ payload: { result }}) => `/applications/${result[0]}`
        }
      }
    }
  };
}

export function submitApplication(id) {
  return ({ dispatch }) => {
    dispatch(postApplication(id))
      .then(() => {
        dispatch(toggleModalConfirm());
        location.reload();
      })
      .catch(() => dispatch(toggleModalConfirm()));
  };
}

const initialState = {
  editingCards: [],
  isConfirmVisible: false
};

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
  case TOGGLE_MODAL_CONFIRM: {
    return {
      ...state,
      isConfirmVisible: !state.isConfirmVisible
    };
  }
  case EDIT_CARD: {
    const { id } = action.payload;

    return {
      ...state,
      editingCards: [...state.editingCards, ...[id]]
    };
  }
  case SAVE_CARD: {
    const { id } = action.payload;

    return {
      ...state,
      editingCards: without(state.editingCards, id)
    };
  }
  default: return state;
  }
};

const entitiesReducer = (state = {}, action) => {
  switch (action.type) {
    case CANCEL_REQUEST: {
      return omit(state, action.payload.id);
    }
  default: return state;
  }
};

export default combineReducers({
  application: applicationReducer,
  entities: createReducer('applications', entitiesReducer)
});
