import axios from 'axios';
import config from 'config';

const getCDNUrl = ({ data }) => {
  const url = decodeURIComponent(/<Key>([^<]+)<\/Key>/.exec(data)[1])
    .split('/')
    .splice(1)
    .join('/');

  return `${config.FILE_READ_URL}/${url}`;
};

export default ({ token, image, id }) => {
  const formData = new FormData();
  const key = `${token.key_prefix}${(1 + Math.random()).toString(36).substr(2)}_avatar_${id}`;

  formData.append('key', key);
  formData.append('AWSAccessKeyId', token.access_key);
  formData.append('policy', token.policy);
  formData.append('signature', token.signature);
  formData.append('Content-Type', '$Content-Type');
  formData.append('acl', 'public-read');
  formData.append('success_action_status', '201');
  formData.append('file', image);

  return axios
    .post(config.FILE_UPLOAD_ENDPOINT, formData)
    .then(getCDNUrl);
};
