import './profile-photo.scss';

import React     from 'react';
import cx        from 'classnames';
import Loading   from 'components/loading';
import SvgIcon   from 'components/svg-icon';
import PropTypes from 'prop-types';

export default class ProfilePhoto extends React.Component {
  renderContent() {
    let { loading, profile, photo, text } = this.props,
        divWithBackground = (src) =>
          <div className="profilePhoto-img"
               key={1}
               style={ { backgroundImage: `url(${src})` } } />;

    if (loading) {
      return <Loading />;
    }

    if (profile) {
      if (profile.photo_url) {
        return divWithBackground(profile.photo_url);
      }

      if (profile.role === 'employer') {
        return divWithBackground(require('images/placeholder--avatar-employer.svg'));
      }
      if (profile.role === 'student') {
        let modProfileId = (profile.id % 4) + 1;
        return divWithBackground(require(`images/placeholder--avatar-student-${modProfileId}.svg`));
      }
    }

    if (photo) {
      return divWithBackground(photo);
    }

    if (text) {
      return text;
    }
  }

  getClassnames() {
    const {
      loading,
      profile,
      photo,
      text
    } = this.props;

    if (loading || profile) {
      // no need for modifier
      return;
    }

    if (photo) {
      return 'is-photo';
    }

    if (text) {
      return 'is-text';
    }
  }

  render() {
    const {
      isEditable,
      size,
      profile,
      photo,
      text,
      className
    } = this.props;

    const  classSet = cx('profilePhoto', className, {
      radial: size === 'radial',
      small: size === 'small',
      medium: size === 'medium',
      large: size === 'large'
    }, this.getClassnames());

    return (
      <div className = { classSet }>
        { this.renderContent() }
        { isEditable &&
          <div className="editable-overlay" key={2}>
            <SvgIcon id = "camera" />
          </div>
        }
      </div>
    );
  }
}

ProfilePhoto.propTypes = {
  className: PropTypes.any,
  size: PropTypes.oneOf([ 'radial', 'small', 'medium', 'large' ]),
  isEditable: PropTypes.bool,

  // In order of preference:
  loading: PropTypes.bool,
  profile: PropTypes.object,
  photo: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};

ProfilePhoto.defaultProps = {
  size: 'small',
  isEditable: false
}
