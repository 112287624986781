import './index.scss';
import React from 'react';
import classNames from 'classnames';

export const IS_PENDING = 'isPending';
export const IS_SUCCESS = 'isSuccess';
export const IS_ERROR = 'isError';

class InputTextSingleLineForProfileSlug extends React.Component {
  constructor(props) {
    super(props);

    this._setPrefixWidthToStyle = () => {
      this.singlelineInput.style.setProperty('--prefix-width', window.getComputedStyle(this.prefixDiv).width);
    };
  }

  componentDidMount() {
    if (this.props.isAutoFocused) {
      window.setTimeout(() => {
        this.singlelineInput.focus();
      }, 0);
    }

    this._setPrefixWidthToStyle();
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) { return; }

    this._setPrefixWidthToStyle();

    if (prevProps.isReadonly === true && this.props.isReadonly === false) {
      this.singlelineInput.focus();
    }
  }

  render() {
    const {
      name,
      label,
      prefix,
      placeholder,
      inputFontSize,
      value,
      maxLength,
      message,
      onChange,
      isReadonly,
      status,
    } = this.props;

    const getPrefixClassName = classNames(
      'v2-input-text-single-line-for-profile-slug__prefix',
      { 'v2-input-text-single-line-for-profile-slug__prefix--small-font-size': inputFontSize === 'small' },
      { 'v2-input-text-single-line-for-profile-slug__prefix--default-font-size': inputFontSize !== 'small' },
    );

    const inputClassName = classNames(
      'v2-input-text-single-line-for-profile-slug__input',
      { 'v2-input-text-single-line-for-profile-slug__input--error': status === IS_ERROR },
      { 'v2-input-text-single-line-for-profile-slug__input--small-font-size': inputFontSize === 'small' },
      { 'v2-input-text-single-line-for-profile-slug__input--default-font-size': inputFontSize !== 'small' },
    );

    const iconClassName = classNames(
      'v2-input-text-single-line-for-profile-slug__icon',
      { 'v2-input-text-single-line-for-profile-slug__icon--pending': status === IS_PENDING },
      { 'v2-input-text-single-line-for-profile-slug__icon--success': status === IS_SUCCESS },
      { 'v2-input-text-single-line-for-profile-slug__icon--error': status === IS_ERROR },
    );

    const messageClassName = classNames(
      'v2-input-text-single-line-for-profile-slug__message',
      { 'v2-input-text-single-line-for-profile-slug__message--success': status === IS_SUCCESS },
      { 'v2-input-text-single-line-for-profile-slug__message--error': status === IS_ERROR },
    );

    return (
      <div className = "v2-input-text-single-line-for-profile-slug">
        <label className = "v2-input-text-single-line-for-profile-slug__label" htmlFor = { name }>
          { label }
        </label>
        <div className = "is-mobile v2-input-text-single-line-for-profile-slug__input-wrapper">
          <div className = { getPrefixClassName } ref = { (element) => { this.prefixDiv = element; } }>
            { prefix }
          </div>
          <input
            ref         = { (realInputElementOnPage) => { this.singlelineInput = realInputElementOnPage; } }
            type        = "text"
            className   = { inputClassName }
            maxLength   = { maxLength }
            name        = { name }
            placeholder = { placeholder }
            value       = { value }
            onChange    = { (event) => { onChange(event.target.value); } }
            disabled    = { isReadonly }
          />
          <div className = "v2-input-text-single-line-for-profile-slug__icon-wrapper">
            <div className = { iconClassName } />
          </div>
        </div>
        <div className = { messageClassName }>
          { message }
        </div>
      </div>
    );
  }
}

export default InputTextSingleLineForProfileSlug;
