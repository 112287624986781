import './index.scss';
import React              from 'react';
import SvgIconPaperPlane  from 'components/v2/svg-icons/svg-icon-paper-plane';

const ApplyJobButton = ({
  onClick
}) => {
  return(
    <button
      type      = "button"
      className =  "button--apply-job"
      onClick   = { onClick } >
      <SvgIconPaperPlane />
    </button>
  )};

  export default ApplyJobButton;
