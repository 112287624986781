import without from 'lodash/without';

export const SAVE_CARD = 'accounts/SAVE_CARD';
export const EDIT_CARD = 'accounts/EDIT_CARD';

const initialState = {
  editingCards: []
};

export default (state = initialState, action) => {
  switch (action.type) {
  case EDIT_CARD: {
    const { id } = action.payload;

    return {
      ...state,
      editingCards: [...state.editingCards, ...[id]]
    };
  }
  case SAVE_CARD: {
    const { id } = action.payload;

    return {
      ...state,
      editingCards: without(state.editingCards, id)
    };
  }
  default: return state;
  }
};

export function editCard({ id }) {
  return {
    type: EDIT_CARD,
    payload: { id }
  };
}

export function saveCard({ id }) {
  return {
    type: SAVE_CARD,
    payload: { id }
  };
}

