import './page-section.scss';

import React      from 'react';
import classNames from 'classnames';

const PageSection = ({
  children,
  skip,
  title,
  subTitle,
  isCta,
  isDark,
  isNarrow,
  isPrimary,
  isStacked,
  isFull,
  isDisabled,
  isCentered,
  hasShadow,
  backgroundUrl
}) => {
  if (skip) return null;

  return (
    <div
      className = { classNames('page__section', {
        'is--cta'        : isCta,
        'is--dark'       : isDark,
        'is--narrow'     : isNarrow,
        'is--primary'    : isPrimary,
        'is--stacked'    : isStacked,
        'is--centered'   : isCentered,
        'is--container'  : !isFull,
        'is--disabled'   : isDisabled,
        'has--shadow'    : hasShadow
      }) }
      style = { backgroundUrl ? { backgroundImage: `url( ${backgroundUrl} )` } : {} }>
      { title &&
        <div className = "page__section__header">
          <div className = "page__section__title">
            { title }
          </div>
          { subTitle &&
            <div className = "page__section__subtitle">
              { subTitle }
            </div>
          }
        </div>
      }
      { children &&
        <div>
          { children }
        </div>
      }
    </div>
  );
};

export default PageSection;
