import './form-questions.scss';

import React                 from 'react';
import FormQuestion          from 'components/form-question';
import { SortableContainer } from 'react-sortable-hoc';

const MAX_LENGTH = 10;

export default SortableContainer(({ fields, name, onDelete, onAdd }) =>
  <div className = "form__questions">
    { fields.map((field, index) =>
        <FormQuestion
          key        = { `item-${index}` }
          count      = { index }
          index      = { index }
          field      = { field }
          size       = { fields.length }
          collection = { `${name}-collection` }
          onDelete   = { onDelete }  />
    )}
    { fields.length < MAX_LENGTH &&
      <div className = "form__questions__add">
        <span onClick = { onAdd }>+ New Question</span>
      </div>
    }
  </div>
);
