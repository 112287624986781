import './profile-info-avatar.scss';

import React  from 'react';
import Avatar from 'components/avatar';

export default class ProfileInfoAvatar extends React.Component {
  constructor() {
    super();

    this.state = { hasChanged: false }
  }
  UNSAFE_componentWillUpdate(nextProps) {
    const {
      isAvatarUploading, isEditing
    } = this.props

    if (isAvatarUploading && nextProps.isAvatarUploading === false) {
      this.setState({ hasChanged: true })
    }

    if (this.state.hasChanged && !isEditing) {
      this.setState({ hasChanged: false })
    }
  }
  render() {
    const {
      id,
      role,
      photo_url,
      isEditing,
      toggleAvatarUploader
    } = this.props;

    const { hasChanged } = this.state;

    return (
      <div
        className = "profile__info__avatar"
        onClick   = { isEditing && toggleAvatarUploader } >
        <Avatar { ...{ id, role, isEditing, hasChanged, url: photo_url } } hasBorder />
      </div>
    )
  }
}
