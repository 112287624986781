/* eslint-disable camelcase */
import inRange from 'lodash/inRange';

export default ({ status, next_status, status_percentage_complete }) => {
  const inTransition = inRange(status_percentage_complete, 77, 100);

  if (!inTransition) return status;

  return `${status}-to-${next_status}`;
};
