import { CALL_API }         from 'middleware/call-api';
import uploadToS3           from 'utils/upload-to-s3';
import createReducer        from 'utils/create-reducer';
import { profileSchema }    from 'schemas';

const FETCH_REQUEST    = 'profiles/FETCH_REQUEST';
const FETCH_SUCCESS    = 'profiles/FETCH_SUCCESS';
const FETCH_FAILURE    = 'profiles/FETCH_FAILURE';

const UPLOAD_REQUEST   = 'avatar/UPLOAD_REQUEST';
const UPLOAD_SUCCESS   = 'avatar/UPLOAD_SUCCESS';
const UPLOAD_FAILURE   = 'avatar/UPLOAD_FAILURE';

const UPDATE_REQUEST   = 'profiles/UPDATE_REQUEST';
const UPDATE_SUCCESS   = 'profiles/UPDATE_SUCCESS';
const UPDATE_FAILURE   = 'profiles/UPDATE_FAILURE';

const S3_TOKEN_REQUEST = 'auth/S3_TOKEN_REQUEST';
const S3_TOKEN_SUCCESS = 'auth/S3_TOKEN_SUCCESS';
const S3_TOKEN_FAILURE = 'auth/S3_TOKEN_FAILURE';

export function uploadImageToS3({ image, id, withoutNotifications }) {
  return ({ dispatch }) => {
    dispatch({
      type: UPLOAD_REQUEST,
      meta: { isFetching: true }
    });

    const uploadImages = ({ payload }) => Promise.all(
      ['resized', 'original'].map(v => uploadToS3({ token: payload, image: image[v], id: v }))
    );
    const formatData = ([photo_url, original_photo_url]) => ({ id, photo_url, original_photo_url });

    return dispatch(getS3UploadToken())
      .then(uploadImages)
      .then(formatData)
      .then(data => dispatch(updateProfile({ ...data, withoutNotifications })))
      .then(() => {
        dispatch({
          type: UPLOAD_SUCCESS,
          meta: { isFetching: false }
        });
      });
  }
};

export function getS3UploadToken() {
  return {
    [CALL_API]: {
      types: [
        S3_TOKEN_REQUEST,
        S3_TOKEN_SUCCESS,
        S3_TOKEN_FAILURE
      ],
      method: 'POST',
      endpoint: '/auth/upload_token',
    }
  }
};

export function fetchProfile(id) {
  return {
    [CALL_API]: {
      types: [
        FETCH_REQUEST,
        FETCH_SUCCESS,
        FETCH_FAILURE
      ],
      endpoint: `/profiles/${id}`,
      schema: profileSchema
    }
  }
};

export function updateProfile({ id, redirect, withoutNotifications, ...values }) {
  const updateSuccessObject = {
    redirect: redirect,
  };

  // We don't use notifications in `v2`.
  if (!withoutNotifications) {
    Object.assign(updateSuccessObject, { notification: { msg: 'Profile Updated' } });
  }

  return {
    [CALL_API]: {
      types: [
        UPDATE_REQUEST,
        UPDATE_SUCCESS,
        UPDATE_FAILURE
      ],
      method: 'PATCH',
      endpoint: `/profiles/${id}`,
      data: values,
      schema: profileSchema,
      meta: {
        [UPDATE_SUCCESS]: updateSuccessObject,
      }
    }
  }
};

export default createReducer('profiles');
