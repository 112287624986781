import './form-question.scss';

import React     from 'react';
import FormField from 'components/form-field';
import SvgIcon   from 'components/svg-icon';
import { TYPES } from 'modules/forms/constants';
import {
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => <SvgIcon id = "drag" className = "form__question__action" />);

export default SortableElement(({ field, onDelete, size, index, count }) => (
  <div
    className = "form__question"
    key       = { index } >
    <div className = "form__question__fields">
      <FormField
        name      = {`${field}.question`}
        type      = { TYPES.TEXTAREA }
        className = "is--medium"
        label     = {`Q${ count + 1 }`}
        attrs     = {{ autoFocus: true }}/>
      <FormField
        name      = {`${field}.answer`}
        type      = { TYPES.RADIO }
        className = "is--medium"
        label     = "Correct Answer"
        options   = { [{ title: 'Yes', value: 'Yes' }, { title: 'No', value: 'No' } ]} />
    </div>
      { size > 1 &&
        <div className = "form__question__actions">
          <span onClick   = { () => onDelete({ index }) }>
            <SvgIcon
              id        = "close"
              className = "form__question__action" />
          </span>
        <DragHandle />
        </div>
      }

  </div>
));
