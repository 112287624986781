import './page-panel-extension.scss';

import React from 'react';

const PagePanelExtension = ({
  title,
  children
}) => (
  <div className = "page-panel__extension">
    { title &&
      <div className = "page-panel__extension__header">
        <span>
          { title }
        </span>
      </div>
    }
    { children &&
      <div className = "page-panel__extension__content">
        {children}
      </div>
    }
  </div>
);
export default PagePanelExtension;
