import './index.scss';
import React from 'react';

const SettingsPageTitle = ({ heading, subheading }) => {
  return (
    <div className = "v2-settings-page-title">
      <h1 className = "v2-settings-page-title__heading">{ heading }</h1>
      <div className = "v2-settings-page-title__subheading">{ subheading }</div>
    </div>
  );
};

export default SettingsPageTitle;
