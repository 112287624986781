import React                 from 'react';
import { connect }           from 'react-redux';
import { jobSelector }       from 'selectors';
import ListPanel             from 'components/list-panel';
import JobApplicationsStatus from 'containers/job-applications-status';

const ApplicationJobListPanel = props => {
  const {
    id,
    title,
    application_ids,
    status
  } = props;

  if (!id) return null;

  const total = application_ids.length;
  const archivedFilters = '?filter=sent&filter=unsuccessful';

  return (
    <ListPanel
      thumb  = { <JobApplicationsStatus id = { id } /> }
      title  = { title }
      data   = { `${total} Application${total > 1 ? 's' : ''}` }
      link   = { `/applications/jobs/${id}${status === 'archived' ? archivedFilters : ''}` } />
  );
};

export default connect((state, { id }) => ({
  ...jobSelector(state, id)
}))(ApplicationJobListPanel);
