import React                from 'react';
import { connect }          from 'react-redux';
import { TYPES }            from 'modules/forms/constants';
import { registerEmployer } from 'modules/register';
import { FORM_ID }          from 'modules/register/employer/constants';
import Box                  from 'components/box';
import Card                 from 'components/card';
import Button               from 'components/button';
import getForm              from 'components/form';
import FormRow              from 'components/form-row';
import FormField            from 'components/form-field';
import FormSection          from 'components/form-section';

const Form = getForm({ id: FORM_ID });

class EmployerRegistration extends React.Component {
  render() {
    const {
      email,
      hasAgreed,
      isSubmitting,
      registerEmployer,
      params: { state }
    } = this.props;

  return (
    <Box isCentered>
      <Form onSubmit  = { registerEmployer }>
        <Card
          title = "Register as an Employer"
          subtitle = "You will be the administrator of this Employer profile">
          <FormSection icon = "user">
            <FormRow>
              <FormField
                label       = "First Name"
                placeholder = "John"
                type        = { TYPES.TEXT }
                name        = "first_name" />
              <FormField
                label       = "Last Name"
                placeholder = "Smith"
                type        = { TYPES.TEXT }
                name        = "last_name" />
            </FormRow>
            <FormRow>
              <FormField
                label       = "Email"
                placeholder = "john.smith@mybusiness.com"
                type        = { TYPES.TEXT }
                name        = "email" />
            </FormRow>
          </FormSection>
          <FormSection icon = "briefcase" className = "is--secondary">
            <FormRow>
              <FormField
                label = "Business Name"
                type  = { TYPES.TEXT }
                name  = "business_name" />
            </FormRow>
            <FormRow>
              <FormField
                label = "Area Code"
                type  = { TYPES.TEXT }
                name  = "phone_prefix" />
              <FormField
                label = "Phone Number"
                type  = { TYPES.TEXT }
                name  = "phone" />
            </FormRow>
          </FormSection>
          <FormRow className = "is--centered">
            <FormField
              type        = { TYPES.CHECKBOX }
              name        = "agreedterms" >
              <span>I agree to the&nbsp;</span>
              <a
                className = "Link"
                href      = "/terms"
              >
                Terms & Conditions
              </a>
            </FormField>
          </FormRow>
          <FormRow className = "is--centered">
            <Button
              disabled  = { !hasAgreed || isSubmitting }
              loading   = { isSubmitting }
              className = "is--action is--fixed">
              Register For Free
            </Button>
          </FormRow>
        </Card>
      </Form>
    </Box>
    );
  }
}

export default connect(({ form }) => ({
  hasAgreed:    form[FORM_ID] && form[FORM_ID].values && form[FORM_ID].values.agreedterms,
  isSubmitting: form && form[FORM_ID] && form[FORM_ID].submitting
}), { registerEmployer })(EmployerRegistration);
