import './index.scss';
import React    from 'react';
import Markdown from 'components/markdown';

const Biography = ({ label, summary }) => {
  return (
    <div className = "v2-biography">
      <div className = "v2-biography__wrapper">
        <div className = "v2-biography__label">
          { label }
        </div>
        <div className = "v2-biography__summary">
          <Markdown>
            { summary }
          </Markdown>
        </div>
      </div>
    </div>
  );
}

export default Biography;
