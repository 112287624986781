import './index.scss';
import React from 'react';

import StateWithSavePoints      from 'lib/helpers/state-with-save-points';
import ProfileExtra             from 'lib/api/profiles/profile-extra';
import StudentLocationPanelForm from 'components/v2/profile/student-location-panel/form';
import StudentLocationPanelView from 'components/v2/profile/student-location-panel/view';
import { StudentPanelAdd }      from 'components/v2/profile/student-panel/add';

class StudentLocationPanel extends React.Component {
  constructor(props) {
    const { appv2 } = props;

    super(props);

    this._state = new StateWithSavePoints({ component: this });

    this._refreshData = (profileId) => {
      appv2.api.profiles
        .fetchWithExtraByIdOrSlug({ idOrSlug: profileId, isLoggedIn: true })
        .then(({ profileExtra }) => {
          this._state.reset({
            profileExtra: profileExtra,
          });
        });
    };
  }

  UNSAFE_componentWillMount() {
    this._refreshData(this.props.profileId);

    this._state.uiSet({ mode: 'VIEW' });
    this._state.reset({
      profileExtra: ProfileExtra.createEmpty(),
    });
  }

  render() {
    const { appv2, profileId, canEdit } = this.props;

    const renderEdit = () => {
      const profileExtra = this._state.get({ id: 'profileExtra' });

      if (!canEdit) {
        throw Error('No permissions to edit');
      }

      return <StudentLocationPanelForm
        profileExtra = { profileExtra }
        updateViaApi = { ({ extra }) => {
          appv2.api.profiles
            .updateExtra({ id: profileId, extra })
            .then(() => {
              this._refreshData(profileId); // this returns an old location.shortText
              this._state.uiSet({ mode: 'VIEW' });

              appv2.ui.displaySnackBar({
                style:    'green',
                text:     'Profile saved!',
                callback: () => { appv2.ui.refreshPage(); },
              });
            });
        } }
        onCancel = { () => { this._state.uiSet({ mode: 'VIEW' }) } }
      />;
    };

    const renderView = () => {
      if (this._state.get({ id: 'profileExtra' }).isEmpty()) {
        return renderEmptyView();
      }

      return renderSavedView();
    };

    const renderEmptyView = () => {
      if (!canEdit) {
        return null;
      }

      return (
        <StudentPanelAdd
          onClick = { () => { this._state.uiSet({ mode: 'EDIT' }); } }
          imgSrc = { require('images/v2/icon-location.svg') }
          heading = "Current location"
          description = "Add your location and local visa conditions."
        />
      );
    };

    const renderSavedView = () => {
      return <StudentLocationPanelView
        profileExtra = { this._state.get({ id: 'profileExtra' }) }
        onStartEdit = { () => { this._state.uiSet({ mode: 'EDIT' }) } }
        canEdit = { canEdit }
      />;
    };

    switch (this._state.uiGet({ id: 'mode' })) {
      case 'EDIT': return renderEdit();
      case 'VIEW': return renderView();
      default: throw Error('Should never happen');
    }
  }
};

export default StudentLocationPanel;
