import React from 'react';

export const SvgIconPlus = props => (
  <svg
    width = { 14 }
    height = { 14 }
    fill = "currentColor"
    { ...props }
  >
    <path d = "M14 7.8H7.8V14H6.2V7.8H0V6.2h6.2V0h1.6v6.2H14v1.6z" />
  </svg>
);
