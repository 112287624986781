import './index.scss';
import React from 'react';

import PanelFormHeader from 'components/v2/panel/form-header';
import V2Switch from 'components/v2/switch';

export const ProfileVisibilitySection = ({ visibilitySettings, onChange, publicProfileUrl }) => {
  const { master, aboutMe, currentLocation } = visibilitySettings;

  const toggleMaster = (value) => {
    const updatedFields = value
      ? { master: true }
      : { master: false, aboutMe: false, currentLocation: false };

    onChange({ ...visibilitySettings, ...updatedFields });
  };

  const toggleAboutMe = (value) => {
    const updatedFields = value
      ? { aboutMe: true, master: true }
      : { aboutMe: false };

    onChange({ ...visibilitySettings, ...updatedFields });
  };

  const toggleCurrentLocation = (value) => {
    const updatedFields = value
      ? { currentLocation: true, master: true }
      : { currentLocation: false };

    onChange({ ...visibilitySettings, ...updatedFields });
  };

  return (
    <div className = "v2-profile-visibility-section">
      <PanelFormHeader
        iconSrc     = { require('images/v2/icon-circle-eye.svg') }
        heading     = "Profile Visibility"
        description = "Select what you share publicly and with search engines."
      />

      <div className = "v2-profile-visibility-section__main">
        <div className = "v2-profile-visibility-section__main-text">
          Show Profile Publicly
        </div>
        <V2Switch
          isChecked = { master }
          onChange  = { (value) => { toggleMaster(value); } }
        />
      </div>

      <div className = "v2-profile-visibility-section__divider" />

      <div className = "v2-profile-visibility-section__subset">
        <div className = "v2-profile-visibility-section__subset-title">
          Show Profile Sections
        </div>
        <ul className = "v2-profile-visibility-section__list">
          <li className = "v2-profile-visibility-section__list-item">
            <div className = "v2-profile-visibility-section__list-item-text">
              About me
            </div>
            <V2Switch
              isChecked = { aboutMe }
              onChange  = { (value) => { toggleAboutMe(value); } }
            />
          </li>
          <li className = "v2-profile-visibility-section__list-item">
            <div className = "v2-profile-visibility-section__list-item-text">
              Current Location
            </div>
            <V2Switch
              isChecked = { currentLocation }
              onChange  = { (value) => { toggleCurrentLocation(value); } }
            />
          </li>
        </ul>
      </div>

      <a
        className = "v2-profile-visibility-section__btn"
        href      = { publicProfileUrl }
        target    = "_blank"
      >
        See your Public Profile
      </a>
    </div>
  );
};
