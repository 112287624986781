import './page-header.scss';
import React  from 'react';

const PageHeader = ({ children, backgroundUrl, role = 'employer' }) => {
  const backgroundImage = backgroundUrl || require(`images/bg--profile-${role}.svg`);

  return (
    <div className = "page__header">
      <span
        className = "page__header__background"
        style     = { { backgroundImage: `url( ${backgroundImage} )`} } />
      { children &&
        <div className = "page__header__content">
          { children }
        </div>
      }
    </div>
  );
};

export default PageHeader;
