import React from 'react';

export const SvgIconPower = props => (
  <svg
    width = { 64 }
    height = { 64 }
    viewBox = "0 0 64 64"
    fill = "currentColor"
    { ...props }
  >
    <path
      d = "M30.3 0h3.5v32h-3.5V0zm12.5 11.4L41 15c7.1 3.4 12 10.6 12 19 0 11.6-9.4 21.1-21.1 21.1-11.6 0-21.1-9.4-21.1-21.1 0-8.4 4.9-15.6 12-19L21 11.4C12.8 15.4 6.9 24 6.9 34c0 13.8 11.2 25.1 25.1 25.1S57.1 47.8 57.1 34c0-10-5.9-18.6-14.3-22.6z"
      fillRule = "evenodd"
      clipRule = "evenodd"
    />
  </svg>
);
