export default {
  path: '/forgot-password',
  indexRoute: {
    getComponent: (location, cb) => {
      cb(null, require('pages/auth/forgot-password').default);
    },
  },
  childRoutes: [{
    path: 'success',
    getComponent: (location, cb) => {
      cb(null, require('pages/auth/forgot-password-success').default);
    },
  }],
};
