export default ({ location, location: { pathname, query }, auth = {} }) => {
  if (!location) return '';

  // eslint-disable-next-line no-confusing-arrow, no-restricted-globals, radix
  const parsePath = (v) => isNaN(parseInt(v)) ? v : 'item';
  const pathArray = pathname.split('/').splice(1);
  const pathId = pathArray.map(parsePath).filter((v) => !!v).join('-');
  const role = auth.role ? `-${auth.role}` : '';
  const queryLocation = query.location ? `-${query.location}` : '';
  const stepIdIndex = pathArray.indexOf('step');
  const currentStep = stepIdIndex > -1 ? ` is--step-${pathArray[stepIdIndex + 1]} ` : '';

  return `${pathId}${role}${queryLocation}${currentStep}`;
};
