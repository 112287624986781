import React from 'react';
import Cookies from 'cookies-js';

import InfoEnvelope from 'components/v2/register/InfoEnvelope/InfoEnvelope';

import './index.scss';

const THANKS_URL = '/register/feedback/thanks';

class FeedbackPage extends React.Component {
  constructor(props) {
    const { appv2 } = props;

    super(props);

    this._email = Cookies.get('v2-registered-email');

    this._redirectToThanks = () => {
      return appv2.ui.redirect({ url: THANKS_URL });
    };

    this.state = {
      countOfSelected: 0,
    };
  }

  UNSAFE_componentWillMount() {
    if (typeof this._email !== 'string') {
      return this._redirectToThanks();
    }
  }

  render() {
    const { appv2 } = this.props;

    const OPTIONS = [
      'Google Search',
      'Facebook',
      'LinkedIn',
      'Instagram',
      'Twitter',
      'Blog Post',
      'On Campus',
      'Event',
      'Paddl Sales',
      'Friend / Coworker',
      'Other',
      "Don't Remember",
    ];

    const getSelected = (elements) => (
      Array.from(elements).filter(e => (
        e.type === 'checkbox' && e.checked
      ))
    );

    const onChange = () => {
      this.setState({
        countOfSelected: getSelected(this._form.elements).length,
      });
    };

    const onSubmit = (event) => {
      const selectedNames = getSelected(this._form.elements).map(e => OPTIONS[e.value]);

      event.preventDefault();

      appv2.api.profiles
        .registerFeedback({ email: this._email, heardFrom: selectedNames })
        .then(() => {
          return this._redirectToThanks();
        })
        .catch(() => {
          return this._redirectToThanks();
        });
    };

    const disabled = this.state.countOfSelected === 0;

    return (
      <div className="v2-register-feedback">
        <InfoEnvelope />

        <div className="v2-register-feedback__box">
          <div className="v2-register-feedback__box-title">
            While you’re here, how did you hear about us?
          </div>

          <form
            className="v2-register-feedback__form"
            ref={(elem) => { this._form = elem; }}
            onSubmit={onSubmit}
          >
            <div className="v2-register-feedback__box-tags-wrapper">
              {
                OPTIONS.map((name, idx) => (
                  <label key={idx} className="v2-register-feedback__box-tag-item">
                    <input
                      className="v2-register-feedback__box-tag-checkbox"
                      type="checkbox"
                      value={idx}
                      onChange={onChange}
                    />
                    <span data-text={name} />
                  </label>
                ))
              }
            </div>
            <button
              className="v2-register-feedback__box-tag-btn"
              type="submit"
              disabled={disabled}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default FeedbackPage;
