import { combineReducers }        from 'redux';
import { reducer as ffReducer }   from './feature-flags';
import { reducer as slugReducer } from './slugs';

const v2 = combineReducers({
  featureFlags: ffReducer,
  slugs:        slugReducer,
});

export default v2;
