import './page-section-link.scss';

import React    from 'react';
import { Link } from 'react-router';
import SvgIcon  from 'components/svg-icon';

const PageSectionLink = ({
  children,
  to,
  icon
}) => (
  <div className = "page__section__link">
    <Link to = { to } >
      { icon && <SvgIcon id = { icon } /> }
      { children }
    </Link>
  </div>
);

export default PageSectionLink;
