import './index.scss';
import React from 'react';

import BasecampContentNav           from 'components/v2/basecamp/basecamp-content-nav';
import BasecampCardList             from 'components/v2/basecamp/basecamp-card-list';
import BasecampCardEmptyView        from 'components/v2/basecamp/basecamp-card-empty-view';
import AddActivityPanel             from 'components/v2/profile/add-activity-panel';
import { DismissiblePanel }         from 'component-lib/components/dismissible-panel';
import ActivityCardSuggestionList   from 'component-lib/components/activity-card-suggestion-list';
import { style20pxRegularBluegrey } from 'component-lib/type/text/styles';

const VARIANT_IDS = [7, 5, 6, 1, 2, 10, 11, 9, 12];

const BasecampContentPanel = ({ appv2, basecampCards, basecampRefreshFunction }) => {
  const renderBasecampContentNav = () => {
    const totalBasecampCardsCount = basecampCards && basecampCards.length;

    const xpReadyBasecampCardsCount = basecampCards && basecampCards.filter(basecampCard => (
      basecampCard.get('cooldownLeft') === 0
    )).length;

    return (
      <BasecampContentNav
        totalBasecampCardsCount   = { totalBasecampCardsCount }
        xpReadyBasecampCardsCount = { xpReadyBasecampCardsCount }
      />
    );
  };

  const renderBasecampCardsOrEmptyView = () => {
    if (basecampCards && basecampCards.length > 0) {
      return (
        <BasecampCardList
          appv2         = { appv2 }
          basecampCards = { basecampCards }
          basecampRefreshFunction = { basecampRefreshFunction }
        />
      );
    }

    return <BasecampCardEmptyView />;
  };

  const renderAddActivityPanel = () => {
    return (
      <AddActivityPanel
        title         = "Add an Activity"
        onAddActivity = { () => { appv2.ui.openNewActivityModal({}); } }
      />
    );
  };

  const renderBasecampActivitySuggestionPanel = () => {
    return (
      <DismissiblePanel
        title = "Suggestions"
        titleStyle = { style20pxRegularBluegrey }
      >
        <ActivityCardSuggestionList
          appv2                        = { appv2 }
          variantIds                   = { VARIANT_IDS }
          activities                   = { basecampCards }
          containerClassName           = "basecamp-content-panel"
          itemShouldBeDisabledIfInList = { false }
          listShouldBeFilteredAndShuffled
          isHidingEnabled
        />
      </DismissiblePanel>
    );
  };

  return (
    <div className = "basecamp-content-panel">
      { renderBasecampContentNav() }
      { renderBasecampCardsOrEmptyView() }
      { renderAddActivityPanel() }
      { renderBasecampActivitySuggestionPanel() }
    </div>
  );
};

export default BasecampContentPanel;
