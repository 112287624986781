import {
  createValidations,
  required,
  requiredIf,
  requiredUnless,
  requiredIfUnless,
  minLength,
  maxLength,
} from 'utils/validations';

export default createValidations({
  details: {
    headline: [required, maxLength(60)],
    category: required,
    num_of_positions: required,
    description: required,
    pay_rate: requiredIfUnless('is_paid', 'is_pay_unknown'),
    hours_amount: requiredUnless('is_hours_unknown'),
    hours_unit: requiredUnless('is_hours_unknown'),
    location: {
      address: required
    }
  },
  challenges: {
    itemRules: {
      question: [required, minLength(10)],
      answer: [required],
    }
  }
});
