import SettingsHomePage        from 'pages/v2/settings/home-page';
import SettingsPreferencesPage from 'pages/v2/settings/preferences-page';

export default ({ appv2, store }) => {
  return {
    path: 'settings',
    indexRoute: {
      title: 'Settings',
      isIndex: true,
      getComponent: ({ params }, cb) => {
        cb(null, SettingsHomePage);
      },
    },
    childRoutes: [
      {
        path: 'account',
        title: 'Account',
        subTitle: 'Back To Settings',
        link: '/settings',
        getComponent: (location, cb) => {
          const { role } = store.getState().auth;

          if (role) {
            cb(null, require(`pages/account/${role}/index`).default);
          }
        }
      },
      {
        path: 'preferences',
        title: 'Preferences',
        subTitle: 'Back To Settings',
        link: '/settings',
        getComponent: ({ params }, cb) => {
          cb(null, SettingsPreferencesPage);
        },
      },
    ]
  };
};
