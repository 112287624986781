import React from 'react';
import SvgIcon from 'components/svg-icon';

import './form-row.scss';

export default ({ icon, children, className = '' }) => (
  <div className={`form__row ${className}`}>
    { icon &&
      <SvgIcon
        id={icon}
        className="form__row__icon"
      />
    }
    { children }
  </div>
);
