import config              from 'config';
import axios               from 'axios';
import { SubmissionError } from 'redux-form'
import { destroyAuth }     from 'modules/auth';
import {
  normalize,
  arrayOf
} from 'normalizr';

import { toggleModal }                  from 'modules/ui';
import { CAMPAIGN_INELIBABLE_MODAL_ID } from 'modules/ui/constants';

export const CALL_API        = 'CALL API';
const VALIDATION_ERROR_CODES = [400, 406];
const AUTH_ERROR_CODES       = [401];

const reduceError = (acc, { params, messages }) => {
  acc[params[0]] = messages.join(', ');
  return acc;
}

const request = ({ endpoint, ...props }) => (
  axios({
    url: (endpoint.indexOf(config.API_URL) === -1) ? config.API_URL + endpoint : endpoint,
    ...props
  })
  .then(({ data }) => data)
);

const handleError = ({ store, error: { response: { data: { error }, status } }}) => {
  if (VALIDATION_ERROR_CODES.indexOf(status) > -1) {
    const errors = Array.isArray(error)
      ? error.reduce(reduceError, {})
      : { _error: error };

    throw new SubmissionError(errors);
  }

  if (AUTH_ERROR_CODES.indexOf(status) > -1) {
    return store.dispatch(destroyAuth());
  }

  if (error === "INELIGIBLE_TO_APPLY_ERROR") {
    store.dispatch(toggleModal(CAMPAIGN_INELIBABLE_MODAL_ID));
  }
};

const normalizeResponse = ({ response, schema }) => {
  if (!schema) {
    return response;
  }
  const resultArray = Array.isArray(response) ? response : [response];

  return normalize(resultArray, arrayOf(schema));
};

export default store => next => action => {
  const callAPI = action[CALL_API];

  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  if (!Array.isArray(callAPI.types) || callAPI.types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }

  const {
    types: [REQUEST, SUCCESS, FAILURE],
    method = 'GET',
    schema,
    meta = {},
    endpoint,
    data,
    params
  } = callAPI;

  next({
    type: REQUEST,
    payload: data,
    meta: {
      isFetching: true,
      ...meta[REQUEST]
    }
  });

  const {
    auth: {
      token,
      profile_id
    }
  } = store.getState();

  const headers = token && {
    'Authorization': `Bearer ${token}`,
    'X-Profile-ID': profile_id
  };

  return request({
      endpoint,
      method,
      headers,
      data,
      params
    }).then(
      response => next({
        type: SUCCESS,
        payload: normalizeResponse({ response, schema }),
        meta: {
          isFetching: false,
          ...meta[SUCCESS]
        }
      }),
      error => {
        next({
          type: FAILURE,
          error: (error && error.response && error.response.data.error) || 'Oops',
          meta: {
            isFetching: false,
            ...meta[FAILURE],
            notification: {
              msg: 'Bad Connection',
              type: 'failure',
              icon: 'refresh' // Using src/components/svg-icon/svgs/refresh.svg
            }
          }
        });

        handleError({ error, store });
      }
    );
};
