import './job-map.scss';

import PropTypes          from 'prop-types';
import React              from 'react';
import GoogleMapContainer from 'components/google-map';
import SvgIcon            from 'components/svg-icon';

const JobMap = ({
  lat,
  lng,
  address,
  distance
}) => (
  <div className = "job-map">
    <div className = "job-map__location">
      <span className = "job-map__location__content">
        <span className = "job-map__location__title">
          Job Location
        </span>
        <span className = "job-map__location__address">
          { address && address.split(',').map((v, index) =>
            <div key = { index }>{ v }</div>) }
        </span>
      </span>
      { distance &&
        <span className = "job-map__location__distance">
          <span className = "job-map__location__label">
            <span className = "job-map__location__icon">
              <SvgIcon id = "basecamp" />
            </span>
            { (distance / 1000).toFixed(2).replace('.00', '') }km from Basecamp
          </span>
        </span>
      }
    </div>
    <div className = "job-map__background">
      { lat && lng &&
        <GoogleMapContainer location = { { lat: parseFloat(lat), lng: parseFloat(lng) } } />
      }
    </div>
  </div>
);
JobMap.propTypes = {
  lat: PropTypes.string,
  lng: PropTypes.string,
  address: PropTypes.string,
  distance: PropTypes.number
};
export default JobMap;
