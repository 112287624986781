import './support.scss';

import React  from 'react';
import config from 'config';
import ImgTag from 'component-lib/components/img-tag';

import { SvgIconComment } from 'components/v2/svg-icons/svg-icon-comment';
import { SvgIconHelp }    from 'components/v2/svg-icons/svg-icon-help';

const { HELP_URL } = config;

export const Support = () => (
  <div className="support">
    <div className="support__container">
      <span className="support__artwork">
        <ImgTag src={require('components/support/icon-support.svg')} />
      </span>
      <div className="support__text">
        <div className="support__title">Having trouble with something?</div>
        <div className="support__buttons">
          <a
            className="support__button"
            id="open-intercom"
          >
            <div className="support__button-icon">
              <SvgIconComment />
            </div>
            <div>Chat Live</div>
          </a>
          <a
            className="support__button"
            href={`${HELP_URL}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="support__button-icon">
              <SvgIconHelp />
            </div>
            <div>Visit Help Centre</div>
          </a>
        </div>
      </div>
    </div>
  </div>
);
