import './index.scss';
import React from 'react';

export const StudentPanelAdd = ({ onClick, imgSrc, heading, description }) => {
  return (
    <div
      className = "v2-student-panel-add"
      onClick = { onClick }
    >
      <div className = "v2-student-panel-add__img-wrapper">
        <img
          className = "v2-student-panel-add__img"
          src = { imgSrc }
          alt = ""
        />
      </div>
      <div className = "v2-student-panel-add__text">
        <h4 className = "v2-student-panel-add__heading">
          { heading }
        </h4>
        <span className = "v2-student-panel-add__description">
          { description }
        </span>
      </div>
    </div>
  );
};
