import './sticker.scss';

import React   from 'react';
import SvgIcon from 'components/svg-icon';

const Sticker = ({ icon }) => {
  return (
    <div className = "sticker">
      <SvgIcon id = { icon } />
    </div>
  );
};
export default Sticker;
