import './index.scss';

import React                 from 'react';
import Common                from 'pages/v2/profile/common';
import { defined }           from 'lib/helpers';
import { ACCESS_DENIED }     from 'lib/paddl-error';
import { STUDENT, EMPLOYER } from 'lib/api/profiles/profile';
import ProfileExtra          from 'lib/api/profiles/profile-extra';

import CompanySummaryContent from 'components/v2/profile/company-summary-content';
import StudentSummaryContent from 'components/v2/profile/student-summary-content';

import Header, {
  SUMMARY_TAB,
  EXPERIENCE_TAB,
  CAMPAIGNS_TAB
} from 'components/v2/profile/header';

import HeaderBannerPositioning from 'components/v2/profile/header-banner-positioning';

export class SummaryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile:      Common.createEmptyProfile(),
      profileExtra: ProfileExtra.createEmpty(),
    };
  }

  componentDidMount() {
    const { appv2, params } = this.props;
    const idOrSlug = defined(params.id);

    appv2.api.profiles
      .fetchWithExtraByIdOrSlug({ idOrSlug, isLoggedIn: true })
      .then(({ profile, profileExtra }) => {
        if (profile.get('urlSlug') && idOrSlug !== profile.get('urlSlug')) {
          const baseUrl = appv2.slugs.generateProfilePath({
            id:   profile.get('id'),
            slug: profile.get('urlSlug'),
          });

          return appv2.ui.redirect({ url: `${baseUrl}/summary` });
        }

        this.setState({ profile, profileExtra });
      })
      .catch((error) => {
        if (error.isPaddlError === true && error.code === ACCESS_DENIED) {
          return appv2.ui.redirect({ url: '/not-found' });
        }

        throw error;
      });
  }

  render() {
    const { appv2 } = this.props;
    const { profile, profileExtra } = this.state;

    const profileId = profile.get('id');
    const canEdit = appv2.auth.isCurrentProfile({ profileId });

    const onTabSwitch = (tab) => {
      const baseUrl = appv2.slugs.generateProfilePath({
        id:   profileId,
        slug: profile.get('urlSlug'),
      });

      if (tab === EXPERIENCE_TAB) {
        return appv2.ui.redirect({ url: `${baseUrl}/experience` });
      } else if (tab === CAMPAIGNS_TAB) {
        return appv2.ui.redirect({ url: `${baseUrl}/campaigns` });
      }

      throw Error('Unexpected TAB');
    };

    const renderSummaryContent = () => {
      switch (profile.get('role')) {
        case EMPLOYER: return <CompanySummaryContent appv2 = { appv2 } profile = { profile } canEdit = { canEdit } />;
        case STUDENT:  return <StudentSummaryContent appv2 = { appv2 } profile = { profile } canEdit = { canEdit } />;
        case null:     return null;
        default:       throw Error('Unexpected role');
      }
    };

    const renderHeader = () => {
      if (window.location.search.indexOf('positioning=1') > -1) {
        return (
          <HeaderBannerPositioning
            appv2 = { appv2 }
            bannerImageUrl = { profile.get('bannerImageUrl') }
          />
        );
      }

      return (
        <Header
          appv2                    = { appv2 }
          profile                  = { profile }
          profileExtra             = { profileExtra }
          canEdit                  = { canEdit }
          currentTab               = { SUMMARY_TAB }
          onTabSwitch              = { onTabSwitch }
          secondTabPlaceholderText = { 'Experience' }
        />
      );
    };

    return (
      <div className = "v2-summary-page">
        { renderHeader() }

        <div className = "v2-summary-page__page-wrapper">
          { renderSummaryContent() }
        </div>
      </div>
    );
  }
}
