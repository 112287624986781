import { CALL_API }        from 'middleware/call-api';
import { SubmissionError } from 'redux-form'

export const CREATE_PAYMENT_REQUEST = 'payments/CREATE_REQUEST';
export const CREATE_PAYMENT_SUCCESS = 'payments/CREATE_SUCCESS';
export const CREATE_PAYMENT_FAILURE = 'payments/CREATE_FAILURE';
export const COUPON_REQUEST         = 'payments/COUPON_REQUEST';
export const COUPON_SUCCESS         = 'payments/COUPON_SUCCESS';
export const COUPON_FAILURE         = 'payments/COUPON_FAILURE';

export function createPayment({ card_token, job }) {
  return {
    [CALL_API]: {
      types: [
        CREATE_PAYMENT_REQUEST,
        CREATE_PAYMENT_SUCCESS,
        CREATE_PAYMENT_FAILURE
      ],
      method: 'POST',
      endpoint: `/orders/${job.stripe_order.id}/payments`,
      data: { card_token },
      meta: {
        [CREATE_PAYMENT_SUCCESS]: {
          redirect: `/jobs/${job.id}`,
          job
        }
      }
    }
  }
}

export function applyCoupon({ coupon, job }) {
   return {
    [CALL_API]: {
      types: [
        COUPON_REQUEST,
        COUPON_SUCCESS,
        COUPON_FAILURE
      ],
      method: 'POST',
      endpoint: `/orders/${job.stripe_order.id}/coupons`,
      data: { coupon },
      meta: {
        [COUPON_SUCCESS]: { job }
      }
    }
  }
}
