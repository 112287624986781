import { sendNotification } from 'modules/notifications';

export default function notificationsMiddleware({ dispatch }) {
  return (next) => (action) => {
    if (!action.meta || !action.meta.notification) {
      return next(action);
    }

    dispatch(sendNotification(action.meta.notification));
    return next(action);
  };
}
