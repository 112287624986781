import './modal.scss';

import React   from 'react';
import SvgIcon from 'components/svg-icon';
import Button  from 'components/button';

import {
  TransitionMotion,
  spring
} from 'react-motion';

const STYLES = [
  { key: 'modal__bg', style: { opacity: spring(1), y: 0 } },
  { key: 'modal',     style: { opacity: spring(1), y: spring(0) }}
];

export default ({
  isOpen,
  isSmall,
  isLarge,
  isDark,
  onClose,
  children,
  action,
  hasCancel,
  icon
}) => (
  <TransitionMotion
    willEnter = { () => ({ opacity: 0, y: -100 }) }
    willLeave = { () => ({ opacity: spring(0, { stiffness: 300, damping: 30 }) }) }
    styles    = { isOpen ? STYLES : [] }>
    { styles =>
      <div className = {
        'modal__container' +
        `${isOpen  ? ' is--open' : ''}` +
        `${isSmall ? ' is--small' : ''}` +
        `${isDark  ? ' is--dark' : ''}` +
        `${isLarge ? ' is--large' : ''}`
      } >
        { styles.map(item =>
          <div
            onClick   = { item.key === 'modal__bg' && isOpen && onClose }
            key       = { item.key }
            className = { item.key }
            style     = { {
              opacity: item.style.opacity,
              transform: `translate3d(0, ${item.style.y}px, 0)`
            } }>
            { item.key === 'modal' && <a className = "modal__close" onClick = { onClose } ><SvgIcon id = "close" /></a> }
            { item.key === 'modal' &&
              <div className = "modal__content">
                { icon && <SvgIcon id = { icon } className = "modal__icon" /> }
                { children }
                { action &&
                  <div className = "modal__actions">
                    { action }
                    { hasCancel && <Button onClick = { onClose } isBlock>Cancel</Button> }
                  </div>
                }
              </div>
            }
          </div>
        )}
      </div>
    }
  </TransitionMotion>
);
