import React from 'react';
import map   from 'lodash/map';
import List  from './list';
import {
  TransitionMotion,
  spring
} from 'react-motion';

const ITEM_HEIGHT = 88;

const TransitionList = ({
  items,
  Component
}) => {
  // const defaultStyles = map(items, item => ({
  //   key: item.id,
  //   data: item,
  //   style: { height: ITEM_HEIGHT, opacity: 0 }
  // }));
  const styles = map(items, item => ({
    key: item.id,
    data: item,
    style: {
      // x:       spring(0),
      opacity: spring(1),
      height:  spring(ITEM_HEIGHT)
    }
  }));
  const willLeave = () => ({
    // x:         spring(300),
    opacity: spring(0),
    height:  spring(0)
  });
  const willEnter = () => ({
    // x:         -200,
    opacity: 0,
    height:  ITEM_HEIGHT
  });
  const itemStyles = style => ({
    // transform: `translate3d(${style.x}px, 0, 0)`,
    opacity: style.opacity,
    height:  style.height
  });

  const children = items => <List { ...{ items, itemStyles, Component } } />;

  return (
    <TransitionMotion { ...{ willLeave, willEnter, styles, children } } />
  );
};

export default TransitionList;
