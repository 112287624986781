import './index.scss';
import React from 'react';

import BasecampStatusPanel  from 'components/v2/basecamp/status-panel';
import BasecampContentPanel from 'components/v2/basecamp/content-panel';
import BasecampFooter       from 'components/v2/basecamp/footer';

class BasecampPage extends React.Component {
  constructor(props) {
    super(props);

    this._intervalId = null;
    this._cachedData = {};

    this.state = {
      basecampCards:              null,
      totalCollectedXp:           null,
      totalActivities:            null,
      totalSkills:                null,
      dailyAverageXp:             null,
      committedHoursPerWeek:      null,
      lastCollectionDateObject: {
        day:                      null,
        month:                    null,
        year:                     null,
      },
      isStatusPanelExpanded:      false,
    };

    this._restoreDataFromCache = () => {
      this.setState(this._cachedData);
      this._cachedData = {};
    };

    this._fetchData = ({ storeInCache, cleanTransportCache }) => {
      const { appv2 } = this.props;
      const profileId = appv2.auth.currentProfileId();

      appv2.api.profiles
        .fetchBasecampStatistics({ id: profileId, cleanTransportCache })
        .then((data) => {
          this._cachedData = {
            ...this._cachedData,

            totalCollectedXp:           data.totalCollectedXp,
            totalActivities:            data.totalActivities,
            totalSkills:                data.totalSkills,
            dailyAverageXp:             data.dailyAverageXp,
            committedHoursPerWeek:      data.committedHoursPerWeek,
            lastCollectionDateObject: {
              day:                      data.lastCollectionDateObject.day,
              month:                    data.lastCollectionDateObject.month,
              year:                     data.lastCollectionDateObject.year,
            },
          };

          if (storeInCache) {
            return;
          }

          this._restoreDataFromCache();
        });

      appv2.api.activities
        .fetchBasecampCards({ profileId, cleanTransportCache })
        .then((basecampCards) => {
          this._cachedData = {
            ...this._cachedData,

            basecampCards,
          };

          if (storeInCache) {
            return;
          }

          this._restoreDataFromCache();
        });
    };
  }

  componentDidMount() {
    const { appv2 } = this.props;

    const isXpFeatureEnabled = appv2.featureFlags.isEnabled({ featureName: 'xp' });

    if (!isXpFeatureEnabled) {
      return appv2.ui.redirect({ url: '/jobs' });
    }

    // At the first load we are happy to accept any data cached on the ApiTransport layer.
    this._fetchData({ storeInCache: false, cleanTransportCache: false });

    setTimeout(() => {
      // However, we want to initiate a real request immediately
      this._fetchData({ storeInCache: false, cleanTransportCache: true });
    }, 1);

    this._intervalId = setInterval(() => {
      // We want to get up-to-date data here, thus cleaning the ApiTransport cache.
      this._fetchData({ storeInCache: false, cleanTransportCache: true });
    }, 60 * 1000); // every minute
  }

  componentWillUnmount() {
    if (this._intervalId) {
      clearInterval(this._intervalId);
    }
  }

  render() {
    const { appv2 } = this.props;

    const {
      basecampCards,
      lastCollectionDateObject,
      totalCollectedXp,
      totalActivities,
      totalSkills,
      dailyAverageXp,
      committedHoursPerWeek,
      isStatusPanelExpanded,
    } = this.state;

    const isXpReadyForCollection = basecampCards && basecampCards.some(
      basecampCard => basecampCard.get('cooldownLeft') === 0
    );

    const onCollectClick = () => {
      const onAnimationButtonClick = () => {
        appv2.ui.closeModal();
        this._restoreDataFromCache();
      };

      appv2.api.activities.collectAllAvailableXP()
        .then((xpNumber) => {
          this._fetchData({ storeInCache: true, cleanTransportCache: true });

          appv2.ui.openAfterActivityUpdatesModal({
            xpNumber:          xpNumber,
            onClick:           onAnimationButtonClick,
            events:            [],
            activityId:        null,
            isVariantUnlocked: false,
          });
        });
    };

    const renderStatusPanel = () => {
      return (
        <BasecampStatusPanel
          totalCollectedXp      = { totalCollectedXp }
          totalActivities       = { totalActivities }
          totalSkills           = { totalSkills }
          dailyAverageXp        = { dailyAverageXp }
          committedHoursPerWeek = { committedHoursPerWeek }
          viewStatsOnClick      = { () => { this.setState({ isStatusPanelExpanded: true }); } }
          hideStatsOnClick      = { () => { this.setState({ isStatusPanelExpanded: false }); } }
          isStatusPanelExpanded = { isStatusPanelExpanded }
        />
      );
    };

    const renderStautsPanelOnly = () => {
      return (
        <div className = "v2-basecamp-page--status-panel-only">
          <div className = "v2-basecamp-page__body">
            <div className = "v2-basecamp-page__status-panel">
              { renderStatusPanel() }
            </div>
          </div>
        </div>
      );
    };

    const renderAllPanels = () => {
      return (
        <div className = "v2-basecamp-page--all-panels">
          <div className = "v2-basecamp-page__body">
            <div className = "v2-basecamp-page__status-panel">
              { renderStatusPanel() }
            </div>
            <div className = "v2-basecamp-page__content-panel">
              <BasecampContentPanel
                appv2         = { appv2 }
                basecampCards = { basecampCards }
                basecampRefreshFunction = { () => { this._fetchData({ storeInCache: false, cleanTransportCache: true }); } }
              />
            </div>
          </div>
          <footer className = "v2-basecamp-page__footer">
            <BasecampFooter
              lastCollectionDateObject = { lastCollectionDateObject }
              isXpReadyForCollection   = { isXpReadyForCollection }
              onCollectClick           = { onCollectClick }
            />
          </footer>
        </div>
      );
    };

    return (
      <div className = "v2-basecamp-page">
        { isStatusPanelExpanded ? renderStautsPanelOnly() : renderAllPanels() }
      </div>
    );
  }
}

export default BasecampPage;
