// Access / Titles Mob
// https://www.figma.com/file/bGuTOkXW01QbI5reSOMLCEhM/Components?node-id=3009%3A1

// Access / Titles
// https://www.figma.com/file/bGuTOkXW01QbI5reSOMLCEhM/Components?node-id=2993%3A11601

import React from 'react';
import PropTypes from 'prop-types';

import './AccessTitles.scss';

const AccessTitles = ({ heading, subheading }) => (
  <div className="AccessTitles">
    <h1 className="AccessTitles__heading">
      { heading }
    </h1>
    <div className="AccessTitles__subheading">
      { subheading }
    </div>
  </div>
);

AccessTitles.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
};

export default AccessTitles;
