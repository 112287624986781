import './input-select.scss';

import React from 'react';

export default ({ addon, options, attrs, placeholder, children, ...props }) => (
  <select { ...props } >
    <option value = "">{ placeholder }</option>
    { options && options.map(({ value, title }) => (
      <option
        key   = { value }
        value = { value } >
        { title }
      </option>
    ))}
    { children }
  </select>
);
