import './loading.scss';

import React       from 'react';
import PropTypes   from 'prop-types';
import LoadingIcon from './loading.svg';

const Loading = ({ fullscreen = false }) => (
  <div className = { `Loading ${fullscreen ? '-fullscreen' : ''} ` } >
    <img src = { LoadingIcon } />
  </div>
);

Loading.propTypes = {
  fullscreen: PropTypes.bool
};

export default Loading;
