import './score.scss';
import React      from 'react';
import classNames from 'classnames';

export default ({
  isWarning,
  isDanger,
  isPrimary,
  isInactive,
  isSuccess,
  className,
  score,
  maxScore
}) => {
  if (!score) return null;

  return (
    <span
      className = { classNames('score', className, {
        'is--primary' : isPrimary,
        'is--danger'  : isDanger,
        'is--warning' : isWarning,
        'is--inactive': isInactive,
        'is--success' : isSuccess
      }) }>
      { score }
      { maxScore &&
        <span className = "score__total"> { `/ ${maxScore}` }</span>
      }
    </span>
  );
};
