import React              from 'react';
import { connect }        from 'react-redux';
import Moment             from 'lib/helpers/moment';
import { jobSelector }    from 'selectors';
import ListPanel          from 'components/list-panel';
import SvgIcon            from 'components/svg-icon';
import JobStatusContainer from 'containers/job-status';

const DATA_BY_ROLE = {
  student:  ({ employer: { business_name }, category}) => (
    <span>
      { business_name}
      <span className = "job-list-panel__category">
      	{ category }
      </span>
    </span>
  ),
  employer: ({ created_at }) => `Created ${ Moment.format_L(new Date(created_at)) }`
};

const JobListPanel = props => {
  const {
    id,
    title,
    role,
    status
  } = props;

  if (!id) return null;

  return (
    <ListPanel
      id = { id }
      className = { `ui__id-${id}` }
      thumb = { <JobStatusContainer id = { id } /> }
      title = { title }
      data  = { DATA_BY_ROLE[role](props) }
      link  = { status === 'draft' ? `/jobs/${id}/edit` : `/jobs/${id}` } />
  );
};

export default connect((state, { id }) => ({
  ...jobSelector(state, id),
  role: state.auth.role
}))(JobListPanel);
