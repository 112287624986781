import './avatar.scss';

import React    from 'react';
import { Link } from 'react-router';
import SvgIcon  from 'components/svg-icon';

class Avatar extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      profilePath: null,
    };
  }

  render() {
    const {
      role = 'employer',
      id,
      url: avatarUrl,
      isEditing,
      hasChanged,
      hasBorder,
      isLinked
    } = this.props;

    const fetchAvatarImage = () => {
      const placeholderId = (role === 'student')
        ? `${role}-${id % 5 + 1}`
        : 'employer';

      const avatarImage = avatarUrl || require(`images/placeholder--avatar-${placeholderId}.svg`);

      return avatarImage;
    };

    const profilePath = this.state.profilePath ||
      (
        window._appv2.slugs.generateProfilePath({
          id:       id || null, // account for `id` being undefined
          callback: ({ path }) => { this.setState({ profilePath: path }); },
        })
      );

    const linkUrl = profilePath + '/' + (role == 'student' ? 'experience' : 'summary');

    return (
      <Link
        to = { isLinked && linkUrl }
        className = {
          'avatar' +
          `${hasBorder                ? ' has--border'  : ''}` +
          `${!hasChanged && isEditing ? ' is--editing'  : ''}` +
          `${hasChanged  && isEditing ? ' is--uploaded' : ''}`
        }
        style = { { backgroundImage: `url(${fetchAvatarImage()})` } } >
        { isEditing && <SvgIcon id = "camera" /> }
      </Link>
    );
  }
}

export default Avatar;
