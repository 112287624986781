import './index.scss';
import React from 'react';

class SnackBar extends React.Component {
  render() {
    const { style, text, storeDivRef, withoutSidebarOffset } = this.props;

    const baseClassName = [
      "v2-snack-bar",
      withoutSidebarOffset ? "v2-snack-bar--without-sidebar-offset" : '',
    ].join(' ');

    const renderGreen = () => {
      const classNames = baseClassName + " v2-snack-bar--valid";
      const iconSrc = require('images/v2/icon-circle-check.svg');

      return (
        <div ref = { storeDivRef } className = { classNames }>
          <img className = "v2-snack-bar__icon" src = { iconSrc } alt = "" />
          <div>{ text }</div>
        </div>
      );
    };

    const renderRed = () => {
      const classNames = baseClassName + " v2-snack-bar--error";
      const iconSrc = require('images/v2/icon-circle-exclamation.svg');

      return (
        <div ref = { storeDivRef } className = { classNames }>
          <img className = "v2-snack-bar__icon" src = { iconSrc } alt = "" />
          <div>{ text }</div>
        </div>
      );
    };

    switch (style) {
      case 'red':
        return renderRed();
      case 'green':
        return renderGreen();
      case null:
        return null;
      default:
        throw Error('Unknown style of SnackBar');
    }
  }
}

export default SnackBar;
