import React          from 'react';
import { withRouter } from 'react-router';

const withEditConfirm = Composed => class View extends React.Component {
  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, () => {
      if (this.props.isEditing) {
        return 'You have unsaved information, are you sure you want to leave this page?';
      }
    });
  }
  render() {
    return (
      <Composed { ...this.props } />
    );
  }
};

export default (Composed) => withRouter(withEditConfirm(Composed));
