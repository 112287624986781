import React                from 'react';
import { connect }          from 'react-redux';
import map                  from 'lodash/map';
import isEmpty              from 'lodash/isEmpty';
import ListFilter           from 'components/list-filter';
import EmptyView            from 'components/empty-view';
import ListGroups           from 'components/list-groups';
import TransitionList       from 'components/list/transition-list';
import PagePanels           from 'components/panels';
import PageActions          from 'components/page-actions';
import PageContent          from 'components/page-content';
import PageSection          from 'components/page-section';
import PageSectionLink      from 'components/page-section-link';
import HelloUsername        from 'components/hellousername';
import JobListPanel         from 'containers/job-list-panel';
import CreateCampaignButton from 'components/v2/buttons/circle-buttons/create-campaign-button';

import {
  filteredGroupedJobsSelector,
  groupsSizeJobsSelector
} from 'selectors/index';

const GROUPS = [{
  id: 'open',
  title: 'Open',
  isSuccess: true
}, {
  id: 'closed',
  title: 'Review',
  isDanger: true
}, {
  id: 'draft',
  title: 'Draft',
  isPrimary: true
}];

const EmployerIndex = (props) => {
  const {
    filteredJobsByGroup,
    groupsSize
  } = props;

  const renderList = () => {
    if (isEmpty(filteredJobsByGroup)) {
      return <EmptyView />;
    }

    return (
      <ListGroups
        groups       = { GROUPS }
        groupedItems = { filteredJobsByGroup }
      >
        { items => (
          <TransitionList
            items     = { items }
            Component = { JobListPanel }
          />
        )}
      </ListGroups>
    );
  };

  const renderArchivedLink = () => {
    if (groupsSize.archived && groupsSize.archived > 0) {
      return (
        <PageSection>
          <PageSectionLink
            icon = "archived"
            to   = "/jobs/archive"
          >
            Show Archived
          </PageSectionLink>
        </PageSection>
      );
    }

    return null;
  };

  return (
    <PageContent>
      <HelloUsername />
      <ListFilter items = { map(GROUPS, v => ({ ...v, size: groupsSize[v.id] })) } />
      <PagePanels />
      { renderList() }
      { renderArchivedLink() }
      <PageActions>
        <CreateCampaignButton link = "/jobs/new" />
      </PageActions>
    </PageContent>
  );
};

export default connect((state, ownProps) => ({
  filteredJobsByGroup: filteredGroupedJobsSelector(state, ownProps),
  groupsSize: groupsSizeJobsSelector(state)
}))(EmployerIndex);
