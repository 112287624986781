import React from 'react';

const SvgIconBasecamp = props => (
  <svg
    width = { 32 }
    height = { 32 }
    viewBox = "0 0 32 32"
    fill = "currentColor"
    { ...props }
  >
    <path
      d = "M30.2 30.6L16.7 8v-.1L27 4.2 15.6 0l-.1 8L1.9 30.6l.1.1H0V32h32v-1.3l-1.8-.1zM16.7 1.8l6.6 2.5-6.7 2.5c0-.1.1-5 .1-5zm3.1 28.8h-7.5l3.7-6.5 3.8 6.5zm-3.3-8.1l.1-12.2 12.2 20.3H21h.1c.1 0-4.6-8.1-4.6-8.1zM4.6 28.3h3.2V27H5.4l.8-1.4H10v-1.3H7l8.5-14-.1 12.5-4.4 7.7H3.2l1.4-2.2z"
      fillRule = "evenodd"
      clipRule = "evenodd"
    />
  </svg>
);

export default SvgIconBasecamp;
