import './index.scss';
import React from 'react';

import Text                          from 'component-lib/type/text';
import { style32pxRegularBluegreyD } from 'component-lib/type/text/styles';
import { style26pxRegularBluegrey }  from 'component-lib/type/text/styles';
import { style14pxRegularBluegreyD } from 'component-lib/type/text/styles';

const BasecampProgressBarTexts = ({ statsValue, unit, subtitle }) => {
  return (
    <div className = "basecamp-progress-bar-texts">
      <div className = "basecamp-progress-bar-texts__values">
        <Text { ...style32pxRegularBluegreyD } text = { statsValue } />
        <Text { ...style26pxRegularBluegrey } text = { unit } />
      </div>
      <Text { ...style14pxRegularBluegreyD } text = { subtitle } />
    </div>
  )
};

export default BasecampProgressBarTexts;
