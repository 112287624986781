import './index.scss';
import React from 'react';
import Biography from 'components/v2/profile/generics/biography';

const CompanyBiographyPanelView = ({ data, uiBehaviour }) => {
  const canEdit = uiBehaviour.canEdit;
  const canEditClassName = canEdit ? 'v2-company-biography-panel-view__container--is-editable' : '';
  const onEditSummary = () => { uiBehaviour.clickToEditSummary(); };

  return (
    <div className = "v2-company-biography-panel-view">
      <div
        className = { `v2-company-biography-panel-view__container ${canEditClassName}` }
        onClick   = { () => { canEdit && onEditSummary(); } }
      >
        <div className = "v2-company-biography-panel-view__content">
          <Biography
            label   = "Company Bio"
            summary = { data.getSummary() }
          />
        </div>
      </div>
    </div>
  );
}

export default CompanyBiographyPanelView;
