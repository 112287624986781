import React      from 'react';
import classNames from 'classnames';
import Cookies    from 'cookies-js';

import Sidenav            from 'component-lib/components/sidenav';
import { SidenavTooltip } from 'component-lib/components/sidenav-tooltip';

class SidenavContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ghostNavOpaque: false,
      ghostNavAvatarFixed: false,
      isInMobileView: null,
      navTooltip: {
        position: [0, 0],
        text: 'Applications',
        visible: false
      },
    };

    this.tooltipTimeout = null;
    this.onWindowScroll = this.onWindowScroll.bind(this);
    this.checkWindowWidth = this.checkWindowWidth.bind(this);
  }

  componentDidMount() {
    this.scrollElement = document.scrollingElement;
    this.documentElement = document.documentElement;

    this.checkWindowWidth();

    window.addEventListener('scroll', this.onWindowScroll);
    window.addEventListener('resize', this.checkWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onWindowScroll);
    window.removeEventListener('resize', this.checkWindowWidth);
  }

  onWindowScroll() {
    this.setState({ ghostNavOpaque: this.scrollElement.scrollTop > 0 });
    this.setState({ ghostNavAvatarFixed: this.scrollElement.scrollTop > 130 });
  }

  checkWindowWidth() {
    this.setState({ isInMobileView: this.documentElement.clientWidth <= 768 });
  }

  render() {
    const { appv2 } = this.props;

    const {
      ghostNavOpaque,
      ghostNavAvatarFixed,
      isInMobileView,
      navTooltip,
    } = this.state;

    const navCollapsed = (Cookies.get('v2-sidenav-collapsed') === 'YES');

    const onToggleExpand = () => {
      Cookies.set('v2-sidenav-collapsed', (navCollapsed ? 'NO' : 'YES'), { expires: 365 * 24 * 3600, path: '/' });
      this.forceUpdate();
    };

    const onTooltipButtonMouseEnter = (e, text) => {
      if (!text) {
        throw Error('No text found');
      }

      const rect = e.currentTarget.getBoundingClientRect();
      const position = [
        80,
        rect.top + (rect.height * 0.5)
      ];

      this.setState({
        navTooltip: {
          ...navTooltip,
          position,
          text,
          visible: true,
        }
      });
    };

    const onTooltipButtonMouseLeave = () => {
      this.setState({
        navTooltip: {
          ...navTooltip,
          visible: false,
        }
      });
    };

    const shouldExpandSidenav = isInMobileView || !navCollapsed;

    return (
      <div
        className = { classNames(
          'sidenav-container',
          { '--sidenav-expanded':      shouldExpandSidenav },
          { '--sidenav-contracted':    !shouldExpandSidenav },
          { '--ghostnav-opaque':       ghostNavOpaque },
          { '--ghostnav-avatar-fixed': ghostNavAvatarFixed },
        ) }
      >
        <Sidenav
          appv2 = { appv2 }
          onToggleExpand = { onToggleExpand }
          onTooltipButtonMouseEnter = { onTooltipButtonMouseEnter }
          onTooltipButtonMouseLeave = { onTooltipButtonMouseLeave }
        />
        <SidenavTooltip { ...navTooltip } />
      </div>
    );
  }
}

export default SidenavContainer;
