import React           from 'react';
import { connect }     from 'react-redux';
import { jobSelector } from 'selectors';
import Share           from 'components/share';
import Text            from 'components/text';
import PageContent     from 'components/page-content';
import PageHeader      from 'components/page-header';
import PageSection     from 'components/page-section';
import JobDetails      from 'components/job-details';
import JobMap          from 'components/job-map';
import Button          from 'components/button';
import Avatar          from 'components/avatar';

const JobViewIndexAnonymous = ({
  job
}) => {
  const isOpen = true;

  return (
    <PageContent isFull>
      <PageHeader>
        <div className = "page__header__badge">
          <div className = "page__header__badge__container">
            <Avatar url = { job.employer.photo_url } />
          </div>
        </div>
      </PageHeader>
      <PageSection >
        <JobDetails { ...job } hasDescription isMuted = { !isOpen } />
      </PageSection>
      { isOpen &&
        <PageSection isFull>
          <JobMap { ...job } />
        </PageSection>
      }
      { isOpen
        ? <PageSection title = "Exclusive to Paddl" isCentered>
            <Button link = "/register" isAction isWide isCentered>
              Sign Up to Apply
            </Button>
            <Text>
              Paddl connects Students and Graduates to <br />
              course relevant opportunities.
            </Text>
          </PageSection>
        : <PageSection title = "Looks like this Job is no longer available :/" isCentered>
            <Text>Login to browse more great Opportunities or explore what Paddl is</Text>
            <Button link = "https://paddljobs.com" isAction isWide isCentered>
              Explore Paddl
            </Button>
            <Text isSmall>
              Paddl connects Students and Graduates to <br />
              course relevant opportunities.
            </Text>
          </PageSection>
      }
      { isOpen &&
        <PageSection
          title = { <span>Know some people who might be interested?<br/><strong>Share this job</strong> to let them know.</span> }
          isFull>
          <Share
            itemTitle  = "I found this awesome job opportunity on PaddlJobs!"
            url        = { `jobs/${job.id}` }
            items      = { ['facebook', 'linkedin', 'twitter'] } />
        </PageSection>
      }
    </PageContent>
  );
};

export default connect((
  state,
  { params: { id } }
) => ({
  job: jobSelector(state, id)
}))(JobViewIndexAnonymous);
