import React              from 'react';
import { connect }        from 'react-redux';
import PagePanel          from 'components/page-panel';
import PagePanelHeader    from 'components/page-panel-header';
import PagePanelExtension from 'components/page-panel-extension';
import SummaryUserDetails from 'components/summary-user-details';
import SvgIcon            from 'components/svg-icon';
import ApplicationStatusContainer from 'containers/application-status';
import {
  applicationSelector
} from 'selectors';

const DATA_BY_STATUS = {
  in_progress: ({ id, status: { days_till_transition }}) => ({
    title    : 'Application in Progress',
    subtitle : 'Submit before the timer runs out',
    thumb    : <ApplicationStatusContainer id = { id } hasDuration />,
    modifiers: { isWarning:  true }
  }),
  closing_soon: ({ id, status: { days_till_transition }}) => ({
    title    : 'Application Closing Soon',
    subtitle : 'Quick! Submit before you miss out',
    thumb    : <ApplicationStatusContainer id = { id } hasDuration />,
    modifiers: { isDanger: true }
  }),
  sent: () => ({
    title    : 'Application Sent',
    subtitle : 'Pending response from the Employer',
    thumb    : <SvgIcon id = "paper-plane" />,
    modifiers: { isPrimary: true }
  }),
  closed: () => ({
    title    : 'Applications Closed',
    subtitle : 'Employer isn’t accepting any more applications for this job. But there’s always more:',
    thumb    : <SvgIcon id = "circle-close" />,
    modifiers: { isInactive: true, isLarge: true },
  }),
  shortlisted: ({ campaign: { employer } }) => ({
    title    : 'You’ve been Shortlisted!',
    subtitle : `${employer.business_name} will contact you to progress your application`,
    thumb    : <SvgIcon id = "star" />,
    modifiers: { isSuccess: true, isLarge: true, hasContact: true },
    extension: <SummaryUserDetails { ...employer } />
  }),
  unsuccessful: () => ({
    title    : 'Application was Unsuccessful',
    subtitle : 'Unfortunately you have not been selected to continue with your application. Practice makes perfect! Let’s try another opportunity…',
    thumb    : <SvgIcon id = "sad-face" />,
    modifiers: { isInactive: true, isLarge: true },
  }),
  viewed: () => ({
    title    : 'Application Viewed',
    subtitle : 'Pending response from the Employer',
    thumb    : <SvgIcon id = "eye" />,
    modifiers: { isPrimary: true }
  }),
  archived: () => ({
    title    : 'Applications Closed',
    subtitle : 'Employer isn’t accepting any more applications for this job. But there’s always more:',
    thumb    : <SvgIcon id = "circle-close" />,
    modifiers: { isInactive: true, isLarge: true },
  }),
};

const ApplicationStatusPanel = props => {
  const {
    id,
    status: { status }
  } = props;

  if (!id) return null;

  const {
    title,
    subtitle,
    thumb,
    extension,
    modifiers
  } = DATA_BY_STATUS[status](props);

  const panelHeader = (
    <PagePanelHeader
      title    = { title }
      subtitle = { subtitle }
      thumb    = { thumb } />
  );

  const panelExtension = extension && <PagePanelExtension title = { extension } />;

  return (
    <PagePanel
      header    = { panelHeader }
      extension = { panelExtension }
      { ...modifiers } />
  );
};

export default connect((state, { id }) => ({
  ...applicationSelector(state, id),
}))(ApplicationStatusPanel);
