import './box.scss';

import React      from 'react';
import classNames from 'classnames';

const Biscuit = ({
  children,
  isCentered
}) => (
  <div className = { classNames('box', {
    'is--centered': isCentered
  }) } >
    { children }
  </div>
);

export default Biscuit;
