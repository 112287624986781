import './index.scss';
import React               from 'react';
import SvgIconUnsuccessful from 'components/v2/svg-icons/svg-icon-unsuccessful';

const BulkUnsuccessfulApplicationButton = ({
  onClick
}) => (
  <button
    type      =  "button"
    className =  "button--application-unsuccessful--bulk"
    onClick   = { onClick } >
    <SvgIconUnsuccessful/>
  </button>
);

export default BulkUnsuccessfulApplicationButton;
