import './index.scss';
import React              from 'react';
import { browserHistory } from 'react-router';
import SvgIconPlus        from 'components/v2/svg-icons/svg-icon-plus';

const CreateCampaignButton = ({
  link
}) => {
  return(
    <button
      type      = "button"
      className =  "button--create-campaign"
      onClick   = { link && (() => browserHistory.push(link)) }>
      <SvgIconPlus />
    </button>
  )};

  export default CreateCampaignButton;
