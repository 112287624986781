import {
  createStore,
  applyMiddleware,
  compose
} from 'redux';

import createLogger            from 'redux-logger';
import reducer                 from 'modules/index';
import config                  from 'config';
import callApiMiddleware       from 'middleware/call-api';
import redirectMiddleware      from 'middleware/redirect';
import authMiddleware          from 'middleware/auth';
import kindathunkMiddleware    from 'middleware/thunk';
import segmentMiddleware       from 'middleware/segment';
import notificationsMiddleware from 'middleware/notifications';
import apiMiddleware           from 'middleware/api';
import { getToken }            from 'utils/auth';
import { setAuth }             from 'modules/auth';
import { initApp }             from 'modules/app';

const logger = createLogger({ collapsed: true });

export default (initialState = {}, options) => {
  const middlewares = [
    kindathunkMiddleware,
    callApiMiddleware,
    notificationsMiddleware,
    redirectMiddleware,
    authMiddleware,
    apiMiddleware,
    segmentMiddleware
  ];

  if (config.ENVIRONMENT === 'development') {
    middlewares.push(logger);
  }

  const reduxDevtoolsCompose = (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__);
  const composeEnhancers = reduxDevtoolsCompose ? reduxDevtoolsCompose({}) : compose;

  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (options && options.isMocked) {
    return store;
  }

  if (typeof window === 'undefined') {
    return store;
  }

  if (window.location.pathname === '/welcome') {
    return store;
  }

  const auth = getToken();

  if (auth && auth.profile_id) {
    store.dispatch(setAuth(auth));
    store.dispatch(initApp());
  }

  return store;
};
