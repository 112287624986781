import _              from 'lodash';
import React          from 'react';
import { connect }    from 'react-redux';
import MainView       from '../index';
import getForm        from 'components/form';
import FormPanel      from 'components/form-panel';
import Administrators from 'components/administrators';
import { FORM_ID }    from 'modules/accounts/constants';
import {
  FORM_PANELS,
  validate
} from 'modules/accounts/employer/constants';

const Form = getForm({
  id: FORM_ID,
  enableReinitialize: true,
  validate
});

class EmployerAccount extends React.Component {
  render() {
    const {
      profile,
      editCard,
      saveCard,
      editingCards,
      handleSubmit,
      tags
    } = this.props;

    const { full_name, email } = profile;
    const industries = _.map(tags, ({ id, title }) => ({ value: id, title }));

    return (
      <Form
        onSubmit      = { handleSubmit  }
        initialValues = { profile } >
        { FORM_PANELS({ industries }).map(({ id, ...rest }) => (
          <FormPanel
            id           = { id }
            key          = { id }
            isEditing    = { editingCards.indexOf(id) > -1 }
            onEdit       = { editCard }
            onSave       = { saveCard }
            { ...rest } />
        ))}
        <FormPanel
          id          = "administrators"
          title       = "Administrators"
          icon        = "user"
          disabled
          description = "Control who has access to manage this company profile and their details.
          There must always be a profile Owner and soon you will be able to add other Administrators.">
          <Administrators users = { [{ role: 'Owner', full_name, email }] } />
        </FormPanel>
      </Form>
    );
  }
}

export default MainView(connect(state => ({
  tags: state.villages
}), {})(EmployerAccount));
