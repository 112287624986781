import './job-status-summary.scss';

import PropTypes          from 'prop-types';
import React              from 'react';
import JobStatusContainer from 'containers/job-status';
import Button             from 'components/button';

const JobStatusSummary = ({
  id,
  status,
  createApplication,
  hasConversation
}) => {
  if (!id) return null;

  return (
    <div className = "job-status-summary">
      <span className = "job-status-summary__status">
        <JobStatusContainer
          id    = { id }
          title = "Applications close in "
          icon  = "colorful-applications"
          hasDuration
          isHorizontal />
      </span>
      <Button
        disabled  = { status !== 'open' }
        onClick   = { createApplication }
        className = "is--primary ui__job-apply">
        { hasConversation ? 'Go to Application' : 'Apply Now' }
      </Button>
    </div>
  );
};

export default JobStatusSummary;

JobStatusSummary.propTypes = {
  id: PropTypes.number.isRequired
};
