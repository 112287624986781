import './index.scss';
import React from 'react';

import {
  stringStartsWithANumber,
  stringIncludesOnlyExpectedCharacters,
  shuffle,
} from 'lib/helpers';

import Text                              from 'component-lib/type/text';
import { style16pxRegularGreyD }         from 'component-lib/type/text/styles';
import InputTextSingleLineForProfileSlug from 'components/v2/input-fields/input-text-single-line-for-profile-slug';
import { ProfileSlugSuggestions }        from 'components/v2/profile-slug-suggestions';
import { FormFooter }                    from 'components/v2/form-footer';
import { UseProfileIdAsSlugSection }     from 'component-lib/components/use-profile-id-as-slug-section';

const START_WITH_A_NUMBER = 'start-with-a-number';
const UNEXPECTED_CHARACTERS = 'unexpected-characters';
const PENDING = 'pending';
const AVAILABLE = 'available';
const UNAVAILABLE = 'unavailable';

export class ProfileSlugSectionFormView extends React.Component {
  constructor(props) {
    super(props);

    this._timerId = null;
    this._initialValue = props.profileSlug;

    this.state = {
      currentValue: this._initialValue,
      isSubmittable: null,
      status: null,
      isReadonly: null,
      suggestions: [],
    };
  }

  componentDidMount() {
    const { appv2 } = this.props;

    appv2.api.profileSlugs
      .fetchSuggestions()
      .then((suggestions) => {
        const visibleSuggestions = suggestions.filter(s => (s !== this._initialValue));

        this.setState({ suggestions: shuffle(visibleSuggestions) });
      });
  }

  render() {
    const { currentValue, isSubmittable, status, isReadonly, suggestions } = this.state;
    const { appv2, urlDomain, onToggleCurrentView } = this.props;

    const maxLength = 40;
    const currentProfileId = appv2.auth.currentProfileId();

    const onChange = ({ value, delay }) => {
      this.setState({
        currentValue: value,
        isSubmittable: false,
        status: null,
      });

      if (this._timerId !== null) {
        clearTimeout(this._timerId);
        this._timerId = null;
      }

      if (value === '') return;
      if (value === this._initialValue) return;

      if (value === currentProfileId) {
        this.setState({ status: AVAILABLE, isReadonly: false, isSubmittable: true });
        return;
      }

      if (value && stringStartsWithANumber.test(value)) {
        this.setState({ status: START_WITH_A_NUMBER, isReadonly: false, isSubmittable: false });
        return;
      }

      if (value && !stringIncludesOnlyExpectedCharacters.test(value)) {
        this.setState({ status: UNEXPECTED_CHARACTERS, isReadonly: false, isSubmittable: false });
        return;
      }

      this._timerId = setTimeout(() => {
        this.setState({ status: PENDING, isReadonly: true, isSubmittable: false });

        appv2.api.profileSlugs
          .checkAvailability(value)
          .then((isAvailable) => {
            isAvailable
              ? this.setState({ status: AVAILABLE, isReadonly: false, isSubmittable: true })
              : this.setState({ status: UNAVAILABLE, isReadonly: false, isSubmittable: false });
          });

        this._timerId = null;
      }, delay);
    };

    const getStatus = () => {
      switch (status) {
        case START_WITH_A_NUMBER: return 'isError';
        case UNEXPECTED_CHARACTERS: return 'isError';
        case PENDING: return 'isPending';
        case AVAILABLE: return 'isSuccess';
        case UNAVAILABLE: return 'isError';
        default: return null;
      }
    };

    const getMessage = () => {
      switch (status) {
        case START_WITH_A_NUMBER: return 'URL cannot start with a number';
        case UNEXPECTED_CHARACTERS: return 'Please use lower-case characters, digits, dashes & underscores only.';
        case PENDING: return 'Checking if the URL is available ...';
        case AVAILABLE: return 'This URL is available!';
        case UNAVAILABLE: return 'This URL is taken.';
        default: return (maxLength ? `Max ${maxLength} Characters` : null);
      }
    };

    const onCancel = () => {
      onToggleCurrentView();
    };

    // Do not use === to compare currentValue & currentProfileId,
    // because currentProfileId is always a number while currentValue can be a string or a number.
    const isUsingProfileIdAsSlug = (currentValue == currentProfileId);

    const onSubmit = () => {
      if (isUsingProfileIdAsSlug) {
        return appv2.api.profileSlugs
          .remove()
          .then(() => {
            onToggleCurrentView();

            appv2.ui.displaySnackBar({
              style:    'green',
              text:     'Profile URL saved as Profile ID!',
              callback: () => { appv2.ui.refreshPage(); },
            });
          });
      }

      return appv2.api.profileSlugs
        .update(currentValue)
        .then(() => {
          onToggleCurrentView();

          appv2.ui.displaySnackBar({
            style:    'green',
            text:     'Profile URL saved!',
            callback: () => { appv2.ui.refreshPage(); },
          });
        });
    };

    return (
      <div className = "v2-profile-slug-section-form-view">
        <div className = "v2-profile-slug-section-form-view__input-field">
          <InputTextSingleLineForProfileSlug
            isAutoFocused
            label       = "Your Profile URL"
            name        = "profile-slug"
            prefix      = { urlDomain }
            placeholder = "jonedoe1985"
            value       = { currentValue }
            maxLength   = { maxLength }
            onChange    = { (value) => { onChange({ value, delay: 500 }); } }
            status      = { getStatus() }
            message     = { getMessage() }
            isReadonly  = { isReadonly }
          />
          <UseProfileIdAsSlugSection
            isDisabled = { isUsingProfileIdAsSlug }
            onClick = { (value) => { onChange({ value }); } }
            profileId = { currentProfileId }
          />
        </div>
        <div className = "v2-profile-slug-section-form-view__suggestions">
          <Text { ...style16pxRegularGreyD } text = "Suggestions" />
          <ProfileSlugSuggestions
            suggestions = { suggestions }
            onClick = { (value) => { onChange({ value, delay: 0 }); } }
          />
        </div>
        <FormFooter
          customSubmitCopy = "Save Changes"
          onCancel         = { onCancel }
          onSubmit         = { onSubmit }
          isSubmitDisabled = { !isSubmittable }
        />
      </div>
    );
  }
}
