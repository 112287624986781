import './index.scss';
import React                 from 'react';
import { defined }           from 'lib/helpers';
import CompanyBiographyInput from 'components/v2/profile/fields/company-biography/input';
import { FormFooter }        from 'components/v2/form-footer';

class CompanyBiographyPanelForm extends React.Component {
  constructor(props) {
    super(props);

    this._handleClick = (event) => {
      if (
        this.props.data.getIsDataChanged() &&
        this._companyBiographyForm &&
        !this._companyBiographyForm.contains(event.target)
      ) {
        if (confirm("You have unsaved information, are you sure you want to leave this page?") === true) {
          this.props.data.resetToPersistedState();
          this.props.data.setIsDataChanged(false);
          this.props.uiBehaviour.cancelSummaryForm();
        }
      }
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClick, false);
  }

  render() {
    const { data, uiBehaviour } = this.props;

    const onCancel = () => {
      data.resetToPersistedState();
      data.setIsDataChanged(false);
      uiBehaviour.cancelSummaryForm();
    };

    const onSubmit = () => {
      data
        .updateSummaryViaApi()
        .then(() => {
          data.setAsPersistedState();
          data.setIsDataChanged(false);
          data.refreshCurrentProfileLegacy();
          uiBehaviour.saveSummaryForm();
        });
    };

    return (
      <div
        className = "v2-company-biography-panel-form"
        ref = { (elem) => { this._companyBiographyForm = elem; } }
      >
        <div className = "v2-company-biography-panel-form__container">
          <div className = "v2-company-biography-panel-form__content">
            <CompanyBiographyInput
              value    = { data.getSummary() || '' }
              onChange = { ({ name }) => { data.setSummary(defined(name)); } }
            />
            <FormFooter
              customSubmitCopy = 'Save'
              onCancel         = { onCancel }
              onSubmit         = { onSubmit }
              isSubmitDisabled = { !data.getIsDataChanged() }
            />
          </div>
        </div>
      </div>
    );
  }
};

export default CompanyBiographyPanelForm;
