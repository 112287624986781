import './page-panel.scss';

import React     from 'react';
import PropTypes from 'prop-types';

const PagePanel = ({
  isWarning,
  isDanger,
  isPrimary,
  isInactive,
  isSuccess,
  isLarge,
  header,
  extension,
  children
}) => (
  <div
    className = {
    'page-panel ' +
    `${isWarning  ? ' is--warning'  :  ''}` +
    `${isDanger   ? ' is--danger'   :  ''}` +
    `${isPrimary  ? ' is--primary'  :  ''}` +
    `${isInactive ? ' is--inactive' :  ''}` +
    `${isSuccess  ? ' is--success'  :  ''}` +
    `${isLarge    ? ' is--large'    :  ''}` }>
    { header }
    { children }
    { extension }
  </div>
);

PagePanel.propTypes = {
  isWarning: PropTypes.bool,
  isDanger: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isInactive: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isLarge: PropTypes.bool
};

export default PagePanel;
