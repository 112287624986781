import { CALL_API }       from 'middleware/call-api';
import without            from 'lodash/without';
import { browserHistory } from 'react-router';
import { jobSchema }      from 'schemas';

const DESTROY           = 'job/create/DESTROY';
const SAVE_CARD         = 'job/create/SAVE_CARD';
const EDIT_CARD         = 'job/create/EDIT_CARD';
const EDIT_CARDS        = 'job/create/EDIT_CARDS';
const TOGGLE_CHALLENGES = 'job/create/TOGGLE_CHALLENGES';

const CREATE_REQUEST    = 'job/create/CREATE_REQUEST';
const CREATE_SUCCESS    = 'job/create/CREATE_SUCCESS';
const CREATE_FAILURE    = 'job/create/CREATE_FAILURE';

const UPDATE_REQUEST    = 'job/create/UPDATE_REQUEST';
const UPDATE_SUCCESS    = 'job/create/UPDATE_SUCCESS';
const UPDATE_FAILURE    = 'job/create/UPDATE_FAILURE';

const initialState = {
  editingCards: [],
  isChallengesModalVisible: false,
};

export default (state = initialState, action) => {
  switch(action.type) {
    case EDIT_CARD: {
      const { id } = action.payload;

      return {
        ...state,
        editingCards: [...state.editingCards, ...[id]]
      }
      break;
    }
    case EDIT_CARDS: {
      const { ids } = action.payload;

      return {
        ...state,
        editingCards: [...state.editingCards, ...ids]
      }
      break;
    }
    case SAVE_CARD: {
      const { id } = action.payload;

      return {
        ...state,
        editingCards: without(state.editingCards, id)
      }
      break;
    }
    case TOGGLE_CHALLENGES: {
      return {
        ...state,
        isChallengesModalVisible: !state.isChallengesModalVisible
      }
      break;
    }
    case DESTROY: {
      return initialState
      break;
    }
    default: return state;
  }
}

export function destroy() {
  return {
    type: DESTROY
  }
}

export function editCard({ id }) {
  return {
    type: EDIT_CARD,
    payload: { id }
  }
}

export function editCards({ ids }) {
  return {
    type: EDIT_CARDS,
    payload: { ids }
  }
}

export function saveCard({ id, jobPayload }) {
  return {
    type: SAVE_CARD,
    payload: { id, jobPayload }
  }
}

export function toggleChallenges() {
  return {
    type: TOGGLE_CHALLENGES
  }
}

export function create({ values }) {
  return {
    [CALL_API]: {
      types: [
        CREATE_REQUEST,
        CREATE_SUCCESS,
        CREATE_FAILURE
      ],
      method: 'POST',
      data: values,
      endpoint: '/jobs',
      schema: jobSchema,
      meta: {
        [CREATE_SUCCESS]: {
          notification: { msg: 'Draft Saved!', type: 'success' },
          redirect: ({ payload: { result } }) => `/jobs/${result[0]}/edit`
        },
        [CREATE_FAILURE]: {
          notification: { msg: 'Oops', type: 'failure' }
        }
      }
    }
  }
}

export function update({ id, values }) {
  return {
    [CALL_API]: {
      types: [
        UPDATE_REQUEST,
        UPDATE_SUCCESS,
        UPDATE_FAILURE
      ],
      method: 'PUT',
      data: values,
      endpoint: `/jobs/${id}`,
      schema: jobSchema,
      meta: {
        [UPDATE_SUCCESS]: {
          notification: { msg: 'Job Updated' }
        }
      }
    }
  }
}
