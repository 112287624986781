import React from 'react';

import Common from 'pages/v2/profile/common';
import { defined } from 'lib/helpers';
import { ACCESS_DENIED } from 'lib/paddl-error';
import { EMPLOYER } from 'lib/api/profiles/profile';
import ProfileExtra from 'lib/api/profiles/profile-extra';

import TimelineOfActivities from 'components/v2/timeline/activities';
import AddActivityPanel from 'components/v2/profile/add-activity-panel';
import ExperienceTabEmptyState from 'components/v2/profile/experience-tab-empty-state';

import Header, {
  SUMMARY_TAB,
  EXPERIENCE_TAB,
} from 'components/v2/profile/header';

import HeaderBannerPositioning from 'components/v2/profile/header-banner-positioning';

import './index.scss';

export class ExperiencePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: Common.createEmptyProfile(),
      profileExtra: ProfileExtra.createEmpty(),
      activities: null,
    };
  }

  componentDidMount() {
    const { appv2, params } = this.props;
    const idOrSlug = defined(params.id);

    appv2.api.profiles
      .fetchWithExtraByIdOrSlug({ idOrSlug, isLoggedIn: true })
      .then(({ profile, profileExtra }) => {
        const baseUrl = appv2.slugs.generateProfilePath({
          id: profile.get('id'),
          slug: profile.get('urlSlug'),
        });

        if (profile.get('role') === EMPLOYER) {
          return appv2.ui.redirect({ url: `${baseUrl}/summary` });
        }

        if (profile.get('urlSlug') && idOrSlug !== profile.get('urlSlug')) {
          return appv2.ui.redirect({ url: `${baseUrl}/experience` });
        }

        this.setState({ profile, profileExtra });

        const isXpFeatureEnabled = appv2.featureFlags.isEnabled({ featureName: 'xp' });

        appv2.api.activities
          .fetchTimeline({ profileId: profile.get('id'), isXpFeatureEnabled, isLoggedIn: true })
          .then((activities) => {
            this.setState({ activities });
          });
      })
      .catch((error) => {
        if (error.isPaddlError === true && error.code === ACCESS_DENIED) {
          return appv2.ui.redirect({ url: '/not-found' });
        }

        throw error;
      });
  }

  render() {
    const { appv2 } = this.props;
    const { profile, profileExtra, activities } = this.state;

    const profileId = profile.get('id');
    const canEdit = appv2.auth.isCurrentProfile({ profileId });

    const onTabSwitch = (tab) => {
      const baseUrl = appv2.slugs.generateProfilePath({
        id: profileId,
        slug: profile.get('urlSlug'),
      });

      if (tab === SUMMARY_TAB) {
        return appv2.ui.redirect({ url: `${baseUrl}/summary` });
      }

      throw Error('Unexpected TAB');
    };

    const renderAddActivityPanel = ({ appv2 }) => (
      <AddActivityPanel
        title="Add Experience"
        onAddActivity={() => { appv2.ui.openNewActivityModal({}); }}
      />
    );

    const renderActivitiesPanel = ({ appv2, canEdit, activities }) => {
      if (activities && activities.length > 0) {
        return (
          <TimelineOfActivities
            appv2={appv2}
            canEdit={canEdit}
            activities={activities}
            viewedAt={new Date()}
            canOpenModal
          />
        );
      }

      if (canEdit) {
        return <ExperienceTabEmptyState />;
      }

      return null;
    };

    const renderHeader = () => {
      if (window.location.search.indexOf('positioning=1') > -1) {
        return (
          <HeaderBannerPositioning
            appv2={appv2}
            bannerImageUrl={profile.get('bannerImageUrl')}
          />
        );
      }

      return (
        <Header
          appv2={appv2}
          profile={profile}
          profileExtra={profileExtra}
          canEdit={canEdit}
          currentTab={EXPERIENCE_TAB}
          onTabSwitch={onTabSwitch}
          secondTabPlaceholderText={'Experience'}
        />
      );
    };

    return (
      <div className="v2-experience-page">
        { renderHeader() }

        <div className="v2-experience-page__page-wrapper">
          { canEdit &&
            <div className="v2-experience-page__panel">
              { renderAddActivityPanel({ appv2 }) }
            </div>
          }
          <div className="v2-experience-page__panel">
            { renderActivitiesPanel({ appv2, canEdit, activities }) }
          </div>
        </div>
      </div>
    );
  }
}
