import './index.scss';
import React from 'react';

const CompanyStraplinePanelView = ({ data, uiBehaviour }) => {
  const canEdit = uiBehaviour.canEdit;
  const canEditClassName = canEdit ? 'v2-company-strapline-panel-view__container--is-editable' : '';
  const onEditSummary = () => { uiBehaviour.clickToEditStrapline(); };

  return (
    <div className = "v2-company-strapline-panel-view">
      <div
        className = { `v2-company-strapline-panel-view__container ${canEditClassName}` }
        onClick   = { () => { canEdit && onEditSummary(); } }
      >
        <div className = "v2-company-strapline-panel-view__content">
          <div className = "v2-company-strapline-panel-view__strapline">
            { data.getStrapline() }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyStraplinePanelView;
