import './job__details.scss';

import React              from 'react';
import { connect }        from 'react-redux';
import { FORM_ID }        from 'modules/jobs/create/constants';
import Markdown           from 'components/markdown';
import GoogleMapContainer from 'components/google-map';
import SvgIcon            from 'components/svg-icon';

const JobDetails = ({ values = {} }) => {
  const {
    headline         = 'Scout Leader',
    category         = 'Unknown Role',
    num_of_positions = '0',
    description      = 'This is an opportunity to add a juicy description to your campaign!',
    pay_rate         = '--',
    hours_amount     = '--',
    hours_unit       = '--',
    location         = {},
    is_paid
  } = values;

  const {
    address = '2 Paddl St, Archipelago, Victoria',
    lat, lng
  } = location;

  const isEmpty = !values.headline;

  return (
    <div className = { `job__details ${isEmpty ? 'is--empty' : ''}` }>
      <div className = "job__details__map" >
        { lat && lng &&
          <GoogleMapContainer location = { { lat: parseFloat(lat), lng: parseFloat(lng) } } />
        }
      </div>
      <div className = "job__details__content">
        <div className = "job__details__title">
          { headline }
        </div>
        <div className = "job__details__positions">
          <span className = "job__details__positions__category">
            { category }
          </span>
          <span className = "job__details__positions__amount">
            x{ num_of_positions }
          </span>
        </div>
        <div className = "job__details__location">
          <SvgIcon id = "location" />
          { address }
        </div>
        <div className = "job__details__description">
          <Markdown>{ description }</Markdown>
        </div>
      </div>
      <div className = "job__details__summary">
        <div className = "job__details__summary__item">
          <SvgIcon id = "wallet" />
          { is_paid
            ? <div>
                <strong><small>$ </small>{ pay_rate }</strong>
                <span>/ hour</span>
              </div>
            : <div><strong>--</strong></div>
          }
        </div>
        <div className = "job__details__summary__item">
          <SvgIcon id = "clock" />
          <div>
            <strong>{ hours_amount } <small>hrs</small></strong>
            <span>/ { hours_unit }</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(({ form }) => ({
  values: form[FORM_ID] && form[FORM_ID].values && form[FORM_ID].values.details
}))(JobDetails);
