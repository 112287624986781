import {
  addFetchingModule,
  removeFetchingModule
} from 'modules/api';

export default function apiMiddleware({dispatch}) {
  return (next) => (action) => {
    if (action.meta && typeof action.meta.isFetching === 'boolean') {
      const moduleId = action.type.split('/')[0];

      if (action.meta.isFetching) {
        dispatch(addFetchingModule(moduleId));
      } else {
        dispatch(removeFetchingModule(moduleId));
      }
    }
    return next(action);
  };
}
