const EMPTY_PANELS = {
  'jobs-employer': {
    action: 'Create Campaign',
    linkTo: '/jobs/new',
    text: 'You haven’t created any campaigns yet. <br /> Let’s get going by creating one now.'
  },
  'jobs-student': {
    text: 'No jobs nearby, try again later!'
  },
  'conversations-employer': {
    action: 'Create Campaign',
    linkTo: '/jobs/new',
    text: 'You haven’t received any applications yet. <br /> Hold tight!'
  },
  'applications-employer': {
    action: 'Create Campaign',
    linkTo: '/jobs/new',
    text: 'You haven’t received any applications yet. <br /> Hold tight!'
  },
  'conversations-student': {
    action: 'Browse Jobs',
    linkTo: '/jobs',
    text: 'You haven’t applied for any jobs yet. <br /> Let’s get going by applying for one now.'
  },
  'applications-student': {
    action: 'Browse Jobs',
    linkTo: '/jobs',
    text: 'You haven’t applied for any jobs yet. <br /> Let’s get going by applying for one now.'
  }
};

const PAGE_PANELS = {
  'jobs-employer': {
    id: 'campaign',
    title: 'This is your Campaigns view where you’ll see any job postings that you’ve created',
    text: '<strong>Hot Tip:</strong> Keep an eye on the alerts in the sidebar to see when an applicant has responded.',
    action: 'Take the Tour',
    tour_id: 'campaign'
  },
  'jobs-student': {
    id: 'campaign',
    title: 'This is your Jobs view, where you’ll see jobs relevant to your course.',
    text: 'You can sort jobs by location using the tabs above. For example, Basecamp shows the jobs closest to your Campus. The further you scroll, the further away the job may be.',
    action: 'Take the Tour',
    tour_id: 'campaign'
  },
  'conversations-employer': {
    id: 'applications',
    title: 'This is your Applications view where you’ll see any applications to your Campaigns.',
    text: '<strong>Hot Tip:</strong> Keep an eye on the alerts in the sidebar to see when a student has responded.'
  },
  'applications-employer': {
    id: 'applications',
    title: 'This is your Applications view where you’ll see any applications to your Campaigns.',
    text: '<strong>Hot Tip:</strong> Keep an eye on the alerts in the sidebar to see when a student has responded.'
  },
  'conversations-student': {
    id: 'applications',
    title: 'This is your Applications view where you’ll see any jobs that you’ve applied to.',
    text: '<strong>Hot Tip:</strong> Keep an eye on alerts in the sidebar to see when an employer has responded.'
  },
  'applications-student': {
    id: 'applications',
    title: 'This is your Applications view where you’ll see any jobs that you’ve applied to.',
    text: '<strong>Hot Tip:</strong> Keep an eye on alerts in the sidebar to see when an employer has responded.'
  },
  'jobs-student-outpost': {
    id: 'outpost',
    title: 'Coming #soon',
    text: 'We\'re currently working on giving you the option of setting a custom location to filter jobs by.',
    type: 'coming-soon'
  },
  'jobs-student-search': {
    id: 'search',
    title: 'Coming #soon',
    text: 'We’re currently working on giving you the option to search jobs by specific criteria like an industry.',
    type: 'coming-soon'
  },
  'jobs-archive-employer': {
    id: 'archive',
    title: 'Welcome to Archive, this is where Campaigns go once they are complete',
    text: 'Tip: You can re-post an old Campaign by clicking into it and selecting \'post and pay\''
  }
};

export { EMPTY_PANELS, PAGE_PANELS };
