import './job__challenges.scss';
import React from 'react';
import {
  TransitionMotion,
  spring
} from 'react-motion';

const HEIGHT = 125;
const STYLES = {
  default: (index) => ({ opacity: 0, y: -60 * (index + 1), height: HEIGHT }),
  mounted: ()      => ({ opacity: spring(1), y: spring(0), height: HEIGHT })
};

const getStyles = ({ type, items }) => items.map((data, index) => ({
  key: data.id,
  style: STYLES[type](index),
  data
}));

export default ({ items, handleClick }) => (
  <div className = "job__challenges">
    <h3 className = "job__challenges__title">
      Challenges
    </h3>
    <h4 className = "job__challenges__subtitle">
      Choose a Challenge below to add to your Campaign
    </h4>
    <TransitionMotion
      willLeave     = { () => ({ opacity: spring(0), height: spring(0) }) }
      defaultStyles = { getStyles({ type: 'default', items }) }
      styles        = { getStyles({ type: 'mounted', items }) }>
      { styles =>
        <div className = "job__challenges__list">
          { styles.map(({ key, data, style }) =>
            <div className = "job__challenges__transition" key = { `container-${key}` }>
              <div
                style = {{
                  opacity:    style.opacity,
                  height:     style.height,
                  transform: `translate3d(0, ${style.y}px, 0)`
                }}
                onClick   = { items.length > 0 && (() => handleClick(data)) }
                key       = { key }
                className = "job__challenges__item" >
                <div className = "job__challenges__item__icon">
                  <img src = { require(`images/icon--${key}.svg`) } />
                </div>
                <div>
                  <div className = "job__challenges__item__title">
                    { data.title }
                  </div>
                  <div className = "job__challenges__item__description">
                    { data.description }
                  </div>
                </div>
                <div className = "job__challenges__item__type">
                  { data.type }
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </TransitionMotion>
  </div>
)
