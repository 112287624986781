import React from 'react';
import ActivationPage from 'pages/v2/register/student/activation-page';

export default ({ appv2 }) => {
  const componentWithQueryProp = (Component, query) => {
    return (props => <Component { ...props } query = { query } />);
  };

  return {
    path:         'activate',
    title:        'Register (Activate)',
    getComponent: ({ query }, cb) => {
      cb(null, componentWithQueryProp(ActivationPage, query));
    },
  };
};
