import './input-radio.scss';

import React     from 'react';
import map       from 'lodash/map';
import { Field } from 'redux-form';

export default ({ options, name }) => (
  <div className = "input__radio">
    { map(options, ({ title, value }) => (
      <div className = "input__radio__item" key = { value }>
        <Field
          className = "input__radio__input"
          component = "input"
          type      = "radio"
          name      = { name }
          id        = { `${name}_${value}` }
          value     = { value } />
        <label
          className = "input__radio__label"
          htmlFor   = { `${name}_${value}` } >
          { title }
        </label>
      </div>
    ))}
  </div>
);
