import union  from 'lodash/union';
import without from 'lodash/without';

export const ADD_FETCHING_MODULE = 'api/ADD_FETCHING_MODULE';
export const REMOVE_FETCHING_MODULE = 'api/REMOVE_FETCHING_MODULE';

const initialState = {
  fetching: []
};

export default function apiReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_FETCHING_MODULE:
      return {
        ...state,
        fetching: union(state.fetching, [action.payload.id])
      }
    case REMOVE_FETCHING_MODULE:
    return {
      ...state,
      fetching: without(state.fetching, action.payload.id)
    }
    default:
    return state;
  }
}


export function addFetchingModule(id) {
  return {
    type: ADD_FETCHING_MODULE,
    payload: { id }
  };
}

export function removeFetchingModule(id) {
  return {
    type: REMOVE_FETCHING_MODULE,
    payload: { id }
  };
}
