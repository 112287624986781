import './index.scss';
import React             from 'react';
import SvgIconEmptyState from 'components/v2/svg-icons/svg-icon-empty-state';

const ExperienceTabEmptyState = () => {
  return (
    <div className = "v2-experience-tab-empty-state">
      <div className = "v2-experience-tab-empty-state__container">
        <div className = "v2-experience-tab-empty-state__content">
          <SvgIconEmptyState />
          <p className = "v2-experience-tab-empty-state__text">
            Add Activities to capture experience on your professional journey.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExperienceTabEmptyState;
