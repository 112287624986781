import { defined } from 'lib/helpers';

class UI {
  constructor({ state, appv2 }) {
    this._ourState = defined(state);
    this._appv2    = defined(appv2);

    this.initializeState = () => {
      this._ourState.uiSet({
        currentlyOpenForm: null,
      });
    };

    this.getCurrentlyOpenForm = () => {
      return this._ourState.uiGet({ id: 'currentlyOpenForm' });
    };

    this.closeForm = () => {
      this._ourState.uiSet({ currentlyOpenForm: null });
    };

    this.openForm = ({ id }) => {
      this._ourState.uiSet({ currentlyOpenForm: id });
    };

    this.displayProfileSaved = () => {
      this._appv2.ui.displaySnackBar({ style: 'green', text: 'Profile saved!' });
    };
  }
}

export default UI;
