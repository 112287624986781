import './index.scss';
import React from 'react';

import BasecampProgressBarTexts from 'components/v2/basecamp/basecamp-progress-bar-texts';
import BasecampProgressBar      from 'components/v2/basecamp/basecamp-progress-bar';

const BasecampDailyAverageXp = ({ dailyAverageXp }) => {
  const dailyAverageThresholds = [0, 40, 70, 100, 140];

  const getDailyAverageXpThresholdDetails = () => {
    if (dailyAverageXp > dailyAverageThresholds[4]) {
      return {
        'color': 'blue',
        'title': 'Extreme',
        'width': '100%',
      };
    }

    if (dailyAverageXp > dailyAverageThresholds[3]) {
      return {
        'color': 'blue',
        'title': 'High',
        'width': '80%',
      };
    }

    if (dailyAverageXp > dailyAverageThresholds[2]) {
      return {
        'color': 'blue',
        'title': 'Solid',
        'width': '60%',
      };
    }

    if (dailyAverageXp > dailyAverageThresholds[1]) {
      return {
        'color': 'blue',
        'title': 'Average',
        'width': '40%',
      };
    }

    if (dailyAverageXp > dailyAverageThresholds[0]) {
      return {
        'color': 'blue',
        'title': 'Low',
        'width': '20%',
      };
    }

    if (dailyAverageXp === dailyAverageThresholds[0]) {
      return {
        'color': 'bluegrey',
        'title': 'No Active Cards',
        'width': '0%',
      };
    }

    if (dailyAverageXp === null) {
      return {
        'color': 'bluegrey',
        'title': 'No Active Cards',
        'width': '0%',
      };
    }

    throw Error('Unexpected dailyAverageXp');
  };

  const details = getDailyAverageXpThresholdDetails();

  return (
    <div className = "basecamp-daily-average-xp">
      <BasecampProgressBarTexts
        statsValue = { dailyAverageXp }
        unit = 'XP'
        subtitle = 'Daily Average'
      />
      <BasecampProgressBar
        color = { details.color }
        width = { details.width }
        title = { details.title }
      />
    </div>
  );
};

export default BasecampDailyAverageXp;
