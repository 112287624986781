import './index.scss';

import React                 from 'react';
import { defined }           from 'lib/helpers';
import HeaderLayout          from 'components/v2/profile/header-layout';
import dataURLtoBlob         from 'utils/dataurl-to-blob';
import { CancelButton }      from 'components/v2/buttons/square-buttons/cancel-button';
import { SaveChangesButton } from 'components/v2/buttons/square-buttons/save-changes-button';

class HeaderBannerPositioning extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bgOffsetX:  0,
      bgOffsetY:  0,
      isGrabbing: false,
    };
  }

  render() {
    const {
      appv2,
      bannerImageUrl,
    } = this.props;

    const renderAvatar = () => {
      return (
        <div></div>
      );
    };

    const renderInfo = () => {
      return (
        <div></div>
      );
    };

    const redirectBack = () => {
      appv2.ui.redirect({ url: window.location.pathname })
    };

    const onSave = () => {
      const origImage = new Image();
      origImage.crossOrigin = 'anonymous';
      origImage.src = bannerImageUrl;

      origImage.onload = () => {
        const realOffsetX = this.state.bgOffsetX / this.state.scale;
        const realOffsetY = this.state.bgOffsetY / this.state.scale;

        const canvas = document.createElement('canvas');
        canvas.width = this.state.width / this.state.scale;
        canvas.height = this.state.height / this.state.scale;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(origImage, realOffsetX, realOffsetY);

        const dataUrl = canvas.toDataURL();
        const base64data = dataURLtoBlob(dataUrl);

        appv2.api.uploading.uploadToS3({ data: base64data, subKey: 'v2banner' })
          .then((url) => {
            appv2.api.heroBannerImages
              .setCustomBanner({ url })
              .then(() => {
                appv2.ui.displaySnackBar({
                  style:    'green',
                  text:     'Image uploaded!',
                  callback: () => { redirectBack(); },
                });
              });
          });
      };
    };

    const renderTabs = () => {
      return (
        <div className = 'v2-profile-header-banner-positioning__buttons'>
          <CancelButton
            onClick = { redirectBack }
          />
          <SaveChangesButton
            onClick = { onSave }
            isDisabled = { false }
          />
        </div>
      );
    };

    const onBannerImageMouseDown = (width, height, realWidth, realHeight, scale, startX, startY) => {
      const initialOffsetX = this.state.bgOffsetX;
      const initialOffsetY = this.state.bgOffsetY;

      const onDragMove = (e) => {
        e.preventDefault();

        const deltaX = e.clientX - startX;
        const deltaY = e.clientY - startY;

        const totalDeltaX = initialOffsetX + deltaX;
        const totalDeltaY = initialOffsetY + deltaY;

        const leftLimitedX = Math.min(totalDeltaX, 0);
        const leftLimitedY = Math.min(totalDeltaY, 0);

        const bothLimitedX = Math.max(leftLimitedX, width - realWidth * scale);
        const bothLimitedY = Math.max(leftLimitedY, height - realHeight * scale);

        this.setState({
          bgOffsetX: bothLimitedX,
          bgOffsetY: bothLimitedY,

          width:  width,
          height: height,
          scale:  scale,

          isGrabbing: true,
        });
      };

      const onDragEnd = (e) => {
        document.removeEventListener('mousemove', onDragMove);
        document.removeEventListener('touchmove', onDragMove);

        document.removeEventListener('mouseup', onDragEnd);
        document.removeEventListener('touchend', onDragEnd);

        this.setState({
          isGrabbing: false,
        });
      };

      document.addEventListener('mousemove', onDragMove);
      document.addEventListener('touchmove', onDragMove);

      document.addEventListener('mouseup', onDragEnd);
      document.addEventListener('touchend', onDragEnd);
    };

    return (
      <HeaderLayout
        appv2 = { appv2 }
        canEdit = { false }
        renderAvatar = { renderAvatar }
        renderInfo = { renderInfo }
        renderTabs = { renderTabs }
        bannerImageUrl = { bannerImageUrl }
        onBannerImageMouseDown = { onBannerImageMouseDown }
        bannerImagePosX = { this.state.bgOffsetX }
        bannerImagePosY = { this.state.bgOffsetY }
        cursorState = { this.state.isGrabbing ? 'grabbing' : 'grab' }
      />
    );
  }
};

export default HeaderBannerPositioning;
