// TODO: remove as soon as conversations page is gone

/*
# formatDate

Takes one parameter -- a date or a date string -- and returns a nice human-
readable representation of that date.
*/

import fecha from 'fecha';

export default function formatDate(date, formatOptions = {}) {
  let { time: includeTime = true, day: includeDay = true } = formatOptions,
      formatStrings = [],
      formatPrefix = '',
      dateSuffix = (includeTime ? ',' : ''),
      now = new Date();

  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  // If the date isn't this year,
  if (date.getFullYear() !== now.getFullYear()) {
    if (includeDay)
      formatStrings.push('dddd');
    formatStrings.push('Do');
    formatStrings.push('MMMM');

    // last one should include a comma if we're printing time
    formatStrings.push('YYYY'+ dateSuffix);

  // Or isn't this month,
  } else if (date.getMonth() !== now.getMonth()) {
    if (includeDay)
      formatStrings.push('dddd');
    formatStrings.push('Do');
    formatStrings.push('MMMM'+ dateSuffix);

  // Or isn't today,
  } else if (date.getDay() !== now.getDay()) {
    if (includeDay)
      formatStrings.push('dddd');
    formatStrings.push('Do'+ dateSuffix);

  // Okay it's actually today
  } else if(includeDay) {
    // No need for the dateSuffix comma here
    formatPrefix = 'Today' + (includeTime ? ' at ' : '');
  }

  // Optionally include the time
  if (includeTime) {
    formatStrings.push('h:mm a');
  }

  return formatPrefix + fecha.format(date, formatStrings.join(' '));
}
