import './index.scss';
import React from 'react';

const SettingsHomePagePanel = ({ href, iconSrc, heading, description }) => {
  return (
    <a className = "v2-settings-home-page-panel" href = { href } >
      <div className = "v2-settings-home-page-panel__img-wrapper">
        <img className = "v2-settings-home-page-panel__img" src = { iconSrc } alt = "" />
      </div>
      <div className = "v2-settings-home-page-panel__text">
        <h4 className = "v2-settings-home-page-panel__heading">{ heading }</h4>
        <span className = "v2-settings-home-page-panel__description">{ description }</span>
      </div>
    </a>
  );
};

export default SettingsHomePagePanel;
