export default ({
  path: 'register/employer',
  indexRoute: {
    getComponent: (location, cb) => {
      cb(null, require('pages/register/employer').default)
    }
  },
  childRoutes: [{
    path: 'success/:emailAddress',
    getComponent: (location, cb) => {
      cb(null, require('pages/register/employer/success').default)
    }
  }]
});
