import React                  from 'react';
import MainView               from '../item-view';
import JobDetails             from 'components/job-details';
import PageContent            from 'components/page-content';
import PageSection            from 'components/page-section';
import ApplicationProfile     from 'components/application-profile';
import ApplicationForm        from 'containers/application-form';
import ApplicationStatusPanel from 'containers/application-status-panel';
import ApplicationChallenges  from 'containers/application-challenges';

const ApplicationViewStudent = ({
  application: {
    campaign,
    id,
    applicant_id
  }
}) => (
  <PageContent isFull>
    <ApplicationStatusPanel id = { id } />
    <PageSection isCta hasShadow>
      <JobDetails { ...campaign  } isHorizontal hasAvatar hasAddress />
    </PageSection>
    <PageSection title = "My Application">
      <ApplicationProfile { ...applicant_id } />
    </PageSection>
    <PageSection>
      <div className = "form__cards">
        <ApplicationForm id = { id } />
        { campaign.challenges && campaign.challenges.length > 0 &&
          <PageSection
            title    = "Challenges"
            subTitle = "Complete the Challenges below before submitting your Application."
            isFull >
            <ApplicationChallenges id = { id } />
          </PageSection>
        }
      </div>
    </PageSection>
  </PageContent>
);

export default MainView(ApplicationViewStudent);
