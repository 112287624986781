import './index.scss';
import React from 'react';

import Text from 'component-lib/type/text';
import { ProgressBarReadonly } from 'component-lib/components/progress-bar-readonly';

const BasecampProgressBar = ({ color, width, title }) => {
  return (
    <div className = "basecamp-progress-bar">
      <ProgressBarReadonly
        fillColor = { color }
        fillWidth = { width }
        progressBarHeight = "12px"
      />
      <div
        className = "basecamp-progress-bar__title-container"
        style = { { width: width } }
      >
        <Text
          text       = { title }
          fontSize   = '14px'
          fontWeight = 'light'
          color      = { color }
        />
      </div>
    </div>
  );
};

export default BasecampProgressBar;
