import { defined } from 'lib/helpers';
import Text        from 'lib/api/activities/generics/fields/text';

class Data {
  constructor({ state, appv2 }) {
    this._ourState       = defined(state);
    this._appv2          = defined(appv2);
    this._persistedState = {};

    this.initializeState = ({ profile }) => {
      const afterDataSet = () => {
        this.setAsPersistedState();
      };

      const initializeSummary = (summary) => {
        return summary ? new Text(summary) : Text.createEmpty();
      };

      const initializeStrapline = (strapline) => {
        return strapline ? new Text(strapline) : Text.createEmpty();
      };

      this._ourState.dataSet({
        profileId:     profile.get('id'),
        summary:       initializeSummary(profile.get('summary')),
        strapline:     initializeStrapline(profile.get('strapline')),
        isDataChanged: false,
      }, afterDataSet);
    };

    this.setAsPersistedState = () => {
      this._persistedState = this._ourState.dataGetAll();
    };

    this.resetToPersistedState = () => {
      this._ourState.dataSet(this._persistedState);
    };

    this.getIsDataChanged = () => {
      return this._ourState.dataGet({ id: 'isDataChanged' });
    };

    this.setIsDataChanged = (value) => {
      this._ourState.dataSet({
        isDataChanged: defined(value)
      });
    };

    this.getProfileId = () => {
      return this._ourState.dataGet({ id: 'profileId' });
    };

    this.getSummary = () => {
      return this._ourState.dataGet({ id: 'summary' }).value;
    };

    this.setSummary = (value) => {
      this._ourState.dataSet({
        summary:       new Text(value),
        isDataChanged: true,
      });
    };

    this.isSummaryDataPresent = () => {
      return !this._ourState.dataGet({ id: 'summary' }).isEmpty();
    };

    this.updateSummaryViaApi = () => {
      const profileId = this.getProfileId();
      const summary = new Text(this.getSummary());

      return this._appv2.api.profiles
        .updateSummary({ id: profileId, summary: summary });
    };

    this.getStrapline = () => {
      return this._ourState.dataGet({ id: 'strapline' }).value;
    };

    this.setStrapline = (value) => {
      this._ourState.dataSet({
        strapline:     new Text(value),
        isDataChanged: true,
      });
    };

    this.isStraplineDataPresent = () => {
      return !this._ourState.dataGet({ id: 'strapline' }).isEmpty();
    };

    this.updateStraplineViaApi = () => {
      const profileId = this.getProfileId();
      const strapline = new Text(this.getStrapline());

      return this._appv2.api.profiles
        .updateStrapline({ id: profileId, strapline: strapline });
    };

    this.refreshCurrentProfileLegacy = () => {
      this._appv2.auth.refreshCurrentProfileLegacy();
    };
  }
}

export default Data;
