import React       from 'react';
import MainView    from '../index';
import getForm     from 'components/form';
import FormPanel   from 'components/form-panel';
import { FORM_ID } from 'modules/accounts/constants';
import {
  FORM_PANELS,
  validate
} from 'modules/accounts/student/constants';

const Form = getForm({
  id: FORM_ID,
  enableReinitialize: true,
  validate
});

const StudentAccount = ({
  profile,
  editCard,
  saveCard,
  editingCards,
  handleSubmit
}) => (
  <Form
    onSubmit      = { handleSubmit }
    initialValues = { profile } >
    { FORM_PANELS.map(({ id, ...rest }) => (
      <FormPanel
        id           = { id }
        key          = { id }
        isEditing    = { editingCards.indexOf(id) > -1 }
        onEdit       = { editCard }
        onSave       = { saveCard }
        { ...rest } />
    ))}
  </Form>
);

export default MainView(StudentAccount);
