import React from 'react';

export default ({ className, value, placeholder, options }) => {
  const option = options && value && options.filter(v => v.value.toString() === value.toString())[0];

  return (
    <div className = { className }>
      { option ? option.title : placeholder }
    </div>
  )
}
