import EmailPage from 'pages/v2/register/student/email-page';

export default ({ appv2 }) => {
  return {
    path:         'register',
    title:        'Register (Email)',
    getComponent: ({ params }, cb) => {
      cb(null, EmailPage);
    },
  };
};
