import React                     from 'react';
import Box                       from 'components/box';
import FormMultiPage             from 'components/form-multipage';
import OnboardingDetailsEmployer from 'components/onboarding-details-employer';
import OnboardingPassword        from 'components/onboarding-password';
import OnboardingPhoto           from 'components/onboarding-photo';
import AvatarUploader            from 'components/avatar-uploader';

export default () => (
  <Box isCentered>
    <FormMultiPage pathname = "/welcome">
      <OnboardingPassword />
      <OnboardingDetailsEmployer />
      <OnboardingPhoto />
    </FormMultiPage>
    <AvatarUploader />
  </Box>
);
