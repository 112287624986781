import './form-subrow.scss';
import React from 'react';

export default ({ title, children }) => (
  <div className = "form__subrow">
    { title && <div className = "form__field__label">{ title }</div> }
    { children }
  </div>
);

