import './index.scss';
import React from 'react';
import classNames from 'classnames';

import PanelFormHeader                 from 'components/v2/panel/form-header';
import { ProfileSlugSectionSavedView } from 'components/v2/profile-slug-section-saved-view';
import { ProfileSlugSectionFormView }  from 'components/v2/profile-slug-section-form-view';

const SAVED_VIEW = 'saved-view';
const FORM_VIEW  = 'form-view';
const URL_DOMAIN = 'paddl.com/profile/';
// URL_DOMAIN is hardcoded instead of using ENV const `PADDL_URL` because `PADDL_URL` in dev, staging & prod are all different.

export class ProfileSlugSection extends React.Component {
  constructor() {
    super();

    this.state = {
      currentView: SAVED_VIEW,
    };
  }

  render() {
    const { appv2, visibility, profileSlug } = this.props;
    const { currentView } = this.state;

    const isSectionClickable = (currentView === SAVED_VIEW);

    const sectionClassNames = classNames(
      'v2-profile-slug-section',
      { 'v2-profile-slug-section--clickable': isSectionClickable }
    );

    const onSectionClick = () => {
      isSectionClickable && this.setState({ currentView: FORM_VIEW });
    };

    const renderCurrentView = () => {
      if (currentView === SAVED_VIEW) {
        return (
          <ProfileSlugSectionSavedView
            urlDomain = { URL_DOMAIN }
            profileSlug = { profileSlug }
            visibility = { visibility }
          />
        );
      }

      if (currentView === FORM_VIEW) {
        return (
          <ProfileSlugSectionFormView
            appv2 = { appv2 }
            urlDomain = { URL_DOMAIN }
            profileSlug = { profileSlug }
            onToggleCurrentView = { () => { this.setState({ currentView: SAVED_VIEW }); } }
          />
        );
      }

      throw Error(`Unexpected currentView: ${currentView}`);
    };

    return (
      <div className = { sectionClassNames } onClick = { onSectionClick }>
        <PanelFormHeader
          iconSrc     = { require('images/v2/icon-profile-url-globe.svg') }
          heading     = "Profile URL"
          description = "Personalise the URL for your Profile for easy sharing and discoverability."
        />

        { renderCurrentView() }
      </div>
    );
  }
}
