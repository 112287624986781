import React from 'react';

export default () => {
  return (
    <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M69.8581 139.976C108.44 139.976 139.716 108.642 139.716 69.9882C139.716 31.3348 108.44 0 69.8581 0C31.2765 0 0 31.3348 0 69.9882C0 108.642 31.2765 139.976 69.8581 139.976Z" transform="translate(0.14209 0.0236816)" fill="#F3F9FA"/>
      <path d="M70 0C31.3345 0 0 31.3928 0 70.1303C0 70.6752 0 71.1965 0.0236486 71.7414C0.875 33.7384 31.8784 3.19851 70 3.19851C108.122 3.19851 139.125 33.7384 139.976 71.7177C140 71.1965 140 70.6516 140 70.1066C140 31.3928 108.666 0 70 0Z" fill="#E9F0F2"/>
      <path opacity="0.25" d="M89.9831 15.5187L63.4493 0L0 62.3828L44.1993 90.3639C97.598 69.0168 90.054 16.8218 89.9831 15.5187Z" transform="translate(49.3076 45.5847)" fill="url(#paint0_linear)"/>
      <path d="M42.3547 0H0V42.244H42.3547V0Z" transform="translate(70.2837 45.5847)" fill="#CFE1E5"/>
      <path d="M20.4088 18.0301L10.2162 24.0481L0 18.0301V6.01794L10.2162 0L20.4088 6.01794V18.0301Z" transform="translate(82.2734 54.4457)" fill="white" stroke="white" strokeWidth="8" strokeMiterlimit="10"/>
      <path d="M0 0V24.4271L10.1926 18.0301V6.01794L0 0Z" transform="translate(92.4897 54.4457)" fill="#E6F2F5"/>
      <path d="M41.527 0H0V1.6111H41.527V0Z" transform="translate(31.6421 78.1384)" fill="#BFD9DD"/>
      <path d="M41.5034 0H0V1.6111H41.5034V0Z" transform="translate(71.2769 87.8051)" fill="#BFD9DD"/>
      <path d="M42.3547 0H0V42.244H42.3547V0Z" transform="translate(31.5474 35.8944)" fill="white"/>
      <path d="M8.01689 9.3823L10.3581 0L0 11.7042L5.67568 14.0498L3.33446 23.4321L12.6993 11.7042L8.01689 9.3823Z" transform="translate(46.2095 44.1868)" fill="#BFD9DD"/>
      <path d="M42.3547 0H0V42.244H42.3547V0Z" transform="translate(49.3076 66.6001)" fill="white"/>
      <path d="M24.4054 0H3.14527C1.39527 0 0 1.44525 0 3.2222V17.272C0 19.0489 1.75 20.1625 3.5 20.1625V24.901L12.8176 20.1625H24.4527C26.2027 20.1625 27.9527 19.4043 27.9527 17.7932V3.5539C27.9527 1.68218 28.1419 0 24.4054 0Z" transform="translate(56.6621 77.2144)" fill="#CFE1E5"/>
      <path d="M19.1791 0H0V1.08986H19.1791V0Z" transform="translate(60.8716 84.7013)" fill="white"/>
      <path d="M9.74325 0H0V1.08986H9.74325V0Z" transform="translate(60.8716 88.35)" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear" x2="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(76.4534 76.689) scale(31.6365 31.6954) rotate(-149.826)">
          <stop stopColor="#F3F9FA"/>
          <stop offset="1" stopColor="#D0E4EA"/>
        </linearGradient>
      </defs>
    </svg>
  );
};
