import './index.scss';
import React from 'react';

import { STUDENT } from 'lib/auth';
import { ACCESS_DENIED } from 'lib/paddl-error';

import SettingsPageTitle            from 'components/v2/settings/page-title';
import { ProfileSlugSection }       from 'components/v2/profile-slug-section';
import { ProfileVisibilitySection } from 'components/v2/profile-visibility-section';

class SettingsPreferencesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visibilitySettings: {},
      profilePath:        null,
      profileSlug:        null,
    };
  }

  componentDidMount() {
    const { appv2 } = this.props;
    const currentProfileId = appv2.auth.currentProfileId();

    if (appv2.auth.currentProfileRole() !== STUDENT) { // Redirect to not-found page if it's not a student account
      return appv2.ui.redirect({ url: '/not-found' }); // Remove this chunk after we have implemented and decided to show settings preferences page for employer
    }

    appv2.api.profiles
      .fetch({ id: currentProfileId, isLoggedIn: true })
      .then((profile) => {
        this.setState({ profileSlug: profile.get('urlSlug') });
      })
      .catch((error) => {
        if (error.isPaddlError === true && error.code === ACCESS_DENIED) {
          return appv2.ui.redirect({ url: '/not-found' });
        }

        throw error;
      });

    return appv2.api.visibilitySettings
      .fetch()
      .then((visibilitySettings) => {
        this.setState({ visibilitySettings });
      })
      .catch((error) => {
        if (error.isPaddlError === true && error.code === ACCESS_DENIED) {
          return appv2.ui.redirect({ url: '/not-found' });
        }

        throw error;
      });
  }

  render() {
    const { appv2 } = this.props;
    const { visibilitySettings, profileSlug } = this.state;

    const visibility = visibilitySettings.master ? 'Public' : 'Private';
    const currentProfileId = appv2.auth.currentProfileId();

    const onChange = (value) => {
      this.setState({ visibilitySettings: value });
      appv2.api.visibilitySettings.update({ data: value });
    };

    const profilePath = this.state.profilePath ||
      (
        appv2.slugs.generateProfilePath({
          id:       currentProfileId,
          callback: ({ path }) => { this.setState({ profilePath: path }); },
        })
      );

    return (
      <div className = "v2-settings-preferences-page">
        <div className = "v2-settings-preferences-page__page-wrapper">
          <SettingsPageTitle
            heading = "Preferences"
            subheading = "Use the settings below to customise your Paddl experience"
          />
          <ProfileSlugSection
            appv2 = { appv2 }
            visibility = { visibility }
            profileSlug = { profileSlug || currentProfileId }
          />
          <ProfileVisibilitySection
            visibilitySettings = { visibilitySettings }
            onChange = { onChange }
            publicProfileUrl = { profilePath + '?public=1' }
          />
        </div>
      </div>
    );
  }
}

export default SettingsPreferencesPage;
