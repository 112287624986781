import './challenges-list-item.scss';

import React                    from 'react';
import PropTypes                from 'prop-types';
import Card                     from 'components/card';
import ChallengeQuestionsHeader from 'components/challenge-questions-header';
import ChallengeQuestionsResult from 'components/challenge-questions-result';

const CHALLENGE_MODULES = {
  questions: {
    header: ChallengeQuestionsHeader,
    result: ChallengeQuestionsResult
  }
};

const ChallengesListItem = ({
  id,
  module,
  data,
  title,
  subline,
  score,
  isCompleted,
  onChallengeStart
}) => {
  if (!id || !module) return null;

  const Challenge = CHALLENGE_MODULES[module];

  return (
    <div className = "challenges-list__item" key = { title }>
      <Card
        title = "1st Challenge"
        extensionComponent = { isCompleted &&
          <Challenge.result
            revealResult = { !!score }
            data         = { data } />
        }>
        <Challenge.header
          id               = { id }
          title            = { title }
          score            = { score }
          onChallengeStart = { onChallengeStart }
          isCompleted      = { isCompleted }
          subTitle         = { subline } />
      </Card>
    </div>
  );
};

export default ChallengesListItem;

ChallengesListItem.propTypes = {
  id: PropTypes.string,
  module: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.array,
  title: PropTypes.string,
};
