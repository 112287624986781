import './input-autocomplete.scss';

import React         from 'react';
import { Creatable } from 'react-select';

const hasTouchSupport  = ('ontouchstart' in window)
  || (navigator.maxTouchPoints > 0)
  || (navigator.msMaxTouchPoints > 0);

export default class Autocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  handleChange(result) {
    this.props.onComplete();
    this.props.onChange(result);
  }
  handleKeyDown(e) {
    if (e.nativeEvent.keyCode === 13) {
      e.preventDefault();

      return false;
    }
  }
  render() {
    const {
      name,
      addon,
      value,
      options,
      placeholder
    } = this.props;

    return (
      <div className = "input__autocomplete">
        { addon !== null &&
          <div className = "input__autocomplete__addon">
            { addon }
          </div>
        }
        <Creatable {...{
          onChange: this.handleChange,
          onInputKeyDown: this.handleKeyDown,
          labelKey: 'title',
          className: 'input__autocomplete',
          clearable: true,
          autofocus: !hasTouchSupport,
          shouldKeyDownEventCreateNewOption: () => { return false },
          promptTextCreator: label => `Use ${label}`,
          options: options,
          placeholder,
          name,
          value
        }} />
      </div>
    );
  }
}
