import React from 'react';

import VariantMapping from 'lib/api/variant-mapping';
import { findCategoryIdBy, getCategoryData } from 'lib/api/categories';
import { shuffle } from 'lib/helpers';

import MobileSwiper                   from 'components/v2/mobile-swiper';
import ActivityCardSuggestionListItem from 'component-lib/components/activity-card-suggestion-list-item';

const NUMBER_OF_CARDS = 3;

class ActivityCardSuggestionList extends React.Component {
  constructor(props) {
    super(props);

    this._hiddenVariantIds = [];

    this.state = {
      toBeRenderedVariantIds: [],
    };

    this._isVariantAlreadyInList = ({ variantId, activities }) => {
      if (variantId === null) throw Error('variantId is null');
      if (activities === null) return false;

      const index = activities.findIndex(activity =>
        activity.get('variantId') === variantId
      );

      return (index !== -1);
    };

    this._getFilterVariantIds = ({ variantIds, activities }) => {
      const oneOffSuggestionVariantIds = [1, 2, 7]; // Bachelor, Master & High School should be removed from suggestion list if they are already in activities

      const availableVariantIds = variantIds.filter(variantId => {
        const isVariantOneOffSuggestion = oneOffSuggestionVariantIds.includes(variantId);
        const shouldBeExcludedVariantId = this._isVariantAlreadyInList({ variantId, activities }) && isVariantOneOffSuggestion;

        return !shouldBeExcludedVariantId;
      });

      const visibleVariantIds = availableVariantIds.filter((id) => {
        return !this._hiddenVariantIds.includes(id);
      });

      const missingNumberOfCards = NUMBER_OF_CARDS - visibleVariantIds.length;

      if (missingNumberOfCards > 0) {
        // this `splice` mutates `hiddenVariantIds`
        const oldestHiddenVariants = this._hiddenVariantIds.splice(0, missingNumberOfCards);

        visibleVariantIds.push(...oldestHiddenVariants);
      }

      return visibleVariantIds;
    };

    this._getToBeRenderedVariantIds = ({ variantIds, activities, listShouldBeFilteredAndShuffled }) => {
      const filteredVariantIds = this._getFilterVariantIds({ variantIds: variantIds, activities: activities });

      return listShouldBeFilteredAndShuffled
        ? shuffle(filteredVariantIds).slice(0, NUMBER_OF_CARDS)
        : variantIds.slice(0, NUMBER_OF_CARDS);
    };
  }

  componentDidMount() {
    const {
      variantIds,
      activities,
      listShouldBeFilteredAndShuffled,
    } = this.props;

    const toBeRenderedVariantIds = this._getToBeRenderedVariantIds({ variantIds, activities, listShouldBeFilteredAndShuffled });

    this.setState({ toBeRenderedVariantIds: toBeRenderedVariantIds });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      variantIds,
      activities,
      listShouldBeFilteredAndShuffled,
    } = this.props;

    if (activities === null && prevProps.activities === null) {
      return;
    }

    const areActivitiesTheSame =
      activities &&
      prevProps.activities &&
      activities.every((activity, index) => {
        const prevActivity = prevProps.activities[index];
        return prevActivity && prevActivity.get('id') === activity.get('id');
      });

    if (areActivitiesTheSame) {
      return;
    }

    const toBeRenderedVariantIds = this._getToBeRenderedVariantIds({ variantIds, activities, listShouldBeFilteredAndShuffled });

    this.setState({ toBeRenderedVariantIds: toBeRenderedVariantIds });
  }

  render() {
    const {
      appv2,
      variantIds,
      activities,
      containerClassName,
      itemShouldBeDisabledIfInList,
      isHidingEnabled,
    } = this.props;

    const { toBeRenderedVariantIds } = this.state;

    const renderActivityCardSuggestionListItem = (variantId) => {
      const alreadyExists = itemShouldBeDisabledIfInList && this._isVariantAlreadyInList({ variantId, activities });
      const variantHandler = VariantMapping.getFor({ variantId: variantId });
      const categoryData = getCategoryData({ categoryId: findCategoryIdBy({ variantId }) });

      const onClick = () => {
        return appv2.ui.openNewActivityModal({ withLeftOffset: false, variantId });
      };

      const onHide = () => {
        if (this._hiddenVariantIds.indexOf(variantId) !== -1) {
          return;
        }

        this._hiddenVariantIds.push(variantId);

        const replacementId = this._getFilterVariantIds({
          variantIds: variantIds.filter((id) => (!this.state.toBeRenderedVariantIds.includes(id))),
          activities: activities,
        })[0]; // getting the first available variant out of the list

        this.setState({
          toBeRenderedVariantIds: this.state.toBeRenderedVariantIds.map((id) => (id === variantId ? replacementId : id)),
        });
      };

      return (
        <ActivityCardSuggestionListItem
          key                  = { variantId }
          hasCheckmark         = { alreadyExists }
          isClickable          = { !alreadyExists }
          onClick              = { onClick }
          variantIcon          = { variantHandler.icon() }
          variantTitle         = { variantHandler.title() }
          variantSubtitle      = { variantHandler.subtitle() }
          categoryTitle        = { categoryData.title() }
          categoryTitleColor   = { categoryData.themeColor() }
          footerBorderTopColor = { categoryData.themeColorL() }
          onHide               = { isHidingEnabled && onHide }
        />
      );
    };

    return (
      <MobileSwiper
        wrapperClassName = "activity-card-suggestion-list"
        containerClassName = { containerClassName }
      >
        {
          toBeRenderedVariantIds.map((variantId) => {
            return renderActivityCardSuggestionListItem(variantId);
          })
        }
      </MobileSwiper>
    );
  };
};

export default ActivityCardSuggestionList;
