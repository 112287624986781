import React from 'react';
import Cookies from 'cookies-js';

import AccessTitles from 'components/v2/register/AccessTitles/AccessTitles';
import CheckImage from 'components/v2/svg-icons/svg-icon-slim-check';

import './index.scss';

class EmailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      errorMessage: null,
    };
  }

  componentDidMount() {
    window.setTimeout(() => {
      this._emailInput.focus();
    }, 0);
  }

  render() {
    const { appv2 } = this.props;
    const { email, errorMessage } = this.state;

    const emailNotValid = (email.match(/^.+@.+$/) === null);

    const onEmailChange = (value) => {
      this.setState({
        email: value,
        errorMessage: null,
      });
    };

    const onSubmit = (event) => {
      event.preventDefault();

      appv2.api.profiles
        .registerEmail({ email })
        .then((success) => {
          if (success) {
            Cookies.set('v2-registered-email', email, { expires: 365 * 24 * 3600, path: '/' });
            return appv2.ui.redirect({ url: '/register/feedback' });
          }

          this.setState({
            errorMessage: 'Cannot register with this email address',
          });
        });
    };

    return (
      <div className="v2-register-student">
        <div className="v2-register-student__info">
          <AccessTitles
            heading="Start your Paddl journey now"
            subheading="You're just moments away from gathering your experience..."
          />
        </div>

        <form className="v2-register-student__form" onSubmit={onSubmit}>
          <input
            ref={(elem) => { this._emailInput = elem; }}
            className="v2-register-student__form-control"
            type="email"
            value={email}
            placeholder="Enter your email"
            onChange={(event) => { onEmailChange(event.target.value); }}
          />
          <div className="v2-register-student__form-btn-wrapper">
            <button
              className="v2-register-student__form-btn"
              type="submit"
              disabled={errorMessage || emailNotValid}
            >
              <span className="v2-register-student__form-btn-content">
                <span className="v2-register-student__form-btn-icon-wrapper">
                  <span className="v2-register-student__form-btn-icon-dot" />
                  <span className="v2-register-student__form-btn-icon-dot" />
                  <span className="v2-register-student__form-btn-icon-dot" />

                  <div className="v2-register-student__form-btn-check-image">
                    <CheckImage isAnimated withShortDelay width="25px" height="25px" />
                  </div>
                </span>
                <span className="v2-register-student__form-btn-title">
                  <span className="v2-register-student__form-btn-title-hide">
                    Let’s
                  </span>
                  { ' Go' }
                </span>
              </span>
            </button>

            <div className="v2-register-student__form-btn-tooltip">
              Enter your valid email address to get started
            </div>
          </div>
        </form>

        { errorMessage &&
          <div className="v2-register-student__message-error">
            { errorMessage }
          </div>
        }

        <div className="v2-register-student__login">
          <span className="v2-register-student__login-text">
            Already using Paddl?
          </span>
          <a className="v2-register-student__login-link" href="/login">
            Login
          </a>
        </div>
      </div>
    );
  }
}

export default EmailPage;
