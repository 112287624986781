import React from 'react';

const TransitionWrapper = ({ itemStyles, module }) => Component => ({
  id,
  data,
  style
}) => {
  if (itemStyles) {
    return (
      <div
        key       = { data.id }
        className = "list-panel__wrapper"
        style     = { itemStyles(style) } >
        <Component
          id     = { data.id }
          module = { module } />
      </div>
    );
  }

  return (
    <Component
      key    = { id }
      id     = { id }
      module = { module } />
  );
};

export default TransitionWrapper;
