import React              from 'react';
import Box                from 'components/box';
import FormMultiPage      from 'components/form-multipage';
import OnboardingDetails  from 'components/onboarding-details-student';
import OnboardingPassword from 'components/onboarding-password';
import OnboardingPhoto    from 'components/onboarding-photo';
import AvatarUploader     from 'components/avatar-uploader';

export default ({ params: { stepId }}) => (
  <Box isCentered>
    <FormMultiPage
      currentPage = { stepId }
      pathname    = "/welcome">
      <OnboardingDetails />
      <OnboardingPassword />
      <OnboardingPhoto />
    </FormMultiPage>
    <AvatarUploader />
  </Box>
);
