import './index.scss';
import React from 'react';

import ImgTag                       from 'component-lib/components/img-tag';
import Text                         from 'component-lib/type/text';
import { style26pxMediumBluegreyD } from 'component-lib/type/text/styles';
import { style16pxLightGrey }       from 'component-lib/type/text/styles';

const BasecampCardEmptyView = () => {
  const iconSrc = require('component-lib/artwork/basecamp.svg');
  const title = 'What are you working on?';
  const subtitle =
    <div>
      You don’t have any Active Cards!<br/>
      Add activities you’re working on to earn XP every day.
    </div>;

  return (
    <div className = "basecamp-card-empty-view">
      <ImgTag width = '208px' src = { iconSrc } />
      <Text { ...style26pxMediumBluegreyD } text = { title } />
      <Text { ...style16pxLightGrey } text = { subtitle } />
    </div>
  );
}

export default BasecampCardEmptyView;
