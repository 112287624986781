import { CALL_API }       from 'middleware/call-api';
import { updateSettings } from 'modules/ui';

const TOGGLE        = 'guide/TOGGLE';
const CLOSE         = 'guide/CLOSE';
export const GOTO_STEP     = 'guide/GOTO_STEP';
const GOTO_TOUR     = 'guide/GOTO_TOUR';
const FETCH_REQUEST = 'guide/FETCH_REQUEST';
const FETCH_SUCCESS = 'guide/FETCH_SUCCESS';
const FETCH_FAILURE = 'guide/FETCH_FAILURE';

const initialState = {
  isOpen: false,
  name  : '',
  steps : [],
  tours : []
};

export default (state = initialState, action) => {
  switch (action.type) {
  case TOGGLE:
    return {
      ...state,
      isOpen: !state.isOpen
    };
  case CLOSE:
    return {
      ...state,
      isOpen: false
    };
  case GOTO_STEP:
    return {
      ...state
    };
  case FETCH_SUCCESS:
    return {
      ...state,
      ...action.payload[0],
    };
  default: { return state; }
  }
};

function closeTourGuide() {
  return {
    type: CLOSE
  };
}

export function close() {
  return ({ dispatch, getState }) => {
    const state        = getState();
    const isComplete   = state.guide.steps.filter(v => !v.completed).length === 0;
    const isFirstClose = state.ui.open_tour_guide_on_load;

    if (isComplete) {
      dispatch(updateSettings({ json: { show_tour_guide_in_sidebar: false }}));
    }

    if (isFirstClose) {
      dispatch(updateSettings({ json: { open_tour_guide_on_load: false }}));
    }

    dispatch(closeTourGuide());
  };
}

export function toggle() {
  return {
    type: TOGGLE
  };
}

export function goToStep({ path }) {
  return {
    type: GOTO_STEP,
    payload: { path },
    meta: {
      redirect: path
    }
  };
}

export function goToTour({ path }) {
  return {
    type: GOTO_TOUR,
    payload: { path },
    meta: {
      redirect: path
    }
  };
}

export function fetch() {
  return {
    [CALL_API]: {
      types: [
        FETCH_REQUEST,
        FETCH_SUCCESS,
        FETCH_FAILURE
      ],
      method: 'GET',
      endpoint: '/guides'
    }
  };
}
