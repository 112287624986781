const SHARE_BUTTON = 'share/SHARE_BUTTON'

export function shareButtonAction(job, share_type) {
  return {
    type: SHARE_BUTTON,
    payload: {
      campaign_name: job.title,
      campaign_id:   job.id,
      employer_name: job.employer.business_name,
      employer_id:   job.employer.id,
      share_type:    share_type
    }
  };
}
