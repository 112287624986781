import ThanksPage from 'pages/v2/register/student/thanks-page';

export default ({ appv2 }) => {
  return {
    path:         'register/feedback/thanks',
    title:        'Register (Thanks)',
    getComponent: ({ params }, cb) => {
      cb(null, ThanksPage);
    },
  };
};
