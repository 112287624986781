import './form-card.scss';

import React     from 'react';
import isArray   from 'lodash/isArray';
import Card      from 'components/card';
import Button    from 'components/button';
import SvgIcon   from 'components/svg-icon';
import FormRow   from 'components/form-row';
import FormField from 'components/form-field';
import {
  SortableHandle
} from 'react-sortable-hoc';

const DragHandle  = SortableHandle(() => <SvgIcon id = "drag" className = "card__action--drag" />);

export default class FormCard extends React.Component {
  constructor(props) {
    super(props);

    this.handleSave    = this.handleSave.bind(this);
    this.handleEdit    = this.handleEdit.bind(this);
    this.handleDelete  = this.handleDelete.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  handleSave()   { this.props.onSave({ id: this.props.id }); }
  handleEdit()   { this.props.onEdit({ id: this.props.id }); }
  handleDelete() { this.props.onDelete({ index: this.props.index }); }
  handleKeyDown({ keyCode, target = {} }) {
    if (keyCode === 13 && target.type !== 'textarea') {
      this.handleSave();
    }
  }
  render() {
    const {
      isEditing,
      isEditingLive,
      views,
      children,
      title,
      description,
      values,
      errors,
      id,
      type,
      name,
      onEdit,
      onDelete,
      disabled,
      fields,
      draggable,
      modifiers
    } = this.props;

    const viewMode = isEditing ? 'edit' : 'default';
    const View     = views && views[viewMode];

    return (
      <Card
        title       = { title }
        description = { description }
        onKeyDown   = { this.handleKeyDown }
        className   = { isEditing ? 'is--editing': '' }
        hasActions
        { ...modifiers } >
        { !disabled && <div className = "card__actions">
          { draggable && <DragHandle />}
          { onEdit && isEditing &&
            <Button
              className = "card__action--bottom is--rounded is--action is--save"
              icon      = "confirm"
              disabled  = { !!errors }
              label     = "Save"
              onClick   = { this.handleSave } />
          }
          { onEdit && !isEditing &&
            <Button
              className = "card__action--bottom is--rounded is--edit"
              icon      = "pencil"
              label     = "Edit"
              onClick   = { this.handleEdit } />
          }
          { onDelete &&
            <Button
              className = "card__action--top is--blank is--delete"
              icon      = "close"
              onClick   = { this.handleDelete } />
          }
          </div>
        }
        { View && <View { ...{ values, errors, id, type, name, isEditingLive } } /> }
        { (children || fields) &&
          <div className = "card__content">
            { fields && fields.map((items, i) => (
              isArray(items)
                ? <FormRow key = { i }>
                    { items.map((props, j) => <FormField key = { j } { ...{ ...props, isEditing } } /> )}
                  </FormRow>
                : <FormField key = { i } { ...{ ...items, isEditing } } />
            ))}
            { children }
          </div>
        }
      </Card>
    )
  }
}
