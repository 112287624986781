import './index.scss';
import React from 'react';

const PanelFormHeader = ({ iconSrc, heading, description }) => {
  return (
    <div className = "v2-panel-form-header">
      <div className = "v2-panel-form-header__img-wrapper">
        <img className = "v2-panel-form-header__img" src = { iconSrc } alt = "" />
      </div>
      <div className = "v2-panel-form-header__text">
        <h4 className = "v2-panel-form-header__heading">{ heading }</h4>
        <div className = "v2-panel-form-header__description">{ description }</div>
      </div>
    </div>
  );
};

export default PanelFormHeader;
