import './ineligible-to-apply-modal.scss';

import React              from 'react';
import Modal              from 'components/modal';
import Button             from 'components/button';
import { browserHistory } from 'react-router';

const helpURL = 'https://help.paddljobs.com/student-and-graduate-help/job-matching-and-course-tags';

const InelibleToApplyModal = ({
  isOpen,
  onClose,
  ctaLink
}) => {
  return(
    <Modal
      icon = "alert"
      onClose = { onClose }
      isOpen  = { isOpen }>
        <div className = "modal__title">Bummer!</div>
        <p>It appears your Course doesn't match <br />
        up with this opportunity. </p>
        <div className = "ineligible-modal">
          <div className = "modal__actions">
            <Button
              onClick = { ctaLink && (() => browserHistory.push(ctaLink)) }
              isBlock
              isAction>
              Back to Jobs
            </Button>
          </div>

          <a className = "modal__help" href = { helpURL } target="_blank">What does this mean?</a>
        </div>
    </Modal>
  )
};

export default InelibleToApplyModal;
