import React                         from 'react';
import { browserHistory }            from 'react-router';
import Moment                        from 'lib/helpers/moment';
import MainView                      from '../item-view';
import Markdown                      from 'components/markdown';
import Button                        from 'components/button';
import PageContent                   from 'components/page-content';
import ProfileInfo                   from 'components/profile-info';
import PageListNav                   from 'components/page-list-nav';
import PageSection                   from 'components/page-section';
import PageTitle                     from 'components/page-title';
import PageActions                   from 'components/page-actions';
import Card                          from 'components/card';
import PageSectionInfoItems          from 'components/page-section-info-items';
import ApplicationTabs               from 'components/application-tabs';
import ApplicationChallenges         from 'containers/application-challenges';
import Modal                         from 'components/modal';
import ApplicationCallToAction       from 'components/application-call-to-action';
import UnsuccessfulApplicationButton from 'components/v2/buttons/circle-buttons/shortlist-application-button';
import ShortlistApplicationButton    from 'components/v2/buttons/circle-buttons/unsuccessful-application-button';
import ProfileExtra                  from 'lib/api/profiles/profile-extra';

const CONFIRM_MODAL_ID = 'CONFIRM';
const REJECT_MODAL_ID  = 'REJECT';

class ApplicationViewEmployer extends React.Component {
  constructor(props) {
    super(props);

    this.handleShortlist          = this.handleShortlist.bind(this);
    this.handleReject             = this.handleReject.bind(this);
    this.handleConfirmModalToggle = this.handleConfirmModalToggle.bind(this);
    this.handleRejectModalToggle  = this.handleRejectModalToggle.bind(this);

    this.state = {
      profileExtra: ProfileExtra.createEmpty(),
    };
  }

  componentDidMount() {
    const { appv2, application } = this.props;

    const profileId = application.applicant_id.id;

    appv2.api.profiles
      .fetchWithExtraByIdOrSlug({ idOrSlug: profileId, isLoggedIn: true })
      .then(({ profileExtra }) => {
        this.setState({ profileExtra });
      });
  }

  handleShortlist() {
    const {
      shortlist,
      toggleModal,
      application: { id },
    } = this.props;

    toggleModal(CONFIRM_MODAL_ID);
    shortlist([id]);
  }

  handleReject() {
    const {
      toggleModal,
      reject,
      application: { id },
    } = this.props;

    toggleModal(REJECT_MODAL_ID);
    reject([id]);
  }

  handleConfirmModalToggle() {
    this.props.toggleModal(CONFIRM_MODAL_ID);
  }

  handleRejectModalToggle() {
    this.props.toggleModal(REJECT_MODAL_ID);
  }

  render() {
    const {
      openedModals,
      application: {
        id,
        cover_note,
        campaign,
        applicant_email,
        received_at,
        status: {
          status
        },
        scores,
        applicant_id: {
          id: applicantId,
          role,
          full_name,
          course,
          educator,
          mobile_number,
          skype_handle,
          photo_url,
        },
      },
      appv2,
    } = this.props;

    const { profileExtra } = this.state;

    const applications        = campaign.application_ids.map(v => v.id);
    const currentIndex        = applications.indexOf(id);
    const nextApplicaton      = applications[currentIndex + 1];
    const prevApplicaton      = applications[currentIndex - 1];
    const locationInShortText = profileExtra.get('location').shortText;

    return (
      <PageContent isFull>
        <PageListNav
          title       = { campaign.title }
          currentItem = { currentIndex + 1 }
          itemsLength = { applications.length }
          onBackClick    = { prevApplicaton && (() => browserHistory.push(`/applications/${prevApplicaton}`)) }
          onForwardClick = { nextApplicaton && (() => browserHistory.push(`/applications/${nextApplicaton}`)) }
        />

        <PageActions>
          <ShortlistApplicationButton onClick = { this.handleRejectModalToggle } />
          <UnsuccessfulApplicationButton onClick = { this.handleConfirmModalToggle } />
        </PageActions>

        <ProfileInfo
          id        = { applicantId }
          role      = { role }
          title     = { full_name }
          subTitle  = { locationInShortText }
          onClick   = { () => browserHistory.push(appv2.slugs.generateProfilePath({ id: applicantId }) + '/experience') }
          photo_url = { photo_url }
        />

        { status === 'shortlisted' &&
          <PageSection isPrimary isStacked>
            <PageSectionInfoItems
              items = { [{
                title: 'Mobile',
                icon: 'phone',
                value: mobile_number
              }, {
                title: 'Email',
                icon: 'envelope',
                value: applicant_email
              }, {
                title: 'Skype',
                icon: 'skype',
                value: skype_handle
              }] }
            />
          </PageSection>
        }

        { status !== 'shortlisted' &&
          <PageSection isPrimary isStacked>
            <ApplicationCallToAction
              onClick = { this.handleConfirmModalToggle }
              status = { status }
            />
          </PageSection>
        }

        <PageSection isCta isStacked>
          <ApplicationTabs
            scores   = { scores }
            course   = { course }
            educator = { educator }
          />
        </PageSection>
        <PageSection>
          <div className = "cards">
            <PageTitle>Application</PageTitle>
            <h3 className = "page__subtitle">
              Received: <strong>{ Moment.format_L(new Date(received_at)) }</strong>
            </h3>
            <Card>
              <div style = { { padding: '0 24px', margin: '-16px 0'} }>
                <Markdown>
                  { cover_note }
                </Markdown>
              </div>
            </Card>
            <ApplicationChallenges id = { id } />
          </div>
        </PageSection>
        { status === 'sent' &&
          <PageActions type = "bottom">
            <Button
              onClick = { this.handleRejectModalToggle }
              isLink
            >
              Not Suitable
            </Button>
            <Button
              onClick = { this.handleConfirmModalToggle }
              isSuccess
            >
              Shortlist
            </Button>
          </PageActions>
        }
        <Modal
          icon = "shortlist"
          onClose = { this.handleConfirmModalToggle }
          action = {
            <Button
              type = "button"
              onClick = { this.handleShortlist }
              isSuccess
              isBlock
              hasShadow
            >
              Confirm Shortlist
            </Button>
          }
          isOpen = { openedModals.indexOf(CONFIRM_MODAL_ID) > -1 }
          isLarge
          hasCancel
        >
          <div className = "modal__title">Shortlisting</div>
          <p><strong>{ full_name }</strong> will be moved to Shortlisted.</p>
          <p>This will reveal the applicant's contact details and they will be notified that they have been Shortlisted.</p>
        </Modal>
        <Modal
          icon = "unsuccessful"
          onClose = { this.handleRejectModalToggle }
          action = {
            <Button
              type = "button"
              onClick = { this.handleReject }
              isPrimary
              isBlock
              hasShadow
            >
              Confirm Unsuccessful
            </Button>
          }
          isOpen = { openedModals.indexOf(REJECT_MODAL_ID) > -1 }
          isLarge
          hasCancel
        >
          <div className = "modal__title">Unsuccessful</div>
          <p><strong>{ full_name }</strong> will be moved to Unsuccessful.</p>
          <p>The Applicant will be notified they have been unsuccessful and presented alternative jobs.</p>
        </Modal>
      </PageContent>
    );
  }
}

export default MainView(ApplicationViewEmployer);
