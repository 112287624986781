import './card-extension.scss';
import React  from 'react';
import Button from 'components/button';

class CardExtension extends React.Component {
  constructor() {
    super();

    this.state = {
      isExpanded: false
    };

    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }

  render() {
    const { isExpanded } = this.state;
    const {
      expandLabelText = 'Show',
      collapseLabelText = 'Hide'
    } = this.props;

    return (
      <div className = { `card__extension ${isExpanded ? 'is--expanded' : ''}` }>
        <div className = "card__extension__body">
          {this.props.children}
        </div>
        <Button
          className = "card__extension__button"
          onClick = { this.handleToggle }
          isBlock>
          <span className = "card__extension__button__label">
            { `${isExpanded ? collapseLabelText : expandLabelText}` }
          </span>
        </Button>
      </div>
    );
  }
}
export default CardExtension;
