import './job-employer-details.scss';

import PropTypes from 'prop-types';
import React     from 'react';
import { Link }  from 'react-router';
import Avatar    from 'components/avatar';
import Markdown  from 'components/markdown';

class JobEmployerDetails extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      profilePath:   null,
    };
  }

  render() {
    const { employer } = this.props;

    const {
      business_name,
      description,
      photo_url,
      id,
      role
    } = employer;

    const profilePath = this.state.profilePath ||
      (
        window._appv2.slugs.generateProfilePath({
          id:       id,
          callback: ({ path }) => { this.setState({ profilePath: path }); },
        })
      );

    const profileUrl = profilePath + '/summary';

    return (
      <div className = "job-employer-details">
        <div className = "job-employer-details__employer">
          <div className = "job-employer-details__employer__logo">
            <Avatar
              id   = { id }
              role = { role }
              url  = { photo_url }
              isLinked />
          </div>
          <div className = "job-employer-details__employer__name">
            About&nbsp;
            <Link to = { profileUrl } >
              { business_name }
            </Link>
          </div>
        </div>
        <div className = "job-employer-details__description">
          <Markdown>
            { description || '-' }
          </Markdown>
          <Link
            className = "job-employer-details__view-profile"
            to        = { profileUrl } >
            View Employer profile
          </Link>
        </div>
      </div>
    );
  }
}

JobEmployerDetails.propTypes = {
  business_name: PropTypes.string,
  description: PropTypes.string,
  photo_url: PropTypes.string,
  id: PropTypes.number,
  role: PropTypes.string
};
export default JobEmployerDetails;
