import './page-panel-stats.scss';

import React              from 'react';
import PagePanelStatsItem from 'components/page-panel-stats-item';

const PagePanelStats = ({
  stats
}) => {
  if (!stats) return null;
  return (
    <div className = "page-panel__stats">
      { stats.map(PagePanelStatsItem) }
    </div>
  );
};
export default PagePanelStats;
