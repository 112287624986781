import './page-panel-stats-item.scss';

import React from 'react';

const PagePanelStatsItem = ({
  value,
  label,
  isDefault
}, index) => {
  if (!value) return null;

  return (
    <div key = { index } className = "page-panel__stats__item">
      <span
        className = { `page-panel__stats__indicator ${isDefault ? ' is--default' : ''} ` }>
        { value }
      </span>
      <span className = "page-panel__stats__label">
        { label }
      </span>
    </div>
  );
};

export default PagePanelStatsItem;
