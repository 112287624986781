import React from 'react';
import Card  from 'components/card';

export default ({ params }) => (
  <Card className = "has--pattern" isCentered>
    <img
      className = "card__heroimg"
      style     = {{ marginTop: '-80px'}}
      src       = { require('images/icon--register-success.svg') } />
    <div className = "card__msg is--success">
      Success! Check <span className = "emailAddress">{ params.emailAddress }</span> for the invitation.
    </div>
  </Card>
)
