import { combineReducers } from 'redux'
import { CALL_API }        from 'middleware/call-api';
import { jobSchema }       from 'schemas';
import createJobReducer    from './create';
import createReducer       from 'utils/create-reducer';
import {
  COUPON_SUCCESS,
  CREATE_PAYMENT_SUCCESS
} from 'modules/payments';

const  FETCH_REQUEST  = 'jobs/FETCH_REQUEST';
const  FETCH_SUCCESS  = 'jobs/FETCH_SUCCESS';
const  FETCH_FAILURE  = 'jobs/FETCH_FAILURE';

const jobsReducer = (state = {}, action) => {
  switch (action.type) {
  case COUPON_SUCCESS:
    return {
      ...state,
      [action.meta.job.id]: {
        ...state[action.meta.job.id],
        ...{ stripe_order: action.payload }
      }
    };
  case CREATE_PAYMENT_SUCCESS:
    return {
      ...state,
      [action.meta.job.id]: {
        ...state[action.meta.job.id],
        ...{ status: 'open' }
      }
    };
  default: return state;
  }
};

export function fetchJob(id) {
  return {
    [CALL_API]: {
      types: [
        FETCH_REQUEST,
        FETCH_SUCCESS,
        FETCH_FAILURE
      ],
      endpoint: id ? `/public/campaigns/${id}` : '/jobs',
      schema: jobSchema
    }
  };
}

export default combineReducers({
  create: createJobReducer,
  entities: createReducer('jobs', jobsReducer)
});

