import { date  } from 'utils/normalize';
import { TYPES } from 'modules/forms/constants';
import {
  createValidations, required, minLength, maxLength
} from 'utils/validations';

const FORM_VALIDATIONS = {};

export const validate    = createValidations(FORM_VALIDATIONS);
export const FORM_PANELS = ({ industries }) => [{
  id: 'details',
  icon: 'tent',
  title: 'Company Details',
  description: 'Manage this information to help us personalise your experience with Paddl. This information will also display on your company profile.',
  fields: [
    [{
      name: 'business_name',
      type: TYPES.TEXT,
      label: 'Company Name',
       placeholders: {
        input: 'Specify your company name',
        default: '-'
      },
    }],
    [{
      name: 'website_url',
      type: TYPES.TEXT,
      label: 'Website URL',
       placeholders: {
        input: 'http://company.url',
        default: '-'
      },
    }],
    [{
      name: 'company_type',
      type: TYPES.SELECT,
      label: 'Company Type',
      placeholders: {
        input: 'Select your company type',
        default: 'Unselected'
      },
      options: [
        { value: 'public_company', title: 'Public Company' },
        { value: 'educational', title: 'Educational' },
        { value: 'self_employed', title: 'Self Employed' },
        { value: 'government_agency', title: 'Government Agency' },
        { value: 'non_profit', title: 'Non Profit' },
        { value: 'self_owned', title: 'Self Owned' },
        { value: 'partnership', title: 'Partnership' },
      ]
    }, {
      name: 'industry',
      type: TYPES.SELECT,
      label: 'Primary Industry',
      placeholders: {
        input: 'Select your primary industry',
        default: 'Unselected'
      },
      options: industries
    }]
  ]
}, {
  id: 'contact',
  icon: 'envelope',
  title: 'Contact Details',
  description: 'Your company contact details help applicants get in touch when they’re successful. They also help Paddl keep you up-to-date with product updates and notifications.',
  fields: [
    [{
      name: 'business_phone',
      type: TYPES.TEXT,
      label: 'Mobile Number',
      placeholders: {
        input: '+00',
        default: '-'
      }
    }, {
      name: 'skype_handle',
      type: TYPES.TEXT,
      label: 'Skype Handle',
      placeholders: {
        input: 'Your Skype account',
        default: '-'
      }
    }],
    [{
      name: 'mail_address',
      type: TYPES.TEXT,
      label: 'Mail Address',
      placeholder: '-'
    }]
  ]
}]
