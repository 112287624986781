import './index.scss';
import React from 'react';

import Text                          from 'component-lib/type/text';
import { style20pxRegularBluegreyD } from 'component-lib/type/text/styles';
import { style20pxRegularBluegrey }  from 'component-lib/type/text/styles';
import { style20pxRegularBluegreyL } from 'component-lib/type/text/styles';

const BasecampContentNav = ({ totalBasecampCardsCount, xpReadyBasecampCardsCount }) => {
  const cardStatisticsCopy = (totalBasecampCardsCount > 0)
    ? `${xpReadyBasecampCardsCount}/${totalBasecampCardsCount}`
    : 0;

  return (
    <div className = "basecamp-content-nav">
      <div className = "basecamp-content-nav__tab basecamp-content-nav__tab--active">
        <Text { ...style20pxRegularBluegreyD } text = 'Active Cards' />
        <Text { ...style20pxRegularBluegrey } text = { cardStatisticsCopy } />
      </div>
      {
        // Remove `{ false && ... }` to display Achievments tab when contents for Achievments tab are ready
        false &&
        <div className = "basecamp-content-nav__tab">
          <Text { ...style20pxRegularBluegrey } text = 'Achievments' />
          <Text { ...style20pxRegularBluegreyL } text = '4' />
        </div>
      }
    </div>
  );
}

export default BasecampContentNav;
