const track = ({
  eventAction,
  metadata = {},
  roles = ['student', 'employer', undefined],
}) => ({
  eventFields: (
    {
      payload = {},
      meta: { segment: { data } = {} } = {},
    },
    { auth },
  ) => ({
    hitType: 'event',
    role: payload.role || auth.role,
    // eslint-disable-next-line no-use-before-define
    profileType: profileType(payload.role || auth.role),
    eventAction,
    ...metadata,
    ...data,
  }),
  eventSchema: {
    role: (v) => roles.indexOf(v) > -1,
  },
});

const profileType = (role) => {
  switch (role) {
    case 'student': return 'Standard';
    case 'employer': return 'Company';
    default: return undefined;
  }
};

export const trackShare = (eventAction) => ({
  eventFields: ({
    payload = {},
    // eslint-disable-next-line no-empty-pattern
    meta: { segment: {} = {} } = {},
  }) => ({
    hitType: 'event',
    campaign_name: payload.campaign_name,
    campaign_id: payload.campaign_id,
    employer_name: payload.employer_name,
    employer_id: payload.employer_id,
    share_type: payload.share_type,
    eventAction,
  }),
});

export const identify = {
  eventFields: ({ payload }, { auth }) => ({
    hitType: 'identify',
    userId: payload && (payload.profile_id || auth.profile_id),
  }),
};

export const trackPageView = {
  eventFields: (action) => ({
    hitType: 'pageview',
    page: action.payload,
  }),
};

export const trackFormPageChange = ({ eventAction, role, url }) => ({
  eventFields: (action, prevState) => ({
    hitType: 'event',
    url: action.meta.redirect,
    role: prevState.auth.role,
    profileType: profileType(prevState.auth.role),
    eventAction,
  }),
  eventSchema: {
    url: (v) => v === url,
    role: (v) => v === role,
  },
});

export const trackFormCardSave = ({ eventAction, id }) => ({
  eventFields: (action) => ({
    hitType: 'event',
    id: action.payload.id,
    campaignId: action.payload.jobPayload.id,
    campaignName: action.payload.jobPayload.title,
    draftCampaignCreatedDate: action.payload.jobPayload.created_at,
    eventAction,
  }),
  eventSchema: {
    id: (v) => v === id,
  },
});

export const trackGuideStepNav = ({ eventAction, path }) => ({
  eventFields: (action) => ({
    hitType: 'event',
    path: action.payload.path,
    eventAction,
  }),
  eventSchema: {
    path: (v) => v === path,
  },
});

export const trackEvent = (eventAction) => track({ eventAction });
export const identifyAndTrack = (eventAction, metadata, roles) => [identify, track({ eventAction, metadata, roles })];
