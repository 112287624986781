import React from 'react';

const SvgIconFeather = props => (
  <svg
    width = { 32 }
    height = { 32 }
    viewBox = "0 0 32 32"
    fill = "currentColor"
    { ...props }
  >
    <path d = "M25.2 8l-9-8-9 8.1 2 11.9h3l-3 2 6.5 4.4V32h1v-5.7l6.6-4.3c-.1 0 1.9-14 1.9-14zM11 22l4.2-3H10L8.2 8.4l7.4-6.7V12l-4.2-2.6-.4.9 4.8 2.9V25c-.1.1-4.8-3-4.8-3zm5.7 3V13.2l4.8-2.9-.5-.8-4.3 2.6V1.7l7.4 6.7-1.9 13-5.5 3.6z" />
  </svg>
);

export default SvgIconFeather;
