import React                        from 'react';
import { connect }                  from 'react-redux';
import JobApplicationsDetails       from 'components/job-applications-details';
import JobApplicationsDetailsHeader from 'components/job-applications-details-header';
import JobApplicationsStatus        from 'containers/job-applications-status';
import PagePanelStats               from 'components/page-panel-stats';
import Score                        from 'components/score';
import { jobSelector }              from 'selectors';

const getScoreAverage = applications => {
  const totalScore = applications.reduce(
    (accumulator, currentValue) => (accumulator + currentValue.scores.score), 0);
  return (Math.floor((totalScore / applications.length)).toFixed(1).replace('.0', ''));
};

const getStats = applications => ([
  {
    value: applications.length,
    label: 'Total Applicants'
  }, {
    value: applications[0].scores.max_score > 0 && <Score
      score    = { getScoreAverage(applications) }
      maxScore = { applications[0].scores.max_score }
      isInactive />,
    label: 'Average Score'
  }
]);

const STATE_MAP = {
  open: {
    statusLabel: 'Applications Open',
    modifiers: { isSuccess: true }
  },
  closed: {
    statusLabel: 'Review your Applications',
    modifiers: { isWarning: true }
  },
  archived: {
    statusLabel: 'Review Period Complete',
    modifiers: { isPrimary: true }
  }
};

const TRANSITION_STATE_MAP = {
  closed: {
    statusLabel: 'Action your Applications!',
    modifiers: { isDanger: true }
  }
};

const JobApplicationsDetailsContainer = props => {
  const {
    id,
    isDisabled
  } = props;

  if (!id) return null;

  const {
    status,
    status_percentage_complete,
    application_ids,
    title
  } = props.job;

  const inTransition = status_percentage_complete > 77;
  const state = (inTransition && TRANSITION_STATE_MAP[status]) || STATE_MAP[status];
  const stats = getStats(application_ids);

  const {
    statusLabel,
    modifiers
  } = state;

  const header = (
    <JobApplicationsDetailsHeader
      link        = { `/jobs/${id}` }
      thumb       = { <JobApplicationsStatus id = { id } /> }
      title       = { title }
      statusLabel = { statusLabel } />
  );

  return (
    <JobApplicationsDetails
      header     = { header }
      stats      = { <PagePanelStats stats = { stats } /> }
      isDisabled = { isDisabled }
      { ...modifiers }
    />
  );
};

export default connect((state, { id }) => ({
  job: jobSelector(state, id),
  isDisabled: state.ui.isBulkActive
}))(JobApplicationsDetailsContainer);
