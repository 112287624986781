import React from 'react';
import classNames from 'classnames';

import { defined } from 'lib/helpers';
import SvgIcon from 'components/svg-icon';
import AccessTitles from 'components/v2/register/AccessTitles/AccessTitles';

import './index.scss';

class ActivationPage extends React.Component {
  constructor(props) {
    super(props);

    this._email = defined(this.props.router.location.query.email);
    this._token = defined(this.props.router.location.query.token);

    this.state = {
      password: '',
      passwordFailed: false,
      isError: false,
    };

    this._updatePassword = (value) => {
      this.setState({ password: value });
    };
  }

  componentDidMount() {
    this._inputElem && this._inputElem.focus();
  }

  render() {
    const { password, passwordFailed, isError } = this.state;

    const isPasswordValid = (password.length > 5) && !!(password.match(/\d/));

    const onSubmit = (e) => {
      const { appv2 } = this.props;

      e.preventDefault();

      if (!isPasswordValid) {
        this.setState({ passwordFailed: true });
        return;
      }

      appv2.api.profiles.activateWithPassword({
        email: this._email,
        token: this._token,
        password,
      })
        .then((data) => {
          appv2.auth.simulateLogin({
            payload: data,
          }).then(() => {
            return appv2.ui.redirect({ url: '/onboarding' });
          });
        })
        .catch((err) => {
          this.setState({ isError: true });
        });
    };

    return (
      <div className="v2-register-student-activation">
        <div className="v2-register-student-activation__info">
          <AccessTitles
            heading="Welcome back!"
            subheading="Let's finish creating your account."
          />
        </div>

        <form className="v2-register-student-activation__form" onSubmit={onSubmit}>
          <div className="v2-register-student-activation__form-login">
            { this._email }
          </div>
          <input
            ref={(elem) => { this._inputElem = elem; }}
            className={classNames('v2-register-student-activation__form-control', {
              'v2-register-student-activation__form-control--error': passwordFailed,
            })}
            type="password"
            placeholder="Create a Password"
            value={password}
            onChange={(event) => { this._updatePassword(event.target.value); }}
          />
          <div
            className={classNames('v2-register-student-activation__form-control-help-text', {
              'v2-register-student-activation__form-control-help-text--failed': passwordFailed,
            })}
          >
            Min 6 characters, must contain at least one numeral.
          </div>
          <button
            className="v2-register-student-activation__form-btn"
            type="submit"
            disabled={!isPasswordValid}
          >
            Create Account
          </button>
          { isError &&
            <div className="v2-register-student-activation__form-error-message">
              Cannot create the account.
            </div>
          }
        </form>

        <div className="v2-register-student-activation__terms">
          <div className="v2-register-student-activation__terms-row">
            <span>
              By continuing you agree to
            </span>
            <a className="v2-register-student-activation__terms-link" href="/terms">
              Paddl's Terms of Service
            </a>
          </div>
          <div className="v2-register-student-activation__terms-row">
            <SvgIcon id="icon-gdpr" />
            <a className="v2-register-student-activation__terms-link" href="/terms#privacy">
              Review Paddl's GDPR Policy
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default ActivationPage;
