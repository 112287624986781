import React              from 'react';
import { defined }        from 'lib/helpers';
import InputTextMultiline from 'components/v2/input-fields/input-text-multiline';

const CompanyStraplineInput = ({ value, onChange }) => {
  const isError = (value) => {
    return false; // TODO: not implemented yet
  };

  const hasError = isError(value);
  const hasHint = true; // TODO: hasHint set as true to show the max characters hint message.
  const maxLength = 190;

  const message = (hasError, hasHint) => {
    if (hasError) { return 'error!' }
    if (hasHint)  { return `Max ${maxLength} characters.` }
    return ''
  };

  return (
    <InputTextMultiline
      isError     = { hasError }
      hasHint     = { hasHint }
      maxLength   = { maxLength }
      value       = { defined(value) }
      onChange    = { (value) => { onChange({ name: defined(value) }); } }
      message     = { message(hasError, hasHint) }
      label       = "Strapline"
      placeholder = "Add a Company Strapline"
    />
  );
};

export default CompanyStraplineInput;
