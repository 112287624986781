import React           from 'react';
import { connect }     from 'react-redux';
import Status          from 'components/status';
import { jobSelector } from 'selectors';

const STATE_MAP = {
  open  : 'success',
  closed: 'warning',
  archived: 'primary'
};

const TRANSITION_STATE_MAP = {
  closed: 'danger'
};

const JobStatusContainer = (props) => {
  const {
    status_percentage_complete,
    status_days_till_transition,
    status
  } = props;

  const inTransition = status_percentage_complete > 77;
  const state = (inTransition && TRANSITION_STATE_MAP[status]) || STATE_MAP[status];

  return (
    <Status
      state    = { state }
      complete = { status_percentage_complete }
      daysLeft = { status_days_till_transition }
      isLarge  = { false }
      hasDuration
      isCompact />
  );
};

export default connect((
  state, { id }
) => {
  const {
    status_percentage_complete,
    status_days_till_transition,
    status
  } = jobSelector(state, id);

  return {
    status_percentage_complete,
    status_days_till_transition,
    status
  };
})(JobStatusContainer);
