import './not-found.scss';
import './not-found-keyframes.scss';

import React  from 'react';
import Button from 'components/button';

export class NotFound extends React.Component {
  render() {
    return (
      <section className="not-found">
        <div className="container">
          <h1 className="animated swing">Oops.</h1>
          <h2 className="animated notFoundFadeIn">Page Not Found</h2>
          <p className="animated notFoundFadeIn delayed">We track these errors automatically, but if the problem persists feel free to contact us. In the meantime, try refreshing.</p>
          <Button
            type = "button"
            link = "/jobs"
            isPrimary
            isWide
          >
            Back to Paddl
          </Button>
          <div className="push"></div>
        </div>
        <div className="illu">
          <div
            className="waves backgroundScroll"
            style={ { backgroundImage: `url(${require('./wave.svg')})` } }>
          </div>
          <div className="water"></div>

          <div className="boat-wrapper">
            <div
              className="boat animated slideInDown"
              style={ { backgroundImage: `url(${require('./boat.svg')})` } }>
            </div>
          </div>

          <div
            className="bg"
            style={ { backgroundImage: `url(${require('./bg.svg')})` } }>
          </div>

        </div>
      </section>
    );
  }
}
