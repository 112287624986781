import './app.scss';
import 'utils/sentry';

import React                     from 'react';
import { render }                from 'react-dom';
import { Provider }              from 'react-redux';
import getRoutes                 from 'routes';
import createStore               from './create-store';
import { setPage, closeSidenav } from 'modules/ui';
import getPageId                 from 'utils/get-page-id';
import realV1deps                from 'lib/real-v1deps';
import Appv2                     from 'lib/appv2';

import {
  Router,
  browserHistory as history
} from 'react-router';

const store = createStore();
const appv2 = new Appv2({ store, v1deps: realV1deps });
const routes = getRoutes(store, appv2);

window._appv2 = appv2; // providing access to appv2 from within v1 components

appv2.featureFlags.refreshAll();

history.listen((location) => {
  store.dispatch(closeSidenav());
  store.dispatch(setPage({
    id: getPageId({ location, auth: store.getState().auth })
  }));
});

if (window.location.pathname.includes('/login')) {
  store.dispatch(setPage({
    id: 'login',
  }));
}

const handleUpdate = () => {
  window.scrollTo(0, 0);
};

render(
  <Provider store = { store } >
    <Router
      onUpdate = { handleUpdate }
      routes   = { routes }
      history  = { history } />
  </Provider>
, document.getElementById('root'));
