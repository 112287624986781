import './panel.scss';

import React   from 'react';
import Button  from 'components/button';
import SvgIcon from 'components/svg-icon';

const PANEL_TYPES = {
  'coming-soon': {
    hasVote: true,
    imageUrl: () => require('images/icon--coming-soon.svg')
  },
  welcome: {
    isClosable: true,
    imageUrl: (icon) => require(`images/icon--welcome-${icon}.svg`)
  },
  cta: {
    imageUrl: (icon) => require(`images/icon--cta-${icon}.svg`)
  }
};

export default (props) => {
  const {
    icon, action, text, title,
    type = 'welcome', onClose,
    linkTo, id,
    onActionButtonClick,
    onVoteClick,
    vote
  } = props;

  if (!icon) return null;

  const {
    isClosable = false,
    hasVote = false,
    imageUrl
  } = PANEL_TYPES[type] || {};

  return (
    <div className = { `panel is--${type}` } >
      { isClosable && <a className = "panel__close" onClick = { onClose } ><SvgIcon id = "close" /></a> }
      { imageUrl &&
        <img src = { imageUrl(icon) } className = "panel__icon" />
      }
      <div className = "panel__content">
        { title &&
          <h3 className = "panel__title">
            { title }
          </h3>
        }
        { text &&
          <p
            className = "panel__text"
            dangerouslySetInnerHTML = { { __html: text } } />
        }
        { type === 'cta'
          ? action && <a
              target    = "_blank"
              className = "button is--wide is--dark"
              href      = { linkTo } >
              <div className="button__label">{ action }</div>
            </a>
          : action && <Button
              type      = "button"
              className = "is--wide is--centered"
              onClick   = { onActionButtonClick }>
              { action }
            </Button>
        }
        { hasVote && vote === null &&
          <div className = "panel__vote">
            Is this a feature you’d like to see?
            { _.map({ 'Yay!': true, 'Nay..': false }, (value, key) => (
              <div
                key       = { key }
                className = "panel__vote__item"
                onClick   = { onVoteClick({ value }) }>
                <SvgIcon id = { `thumbs-${value ? 'up' : 'down'}` } className = "panel__vote__icon" />
                { key }
              </div>
              ))
            }
          </div>
        }
      </div>
    </div>
  );
};
