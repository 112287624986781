import { SummaryPage }       from 'pages/v2/profile/summary-page';
import { PublicSummaryPage } from 'pages/v2/public/profile/summary-page';

export default ({ appv2 }) => {
  return {
    path:         'profile/:id/summary',
    title:        'Profile Summary',
    getComponent: (nextState, cb) => {
      const { location } = nextState;
      const isPublicPreviewPage = (location.search.indexOf('public=1') > -1);

      return cb(null, isPublicPreviewPage ? PublicSummaryPage : SummaryPage);
    },
  };
};
