import React from 'react';
import classNames from 'classnames';
import { Field } from 'redux-form';
import SvgIcon from 'components/svg-icon';
import {
  TYPES,
  COMPONENTS,
} from 'modules/forms/constants';

import './form-field.scss';

const getComponent = ({ mode, type }) => COMPONENTS[mode][type] || COMPONENTS[mode][TYPES.DEFAULT];

const Editable = props => {
  const {
    input,
    label,
    type,
    addon,
    icon,
    options,
    children,
    description,
    disabled,
    attrs,
    placeholders,
    isEditing,
    canEdit = true,
    meta: { touched, error, dirty, warning },
    ...rest
  } = props;

  const Component = getComponent({ mode: isEditing ? 'edit' : 'static', type });

  if (!Component) return null;

  const typeString = Symbol.keyFor(type);
  const placeholder = props.placeholder || placeholders && (isEditing ? placeholders.input : placeholders.default);
  const className = isEditing
    ? `input__${typeString} ${props.className ? props.className : ''}`
    : `input__value is--${typeString} ${props.className ? props.className : ''}`;

  return (
    <div
      className={classNames('form__field', props.className, {
        'has--error': (error || warning) && (dirty || touched),
        'is--disabled': disabled,
        'has--icon': !!icon,
        'is--checked': typeString === 'checkbox' && input.value,
      }, `is--${input.name}`)}
    >
      { label && <div className="form__field__label">{ label }</div> }
      { description && <div className="form__field__description">{ description }</div> }
      <div className="form__field__wrapper">
        { icon && <SvgIcon
          id={icon}
          className="form__field__icon"
        />
        }
        <Component
          {...{
            ...input,
            type: typeString,
            placeholder: canEdit && placeholder,
            className,
            addon,
            options,
            disabled,
            children,
            attrs,
            ...rest
          }}
        />
      </div>
      { isEditing && (dirty || touched) && error &&
        <div className="form__field__error">{ error.address || error }</div>
      }
      { warning && <div className="form__field__error">{ warning }</div>}
    </div>
  );
};

export default ({ isEditing = true, editOnly = false, ...props }) => {
  if (!isEditing && editOnly) return null;

  return <Field {...props} isEditing={isEditing} component={Editable} />;
};
