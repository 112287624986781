import {
  identify,
  identifyAndTrack,
  trackPageView,
  trackFormPageChange,
  trackFormCardSave,
  trackGuideStepNav,
  trackEvent,
  trackShare,
} from './track';

const EVENTS_MAP = {
  'ui/SET_PAGE': trackPageView,
  'auth/SET': identify,
  'auth/LOGIN_SUCCESS': identifyAndTrack('User Logged In'),
  'auth/LOGIN_FAILURE': trackEvent('User Failed to Login'),
  'register/STUDENT_SUCCESS': identifyAndTrack('User Registered', { registrationForm: 'Standard' }),
  'register/EMPLOYER_SUCCESS': identifyAndTrack('User Registered', { registrationForm: 'Company' }),
  'register/WAITLIST_SUCCESS': identifyAndTrack('Registered to Waitlist'),
  'profiles/UPDATE_SUCCESS': identifyAndTrack('Updated Profile', { profileField: 'About Me' }),
  'auth/SET_PASSWORD_SUCCESS': identifyAndTrack('Activated Account'),
  'ui/SKIP_AVATAR_UPLOAD': trackEvent('Onboarding: Step 3 Skipped'),
  'avatar/UPLOAD_SUCCESS': trackEvent('Onboarding: Step 3 Complete'),
  'share/SHARE_BUTTON': trackShare('Shared a Job'),
  'guide/GOTO_STEP': [
    trackGuideStepNav({
      eventAction: 'Tour Guide: About Me',
      path: '/profile?edit',
    }),
    trackGuideStepNav({
      eventAction: 'Tour Guide: Campaign',
      path: '/jobs',
    }),
  ],
  'job/create/SAVE_CARD': [
    trackFormCardSave({
      eventAction: 'Draft Campaign: Saved Job Details',
      id: 'details',
    }),
    trackFormCardSave({
      eventAction: 'Draft Campaign: Saved Reach',
      id: 'tags',
    }),
  ],
  'forms/SET_PAGE': [
    trackFormPageChange({
      eventAction: 'Onboarding: Step 1 Complete',
      url: '/welcome/step/1',
      role: 'student',
    }),
    trackFormPageChange({
      eventAction: 'Onboarding: Step 1 Complete',
      url: '/welcome/step/2',
      role: 'employer',
    }),
  ],
};

export default EVENTS_MAP;
