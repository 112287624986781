import React          from 'react';
import { FieldArray } from 'redux-form'
import FormQuestions  from 'components/form-questions';

class SortableFormQuestions extends React.Component {
  constructor(props) {
    super(props);

    this.handleAdd     = this.handleAdd.bind(this);
    this.handleDelete  = this.handleDelete.bind(this);
    this.handleSortEnd = this.handleSortEnd.bind(this);
  }
  handleAdd()             { this.props.fields.push({}) }
  handleDelete({ index }) { this.props.fields.remove(index) }
  handleSortEnd({ oldIndex, newIndex }) {
    if (oldIndex !== newIndex) {
      this.props.fields.swap(oldIndex, newIndex);
    }
  }
  componentDidMount() {
    const { fields } = this.props;

    if (fields.length === 0) {
      fields.push({});
    }
  }
  render() {
    const {
      fields, name, meta: { touched, error }
    } = this.props;

    return (
      <FormQuestions
        fields        = { fields }
        name          = { name }
        onSortEnd     = { this.handleSortEnd }
        onAdd         = { this.handleAdd }
        onDelete      = { this.handleDelete }
        useDragHandle = { true }
        helperClass   = "is--dragging"
        axis          = "y"
        lockAxis      = "y" />
    );
  }
}

export default ({ name }) => <FieldArray name = { name } component = { SortableFormQuestions } />;
