import './list-group.scss';

import React      from 'react';
import classNames from 'classnames';

const ListGroup = ({
  children,
  title,
  hasTitle,
  isPrimary,
  isDanger,
  isWarning,
  isInactive,
  isSuccess
}) => (
  <div
    className = { classNames('list-group', {
      'is--success' : isSuccess,
      'is--primary' : isPrimary,
      'is--warning' : isWarning,
      'is--danger'  : isDanger,
      'is--inactive': isInactive
    }) }>
    { title &&
      <div
        className = { classNames('list-group__title', {
          'is--visible': hasTitle
        }) }>
        <span>{ title }</span>
      </div>
    }
    { children }
  </div>
);

export default ListGroup;

