import React from 'react';

export default class DocumentMeta extends React.Component {
  componentDidMount() {
    const {
      title, raw
    } = this.props;

    if (title) {
      document.title = raw ? title : `Paddl Jobs - ${title}`;
    }
  }
  render() { return null }
}
