import {
  Schema,
  arrayOf
} from 'normalizr';

export const applicationSchema  = new Schema('applications');
export const conversationSchema = new Schema('conversations');
export const jobSchema          = new Schema('jobs');
export const paymentSchema      = new Schema('payments');
export const profileSchema      = new Schema('profiles');
export const villageSchema      = new Schema('villages');
export const courseSchema       = new Schema('courses');
export const campusSchema       = new Schema('campuses');
export const educatorSchema     = new Schema('educators');

conversationSchema.define({
  job: jobSchema,
  applicant: profileSchema
});

applicationSchema.define({
  campaign: jobSchema,
  conversation: conversationSchema,
  applicant_id: profileSchema
});

jobSchema.define({
  employer: profileSchema,
  application_ids: arrayOf(applicationSchema),
  villages: arrayOf(villageSchema)
});

profileSchema.define({
  villages: arrayOf(villageSchema),
  course: courseSchema,
  campus: campusSchema
});

campusSchema.define({ educator: educatorSchema });
courseSchema.define({ educator: educatorSchema });
paymentSchema.define({ jobs: arrayOf(jobSchema) });
