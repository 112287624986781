import React           from 'react';
import { connect }     from 'react-redux';
import Status          from 'components/status';
import SvgIcon         from 'components/svg-icon';
import {
  applicationSelector
} from 'selectors';

const STATE_MAP = {
  in_progress : 'warning',
  closing_soon: 'danger',
  sent        : 'primary',
  shortlisted : 'success'
};

const MODIFIERS_BY_STATUS = {
  in_progress: { },
  sent:        { },
  shortlisted: {
    hasDuration: false,
    complete: 0
  }
};

const ICONS_BY_STATUS = {
  sent: <SvgIcon id = "paper-plane" />,
  shortlisted : <SvgIcon id = "paper-plane" />
};

const ApplicationStatusContainer = (props) => {
  const {
    percentage_complete,
    days_till_transition,
    status,
    icon,
    title,
    hasDuration
  } = props;

  const modifiers = MODIFIERS_BY_STATUS[status];

  return (
    <Status
      title        = { title }
      icon         = { icon || ICONS_BY_STATUS[status] }
      state        = { STATE_MAP[status] }
      complete     = { percentage_complete }
      daysLeft     = { days_till_transition }
      hasDuration  = { hasDuration }
      isCompact
      { ...modifiers } />
  );
};

export default connect((
  state, { id }
) => {
  const application = applicationSelector(state, id);

  return {
    role: state.auth.role,
    ...application.status
  };
})(ApplicationStatusContainer);
