import './payment.scss';

import React         from 'react';
import { Link }      from 'react-router';
import PageTitle     from 'components/page-title';
import Loading       from 'components/loading';
import Checkout      from 'components/checkout';
import PageContent   from 'components/page-content';
import Formatted     from 'components/formatted';
import CampaignsLite from 'components/v2/campaigns-lite';
import PageActions   from 'components/page-actions';

export default (props) => {
  const {
    job,
    items,
    summaryItems,
    addons,
    total,
    profile,
    ui,
    isFetching,
    handlePaymentSuccess
  } = props;

  const isLoading = (!job || !profile) || isFetching;

  return (
    <PageContent>
      { ui.error &&
        <div className = "page__error">
          { ui.error }
        </div>
      }
      <PageTitle>
        Payment Summary
      </PageTitle>
      <h4>
        Please review your campaign before payment
      </h4>
      <CampaignsLite />
      { isLoading && <Loading /> }
      { !isLoading &&
        [
          <div className = "payment" key = "payment">
            <div className = "payment__card">
              { items.map(item => (
                <div className = "payment__item" key = { item.description } >
                  <h3 className = "payment__item__title">
                    { job.title }
                  </h3>
                  <div className = "payment__item__duration">
                    { job.duration.days_open + job.duration.days_closed } days
                  </div>
                  <div className = "payment__item__price">
                    <Formatted type = "currency" value = { item.amount } />
                  </div>
                </div>
              ))}
              { addons.map(item => (
                <div className = "payment__addon" key = { item.description } >
                  <h5 className = "payment__addon__title">
                    • { item.description }
                  </h5>
                  <div className = "payment__addon__price">
                    <Formatted type = "currency" value = { item.amount } />
                  </div>
                </div>
              ))}
              <div className = "payment__summary">
                { summaryItems.map(item => (
                  <div className = { `payment__summary__item ${item.type}` } key = { item.description } >
                    <div className = "payment__summary__item__title">
                      { item.description }:
                    </div>
                    <div className = "payment__summary__item__price">
                      <Formatted type = "currency" value = { item.amount } />
                    </div>
                  </div>
                ))}
                <div className = "payment__summary__item is--total">
                  <div className = "payment__summary__item__title">Total:</div>
                  <div className = "payment__summary__item__price">
                    <Formatted type = "currency" value = { total } />
                  </div>
                </div>
              </div>
            </div>
            {/*
            <div className = "payment__footer">
              <Form.Coupon onSubmit = { handleCouponApply } />
            </div>
            */}
          </div>,
          <PageActions type = "bottom">
            <Link
              to        = { `/jobs/${job.id}/edit` }
              className = "button"
            >
              <div className = "button__label">Edit Job</div>
            </Link>
            <Checkout
              token   = { handlePaymentSuccess }
              amount  = { total }
              roles   = { items[0].quantity }
              profile = { profile }
              isFetching = { isFetching }
            />
          </PageActions>
        ]
      }
    </PageContent>
  );
};
