/* eslint-disable no-param-reassign, no-confusing-arrow, no-use-before-define */

import _ from 'lodash';
import memoize from 'lru-memoize';

const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';
const isEmpty = (value) => value === undefined || value === null || value === '';
const validateRuleset = memoize(10)((rules, data) => _.reduce(rules, reduceRuleSet(data), {}));
const applyRules = memoize(10)((rules) => (value, values) => rules.map((rule) => rule(value, values)).filter((error) => !!error)[0]);
const validateFieldsArray = memoize(10)((values, rules) => _
  .map(values, ({ data }) => ({ data: _.map(data, (item) => validateRuleset(rules, item)) }))
  .map((fields) => fields.data.filter((v) => !_.isEmpty(v)).length > 0 ? fields : undefined));
const reduceRuleSet = (values = {}) => (result, rules, key) => {
  const isFieldsArray = !!rules.itemRules;
  const isNested      = isObject(rules);

  if (isFieldsArray) {
    const errors = validateFieldsArray(values[key], rules.itemRules);

    if (errors.filter((v) => v).length > 0) { result[key] = errors; }
  } else if (isNested) {
    const error = validateRuleset(rules, values[key]);

    if (!_.isEmpty(error)) { result[key] = error; }
  } else {
    const error = applyRules([].concat(rules))(values[key], values);

    if (error) { result[key] = error; }
  }

  return result;
};

export const createValidations = memoize(10)((rules) => (values) => _.reduce(rules, reduceRuleSet(values), {}));

export const requiredIf = (dependsOn) => (value, values) => {
  if (values[dependsOn] && isEmpty(value)) { return 'Required'; }

  return undefined;
};

export const requiredUnless = (dependsOn) => (value, values) => {
  if (!values[dependsOn] && isEmpty(value)) { return 'Required'; }

  return undefined;
};

export const requiredIfUnless = (ifField, unlessField) => (value, values) => {
  if (values[ifField] && !values[unlessField] && isEmpty(value)) { return 'Required'; }

  return undefined;
};

export const required = (value) => {
  if (isEmpty(value)) { return 'Required'; }

  return undefined;
};

export const maxLength = (max, message) => (value) => {
  if (value && value.length > max) {
    return message || `Must be no more than ${max} characters`;
  }

  return undefined;
};

export const dob = (value) => {
  const pattern = new RegExp('([0-9]{2})/([0-9]{2})/([0-9]{4})');

  if (!pattern.test(value)) {
    return 'Please use DD/MM/YYYY date format';
  }

  return undefined;
};

export const minLength = (min, message) => (value) => {
  if (!value || value.length < min) {
    return message || `Must be at least ${min} characters`;
  }

  return undefined;
};

