import { NotFound } from 'components/not-found';
import AppContainer from 'containers/app';
import LandingContainer from 'containers/landing';
import PublicContainer from 'containers/public';
import { V2Landing } from 'components/v2/landing';
import { PublicLandingPage } from 'pages/v2/public/landing-page';
import { V2LandingGetStarted } from 'components/v2/landing-get-started';
import { V2PublicProfile } from 'components/v2/public-profile';
import { componentWithAppv2 } from 'lib/helpers';

import requireAuth from './require-auth';

const indexRedirect = (store) => (nextState, replace) => {
  if (typeof window === 'undefined') return;

  const {
    auth: { token },
  } = store.getState();

  if (token) {
    replace('/basecamp');
  }
};

export default (store, appv2) => ({
  path: '/',
  indexRoute: {
    onEnter: indexRedirect(store),
    component: componentWithAppv2(PublicLandingPage, appv2),
  },
  childRoutes: [
    {
      component: LandingContainer,
      childRoutes: [
        require('./welcome').default(store),
        require('./login').default,
        require('./register').default,
        require('./forgot-password').default,
        require('./reset-password').default,
      ],
    },
    {
      getComponent: (nextState, cb) => {
        if (appv2.auth.currentProfileId()) {
          return appv2.ui.redirect({ url: '/jobs' });
        }

        return cb(null, componentWithAppv2(V2Landing, appv2));
      },
      childRoutes: [
        require('./v2/register/student-redirect-old').default({ appv2 }),
        require('./v2/register/student-email').default({ appv2 }),
        require('./v2/register/student-feedback').default({ appv2 }),
        require('./v2/register/student-thanks').default({ appv2 }),
        require('./v2/register/student-activation').default({ appv2 }),
      ],
    },
    {
      getComponent: (nextState, cb) => {
        return cb(null, componentWithAppv2(V2LandingGetStarted, appv2));
      },
      onEnter: requireAuth(store),
      childRoutes: [
        require('./v2/get-started/student-profile').default({ appv2 }),
        require('./v2/get-started/student-activity-card').default({ appv2 }),
      ],
    },
    {
      getComponent: (nextState, cb) => {
        const { location } = nextState;
        const { role } = store.getState().auth;

        const isPublicPreviewPage = (location.search.indexOf('public=1') > -1);

        if (location.pathname.indexOf('/profile') === 0 && isPublicPreviewPage) {
          return cb(null, componentWithAppv2(V2PublicProfile, appv2));
        }

        if (role) {
          return cb(null, componentWithAppv2(AppContainer, appv2));
        }

        return cb(null, PublicContainer);
      },
      onEnter: requireAuth(store),
      childRoutes: [
        require('./jobs').default(store),
        require('./applications').default(store),
        require('./conversations').default(store),
        require('./profile/root').default({ appv2 }),
        require('./profile/summary').default({ appv2 }),
        require('./profile/experience').default({ appv2 }),
        require('./profile/campaigns').default({ appv2 }),
        require('./settings').default({ appv2, store }),
        require('./basecamp').default({ appv2, store }),
        // TODO: Uncomment the new routes for verified activities in an integration branch
        // require('./verified-activities').default({ appv2, store }),
        // require('./verified-activities-redemption').default({ appv2, store }),
      ],
    },
    {
      path: '*',
      component: NotFound,
    },
  ],
});
