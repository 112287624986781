import React from 'react';
import { connect } from 'react-redux';

import { Page } from 'components/page';
import { Navbar } from 'components/navbar';
import { NavbarPageActions } from 'components/navbar-page-actions';
import V2PageContent from 'components/v2/page-content';
import PageContent from 'components/page-content';
import { Support } from 'components/support';
import { PageFooter } from 'components/v2/page-footer';

import './landing.scss';

const LandingContainer = (props) => {
  const {
    pageId,
    currentProfileId,
    children,
  } = props;

  return (
    <Page className={`page--${pageId} is--landing`}>
      <Navbar hasLogo isExpanded>
        <NavbarPageActions currentProfileId={currentProfileId} />
      </Navbar>
      <V2PageContent isFull>
        <PageContent>
          { React.cloneElement(children, props) }
        </PageContent>
      </V2PageContent>
      <div className="landing-container__page-footer">
        <Support />
        <PageFooter />
      </div>
    </Page>
  );
};

export default connect((state) => ({
  currentProfileId: state.auth.profile_id,
  pageId: state.ui.pageId,
}))(LandingContainer);
