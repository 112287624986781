import './index.scss';
import React from 'react';

import Text                          from 'component-lib/type/text';
import { style26pxRegularBlueL }     from 'component-lib/type/text/styles';
import { style14pxRegularBluegreyD } from 'component-lib/type/text/styles';

const BasecampTotalSkills = ({ totalSkills }) => {
  return (
    <div className = "basecamp-total-skills">
      <Text { ...style26pxRegularBlueL } text = { totalSkills } />
      <Text { ...style14pxRegularBluegreyD } text = 'Skills' />
    </div>
  );
};

export default BasecampTotalSkills;
