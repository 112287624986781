import './job-applications-details.scss';

import React       from 'react';
import classNames  from 'classnames';
import Button      from 'components/button';
import PageSection from 'components/page-section';

const JobApplicationsDetails = ({
  header,
  stats,
  isWarning,
  isDanger,
  isPrimary,
  isInactive,
  isSuccess,
  isDisabled,
  className
}) => {
  if (!header) return null;

  return (
    <PageSection isCta hasShadow isDisabled = { isDisabled } >
      <div
        className = { classNames('job-applications-details', className, {
          'is--primary' : isPrimary,
          'is--danger'  : isDanger,
          'is--warning' : isWarning,
          'is--inactive': isInactive,
          'is--success' : isSuccess
        }) }>
        { header }
        { stats }
      </div>
    </PageSection>
  );
};

export default JobApplicationsDetails;
