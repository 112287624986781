import './summary-user-details.scss';

import React from 'react';
import Avatar from 'components/avatar';

const SummaryUserDetails = ({
  id,
  full_name,
  role,
  photo_url,
  business_name
}) => {
  return (
    <div className = "summary-user-details">
      <div className = "summary-user-details__avatar">
        <Avatar
          url = { photo_url }
          role = { role }
          id = { id } />
      </div>
      <div className = "summary-user-details__wrapper">
        <div className = "summary-user-details__name">
          { full_name }
          <br />
          { business_name }
        </div>
      </div>
    </div>
  );
};
export default SummaryUserDetails;
