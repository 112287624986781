import { CALL_API } from 'middleware/call-api';
import { profileSchema } from 'schemas';
import { initApp } from 'modules/app';
import realV1deps from 'lib/real-v1deps';
import Appv2 from 'lib/appv2';

export const SET = 'auth/SET';
export const DESTROY = 'auth/DESTROY';

export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';

export const REQUEST_RESET_REQUEST = 'auth/REQUEST_RESET_REQUEST';
export const REQUEST_RESET_SUCCESS = 'auth/REQUEST_RESET_SUCCESS';
export const REQUEST_RESET_FAILURE = 'auth/REQUEST_RESET_FAILURE';

export const RESET_PASSWORD_REQUEST = 'auth/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'auth/RESET_PASSWORD_FAILURE';

export const SET_PASSWORD_REQUEST = 'auth/SET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = 'auth/SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILURE = 'auth/SET_PASSWORD_FAILURE';

export const GET_TOKEN_REQUEST = 'auth/GET_TOKEN_REQUEST';
export const GET_TOKEN_SUCCESS = 'auth/GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAILURE = 'auth/GET_TOKEN_FAILURE';

export const RESET_PROFILE_SUCCESS = 'auth/RESET_PROFILE_SUCCESS'

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    // There is a hack related to this DESTROY action, take a look at `src/modules/index.js`
    // When the action is being dispatched, all kinds of states are reset to `undefined`...

    case DESTROY:
      const {
        location,
      } = action.payload;

      return {
        ...state,
        redirectTo: location,
      };
    case LOGIN_SUCCESS:
    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
      break;
    case GET_TOKEN_REQUEST:
      const {
        token,
      } = action.payload;

      return {
        ...state,
        onboarding_token: token,
      };
      break;
    case GET_TOKEN_SUCCESS:
      const {
        result, entities,
      } = action.payload;

      return {
        ...state,
        profile_id: result[0],
        role: entities.profiles[result[0]].role,
      };
    case SET:
      return {
        ...state,
        ...action.payload,
        redirectTo: null,
      };
    default: return state;
  }
};

export function resetPasswordRequest(values) {
  return {
    [CALL_API]: {
      types: [
        REQUEST_RESET_REQUEST,
        REQUEST_RESET_SUCCESS,
        REQUEST_RESET_FAILURE,
      ],
      method: 'POST',
      endpoint: '/auth/password/reset',
      data: values,
      meta: {
        [REQUEST_RESET_SUCCESS]: {
          redirect: '/forgot-password/success',
        },
      },
    },
  };
}

export function resetPassword(values) {
  return {
    [CALL_API]: {
      types: [
        RESET_PASSWORD_REQUEST,
        RESET_PASSWORD_SUCCESS,
        RESET_PASSWORD_FAILURE,
      ],
      method: 'POST',
      endpoint: '/auth/password',
      data: values,
      meta: {
        [RESET_PASSWORD_SUCCESS]: {
          redirect: '/login',
        },
      },
    },
  };
}

export function postPassword(values) {
  return {
    [CALL_API]: {
      types: [
        SET_PASSWORD_REQUEST,
        SET_PASSWORD_SUCCESS,
        SET_PASSWORD_FAILURE,
      ],
      method: 'POST',
      endpoint: '/onboarding/password',
      data: values,
    },
  };
}

export function setPassword(values) {
  return ({ dispatch }) => (
    dispatch(postPassword(values))
      .then(() => dispatch(initApp()))
  );
}

export function postLogin(values) {
  return {
    [CALL_API]: {
      types: [
        LOGIN_REQUEST,
        LOGIN_SUCCESS,
        LOGIN_FAILURE,
      ],
      method: 'POST',
      endpoint: '/auth/login',
      data: values,
    },
  };
}

export function login(values) {
  return ({ dispatch, getState }) => {
    return (
    dispatch(postLogin(values))
      .then(() => {
        dispatch(initApp());
        dispatch({
          type: 'auth/AFTER_LOGIN_SUCCESS',
          meta: {
            redirect: getState().auth.redirectTo || '/jobs',
          },
        });
      })
  );
  };
}

export function getToken(values) {
  return {
    [CALL_API]: {
      types: [
        GET_TOKEN_REQUEST,
        GET_TOKEN_SUCCESS,
        GET_TOKEN_FAILURE,
      ],
      method: 'POST',
      endpoint: '/onboarding/token',
      data: values,
      schema: profileSchema,
      meta: {
        [GET_TOKEN_FAILURE]: {
          redirect: '/',
        },
      },
    },
  };
}

export function logout(store) {
  store.dispatch(destroyAuth());

  const appv2 = new Appv2({ store, v1deps: realV1deps });
  return appv2.featureFlags.refreshAll();
}

export function destroyAuth(location) {
  return {
    type: DESTROY,
    payload: { location },
    meta: {
      redirect: '/login',
    },
  };
}

export function setAuth(values) {
  return {
    type: SET,
    payload: values,
  };
}
