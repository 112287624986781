import React from 'react';

import Card from 'components/card';

export default () => (
  <Card
    title="Success"
    subtitle="Email Sent!"
    isCentered
  >
    <div className="card__msg">
      Password has been reset.
    </div>
  </Card>
);
