import './list-panel-checkbox.scss';

import React     from 'react';
import { TYPES } from 'modules/forms/constants';
import FormField from 'components/form-field';

const ListPanelCheckbox = ({
  id
}) => (
  <FormField
    className = "list-panel__checkbox"
    type = { TYPES.CHECKBOX }
    name = { `list[]${id}` } />
);

export default ListPanelCheckbox;
