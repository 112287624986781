import './index.scss';
import React from 'react';

import State from 'components/v2/profile/student-summary-content/state';
import Data  from 'components/v2/profile/student-summary-content/data';

import { StudentPanelAdd }   from 'components/v2/profile/student-panel/add';
import StudentAboutPanelForm from 'components/v2/profile/student-about-panel/form';
import StudentAboutPanelView from 'components/v2/profile/student-about-panel/view';
import StudentLocationPanel  from 'components/v2/profile/student-location-panel';

class StudentSummaryContent extends React.Component {
  constructor(props) {
    super(props);

    const state = new State({ component: this });
    this._data = new Data({ state, appv2: props.appv2 });
  }

  UNSAFE_componentWillMount() {
    const { profile, canEdit } = this.props;

    this._data.initializeState({ profile, canEdit });
  }

  render() {
    const renderStudentAboutPanel = () => {
      if (this._data.isSummaryFormOpen()) {
        return <StudentAboutPanelForm data = { this._data } />;
      }

      if (this._data.isSummaryDataPresent()) {
        return <StudentAboutPanelView data = { this._data } />;
      }

      if (this._data.canEdit()) {
        return (
          <StudentPanelAdd
            onClick = { this._data.openSummaryForm }
            imgSrc = { require('images/v2/icon-circle-paper.svg') }
            heading = "About me"
            description = "Add more about yourself and your experience."
          />
        );
      }
    };

    const renderStudentLocationPanel = () => {
      const { appv2, profile, canEdit } = this.props;
      return <StudentLocationPanel appv2 = { appv2 } profileId = { profile.get('id') } canEdit = { canEdit } />;
    };

    return (
      <div className = "v2-student-summary-content">
        <div className = "v2-student-summary-content__panel">
          { renderStudentAboutPanel() }
        </div>
        <div className = "v2-student-summary-content__panel">
          { renderStudentLocationPanel() }
        </div>
      </div>
    );
  }
};

export default StudentSummaryContent;
