import './administrators.scss';
import React from 'react';

export default ({ users }) => (
  <div className = "administrators">
    { users.map(({ full_name, email, role }) => (
      <div className = "administrator" key = { email }>
        <div className = "administrator__name">{ full_name }</div>
        <div className = "administrator__email">{ email }</div>
        <div className = "administrator__role">{ role }</div>
      </div>
    ))}
  </div>
)
