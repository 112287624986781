import './index.scss';
import React from 'react';
import classNames from 'classnames';

import ImgTag                       from 'component-lib/components/img-tag';
import Text                         from 'component-lib/type/text';
import { style18pxRegularBluegrey } from 'component-lib/type/text/styles';

export const ProfileSlugSuggestionListItem = ({ suggestion, onClick, onHideClick, shouldFadeIn }) => {
  const itemClassNames = classNames(
    'v2-profile-slug-suggestion-list-item',
    { 'v2-profile-slug-suggestion-list-item--fade-in': shouldFadeIn },
  );

  return (
    <div className = { itemClassNames }>
      <ImgTag width = "24px" src = { require('component-lib/icons/globe.svg') } />

      <div className = "v2-profile-slug-suggestion-list-item__text-wrapper">
        <div className = "v2-profile-slug-suggestion-list-item__text" onClick = { onClick }>
          <Text { ...style18pxRegularBluegrey } text = { suggestion } />
        </div>
      </div>

      <div className = "v2-profile-slug-suggestion-list-item__cross-icon" onClick = { onHideClick }>
        <ImgTag width = "16px" src = { require('component-lib/icons/cross.svg') } />
      </div>
    </div>
  );
};
