import {
  SET,
  LOGIN_SUCCESS,
  SET_PASSWORD_SUCCESS,
  RESET_PROFILE_SUCCESS,
  DESTROY
} from 'modules/auth';
import {
  setToken,
  clearToken
} from 'utils/auth';

const SET_AUTH_ACTIONS = [
  SET,
  LOGIN_SUCCESS,
  SET_PASSWORD_SUCCESS
];

const CLEAR_AUTH_ACTIONS = [
  DESTROY,
  RESET_PROFILE_SUCCESS
];

export default () => (next) => (action) => {
  if (SET_AUTH_ACTIONS.indexOf(action.type) > -1 ) {
    setToken(action.payload);
  }

  if (CLEAR_AUTH_ACTIONS.indexOf(action.type) > -1 ) {
    clearToken();
  }

  return next(action);
};
