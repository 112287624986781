import React from 'react';
import Modal from 'components/modal';
import Share from 'components/share';
import { connect } from 'react-redux';
import { shareButtonAction } from 'modules/share';

const SHARE_TEXT_COPY = 'I found this awesome job opportunity on PaddlJobs!';
const shareItems = ['facebook', 'linkedin', 'twitter', 'email'];

class ShareModalContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      isCopied: false
    };
    this.handleCopy = this.handleCopy.bind(this);
    this.handleRef = this.handleRef.bind(this);
  }

  handleRef(el) {
    this.input = el;
  }

  handleCopy() {
    this.input.select();

    document.execCommand('copy');

    this.setState({
      isCopied: !this.state.isCopied
    });

    setTimeout(() => {
      this.setState({
        isCopied: false
      });
    }, 2000);
  }

  render() {
    const {
      job,
      isOpen,
      onClose,
      onShare
    } = this.props;

    return (
      <Modal
        isOpen = { isOpen }
        onClose = { onClose }
      >
        <Share
          title       = "Share this Job"
          subtitle    = "Select to share or copy the link below"
          itemTitle   = { SHARE_TEXT_COPY }
          url         = { `jobs/${job.id}` }
          job         = { job }
          handleCopy  = { this.handleCopy }
          isCopied    = { this.state.isCopied }
          items       = { shareItems }
          setRef      = { this.handleRef }
          onShare     = { onShare }
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onShare: (job, share_type) => {
      dispatch(
        shareButtonAction(job, share_type)
      )
    }
  }
}

export default connect(null, mapDispatchToProps)(ShareModalContainer);
