import './job__questions.scss';
import React from 'react';

export default ({ values, id, type }) => {
  return (
    <div className = { `job__questions is--${type}` }>
      { !values &&
        <div className = "job__questions__placeholder">Click Edit to add your first Question.</div>
      }
      { values && values.map && values.map(({ question }, index) => (
        <div className = "job__questions__item" key = { index }>
          <div className = "job__questions__item__title">Q{ index + 1}</div>
          <div className = "job__questions__item__value">
            { question }
          </div>
        </div>
      ))}
    </div>
  )
}
