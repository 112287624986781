import './index.scss';
import React from 'react';

import Text                          from 'component-lib/type/text';
import { style18pxRegularBlueD }     from 'component-lib/type/text/styles';
import { style26pxRegularBlue }      from 'component-lib/type/text/styles';
import { style14pxRegularBluegreyD } from 'component-lib/type/text/styles';

const BasecampCollectedXpSection = ({ totalCollectedXp }) => {
  return (
    <div className = "basecamp-collected-xp-section">
      <div className = "basecamp-collected-xp-section__xp-value">
        <Text { ...style26pxRegularBlue } text = { totalCollectedXp } />
        <Text { ...style18pxRegularBlueD } text = 'XP' />
      </div>
      <Text { ...style14pxRegularBluegreyD } text = 'Total XP' />
    </div>
  );
};

export default BasecampCollectedXpSection;
