import React                from 'react';
import { connect }          from 'react-redux';
import Modal                from 'components/modal';
import SvgIcon              from 'components/svg-icon';
import { toggleChallenges } from 'modules/jobs/create/index';
import JobChallengesModal   from './modal';
import {
  FORM_ID,
  CHALLENGES
}  from 'modules/jobs/create/constants';

const JobChallengesAdd = ({ challenges, handleAddChallenge, isModalVisible, toggleChallenges }) => (
  <div className = "job__challenges__add">
    { challenges.length > 0 &&
      <div
        onClick   = { toggleChallenges }
        className = "job__challenges__add__button">
        <SvgIcon id = "plus" />
        Add a Challenge
      </div>
    }
    <Modal
      isOpen  = { isModalVisible }
      onClose = { toggleChallenges }
      isDark >
      <JobChallengesModal
        handleClick = { handleAddChallenge }
        items       = { challenges } />
    </Modal>
  </div>
);

export default connect(({ form, jobs: { create } }) => {
  const values = form[FORM_ID] && form[FORM_ID].values && form[FORM_ID].values.challenges;
  const challenges = values
      ? CHALLENGES.filter(({ id }) => values.map(v => v.id).indexOf(id) === -1)
      : CHALLENGES;
  const isModalVisible = challenges.length === 0 ? false : create.isChallengesModalVisible;

  return {
    challenges,
    isModalVisible
  };
}, { toggleChallenges })(JobChallengesAdd);
