import React from 'react';

const SvgIconHelp = props => (
  <svg
    width = { 98 }
    height = { 98 }
    viewBox = "0 0 98 98"
    fill = "currentColor"
    { ...props }
  >
    <path d = "M49 4.3125C73.64 4.3125 93.6875 24.36 93.6875 49C93.6875 73.64 73.64 93.6875 49 93.6875C24.36 93.6875 4.3125 73.64 4.3125 49C4.3125 24.36 24.36 4.3125 49 4.3125ZM49 0.875C22.4212 0.875 0.875 22.4212 0.875 49C0.875 75.5788 22.4212 97.125 49 97.125C75.5788 97.125 97.125 75.5788 97.125 49C97.125 22.4212 75.5788 0.875 49 0.875Z" />
    <path d = "M35.25 36.5382C35.25 27.3455 42.1837 21.5 50.4449 21.5C58.706 21.5 66.2581 27.3455 66.2581 36.5382C66.2581 44.0161 61.1959 49.3174 55.1196 51.1065C51.2281 52.2773 48.4167 53.5222 48.4167 58.2711V61.3134H43.272V58.2711C43.272 50.9499 47.6335 47.99 53.5531 46.1927C57.527 44.9478 61.1134 41.4438 61.1134 36.53C61.1134 30.4537 55.8946 26.6364 50.4366 26.6364C44.9869 26.6364 40.3864 30.4537 40.3864 36.53H35.25V36.5382ZM45.8444 68.4038C48.1776 68.4038 50.1316 70.1187 50.1316 72.4519C50.1316 74.7934 48.1858 76.5 45.8444 76.5C43.5029 76.5 41.5572 74.7851 41.5572 72.4519C41.5572 70.1187 43.5111 68.4038 45.8444 68.4038Z" />
  </svg>
);

export default SvgIconHelp;
