import './index.scss';

import React        from 'react';
import List         from 'components/list';
import JobListPanel from 'containers/job-list-panel';

const ProfileCampaignsList = ({ campaigns }) => {
  return (
    <div className = "v2-profile-campaigns-list">
      <div className = "v2-profile-campaigns-list__container">
        <div className = "v2-profile-campaigns-list__content">
          <List items = { campaigns } Component = { JobListPanel } />
        </div>
      </div>
    </div>
  );
};

export default ProfileCampaignsList;
