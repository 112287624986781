import createReducer from 'utils/create-reducer';
import { CALL_API }  from 'middleware/call-api';
import { villageSchema } from 'schemas';

const FETCH_REQUEST = 'villages/FETCH_REQUEST';
const FETCH_SUCCESS = 'villages/FETCH_SUCCESS';
const FETCH_FAILURE = 'villages/FETCH_FAILURE';

export function fetchVillages() {
  return {
    [CALL_API]: {
      types: [
        FETCH_REQUEST,
        FETCH_SUCCESS,
        FETCH_FAILURE
      ],
      endpoint: '/villages',
      schema: villageSchema
    }
  }
}

export default createReducer('villages');
