import './job-challenges-list.scss';

import PropTypes from 'prop-types';
import React from 'react';

const JobChallengesList = ({ challenges }) =>  {
  return (
    <ul className = "job-challenges__list">
      { challenges && challenges.length > 0 &&
        challenges.map((challenge, index) => (
          <li key = { index } className = "job-challenges__list__item">
            <img
              className = "job-challenges__list__icon"
              src = { require(`./Paddl-ChallengeIcon-${challenge.id}.svg`) } />
            <div className = "job-challenges__list__label">
              { challenge.title }
            </div>
          </li>
      ))
    }
    </ul>
  );
};

JobChallengesList.propTypes = {
  challenges: PropTypes.array
};

export default JobChallengesList;
