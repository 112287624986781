import './empty-view.scss';

import React            from 'react';
import { connect }      from 'react-redux';
import Button           from 'components/button';
import { EMPTY_PANELS } from 'config/panels';

const PAGES_WITH_ICON = [
  'applications-employer',
  'applications-student',
  'conversations-employer',
  'conversations-student',
  'jobs-student',
  'jobs-employer'
];

const getProps = (id) => ({
  icon: id,
  ...EMPTY_PANELS[id]
});

const EmptyView = ({ pageId }) => {
  const {
    icon,
    action,
    linkTo,
    text
  } = getProps(pageId);

  const hasIcon  = icon && PAGES_WITH_ICON.indexOf(icon) > -1;

  return (
    <div className = "empty-view">
      { hasIcon &&
        <img
          src       = { require(`images/icon--empty-${icon}.svg`) }
          className = "empty-view__icon" />
      }
      <p
        className = "empty-view__text"
        dangerouslySetInnerHTML = { { __html: text } } />
      { action &&
        <Button
          className = "is--action is--centered is--wide"
          link      = { linkTo } >
          { action }
        </Button>
      }
    </div>
  );
};

export default connect(state => ({
  pageId: state.ui.pageId
}))(EmptyView);
