import React from 'react';
import { withScriptjs } from "react-google-maps"

const AsyncAutocomplete = withScriptjs((props) => <FormLocationInput { ...props } />);

export default (props) => (
  <AsyncAutocomplete
    googleMapURL     = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDMJNmxwNJspSrQvgejfgB4KxqK8Wsxh-Q&libraries=places&language=en-AU"
    loadingElement   = { <div /> }
    loadingElement   = { <div /> }
    loadingElement   = { <div /> }
    { ...props } />
);

class FormLocationInput extends React.Component {
  constructor(props) {
    super(props);

    this.autocomplete       = null;
    this.handleKeyDown      = this.handleKeyDown.bind(this);
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
  }
  componentDidMount() {
    if (typeof google === 'undefined') return;

    this.autocomplete = new google.maps.places.Autocomplete(this._input);
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }
  handleKeyDown(e) {
    if (e.keyCode === 13) {
      e.stopPropagation();
      e.nativeEvent.preventDefault();
    }
  }
  handlePlaceChanged() {
    const place    = this.autocomplete.getPlace();
    const location = place && place.geometry && place.geometry.location;
    const address  = this._input.value;

    this.props.onChange({
      lat: location.lat(),
      lng: location.lng(),
      address
    });
  }
  render() {
    const {
      value: { address }
    } = this.props;

    return (
      <input
        onKeyDown    = { this.handleKeyDown }
        type         = "text"
        className    = "input__text"
        ref          = { r => this._input = r }
        defaultValue = { address } />
    )
  }
}
