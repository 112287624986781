import './index.scss';
import React from 'react';

import { STUDENT } from 'lib/api/profiles/profile';
import { ACCESS_DENIED } from 'lib/paddl-error';

import Common                from 'pages/v2/profile/common';
import SettingsHomePagePanel from 'components/v2/settings/settings-home-page-panel';
import SettingsPageTitle     from 'components/v2/settings/page-title';

class SettingsHomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: Common.createEmptyProfile(),
    };
  }

  componentDidMount() {
    const { appv2 } = this.props;
    const currentProfileId = appv2.auth.currentProfileId();

    appv2.api.profiles
      .fetch({ id: currentProfileId, isLoggedIn: true })
      .then((profile) => {
        this.setState({ profile });
      })
      .catch((error) => {
        if (error.isPaddlError === true && error.code === ACCESS_DENIED) {
          return appv2.ui.redirect({ url: '/not-found' });
        }

        throw error;
      });
  }

  render() {
    const { profile } = this.state;

    const firstName = profile.get('fullName').split(' ')[0];
    const role      = profile.get('role');

    return (
      <div className = "v2-settings-home-page">
        <div className = "v2-settings-home-page__page-wrapper">
          <SettingsPageTitle
            heading    = { `${firstName}’s Settings` }
            subheading = "Select a section below to make changes to your Paddl Settings"
          />

          <div className = "v2-settings-home-page__panel">
            <SettingsHomePagePanel
              href        = "/settings/account"
              iconSrc     = { require('images/v2/icon-circle-profile.svg') }
              heading     = "Your Account"
              description = "Manage your personal details, contact details, security settings and more."
            />
          </div>

          {
            role === STUDENT &&
            <div className = "v2-settings-home-page__panel">
              <SettingsHomePagePanel
                href        = "/settings/preferences"
                iconSrc     = { require('images/v2/icon-circle-preferences.svg') }
                heading     = "Preferences"
                description = "Manage your profile visibility settings."
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

export default SettingsHomePage;
