import './list-panel.scss';

import React              from 'react';
import classNames         from 'classnames';
import SvgIcon            from 'components/svg-icon';
import ListPanelThumb     from 'components/list-panel-thumb';
import { browserHistory } from 'react-router';

const handleClick = e => {
  return e.target.tagName !== 'LABEL' && e.target.tagName !== 'INPUT';
};

const ListPanel = (props) => {
  const {
    id,
    thumb,
    sticker,
    title,
    data,
    addons,
    link,
    className,
    onClick,
    isSelectable
  } = props;

  return (
    <div
      onClick   = { e => handleClick(e) && (onClick ? onClick(e) : link && browserHistory.push(link) ) }
      className = { classNames('list-panel', className, {
        'has--link': !!link
      }) }>

      { (isSelectable || thumb) &&
        <ListPanelThumb
          id           = { id }
          isSelectable = { isSelectable }
          thumb        = { thumb }
          sticker      = { sticker } />
      }
      <div className = "list-panel__content">
        <div className = "list-panel__content__inner">
          <h3 className = "list-panel__title">
            { title }
          </h3>
          <div className = "list-panel__data">
            { data }
          </div>
        </div>
        { addons &&
          <div className = "list-panel__addons">
            { addons }
          </div>
        }
      </div>
      { link &&
        <div className = "list-panel__icon">
          <SvgIcon id = "arrow-right" />
        </div>
      }
    </div>
  );
};

export default ListPanel;
