import React        from 'react';
import PropTypes    from 'prop-types';
import map          from 'lodash/map';
import cx           from 'classnames';
import ProfilePhoto from 'components/profile-photo';
import Question     from 'components/question';
import Button       from 'components/button';
import SvgIcon      from 'components/svg-icon';
import { browserHistory } from 'react-router';

const answers = ['Yes', 'No'];

export default class QuestionThread extends React.Component {

  rendertukiQuestion(message, key) {
    return (
      <li key={ key } className="message is--tuki">
        <div className="messageThread--photo">
          <ProfilePhoto photo={ require('images/tukiAvatar.svg') }/>
        </div>
        <div className="content pls">
          <h3 className="author">
            Tuki
          </h3>
          { message }
        </div>
      </li>
    );
  }

  renderConfirmation() {
    let { questions, onConfirmation, showConfirmation } = this.props,
      confirmationSuccess = null; // null represents no questions

    if (showConfirmation === false)
      return null;

    for (let question of questions) {
      // if any are unanswered, all bets are off.
      if (!question.response)
        return null;

      // we need to set confirmation success, if unset.
      if (confirmationSuccess === null)
        confirmationSuccess = true;
    }

    if (confirmationSuccess === null) {
      // no questions
      return null;

    } else if (confirmationSuccess) {
      // all questions correct
      let employer = this.props.employer.business_name;
      let enableButton = true; // disabling the button for the launch event

      return this.rendertukiQuestion(
        <div>
          <p>
            All done! <br/><br/>
            { employer } will reveal your Challenge Score once they have processed your Application. Let’s go back to your Application and get it ready to Submit!
          </p>
          {
            enableButton
              ? <Button className = "is--action ui__back-to-application" onClick = { () => browserHistory.goBack() }>
              Return to Application
            </Button>
              : null
          }
        </div>, 'success');
    }
  }

  render() {
    const {
      auth,
      questions,
      onQuestionAnswer,
      className
    } = this.props;

    if (questions && questions.length === 0) return null;
    const isStudent = auth.role === 'student';

    return (
      <ul className = { cx("messageThread -questions", className) }>
        { isStudent &&
          <div style = {{ textAlign: 'center', marginTop: 56 }}>
            <img
              style = {{ width: 150, height: 150 }}
              src = { require('images/icon--questions-insight.svg') } />
              <h2 style = {{ color: '#124f71', fontWeight: '400' }}>Insights Challenge</h2>
          </div>
        }
        { isStudent && this.rendertukiQuestion(
          <div>
            Welcome to the Insights Challenge! <br/>
            {this.props.employer.business_name} have prepared a few yes or no questions to learn a bit more about you.
            Just read each question and answer truthfully by hitting a ‘Yes’ or ‘No’ button below it.
            <br />
            <br/>
            Let’s get started…
        </div>, 'tuke-info') }
        {
          map(questions, question =>
            <Question key={ question.id }
                      question={ question }
                      answers={ answers }
                      onQuestionAnswer={ onQuestionAnswer }/>
          )
        }
        { this.renderConfirmation() }
      </ul>
    )
  }
};

QuestionThread.propTypes = {
  className: PropTypes.string,

  default: PropTypes.node,
  questions: PropTypes.array.isRequired,
  showConfirmation: PropTypes.bool,

  onQuestionAnswer: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired
};

QuestionThread.defaultProps = {
  showConfirmation: true
}
