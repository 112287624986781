// Module / Reg Sent Mobile
// https://www.figma.com/file/C7moYN335w1QAgCkrbTZCRSx/Get-Started-with-Paddl?node-id=2455%3A635

// Module / Reg Sent
// https://www.figma.com/file/C7moYN335w1QAgCkrbTZCRSx/Get-Started-with-Paddl?node-id=2455%3A591

import React from 'react';

import AccessTitles from 'components/v2/register/AccessTitles/AccessTitles';

import './InfoEnvelope.scss';

const InfoEnvelope = () => (
  <div className="InfoEnvelope">
    <img
      className="InfoEnvelope__img"
      src={require('images/icon--register-success.svg')}
      alt=""
    />
    <AccessTitles
      heading="Check your inbox :)"
      subheading="We have sent you an Activation Link."
    />
  </div>
);

export default InfoEnvelope;
