import React   from 'react';
import Flexbox from 'components/flexbox';
import Button  from 'components/button';

const DETAILS_BY_STYLE = {
  highlighted: {
    description : 'Shortlist this Applicant to reveal their Contact Details',
    buttonLabel : 'Shortlist',
    isSuccess: true,
    isOpaque: false
  },
  opaque: {
    description : 'You have marked this Application Unsuccessful.',
    buttonLabel : 'Move to Shortlist',
    isSuccess: false,
    isOpaque: true
  }
};

const statusToStyle = (status) => {
  switch (status) {
  case 'unsuccessful':
    return 'opaque';
  default:
    return 'highlighted';
  }
};

const ApplicationCallToAction = ({
  status,
  onClick
}) => {
  const style = statusToStyle(status);
  const details = DETAILS_BY_STYLE[style];
  return (
    <Flexbox>
      { details.description }
      <Button
        onClick = { onClick }
        isWide
        isSuccess = { details.isSuccess }
        isOpaque = { details.isOpaque }>
        { details.buttonLabel }
      </Button>
    </Flexbox>
  );
};
export default ApplicationCallToAction;
