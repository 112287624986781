import React              from 'react';
import omitBy             from 'lodash/omitBy';
import flatten            from 'lodash/flatten';
import map                from 'lodash/map';
import isArray            from 'lodash/isArray';
import isEmpty            from 'lodash/isEmpty';
import { connect }        from 'react-redux';
import { browserHistory } from 'react-router';
import { jobSelector }    from 'selectors/index';
import Form               from 'components/job-create/form';
import PageTitle          from 'components/page-title';
import withEditConfirm    from 'containers/withEditConfirm';
import validate           from 'modules/jobs/create/validate';
import PageContent        from 'components/page-content';

import {
  reset,
  submit,
  arrayPush,
  arrayRemove,
  arraySwap,
  stopSubmit
} from 'redux-form';

import {
  editCard,
  editCards,
  saveCard,
  create,
  update,
  destroy,
  toggleChallenges
} from 'modules/jobs/create/index';

import {
  FORM_ID,
  MAP_JOB_TO_CAMPAIGN
} from 'modules/jobs/create/constants';

class JobCreate extends React.Component {
  constructor() {
    super();

    this.shouldValidate        = this.shouldValidate.bind(this);
    this.handleSubmit          = this.handleSubmit.bind(this);
    this.handleSaveCard        = this.handleSaveCard.bind(this);
    this.handlePost            = this.handlePost.bind(this);
    this.handleReset           = this.handleReset.bind(this);
    this.handleAddChallenge    = this.handleAddChallenge.bind(this);
    this.handleRemoveChallenge = this.handleRemoveChallenge.bind(this);
    this.handleSwapChallenge   = this.handleSwapChallenge.bind(this);
  }

  UNSAFE_componentWillMount() {
    const {
      isEditingNew,
      editCard
    } = this.props;

    if (!isEditingNew) {
      editCard({ id: 'details' });
    }
  }

  componentWillUnmount() { this.props.destroy(); }

  shouldValidate() { return this.props.isEditingNewLive; }

  handleReset() { this.props.reset(FORM_ID); }

  handleAddChallenge(value) {
    const {
      arrayPush,
      editCard,
      toggleChallenges,
      values: { challenges }
    } = this.props;

    arrayPush(FORM_ID, 'challenges', value);
    editCard({ id: `challenges[${challenges ? challenges.length : 0}]`});
    toggleChallenges();
  }

  handleRemoveChallenge({ index }) {
    const {
      arrayRemove,
      submit,
      isEditingNew
    } = this.props;

    arrayRemove(FORM_ID, 'challenges', index);
    // refactor job__chalenges to FieldsArray to fix setTimeout
    isEditingNew && setTimeout(() => submit(FORM_ID));
  }

  handleSwapChallenge({ oldIndex, newIndex }) {
    if (oldIndex !== newIndex) {
      this.props.arraySwap(FORM_ID, 'challenges', oldIndex, newIndex);
    }
  }

  handleSubmit(data, action, { dirty }) {
    if (!dirty) return;

    const {
      create, update, job
    } = this.props;

    const id = job && job.id;
    const values = {
      ...data,
      details: omitBy(data.details, v => v === '')
    };

    if (id) {
      return update({ values, id });
    }

    return create({ values });
  }

  handleSaveCard({ id }) {
    const {
      jobPayload
    } = this.props;
    this.props.saveCard({ id, jobPayload });
    this.props.submit(FORM_ID);
  }

  handlePost() {
    const {
      editCards,
      job: { id },
      stopSubmit,
      values
    } = this.props;

    const errors = validate(values);

    if (isEmpty(errors)) {
      browserHistory.push(`/jobs/${id}/confirm`);
    }

    // again, refactor challenges to FieldsArray;
    editCards({
      ids: flatten(map(errors, (v, k) => (
        isArray(v) ? v.map((item, index) => item && `${k}[${index}]`) : k
      )))
    });

    return stopSubmit(FORM_ID, errors);
  }

  render() {
    const {
      isDraft,
      isEditingNew,
      isEditingNewLive,
      editingCards,
      job,
      editCard
    } = this.props;

    // WEBPACKU NOTE:
    // on first creation of a new job, this "job" comes in as undefiend, which kills the whole page
    // returning instead seems to prevent the problem.
    // no idea why the behavior is different to the previous webpack setup
    if (!job) return null

    return (
      <PageContent>
        { isDraft &&
          <PageTitle>
            Job Post
          </PageTitle>
        }
        { isDraft &&
          <h3 className = "page__subtitle">
            Let's start by attracting the right students to your role.
          </h3>
        }
        <Form
          { ...{
            shouldValidate:        this.shouldValidate,
            handleAddChallenge:    this.handleAddChallenge,
            handleRemoveChallenge: this.handleRemoveChallenge,
            handleSwapChallenge:   this.handleSwapChallenge,
            handleSubmit:          this.handleSubmit,
            handlePost:            this.handlePost,
            handleReset:           this.handleReset,
            saveCard:              this.handleSaveCard,
            initialValues:         job,
            canEditChallenges:     isDraft || !job,
            canPay:                isDraft && job.id,
            isChallengesHidden:    !isDraft && job.challenges && job.challenges.length === 0,
            editCard,
            editingCards,
            isEditingNew,
            isEditingNewLive
          } }
        />
      </PageContent>
    );
  }
}

export default connect(
  (state, { params: { id } }) => {
    const {
      form, jobs
    } = state;

    const job           = jobSelector(state, id);
    const normalizedJob = MAP_JOB_TO_CAMPAIGN(job);

    // WEBPACKU NOTE:
    // when a job is submitted for the first time, this component re-renders
    // with: form[FORM_ID] === {submitSucceeded: true} which doesn't contain .values
    // and for some reason all the user entered data disappears
    // this just prevents the re-rendering so that doesn't happen.
    // no idea why the behavior is different to the previous webpack setup
    if (form[FORM_ID] && !form[FORM_ID].values) {
      return null
    }

    const jobPayload = {
      id: job.id,
      title: form[FORM_ID] ? form[FORM_ID].values.details.headline : job.title,
      created_at: job.created_at
    };

    return {
      isEditing:     !!id && jobs.create.editingCards && jobs.create.editingCards.length,
      isEditingNew:  !!id,
      isEditingLive: job.status === 'open' && !!id,
      isDraft:       !job.status || job.status === 'draft',
      editingCards:  jobs.create.editingCards,
      values:        form[FORM_ID] && form[FORM_ID].values,
      jobPayload:    jobPayload,
      job:           normalizedJob
    };
  },
  {
    editCard,
    editCards,
    saveCard,
    arrayPush,
    arrayRemove,
    arraySwap,
    reset,
    stopSubmit,
    create,
    update,
    submit,
    destroy,
    toggleChallenges
  }
)(withEditConfirm(JobCreate));
