import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { closeSidenav } from 'modules/ui';

import { SvgLogoPaddl } from 'component-lib/svg-components/sidenav/svg-logo-paddl';
import { SvgIconPower } from 'component-lib/svg-components/sidenav/svg-icon-power';
import { SvgIconExpand } from 'component-lib/svg-components/sidenav/svg-icon-expand';
import { SvgIconPlus } from 'component-lib/svg-components/sidenav/svg-icon-plus';
import { AvatarSpinner } from 'component-lib/components/avatar-spinner';
import { NotificationBubble } from 'component-lib/components/notification-bubble';
import { CtaButton } from 'component-lib/components/cta-button';
import { V2BetaBiscuit } from 'components/v2/beta-biscuit';

import { menuItems, footerMenuItems } from './sidenav.content';
import './index.scss';

// TODO: Remove commented-out GuideWidget after the entire infrastructure of Tour Guide is removed
// import GuideWidget from 'components/guide/guide-widget';

export class Sidenav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuItems,
      profileAvatarAnimate: true,
      totalXpNumber: 0,
      profile: null,
      profilePath: null,
    };

    this.profileAvatarAnimateTimeout = null;
    this.onOutsideClick = this.onOutsideClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.onOutsideClick, false);
    document.addEventListener('touchstart', this.onOutsideClick, false);

    const { appv2 } = this.props;
    const currentProfileId = appv2.auth.currentProfileId();

    appv2.api.profiles
      .fetchWithExtraByIdOrSlug({ idOrSlug: currentProfileId, isLoggedIn: true })
      .then(({ profile }) => {
        this.setState({ profile });
      });

    appv2.api.profiles
      .fetchTotalXpNumber({ id: currentProfileId })
      .then((totalXpNumber) => {
        this.setState({ totalXpNumber });
      });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onOutsideClick, false);
    document.removeEventListener('touchstart', this.onOutsideClick, false);
  }

  onOutsideClick({ target }) {
    const { isOpen, onClose } = this.props;

    const isInside = this.refs.sidenavContainer.contains(target);
    const isToggleButton = target.className === 'navbar__toggle';

    if (isOpen && !isInside && !isToggleButton) {
      onClose();
    }
  }

  onIconButtonClick(e, type) {
    const href = e.currentTarget.attributes.href.value;

    if (type === 'menuItems') {
      const items = this.state[type].map((item) => ({
        ...item,
        active: item.url === href,
      })
    );

      this.setState({ [type]: items });
    }
  }

  onTooltipButtonMouseEnter(e, text) {
    this.props.onTooltipButtonMouseEnter(e, text);
  }

  onTooltipButtonMouseLeave(e) {
    this.props.onTooltipButtonMouseLeave(e);
  }

  toggleExpand(e) {
    this.props.onToggleExpand(e);
  }

  toggleProfileAvatarAnimate(state) {
    const profileAvatarAnimate = typeof state === 'boolean' ? state : !this.state.profileAvatarAnimate;
    this.setState({ profileAvatarAnimate });

    if (profileAvatarAnimate) {
      if (this.profileAvatarAnimateTimeout) {
        window.clearTimeout(this.profileAvatarAnimateTimeout);
      }

      this.profileAvatarAnimateTimeout = window.setTimeout(() => {
        this.setState({ profileAvatarAnimate: false });
      }, 4000);
    }
  }

  renderIconButton(item, type) {
    const { appv2, onClose } = this.props;

    const onSupportClick = () => {
      onClose();
      appv2.ui.openSidenavSupportModal({ withLeftOffset: true });
    };

    return (
      <Link
        to={item.url}
        className="sidenav__icon-button-list__button"
        activeClassName="active"
        onClick={item.title === 'Support' ? onSupportClick : ((e) => this.onIconButtonClick(e, type))}
        onMouseEnter={(e) => this.onTooltipButtonMouseEnter(e, item.title)}
        onMouseLeave={(e) => this.onTooltipButtonMouseLeave(e)}
      >
        <span className="sidenav__icon-button-list__button__icon">
          { React.createElement(item.icon, {}) }
        </span>
        <span className="sidenav__icon-button-list__button__text">
          { item.title }
        </span>
        {
          item.notification && item.notification.count > 0
            ? (
              <span className="sidenav__icon-button-list__button__notification">
                <NotificationBubble notification={item.notification} />
              </span>
            )
            : null
        }
        {
          item.title === 'Basecamp' &&
            <span className="sidenav__icon-button-list__button__notification">
              <V2BetaBiscuit />
            </span>
        }
      </Link>
    );
  }

  render() {
    const { appv2, onClose } = this.props;
    const { menuItems, totalXpNumber, profile, profileAvatarAnimate } = this.state;

    const isXpFeatureEnabled = appv2.featureFlags.isEnabled({ featureName: 'xp' });

    const fetchAvatarImage = () => {
      if (!profile) {
        return null;
      }

      const id = profile.get('id');
      const role = profile.get('role');
      const avatarUrl = profile.get('avatarUrl');

      const placeholderId = (role === 'student')
        ? `${role}-${id % 5 + 1}`
        : 'employer';

      const avatarImage = avatarUrl || require(`images/placeholder--avatar-${placeholderId}.svg`);

      return avatarImage;
    };

    const header = (
      <div className="sidenav__header">
        <div className="sidenav__logo">
          <Link className="sidenav__logo-link" to="/">
            <SvgLogoPaddl />
          </Link>
          { /* TODO: Try using refactored PaddlLogo to replace SvgLogoPaddl
          after feature/landing-pages is merged into master */ }
        </div>
        <div
          className="sidenav__logout-button"
          onClick={() => {
            onClose();
            appv2.ui.openSidenavLogoutModal({
              withLeftOffset: true,
              avatarImage: fetchAvatarImage(),
              fullName: profile && profile.get('fullName'),
            });
          }}
        >
          <SvgIconPower />
        </div>
      </div>
    );

    const renderProfileSection = () => {
      if (!profile) {
        return null;
      }

      const id = profile.get('id');
      const role = profile.get('role');
      const fullName = profile.get('fullName');

      const getLinkUrl = () => {
        const profilePath = this.state.profilePath ||
        (
          appv2.slugs.generateProfilePath({
            id,
            callback: ({ path }) => { this.setState({ profilePath: path }); },
          })
        );

        const linkUrl = profilePath + '/' + (role === 'student' ? 'experience' : 'summary');

        return linkUrl;
      };

      const profileTooltipCopy = (isXpFeatureEnabled && role === 'student')
        ? `${fullName} - ${totalXpNumber} XP`
        : fullName;

      return (
        <Link
          to={getLinkUrl()}
          className="sidenav__profile"
          activeClassName="active"
          onClick={() => { this.toggleProfileAvatarAnimate(true); }}
          onMouseEnter={(e) => this.onTooltipButtonMouseEnter(e, profileTooltipCopy)}
          onMouseLeave={(e) => this.onTooltipButtonMouseLeave(e)}
        >
          <div className="sidenav__profile__avatar">
            <AvatarSpinner
              animated={profileAvatarAnimate}
              backgroundImage={`url(${fetchAvatarImage()})`}
            />
          </div>
          <div className="sidenav__profile__details">
            <div className="sidenav__profile__details__name">
              { fullName }
            </div>
            {
              (isXpFeatureEnabled && role === 'student') &&
              <div className="sidenav__profile__details__xp">
                { totalXpNumber } XP
              </div>
            }
          </div>
        </Link>
      );
    };

    const renderCtaList = () => {
      if (!profile) {
        return null;
      }

      const role = profile.get('role');

      if (role !== 'student') {
        return null;
      }

      return (
        <div className="sidenav__cta">
          <ul className="sidenav__cta-list">
            <li>
              <CtaButton
                onClick={() => { onClose(); appv2.ui.openNewActivityModal({}); }}
                onMouseEnter={(e) => this.onTooltipButtonMouseEnter(e, 'Add an Activity')}
                onMouseLeave={(e) => this.onTooltipButtonMouseLeave(e)}
              >
                <span className="visible-toggle visible-expanded tooltip-text">
                  Add an Activity
                </span>
                <span className="visible-toggle visible-contracted">
                  <SvgIconPlus />
                </span>
              </CtaButton>
            </li>
          </ul>
        </div>
      );
    };

    const renderMenuList = () => {
      if (!profile) {
        return null;
      }

      const role = profile.get('role');

      const items = isXpFeatureEnabled
        ? menuItems.filter(item => item.visibleToRoles.indexOf(role) !== -1)
        : menuItems.filter(item => item.visibleToRoles.indexOf(role) !== -1).filter(item => !item.xpFeatureEnabledRequired);

      return (
        <div className="sidenav__menu">
          <ul className="sidenav__icon-button-list">
            {
              items.map((item, index) => (
                <li key={`sidenav__icon-button-list__item-${index}`}>
                  { this.renderIconButton(item, 'menuItems') }
                </li>
              ))
            }
          </ul>
        </div>
      );
    };

    const renderFooter = () => {
      const currentYear = new Date().getFullYear();

      return (
        <div className="sidenav__footer">
          <div className="sidenav__footer__menu">
            <ul className="sidenav__icon-button-list">
              {
                footerMenuItems.map((item, index) => (
                  <li key={`sidenav__icon-button-list__item-${index}`}>
                    { this.renderIconButton(item, 'footerMenuItems') }
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="sidenav__footer__expand-button">
            <div
              className="sidenav__footer__expand-button__icon"
              onClick={(e) => this.toggleExpand(e)}
            >
              <SvgIconExpand />
            </div>
          </div>

          <div
            className="sidenav__footer__company-info"
            onClick={() => {
              onClose();
              appv2.ui.openSidenavCompanyModal({ withLeftOffset: true });
            }}
          >
            <div className="visible-toggle visible-expanded">
              <div className="sidenav__footer__company-info__text">
                Made with <span className="highlight">♡</span> by Paddl Co.
              </div>
              <div className="sidenav__footer__company-info__copyright">
                { `© Paddl Co. ${currentYear}` }
              </div>
            </div>
            <div className="visible-toggle visible-contracted">
              <div className="sidenav__footer__company-info__text">
                <span className="highlight">♡</span> Paddl Co.
              </div>
              <div className="sidenav__footer__company-info__copyright">
                { `© ${currentYear}` }
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <nav className="sidenav" ref="sidenavContainer">
        <div className="sidenav__inner">
          { header }
          { renderProfileSection() }
          { renderCtaList() }
          { renderMenuList() }
          {/* <GuideWidget /> */}
          { renderFooter() }
        </div>
      </nav>
    );
  }
}

export default connect(
  state => ({
    isOpen: state.ui.isSidenavOpen,
  }),
  dispatch => ({
    onClose: () => dispatch(closeSidenav()),
  }),
)(Sidenav);
