import Slugs from 'lib/slugs';

export default ({ appv2 }) => {
  return {
    path:         'profile(/:id)',
    title:        'Profile',
    getComponent: (nextState, cb) => {
      const { location } = nextState;
      const isPublicPreviewPage = (location.search.indexOf('public=1') > -1);

      if (typeof nextState.params.id === 'undefined') {
        return appv2.ui.redirect({
          url: appv2.slugs.generateProfilePath({ id: appv2.auth.currentProfileId() }),
        });
      }

      return appv2.ui.redirect({
        url: `${ Slugs.createPath(nextState.params.id, null) }/experience${ isPublicPreviewPage ? '?public=1' : ''}`,
      });
    },
  };
};
