import './index.scss';
import React from 'react';

export class NotificationBubble extends React.Component {
  renderCount(count) {
    return parseInt(count, 10) > 99 ? '99+' : count;
  }

  render() {
    const { type, count } = this.props.notification;

    return (
      <div className = { `notification-bubble notification-bubble--${type}` }>
        { this.renderCount(count) }
      </div>
    );
  }
}
