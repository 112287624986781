import React from 'react';

const SvgIconParchment = props => (
  <svg
    width = { 32 }
    height = { 32 }
    viewBox = "0 0 32 32"
    fill = "currentColor"
    { ...props }
  >
    <path d = "M27 19.5l-1.3-2.8 1.2-3.1h-6.6L22.5.1H9.9l2 13.5h-1v6h.9l-2 12.5h12.8L21 22.2l3.8 2.8v-3.8l1.7-1.7h.5zm-1.5-5l-.9 2.2.9 1.8h-3.7v-4h3.7zM21.5 1l-2.1 12.5H18L19.5 1h2zM11 1h7.7l-1.5 12.5h-4.3L11 1zm.8 17.5v-4h9v4h-9zM18.6 31H11l1.8-11.5h4.5L18.6 31c.1 0 0 0 0 0zm1 0l-1.5-11.5h1.5L21.5 31h-1.9zm4.2-10.2V23l-3-2.2-.2-1.2h4.6l-1.4 1.2z" />
  </svg>
);

export default SvgIconParchment;
