import './index.scss';

import React      from 'react';
import classNames from 'classnames';

const V2PageContent = ({
  children,
  isFull,
}) => (
  <div
    className = { classNames('v2-page-content', {
      'is--full': isFull,
    }) }
  >
    { children }
  </div>
);

export default V2PageContent;
