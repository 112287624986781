import React from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import { Navbar } from 'components/navbar';
import { NavbarPageActions } from 'components/navbar-page-actions';
import {
  toggleSidenav,
  toggleBulk,
} from 'modules/ui';

class NavbarContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.isActive && nextProps.isActive && !nextProps.currentRoute.formId) {
      this.props.toggleBulk();
    }
  }

  handleOnClick() {
    const {
      router,
      hasBack,
      isActive,
      params,
      currentRoute: {
        link,
        formId,
      },
      toggleBulk,
      reset,
    } = this.props;

    if (!isActive && !hasBack) return;

    if (isActive) {
      if (formId) reset(formId);
      return toggleBulk();
    }

    if (link) {
      return router.push(typeof link === 'function' ? link(params) : link);
    }

    return router.goBack();
  }

  render() {
    const {
      appv2,
      isActive,
      currentProfileId,
      params,
      hasBack,
      currentRoute: {
        title,
        subTitle,
      },
      toggleSidenav,
    } = this.props;

    const routeTitle = typeof title === 'function'
      ? title(params)
      : title;

    if (!title) return null;

    return (
      <Navbar
        onClick={this.handleOnClick}
        title={routeTitle}
        subTitle={subTitle}
        hasBack={hasBack}
        onSidenavToggle={toggleSidenav}
        isActive={isActive}
      >
        <NavbarPageActions
          appv2={appv2}
          params={params}
          currentProfileId={currentProfileId}
        />
      </Navbar>
    );
  }
}

export default withRouter(connect((state, { routes }) => {
  const depth = routes.length;
  const currentRoute = routes[depth - 1];

  return {
    isActive: state.ui.isBulkActive,
    currentProfileId: state.auth.profile_id,
    hasBack: depth > 3 && !currentRoute.isIndex,
    currentRoute,
  };
}, { toggleSidenav, toggleBulk, reset })(NavbarContainer));
